import { action, computed, makeObservable, observable } from 'mobx';

import { UniqueKeysRegistry } from '@mainApp/src/stores/types';

/* The main purpose of this data structure
 * is to dedupe data items as in the case of
 * paginated items we could have duplicates in BE response
 * so I decided to use unique key based registry and just
 * generate the values array based on it. Also it will pupulate the array on
 * every set/remove operation.
 *
 * If your T objects will contain an order property, it will sort the
 * valuesArray according it
 */

export class ArrayOrientedOrderedRegistryService<T> {
  registry: UniqueKeysRegistry<T> = {};
  valuesArray: T[] = [];

  get itemsLn() {
    return this.valuesArray.length;
  }

  constructor(initialRegistry: UniqueKeysRegistry<T> = {}) {
    makeObservable(this, {
      registry: observable,

      valuesArray: observable,

      addOrReplaceItem: action,

      addOrReplaceItems: action,

      removeItem: action,

      reset: action,

      itemsLn: computed,
    });

    this.registry = initialRegistry;
    this.valuesArray = this.generateOrderedValuesArray();
  }

  private generateOrderedValuesArray() {
    const orderedValues = Object.values(this.registry).sort((a, b) => {
      const orderA = (a as any).order ?? Infinity;
      const orderB = (b as any).order ?? Infinity;
      return orderA - orderB;
    });

    return orderedValues;
  }

  hasKey(key: string) {
    return !!this.registry[key];
  }

  generateRegistryFromObject(object: UniqueKeysRegistry<T>) {
    this.registry = object;
    this.valuesArray = this.generateOrderedValuesArray();
  }

  addOrReplaceItem(key: string, item: T) {
    this.registry[key] = item;
    this.valuesArray = this.generateOrderedValuesArray();
  }

  addOrReplaceItems(items: T[], keyExtractor: (item: T) => string) {
    items.forEach((item) => {
      this.registry[keyExtractor(item)] = item;
    });
    this.valuesArray = this.generateOrderedValuesArray();
  }

  addOnlyIfNotExists(key: string, item: T): boolean {
    let success = false;
    if (!this.registry[key]) {
      this.registry[key] = item;
      this.valuesArray = this.generateOrderedValuesArray();
      success = true;
    }
    return success;
  }

  removeItem(key: string) {
    delete this.registry[key];
    this.valuesArray = this.generateOrderedValuesArray();
  }

  generateClonedRegistry() {
    return { ...this.registry };
  }

  reset() {
    this.registry = {};
    this.valuesArray = [];
  }
}
