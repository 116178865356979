import { gql } from '@urql/core';

export const AccountUsernameFragment = gql`
  fragment AccountUsernameFragment on AccountUsernamePayload {
    id
    username
    status
    activatedAt
    isPro
  }
`;

export const CommonUserFragment = gql`
  fragment CommonUserFragment on User {
    id
    email
    bio
    firstName
    lastName
    locale
    website
    displayName
    username
    imageUrls
    online
    proUsername

    pro {
      ...AccountUsernameFragment
    }
  }
  ${AccountUsernameFragment}
`;

export const GenerateUserPreSignedPostUrlFragment = gql`
  fragment GenerateUserPreSignedPostUrlFragment on PreSignedPost {
    id
    url
    fields {
      key
      value
    }
  }
`;
export const UserSubscriptionFragment = gql`
  fragment UserSubscriptionFragment on AccountProUsernameSubscription {
    id
    createdAt
    updatedAt
    created {
      user
      when
    }
    updated {
      user
      when
    }
    deleted {
      user
      when
    }
    state
    ownerId
    interval
    subscriptionTypeSlug
    expiresAt
    cancelAtPeriodEnd
  }
`;
