import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import {
  MembersRoleList,
  MembersScrollableListWrapper,
  MembersUserList,
} from '@mainApp/src/modules/channel/common/members-list';
import { PermissionsSearch } from '@mainApp/src/modules/channel/common/permissions-components';
import { SearchEnum } from '@mainApp/src/stores/permissions';

import { PermissionsStore } from '../data/Permissions.store.local';

export function _MembersAdd() {
  const channelGroupSettingsStore = useInjection(
    IOC_TOKENS.channelGroupSettingsStore
  );

  const { t } = useTranslation(['common', 'channel']);

  const activeStore =
    channelGroupSettingsStore.activeChildScreen as PermissionsStore;

  const {
    addUser,
    removeUser,
    addRole,
    removeRole,
    isDirty,
    onViewRoleMembers,
    permissionsServiceStore,
  } = activeStore;

  const {
    memberItems,
    roleItems,
    setupData,
    fetchMoreCommunityMembers,
    setSearchCommunityMembers,
    [SearchEnum.COMMUNITY_MEMBERS]: communityMembersSearch,
  } = permissionsServiceStore;

  useEffect(() => {
    if (!activeStore) return;
    activeStore.setChannelGroupModel(
      channelGroupSettingsStore.channelGroupModel
    );
  }, [channelGroupSettingsStore.channelGroupModel]);

  useEffect(() => {
    setupData();
  }, []);

  return (
    <ScreenWrapper
      title={t('channel:modal.addMembersAndRoles')}
      sidebarModalController={channelGroupSettingsStore}
      showBackBtn={true}
    >
      <div className="flex flex-1 flex-col">
        <PermissionsSearch
          title={t('channel:modal.channelGroupVisibility')}
          onChange={setSearchCommunityMembers}
          value={communityMembersSearch.value}
          addMembersBtnText={t('channel:modal.addMembersAndRoles')}
          searchPlaceholder={t('channel:modal.findMembersToAdd')}
          isSearching={communityMembersSearch.loading}
        />
        <MembersScrollableListWrapper
          rootCn="md:max-h-none"
          onScrollEnd={fetchMoreCommunityMembers}
        >
          <MembersRoleList
            title={t<string>('channel:modal.roles')}
            titleCn="block text-left"
            data={roleItems.data}
            disableOnClick={isDirty}
            onAdd={addRole}
            onRemove={removeRole}
            onClick={(roleItem) => {
              onViewRoleMembers(roleItem);
            }}
          />

          <MembersUserList
            title={t<string>('channel:modal.members')}
            titleCn="block text-left"
            data={memberItems.data}
            onAdd={addUser}
            onRemove={removeUser}
          />
        </MembersScrollableListWrapper>
      </div>
    </ScreenWrapper>
  );
}

export const MembersAdd = withAnimatedStack(observer(_MembersAdd));
