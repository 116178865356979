import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Role_List_Types } from '@10x/foundation/types';
import { SmallLoader } from '@foundationPathAlias/components/loaders';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import {
  MembersRoleList,
  MembersScrollableListWrapper,
  MembersUserList,
} from '@mainApp/src/modules/channel/common/members-list';
import {
  NoAddedMembers,
  PermissionsSearch,
} from '@mainApp/src/modules/channel/common/permissions-components';
import { NoResult } from '@mainApp/src/modules/channel/common/permissions-components/NoResult';
import { SearchEnum } from '@mainApp/src/stores/permissions';

import { PermissionsStore } from '../data/Permissions.store.local';

import { ScreenIds } from '../constants';

export function _Visibility() {
  const { channelSettingsStore, communityStore } = useMultipleInjection([
    IOC_TOKENS.channelSettingsStore,
    IOC_TOKENS.communityStore,
  ]);

  const activeStore =
    channelSettingsStore.activeChildScreen as PermissionsStore;

  const {
    removeUser,
    removeRole,
    onViewRoleMembers,
    isInitializing,
    isDirty,
    permissionsServiceStore,
  } = activeStore;

  const { t } = useTranslation(['common', 'channel']);

  useEffect(() => {
    const communityId = activeStore.channelModel?.serverData.communityId;
    if (!communityId) {
      throw new Error('Community id is not defined');
    }
    communityStore.getCommunityMembers({
      communityId,
    });

    permissionsServiceStore.loadCommunityRoles(
      communityId,
      Role_List_Types.Community
    );
  }, []);

  if (isInitializing) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <SmallLoader height={24} width={24} />
      </div>
    );
  }

  if (!permissionsServiceStore.hasAddedAnyMember) {
    return (
      <NoAddedMembers
        title={t('channel:modal.channelVisibility')}
        onAddMembers={() => {
          activeStore.setNextScreenId(ScreenIds.MEMBERS_ADD);
        }}
      />
    );
  }

  const search = permissionsServiceStore[SearchEnum.SAVED_MEMBERS];
  const addedMembersReg = permissionsServiceStore.addedMembersReg;
  const isSearchNoResult =
    addedMembersReg.valuesArray.length === 0 && search.value && !search.loading;

  return (
    <div className="flex flex-1 flex-col">
      <PermissionsSearch
        title={t('channel:modal.channelVisibility')}
        onChange={permissionsServiceStore.setSearchSavedMembers}
        onAddMembers={() => {
          activeStore.setNextScreenId(ScreenIds.MEMBERS_ADD);
        }}
        value={search.value as string}
        addMembersBtnText={t('channel:modal.addMembersAndRoles')}
        searchPlaceholder={t('channel:modal.findMembersToAdd')}
        isSearching={search.loading}
        showAddMembersBtn={true}
        isDisabledAddMemberBtn={isDirty}
      />
      <div className="flex flex-1 ">
        <MembersScrollableListWrapper
          rootCn="md:max-h-none"
          contenttWrapperCn="md:absolute"
          onScrollEnd={() => {
            permissionsServiceStore.debouncedLoadMoreSavedMembers();
          }}
        >
          <MembersRoleList
            title={t<string>('channel:modal.roles')}
            titleCn="block text-left"
            data={permissionsServiceStore.addedRolesReg.valuesArray}
            loading={permissionsServiceStore.savedRoleMembers.loading}
            onRemove={removeRole}
            onClick={(roleItem) => {
              onViewRoleMembers(roleItem);
            }}
          />
          <MembersUserList
            title={t<string>('channel:modal.members')}
            titleCn="block text-left"
            data={addedMembersReg.valuesArray}
            loading={permissionsServiceStore.savedUserMembers.loading}
            onRemove={removeUser}
          />
          {isSearchNoResult && (
            <NoResult> {t<string>('common:noMembers')} </NoResult>
          )}
        </MembersScrollableListWrapper>
      </div>
    </div>
  );
}

export const Visibility = observer(_Visibility);
