import { classNames } from '@foundationPathAlias/utilities';
import { ArrowDownIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/outline';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { PricingSource } from '@mainApp/src/modules/pricing';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddedItemsType } from '../Pricing';
import { usePlanDetails } from '../section-2/data';

function _PlanCard({
  highlight = false,
  addedItems,
  setAddedItems,
  from,
}: {
  highlight?: boolean;
  setAddedItems: (items: AddedItemsType) => void;
  addedItems: AddedItemsType;
  from: PricingSource;
}) {
  const { t } = useTranslation(['pricing']);
  const planDetails = usePlanDetails();

  const plan = highlight ? planDetails.highlighted : planDetails.free;
  const { isMobile } = useInjection(IOC_TOKENS.dimensionsStore);
  const isFromRules = useMemo(() => from === PricingSource.RULES, [from]);

  const planStatus = useMemo(() => {
    const isAdded =
      addedItems.basePlan?.title === planDetails.highlighted.title &&
      plan.title === planDetails.highlighted.title;
    if (isAdded) {
      return t('pricing:plans.added');
    }
    const isCurrentPlan =
      addedItems.basePlan?.title === plan.title ||
      (!addedItems.basePlan && !highlight);
    if (isCurrentPlan) {
      return t('pricing:plans.currentPlan');
    }
    return t('pricing:plans.choosePlan');
  }, [addedItems.basePlan?.title, plan.title]);

  return (
    <div className="flex-shrink-0">
      <div
        className={
          isFromRules ? plan.styles.outerCardRules : plan.styles.outerCard
        }
      >
        {!isFromRules && (
          <div className="absolute left-0 right-0 top-[15px] text-center">
            <span className="text-body16SB text-background-primary">
              {plan.offer}
              {highlight && (
                <ArrowDownIcon className="background-primary mb-1 ml-1 inline-block h-4 w-4 align-middle" />
              )}
            </span>
          </div>
        )}

        <div
          className={
            isFromRules ? plan.styles.innerCardRules : plan.styles.innerCard
          }
        >
          <div className="flex flex-grow flex-col text-left">
            <div className="mb-[8px] flex items-center justify-between">
              <span className="themed-text text-miniHead">{plan.title}</span>
              {highlight && from === PricingSource.RULES && !isMobile && (
                <span className="bg-brand text-brand-text rounded-full px-3 py-1 text-sm dark:bg-background-ultra-dim-dark dark:text-primary-100-dark">
                  {t('pricing:plans.bestDeal')}
                </span>
              )}
            </div>
            <p className="themed-text mb-[12px] text-sm14R">
              {plan.description}
            </p>
            <div
              className={classNames(
                isFromRules ? 'mb-[23px]' : 'mb-[32px]',
                'themed-text text-subHeadL'
              )}
            >
              {plan.priceText}
            </div>

            <div
              className={classNames(
                isFromRules ? 'mt-[2px]' : 'mt-[32px]',
                'flex-grow'
              )}
            >
              <h3 className="themed-text mb-[16px] text-sm14SB">
                {t('pricing:plans.includes')}
              </h3>
              <ul className="space-y-[13px]">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <CheckIcon
                      height={16}
                      width={16}
                      fontSize={14}
                      className="text-text-primary dark:text-text-primary-dark"
                    />
                    <span className="themed-text ml-2 text-sm14R">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <button
              onClick={() => {
                if (setAddedItems && addedItems) {
                  if (plan.title === planDetails.highlighted.title) {
                    setAddedItems({
                      basePlan: {
                        title: plan.title,
                        price: plan.price,
                        features: plan.features,
                        allowQuantity: false,
                      },
                      aLaCartePlan: [...(addedItems.aLaCartePlan || [])],
                    });
                  } else if (
                    addedItems.aLaCartePlan &&
                    addedItems.aLaCartePlan.length === 0
                  ) {
                    setAddedItems({ basePlan: null, aLaCartePlan: null });
                  }
                }
              }}
              className={classNames(
                isFromRules ? plan.styles.buttonRules : plan.styles.button
              )}
            >
              {planStatus}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export const PlanCard = observer(_PlanCard);
