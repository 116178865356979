import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MasonryHeightGrid,
  MasonryHeightGridResponsiveWrapper,
} from '@10x/foundation/src/components/grids/masonry-height-grid';
import { Featured_Communities_Setting_Sort_Type } from '@10x/foundation/types';
import { CLOUDFLARE_VARIANTS_ENUM } from '@foundationPathAlias/utilities/getCloudflareSizeRecognition';

import { paths } from '@mainApp/src/config';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { SectionTitle } from './SectionTitle';

import { CommunityCard } from '@foundationPathAlias/components/community-card';
import { CommunityCardLoader } from '@mainApp/src/components/loaders/CommunityCardLoader';

const COMMUNITIES_COUNT = 8;

export function _FeaturedCommunitiesSection() {
  const {
    communityStore,
    systemStore: { isDarkTheme },
  } = useMultipleInjection([IOC_TOKENS.communityStore, IOC_TOKENS.systemStore]);

  const { t } = useTranslation(['common', 'create-community']);

  useEffect(() => {
    communityStore.getFeaturedCommunities(
      COMMUNITIES_COUNT,
      Featured_Communities_Setting_Sort_Type.Fixed
    );
  }, [communityStore]);

  const renderCommunityCards = () => {
    if (communityStore.featuredCommunities.loading) {
      return [...Array(COMMUNITIES_COUNT)].map((_community, index) => {
        return (
          <CommunityCardLoader key={`featured-communities-loader--${index}`} />
        );
      });
    }

    if (
      !communityStore.featuredCommunities.data ||
      communityStore.featuredCommunities.data.length === 0
    ) {
      return null;
    }

    return communityStore.featuredCommunities.data.map((community) => {
      const newPath = paths.getCommunityPath(community.serverData.slug);
      const imageSrc = community.getThumbnailUrl(
        CLOUDFLARE_VARIANTS_ENUM.MEDIUM
      );
      const logoSrc = community.getLogoUrl(CLOUDFLARE_VARIANTS_ENUM.MEDIUM);
      const membersCount = community.serverData.members.totalCount;
      const membersStr =
        membersCount > 0
          ? t('members', { count: membersCount })
          : t('noMembers');
      return (
        <a
          href={newPath}
          target="__blank"
          key={`a_${community.serverData.name}`}
        >
          <CommunityCard
            key={community.serverData.name}
            imageSrc={imageSrc}
            logoSrc={logoSrc}
            description={community.serverData.description}
            name={community.serverData.name}
            membersCount={membersCount}
            membersCountStr={membersStr}
            communityColor={
              isDarkTheme
                ? community.serverData.color.dark
                : community.serverData.color.light
            }
            topics={community.serverData.topics}
            cn="w-full h-[380px]"
          />
        </a>
      );
    });
  };

  return (
    <section className="relative min-h-[320px] px-[12px] md:px-[48px]">
      <SectionTitle title="Featured Communities" />
      <MasonryHeightGridResponsiveWrapper>
        <MasonryHeightGrid gutter={24}>
          {renderCommunityCards()}
        </MasonryHeightGrid>
      </MasonryHeightGridResponsiveWrapper>
    </section>
  );
}

export const FeaturedCommunitiesSection = observer(_FeaturedCommunitiesSection);
