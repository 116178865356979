import { action, computed, makeAutoObservable, observable } from 'mobx';

// TODO: DI
import {
  CLOUDFLARE_VARIANTS_ENUM,
  getCloudflareSizeRecognition,
} from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';
import { Account_Username_Statuses, User } from '@10x/foundation/types';

const userMock = {};

export type Role = {
  id: string;
  name: string;
};

export type DataFieldNames =
  | 'displayName'
  | 'bio'
  | 'website'
  | 'firstName'
  | 'lastName';

export class UserModel {
  serverData: User;

  displayName = '';
  firstName = '';
  lastName = '';
  bio = '';
  website = '';
  id = '';
  online = false;
  username = '';
  email = '';
  memberSince: Date | null;
  lastActive: Date | null;
  noOfReports: number;
  roles: Role[] = [];
  author: User | null = null;
  createdAt: Date | null = null;
  isModuleMember = false;

  static generateMockedChannelModel() {
    return new UserModel({ ...userMock });
  }

  get isActivePro() {
    const proObject = this.serverData?.pro;
    return (
      proObject?.isPro && proObject?.status === Account_Username_Statuses.Active
    );
  }

  get avatarUrl() {
    if (!this.serverData?.imageUrls?.length) {
      return undefined;
    }

    return getCloudflareSizeRecognition(this.serverData.imageUrls);
  }

  get standardOrProUsername() {
    return this.isActivePro
      ? this.serverData?.proUsername
      : this.serverData?.username;
  }

  constructor(data: any) {
    this.serverData = data;
    this.online = data?.online;

    if (data?.user) {
      this.serverData = data.user;
      this.username = data.user?.username;
      this.email = data.user?.email;
      this.displayName = data.user?.displayName;
      this.bio = data.user?.bio;
      this.website = data.user?.website;
      this.firstName = data.user?.firstName;
      this.lastName = data.user?.lastName;
      this.id = data.user?.id;
    } else {
      this.username = data?.username;
      this.email = data?.email;
      this.displayName = data?.displayName;
      this.bio = data?.bio;
      this.website = data?.website;
      this.firstName = data?.firstName;
      this.lastName = data?.lastName;
      this.id = data?.id;
    }

    this.memberSince = data?.memberSince ? new Date(data?.memberSince) : null;
    this.lastActive = data?.lastActive ? new Date(data?.lastActive) : null;
    this.noOfReports = data?.noOfReports;
    this.isModuleMember = data?.isModuleMember;
    this.roles = (data?.roles || []).map((role: any) => ({
      id: role.id,
      name: role.name,
    }));
    this.author = data?.author;

    if (data?.createdAt) {
      this.createdAt = new Date(data?.createdAt);
    }

    makeAutoObservable(this, {
      avatarUrl: computed,
      standardOrProUsername: computed,
      isActivePro: computed,
      serverData: observable,
      displayName: observable,
      firstName: observable,
      email: observable,
      lastName: observable,
      bio: observable,
      website: observable,
      online: observable,
      roles: observable,
      id: observable,
      username: observable,
      author: observable,
      createdAt: observable,
      setTextFields: action,
      setServerData: action,
    });
  }

  setServerData(data: Partial<User>) {
    this.serverData = {
      ...this.serverData,
      ...data,
    };
  }

  setTextFields(field: DataFieldNames, val: string) {
    this[field] = val;
  }

  reset() {
    this.displayName = this.serverData.displayName ?? '';
    this.bio = this.serverData.bio ?? '';
    this.website = this.serverData.website ?? '';
    this.firstName = this.serverData.firstName ?? '';
    this.lastName = this.serverData.lastName ?? '';
    this.roles = (this.serverData as any)?.roles
      ? (this.serverData as any)?.roles.map(({ id, name }: any) => ({
          id,
          name,
        }))
      : [];
  }

  // for the temporary storing in localStorage
  get asJson() {
    return {};
  }

  // for BE integration - create community
  get backendPayload() {
    return {
      ...this.serverData,
    };
  }

  getAvatar(prefferedSize?: CLOUDFLARE_VARIANTS_ENUM) {
    if (!this?.serverData) return;
    return getCloudflareSizeRecognition(
      this.serverData.imageUrls as string[],
      prefferedSize
    );
  }
}
