import { PricingSource } from '@mainApp/src/modules/pricing';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddedItemsType } from '../Pricing';
import { PlanCard } from './PlanCard';

export function _PricingSection1({
  setAddedItems,
  addedItems,
  from,
}: {
  setAddedItems: (items: AddedItemsType) => void;
  addedItems: AddedItemsType;
  from: PricingSource;
}) {
  const { t } = useTranslation(['pricing']);
  const isFromRules = useMemo(() => from === PricingSource.RULES, [from]);

  return (
    <div className="flex flex-col items-center justify-center">
      {!isFromRules && (
        <>
          <div className="mt-[48px] flex flex-col items-center justify-center text-center md:mt-[130px]">
            {/* Flex for larger screens, stacked for mobile */}
            <div className="flex flex-col items-center justify-center md:flex-row">
              {/* "Simple &" on top in mobile view */}
              <span className="grad-t-build text-subHeadingB md:text-h1">
                {t('pricing:plans.simple')} &&nbsp;
              </span>
              {/* "Flexible pricing" on the same line in mobile, inline for larger screens */}
              <div className="flex text-subHeadingB md:text-h1">
                <span className="grad-t-build">
                  {t('pricing:plans.flexible')}&nbsp;
                </span>
                <span>{t('pricing:plans.pricing')}</span>
              </div>
            </div>
          </div>

          {/* Subtitle */}
          <span className="themed-text mt-[24px] text-center text-body18R md:mt-[32px] md:text-subheading">
            {t('pricing:plans.selectBestPlan')}
          </span>
        </>
      )}

      {/* Plan Cards Section with custom gap */}
      <div
        className={`scrollbar-hide ${
          isFromRules ? 'mt-[24px]' : 'mt-[72px] md:mt-[96px]'
        } flex w-full justify-center overflow-x-auto px-[20px] md:flex-row`}
      >
        <div className="flex w-full flex-row gap-4 md:w-auto md:gap-8">
          {/* Highlighted card - Comes first on mobile, second on larger screens */}
          <div className="order-1 min-w-[306px] md:order-2 md:min-w-[427px]">
            <PlanCard
              highlight={true}
              addedItems={addedItems}
              setAddedItems={setAddedItems}
              from={from}
            />
          </div>
          {/* Non-highlighted card - Comes second on mobile, first on larger screens */}
          <div className="order-2 min-w-[306px] md:order-1 md:min-w-[427px]">
            <PlanCard
              addedItems={addedItems}
              setAddedItems={setAddedItems}
              from={from}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export const PricingSection1 = observer(_PricingSection1);
