import { classNames } from '@foundationPathAlias/utilities';

export type NoResultProps = {
  cn?: string;
  children: React.ReactNode;
};
export const NoResult = (props: NoResultProps) => {
  const { cn, children } = props;
  return (
    <b className={classNames('themed-text self-center text-body16SB', cn)}>
      {children}
    </b>
  );
};
