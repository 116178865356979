export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export enum Account_Username_Statuses {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type AccountProUsernameSubscription = {
  __typename?: 'AccountProUsernameSubscription';
  cancelAtPeriodEnd: Scalars['Boolean'];
  created?: Maybe<GqlDateObjectType>;
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  deleted?: Maybe<GqlDateObjectType>;
  expiresAt: Scalars['Float'];
  id: Scalars['ID'];
  interval: Scalars['String'];
  ownerId: Scalars['String'];
  state: Payment_Subscription_States;
  subscriptionTypeSlug: Scalars['String'];
  updated?: Maybe<GqlDateObjectType>;
  /** @deprecated use `updated` field instead. */
  updatedAt?: Maybe<Scalars['Float']>;
};

export type AccountProUsernameSubscriptionTypeInput = {
  description: Scalars['String'];
  items: Array<AccountProUsernameSubscriptionTypeItemInput>;
  label: Scalars['String'];
};

export type AccountProUsernameSubscriptionTypeItemExtInput = {
  discount?: InputMaybe<Scalars['Float']>;
  price: Scalars['Float'];
};

export type AccountProUsernameSubscriptionTypeItemInput = {
  description?: InputMaybe<Scalars['String']>;
  ext: AccountProUsernameSubscriptionTypeItemExtInput;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type AccountProUsernameTypeItemExtPayload = {
  __typename?: 'AccountProUsernameTypeItemExtPayload';
  discount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type AccountProUsernameTypeItemPayload = {
  __typename?: 'AccountProUsernameTypeItemPayload';
  authorId: Scalars['String'];
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  ext: AccountProUsernameTypeItemExtPayload;
  id: Scalars['ID'];
  label: Scalars['String'];
  order: Scalars['Int'];
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type AccountProUsernameTypePayload = {
  __typename?: 'AccountProUsernameTypePayload';
  authorId: Scalars['String'];
  created?: Maybe<GqlDateObjectType>;
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  deleted?: Maybe<GqlDateObjectType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  items: Array<AccountProUsernameTypeItemPayload>;
  label: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  updated?: Maybe<GqlDateObjectType>;
  /** @deprecated use `updated` field instead. */
  updatedAt?: Maybe<Scalars['Float']>;
};

export type AccountUsernamePayload = {
  __typename?: 'AccountUsernamePayload';
  activatedAt: Scalars['Float'];
  id: Scalars['String'];
  isPro: Scalars['Boolean'];
  status: Account_Username_Statuses;
  username: Scalars['String'];
};

export type Article = {
  __typename?: 'Article';
  authorId: Scalars['String'];
  children: Array<Scalars['String']>;
  commentsEnabled: Scalars['Boolean'];
  commentsThreadId?: Maybe<Scalars['String']>;
  communityId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  fields: Array<ArticleField>;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['String']>;
  imagePlaceholder?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  templateId: Scalars['String'];
};

export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  cursor: Scalars['String'];
  node: Article;
};

export type ArticleField = {
  __typename?: 'ArticleField';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AuthTestResponse = {
  __typename?: 'AuthTestResponse';
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type BlockedUsersConnectionPayload = {
  __typename?: 'BlockedUsersConnectionPayload';
  edges: Array<BlockedUsersPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BlockedUsersPayload = {
  __typename?: 'BlockedUsersPayload';
  author: User;
  authorId: Scalars['String'];
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user: User;
  userId: Scalars['String'];
};

export type BlockedUsersPayloadEdge = {
  __typename?: 'BlockedUsersPayloadEdge';
  cursor: Scalars['String'];
  node: BlockedUsersPayload;
};

export type BrandAssetsItemTypePayload = {
  __typename?: 'BrandAssetsItemTypePayload';
  authorId: Scalars['String'];
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  fileMetadata?: Maybe<FilesMetadataPayload>;
  id: Scalars['ID'];
  label: Scalars['String'];
  order: Scalars['Int'];
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type BrandAssetsPresignedPayload = {
  __typename?: 'BrandAssetsPresignedPayload';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type BrandAssetsTypeItemInput = {
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type BrandAssetsTypePayload = {
  __typename?: 'BrandAssetsTypePayload';
  authorId: Scalars['String'];
  created?: Maybe<GqlDateObjectType>;
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  deleted?: Maybe<GqlDateObjectType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  items: Array<TypeItemPayload>;
  label: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  updated?: Maybe<GqlDateObjectType>;
  /** @deprecated use `updated` field instead. */
  updatedAt?: Maybe<Scalars['Float']>;
};

export type BulkContentCreateInput = {
  channelId?: InputMaybe<Scalars['String']>;
  numberOfContent: Scalars['Int'];
};

export enum Channel_Content_Subscription_Events {
  Create = 'CREATE',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export enum Channel_Content_Types {
  Comment = 'COMMENT',
  Message = 'MESSAGE',
  PhotoContent = 'PHOTO_CONTENT'
}

export enum Channel_Group_Member_Action {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export enum Channel_Group_Member_Type {
  Role = 'ROLE',
  User = 'USER'
}

export enum Channel_Group_Types {
  Group = 'GROUP',
  Moderation = 'MODERATION',
  UngroupedChannel = 'UNGROUPED_CHANNEL'
}

export enum Channel_Member_Action {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export enum Channel_Member_Type {
  Role = 'ROLE',
  User = 'USER'
}

export enum Channel_Sort_Types {
  Hot = 'HOT',
  New = 'NEW'
}

export enum Channel_Types {
  Feeds = 'FEEDS',
  Messages = 'MESSAGES',
  Report = 'REPORT',
  ReportHistory = 'REPORT_HISTORY',
  Rules = 'RULES'
}

export enum Community_Connected_Account_States {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export enum Community_Connected_Price_Billing_Period {
  Annually = 'ANNUALLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum Community_Connected_Price_Currency {
  Aed = 'AED',
  Aud = 'AUD',
  Bgn = 'BGN',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Huf = 'HUF',
  Inr = 'INR',
  Jpy = 'JPY',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Ron = 'RON',
  Sek = 'SEK',
  Sgd = 'SGD',
  Usd = 'USD'
}

export enum Community_Connected_Price_Types {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING'
}

export enum Community_Content_Types {
  Photos = 'PHOTOS',
  Spaces = 'SPACES'
}

export enum Community_Custom_Domain_Types {
  Native = 'NATIVE',
  Squarespace = 'SQUARESPACE',
  Wordpress = 'WORDPRESS'
}

export enum Community_Explorer_Page_Settings_Status {
  Allowed = 'ALLOWED',
  Blocked = 'BLOCKED',
  Pending = 'PENDING'
}

export enum Community_Member_Module_Types {
  Channel = 'CHANNEL',
  ChannelGroup = 'CHANNEL_GROUP'
}

export enum Community_Role_Member_Invitation_States {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum Content_Rule_Type {
  Category = 'CATEGORY',
  Rule = 'RULE'
}

export enum Content_Types {
  Photo = 'PHOTO',
  Post = 'POST',
  Video = 'VIDEO'
}

export enum Context_Menu_Module_Type {
  ChannelMessage = 'CHANNEL_MESSAGE',
  Comment = 'COMMENT',
  PhotoContent = 'PHOTO_CONTENT',
  User = 'USER'
}

export enum Context_Menu_Type {
  CopyLink = 'COPY_LINK',
  CreateThread = 'CREATE_THREAD',
  Delete = 'DELETE',
  Edit = 'EDIT',
  MarkRead = 'MARK_READ',
  MarkUnread = 'MARK_UNREAD',
  Reply = 'REPLY',
  Report = 'REPORT'
}

export type ChannelAddMemberInput = {
  action: Channel_Member_Action;
  /** role or user id. */
  id: Scalars['String'];
  type: Channel_Member_Type;
};

export type ChannelContentSubscriptionPayload = {
  __typename?: 'ChannelContentSubscriptionPayload';
  contentType: Channel_Content_Types;
  event: Channel_Content_Subscription_Events;
  payload: ChannelContentsUnionPayload;
};

export type ChannelContentsUnionPayload = Comment | ContentBasePayload | MessageBasePayload;

export type ChannelCreateInput = {
  channelType: Channel_Types;
  members?: InputMaybe<Array<ChannelAddMemberInput>>;
  name: Scalars['String'];
  option: ChannelOptionInput;
  order?: InputMaybe<Scalars['Int']>;
  private: Scalars['Boolean'];
};

export type ChannelGroupAddMemberInput = {
  action: Channel_Group_Member_Action;
  /** role or user id. */
  id: Scalars['String'];
  type: Channel_Group_Member_Type;
};

export type ChannelGroupCreateInput = {
  members?: InputMaybe<Array<ChannelGroupAddMemberInput>>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  private: Scalars['Boolean'];
};

export type ChannelGroupPaginatedPayload = {
  __typename?: 'ChannelGroupPaginatedPayload';
  edges: Array<ChannelGroupPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ChannelGroupPayload = {
  __typename?: 'ChannelGroupPayload';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  channelGroupType: Channel_Group_Types;
  channels: ChannelPaginationPayload;
  communityId: Scalars['String'];
  createdAt: Scalars['Float'];
  defaulted: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationSettings?: Maybe<ModuleNotificationSettingsPayload>;
  order: Scalars['Int'];
  private: Scalars['Boolean'];
  /** roles member. */
  roles: CommunityRoleConnection;
  updatedAt: Scalars['Float'];
  /** users member. */
  users: UsersConnection;
};


export type ChannelGroupPayloadChannelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type ChannelGroupPayloadNotificationSettingsArgs = {
  customized?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type ChannelGroupPayloadRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ChannelGroupPayloadEdge = {
  __typename?: 'ChannelGroupPayloadEdge';
  cursor: Scalars['String'];
  node: ChannelGroupPayload;
};

export type ChannelGroupUpdateInput = {
  members?: InputMaybe<Array<ChannelGroupAddMemberInput>>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  private?: InputMaybe<Scalars['Boolean']>;
};

export type ChannelHeatOptionInput = {
  duration: Scalars['Int'];
  initialTemperature: Scalars['Int'];
};

export type ChannelHeatOptionPayload = {
  __typename?: 'ChannelHeatOptionPayload';
  duration: Scalars['Int'];
  initialTemperature: Scalars['Int'];
};

export type ChannelMessageAttachmentPayload = {
  __typename?: 'ChannelMessageAttachmentPayload';
  filename: Scalars['String'];
  id: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  urls?: Maybe<Array<Scalars['String']>>;
};

export type ChannelMessageConnectionPayload = {
  __typename?: 'ChannelMessageConnectionPayload';
  edges: Array<ChannelMessagePayloadEdge>;
  firstUnreadCursor?: Maybe<Scalars['String']>;
  lastReadAt: Scalars['Float'];
  latestCursor?: Maybe<Scalars['String']>;
  newMessagesCount: Scalars['Int'];
  /** @deprecated must use `firstUnreadCursor` instead. */
  newestMessageCursor?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ChannelMessageCreateInput = {
  attachmentsId?: InputMaybe<Array<Scalars['String']>>;
  parentId?: Scalars['String'];
  preCreatedId?: InputMaybe<Scalars['String']>;
  rawJson: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
  threadId?: Scalars['String'];
};

export type ChannelMessageEdgePayload = {
  __typename?: 'ChannelMessageEdgePayload';
  cursor: Scalars['String'];
  node: ChannelMessagePayload;
};

export type ChannelMessagePayload = {
  __typename?: 'ChannelMessagePayload';
  attachments: Array<ChannelMessageAttachmentPayload>;
  author?: Maybe<User>;
  authorId: Scalars['String'];
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  contentType: Message_Content_Types;
  contextMenu: Array<ContextMenuPayload>;
  createdAt: Scalars['Float'];
  deleted: Scalars['Boolean'];
  deletedAt: Scalars['Float'];
  edited: Scalars['Boolean'];
  id: Scalars['ID'];
  linkPreviews: Array<MarkupContentTextLinkPreviewPayload>;
  markupContent?: Maybe<MarkupContentTextPayload>;
  parent?: Maybe<ChannelMessageEdgePayload>;
  parentId?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  publishedAt: Scalars['Float'];
  rawJson?: Maybe<Scalars['String']>;
  reactions: Array<ContentReactionPayload>;
  read: Scalars['Boolean'];
  reports: ReportConnection;
  text?: Maybe<Scalars['String']>;
  thread?: Maybe<ContentThreadPayload>;
  threadId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
};


export type ChannelMessagePayloadReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ChannelMessagePayloadEdge = {
  __typename?: 'ChannelMessagePayloadEdge';
  cursor: Scalars['String'];
  node: ChannelMessagePayload;
};

export type ChannelMessageSubscriptionPayload = {
  __typename?: 'ChannelMessageSubscriptionPayload';
  action: Message_Action_Types;
  communityId: Scalars['String'];
  event: Message_Action_Types;
  payload: ChannelMessageEdgePayload;
};

export type ChannelMessageThreadInput = {
  messageId: Scalars['String'];
  title: Scalars['String'];
};

export type ChannelMessageUpdateInput = {
  attachmentsId?: InputMaybe<Array<Scalars['String']>>;
  rawJson: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
};

export type ChannelNotificationCountPayload = {
  __typename?: 'ChannelNotificationCountPayload';
  mentioned: Scalars['Int'];
  unread: Scalars['Int'];
};

export type ChannelOptionInput = {
  heatOption?: InputMaybe<ChannelHeatOptionInput>;
  sort: Channel_Sort_Types;
};

export type ChannelOptionPayload = {
  __typename?: 'ChannelOptionPayload';
  heatOption: ChannelHeatOptionPayload;
  sort: Channel_Sort_Types;
};

export type ChannelPaginationPayload = {
  __typename?: 'ChannelPaginationPayload';
  edges: Array<ChannelPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ChannelPayload = {
  __typename?: 'ChannelPayload';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  channelGroupId: Scalars['String'];
  channelType: Channel_Types;
  communityId: Scalars['String'];
  createdAt: Scalars['Float'];
  defaulted: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** new contents and new mentioned */
  notificationCounts: ChannelNotificationCountPayload;
  notificationSettings?: Maybe<ModuleNotificationSettingsPayload>;
  option: ChannelOptionPayload;
  order: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  private: Scalars['Boolean'];
  /** roles member. */
  roles: CommunityRoleConnection;
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
  /** users member. */
  users: UsersConnection;
};


export type ChannelPayloadNotificationSettingsArgs = {
  customized?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type ChannelPayloadRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ChannelPayloadEdge = {
  __typename?: 'ChannelPayloadEdge';
  cursor: Scalars['String'];
  node: ChannelPayload;
};

export type ChannelUpdateInput = {
  channelGroupId?: InputMaybe<Scalars['String']>;
  channelType?: InputMaybe<Channel_Types>;
  members?: InputMaybe<Array<ChannelAddMemberInput>>;
  name?: InputMaybe<Scalars['String']>;
  option?: InputMaybe<ChannelOptionInput>;
  order?: InputMaybe<Scalars['Int']>;
  private?: InputMaybe<Scalars['Boolean']>;
};

export type Comment = {
  __typename?: 'Comment';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  communityId?: Maybe<Scalars['String']>;
  /** flag to identity if the author of comment and the author of content is the same. */
  contentOwner: Scalars['Boolean'];
  contextMenu: Array<ContextMenuPayload>;
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  /** toggle to identify if the current user already liked the content */
  liked: Scalars['Boolean'];
  likes: Scalars['Float'];
  orderScore: Scalars['Float'];
  parent?: Maybe<Comment>;
  parentId?: Maybe<Scalars['String']>;
  /** comment where the user directly reply on. */
  repliedTo?: Maybe<Comment>;
  replies: CommentsConnection;
  reports: ReportConnection;
  text: Scalars['String'];
  threadId: Scalars['String'];
};


export type CommentRepliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type CommentReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type CommentDummyCreateInput = {
  count: Scalars['Int'];
  parentId?: InputMaybe<Scalars['String']>;
  repliedTo?: InputMaybe<Scalars['String']>;
  threadId: Scalars['String'];
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String'];
  node: Comment;
};

export type CommentsConnection = {
  __typename?: 'CommentsConnection';
  edges: Array<CommentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CommunitiesConnection = {
  __typename?: 'CommunitiesConnection';
  edges: Array<CommunityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Community = {
  __typename?: 'Community';
  /** @deprecated please use `description` field instead. */
  about?: Maybe<Scalars['String']>;
  admins: UsersConnection;
  blockedUsers: UsersConnection;
  /** total new message count for specific channel. */
  channelGroups?: Maybe<ChannelGroupPaginatedPayload>;
  color: CommunityColorPayload;
  currentMonthStats: StatsPoint;
  /** total new message count for specific channel. */
  defaultChannel?: Maybe<ChannelPayload>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  logoImageUrls: Array<Scalars['String']>;
  memberCounts: Scalars['Int'];
  members: UsersConnection;
  moderators: UsersConnection;
  name: Scalars['String'];
  /** total new message count for specific channel. */
  notificationCounts: CommunityNotificationCountPayload;
  notificationSettings?: Maybe<ModuleNotificationSettingsPayload>;
  numericId?: Maybe<Scalars['String']>;
  onlineMembers: UsersConnection;
  owner?: Maybe<User>;
  ownerId: Scalars['String'];
  reportedContentsCount: Scalars['Int'];
  scoreBasedFeed: Scalars['String'];
  settings?: Maybe<CommunitySettingsPayload>;
  slug: Scalars['String'];
  /** @deprecated please use `slug` field instead. */
  slugName?: Maybe<Scalars['String']>;
  /** @deprecated use `numericId` instead. */
  snippetButtonId?: Maybe<Scalars['String']>;
  stats: Array<StatsPoint>;
  thumbnailImageUrls?: Maybe<Array<Scalars['String']>>;
  timeBasedFeed: Scalars['String'];
  topics: Array<Topic>;
  userAssignedRoles: Array<Role_Levels>;
};


export type CommunityAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type CommunityBlockedUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type CommunityChannelGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type CommunityMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type CommunityModeratorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type CommunityNotificationSettingsArgs = {
  customized?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type CommunityOnlineMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type CommunityColorInput = {
  dark: CommunityColorPropertiesInput;
  light: CommunityColorPropertiesInput;
};

export type CommunityColorPayload = {
  __typename?: 'CommunityColorPayload';
  dark: CommunityColorPropertiesPayload;
  light: CommunityColorPropertiesPayload;
};

export type CommunityColorPropertiesInput = {
  /** alpha/opacity */
  a?: InputMaybe<Scalars['Float']>;
  /** blue */
  b: Scalars['Int'];
  /** green */
  g: Scalars['Int'];
  /** red */
  r: Scalars['Int'];
};

export type CommunityColorPropertiesPayload = {
  __typename?: 'CommunityColorPropertiesPayload';
  /** alpha/opacity */
  a: Scalars['Float'];
  /** blue */
  b: Scalars['Int'];
  /** green */
  g: Scalars['Int'];
  /** red */
  r: Scalars['Int'];
};

export type CommunityConnectedPriceInput = {
  amount: Scalars['Float'];
  communityConnectedProductId: Scalars['String'];
  currency: Community_Connected_Price_Currency;
  label?: InputMaybe<Scalars['String']>;
  recurring?: InputMaybe<CommunityConnectedPriceRecurringInput>;
  type?: Community_Connected_Price_Types;
};

export type CommunityConnectedPricePayload = {
  __typename?: 'CommunityConnectedPricePayload';
  archived?: Maybe<Scalars['Boolean']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  authorId: Scalars['String'];
  communityConnectedAccountId: Scalars['String'];
  communityConnectedProductId: Scalars['String'];
  communityId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  stripePrice?: Maybe<StripePricePayload>;
  stripePriceId: Scalars['String'];
  totalSubscriptors: Scalars['Int'];
};

export type CommunityConnectedPriceRecurringInput = {
  interval: Community_Connected_Price_Billing_Period;
};

export type CommunityConnectedPriceRecurringPayload = {
  __typename?: 'CommunityConnectedPriceRecurringPayload';
  interval: Community_Connected_Price_Billing_Period;
};

export type CommunityConnectedProductInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CommunityConnectedProductPayload = {
  __typename?: 'CommunityConnectedProductPayload';
  authorId: Scalars['String'];
  communityConnectedAccountId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['ID'];
  stripeProduct: StripeProductPayload;
  stripeProductId: Scalars['String'];
};

export type CommunityContentInput = {
  contentType: Community_Content_Types;
  status: Scalars['Boolean'];
};

export type CommunityContentPayload = {
  __typename?: 'CommunityContentPayload';
  authorId: Scalars['String'];
  communityId: Scalars['String'];
  contentType: Community_Content_Types;
  id: Scalars['ID'];
  status: Scalars['Boolean'];
};

export type CommunityCustomDomainInput = {
  type: Community_Custom_Domain_Types;
  value: Scalars['String'];
};

export type CommunityCustomDomainPayload = {
  __typename?: 'CommunityCustomDomainPayload';
  activated: Scalars['Boolean'];
  author?: Maybe<User>;
  authorId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['ID'];
  type: Community_Custom_Domain_Types;
  value: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type CommunityDeleteOptions = {
  eraseAllData: Scalars['Boolean'];
};

export type CommunityEdge = {
  __typename?: 'CommunityEdge';
  cursor: Scalars['String'];
  node: Community;
};

export type CommunityExplorePageSettingsAdminReviewStatusInput = {
  status: Community_Explorer_Page_Settings_Status;
};

export type CommunityExplorePageSettingsPayload = {
  __typename?: 'CommunityExplorePageSettingsPayload';
  adminReviewStatus: Community_Explorer_Page_Settings_Status;
  status: Scalars['Boolean'];
};

export type CommunityExplorePageSettingsStatusInput = {
  status: Scalars['Boolean'];
};

export type CommunityLauncherSettingsInput = {
  positionType?: InputMaybe<Launcher_Settings_Position_Types>;
  spacing?: InputMaybe<CommunityLauncherSpacingSettingsInput>;
  theme?: InputMaybe<Launcher_Settings_Themes>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type CommunityLauncherSettingsPayload = {
  __typename?: 'CommunityLauncherSettingsPayload';
  id: Scalars['ID'];
  positionType: Scalars['String'];
  spacing: CommunityLauncherSpacingSettingsPayload;
  theme: Launcher_Settings_Themes;
  visible: Scalars['Boolean'];
};

export type CommunityLauncherSpacingSettingsInput = {
  bottom?: InputMaybe<Scalars['Int']>;
  side?: InputMaybe<Scalars['Int']>;
};

export type CommunityLauncherSpacingSettingsPayload = {
  __typename?: 'CommunityLauncherSpacingSettingsPayload';
  bottom?: Maybe<Scalars['Int']>;
  side?: Maybe<Scalars['Int']>;
};

export type CommunityMemberPayload = {
  __typename?: 'CommunityMemberPayload';
  bio?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  deactivatedAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** is only available if the moduleId is provided in the query. this is to check if the community member has an access or a member of the provided moduleId. */
  isModuleMember?: Maybe<Scalars['Boolean']>;
  lastActive: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  memberSince: Scalars['Float'];
  noOfReports: Scalars['Int'];
  notificationSettings?: Maybe<ModuleNotificationSettingsBasePayload>;
  /** @deprecated no longer needed. will remove in the future */
  numericId?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
  permissions: Array<ModulePermissionPayload>;
  pro?: Maybe<AccountUsernamePayload>;
  proUsername?: Maybe<Scalars['String']>;
  roles: Array<CommunityRolePayload>;
  scoreBasedFeed: Scalars['String'];
  status: Scalars['String'];
  timeBasedFeed: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type CommunityMemberPayloadNotificationSettingsArgs = {
  customized?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type CommunityMemberPayloadPermissionsArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  moduleId?: InputMaybe<Scalars['String']>;
  permissionGroupModuleType?: InputMaybe<Array<Permission_Group_Module_Types>>;
  values?: InputMaybe<Array<Permission_Values>>;
};

export type CommunityMemberPayloadEdge = {
  __typename?: 'CommunityMemberPayloadEdge';
  cursor: Scalars['String'];
  node: CommunityMemberPayload;
};

export type CommunityMembersConnection = {
  __typename?: 'CommunityMembersConnection';
  edges: Array<CommunityMemberPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CommunityMembersListModuleOption = {
  /** moduleId to identify if the community member has an access or a member of the module. */
  id: Scalars['String'];
  type: Community_Member_Module_Types;
};

export type CommunityNotificationCountPayload = {
  __typename?: 'CommunityNotificationCountPayload';
  mentioned: Scalars['Int'];
  unread: Scalars['Int'];
};

export type CommunityOnlineMemberObject = {
  __typename?: 'CommunityOnlineMemberObject';
  communityId: Scalars['String'];
  payload: CommunityOnlineMemberPayload;
};

export type CommunityOnlineMemberPayload = {
  __typename?: 'CommunityOnlineMemberPayload';
  member: User;
  onlineMemberCount: Scalars['Int'];
};

export type CommunityRoleConnection = {
  __typename?: 'CommunityRoleConnection';
  edges: Array<CommunityRolePayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CommunityRoleInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type CommunityRoleListOption = {
  /** include inactive roles */
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  listType: Role_List_Types;
  /** filter by the role levels */
  roleLevels?: InputMaybe<Array<Role_Levels>>;
};

export type CommunityRoleMemberInvitationPayload = {
  __typename?: 'CommunityRoleMemberInvitationPayload';
  communityId: Scalars['String'];
  id: Scalars['ID'];
  roleId: Scalars['String'];
  spaceId: Scalars['String'];
  state: Scalars['String'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type CommunityRolePayload = {
  __typename?: 'CommunityRolePayload';
  authorId: Scalars['String'];
  communityId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  iconImageUrls?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  level: Scalars['String'];
  members: UsersConnection;
  name: Scalars['String'];
  order: Scalars['Int'];
  permissions: Array<ModulePermissionPayload>;
  totalMemberCount: Scalars['Int'];
};


export type CommunityRolePayloadMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  showPlatformBlockedUsers?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type CommunityRolePayloadPermissionsArgs = {
  moduleId?: InputMaybe<Scalars['String']>;
  permissionGroupModuleType?: InputMaybe<Array<Permission_Group_Module_Types>>;
  values?: InputMaybe<Array<Permission_Values>>;
};

export type CommunityRolePayloadEdge = {
  __typename?: 'CommunityRolePayloadEdge';
  cursor: Scalars['String'];
  node: CommunityRolePayload;
};

export type CommunityRolesMemberConnectionPayload = {
  __typename?: 'CommunityRolesMemberConnectionPayload';
  edges: Array<CommunityRolesMemberPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CommunityRolesMemberPayload = {
  __typename?: 'CommunityRolesMemberPayload';
  communityId: Scalars['String'];
  created?: Maybe<GqlDateObjectType>;
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  deleted?: Maybe<GqlDateObjectType>;
  gatedRoleId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  roleId: Scalars['String'];
  spaceId?: Maybe<Scalars['String']>;
  updated?: Maybe<GqlDateObjectType>;
  /** @deprecated use `updated` field instead. */
  updatedAt?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type CommunityRolesMemberPayloadEdge = {
  __typename?: 'CommunityRolesMemberPayloadEdge';
  cursor: Scalars['String'];
  node: CommunityRolesMemberPayload;
};

export type CommunitySettingsPayload = {
  __typename?: 'CommunitySettingsPayload';
  _v?: Maybe<Scalars['Int']>;
  created?: Maybe<GqlDateObjectType>;
  deleted?: Maybe<GqlDateObjectType>;
  explorePage: CommunityExplorePageSettingsPayload;
  id: Scalars['ID'];
  launcher: CommunityLauncherSettingsPayload;
  updated?: Maybe<GqlDateObjectType>;
};

export type CommunitySnippetButtonIdPayload = {
  __typename?: 'CommunitySnippetButtonIdPayload';
  snippetButtonId: Scalars['String'];
};

export type ConnectedAccountPayload = {
  __typename?: 'ConnectedAccountPayload';
  authorId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['ID'];
  state: Community_Connected_Account_States;
  stripeAccountId: Scalars['String'];
};

export type ContentBasePayload = {
  __typename?: 'ContentBasePayload';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  basedScore: Scalars['Float'];
  channelId: Scalars['String'];
  commentEnabled: Scalars['Boolean'];
  commentThreadId: Scalars['String'];
  comments: CommentsConnection;
  communityId: Scalars['String'];
  contextMenu: Array<ContextMenuPayload>;
  createdAt: Scalars['Float'];
  date: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  imagePlaceholder?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** toggle to identify if the current user already liked the content */
  liked: Scalars['Boolean'];
  likes: Scalars['Float'];
  reports: ReportConnection;
  topics: Array<Scalars['String']>;
  type: Content_Types;
};


export type ContentBasePayloadCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type ContentBasePayloadReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ContentBasePayloadEdge = {
  __typename?: 'ContentBasePayloadEdge';
  cursor: Scalars['String'];
  node: ContentBasePayload;
};

export type ContentCreateInput = {
  commentEnabled?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['String'];
  imagePlaceholder: Scalars['String'];
  topics?: InputMaybe<Array<Scalars['String']>>;
};

export type ContentDummyCreateInput = {
  authorId: Scalars['String'];
  commentEnabled?: InputMaybe<Scalars['Boolean']>;
  createdDate: Scalars['DateTime'];
  description: Scalars['String'];
  imagePlaceholder: Scalars['String'];
  topics?: InputMaybe<Array<Scalars['String']>>;
};

export type ContentPaginationPayload = {
  __typename?: 'ContentPaginationPayload';
  edges: Array<ContentBasePayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ContentReactionInput = {
  activeSkinTone?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
  unified: Scalars['String'];
  unifiedWithoutSkinTone?: InputMaybe<Scalars['String']>;
};

export type ContentReactionPayload = {
  __typename?: 'ContentReactionPayload';
  activeSkinTone: Scalars['String'];
  count: Scalars['Int'];
  id: Scalars['ID'];
  /** flag to check if the user is already reacted for the specific content reaction. */
  reacted: Scalars['Boolean'];
  unified: Scalars['String'];
  users: UsersConnection;
};


export type ContentReactionPayloadUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ContentRuleActiveStatusDataInput = {
  status: Scalars['Boolean'];
};

export type ContentRuleInput = {
  html?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  rawJSON?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ContentRuleListOption = {
  includeInactive?: InputMaybe<Scalars['Boolean']>;
};

export type ContentRuleParentInput = {
  order?: InputMaybe<Scalars['Int']>;
  parent: Scalars['String'];
};

export type ContentRulePayload = {
  __typename?: 'ContentRulePayload';
  _v?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  author?: Maybe<User>;
  communityId?: Maybe<Scalars['String']>;
  created?: Maybe<GqlDateObjectType>;
  deleted?: Maybe<GqlDateObjectType>;
  html: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  rawJSON: Scalars['String'];
  title: Scalars['String'];
  type: Content_Rule_Type;
  updated?: Maybe<GqlDateObjectType>;
};

export type ContentThreadPayload = {
  __typename?: 'ContentThreadPayload';
  authorId: Scalars['String'];
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['Float'];
};

export type ContentUpdateInput = {
  commentEnabled?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  imagePlaceholder?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<Array<Scalars['String']>>;
};

export type ContextMenuDataInput = {
  label: Scalars['String'];
  module: Context_Menu_Module_Type;
  option: ContextMenuDataOptionInput;
  orderNo?: InputMaybe<Scalars['Int']>;
  type: Context_Menu_Type;
};

export type ContextMenuDataOptionInput = {
  hideUnderMoreButton?: InputMaybe<Scalars['Boolean']>;
  showToAuthorContentOnly?: InputMaybe<Scalars['Boolean']>;
};

export type ContextMenuOptionPayload = {
  __typename?: 'ContextMenuOptionPayload';
  hideUnderMoreButton: Scalars['Boolean'];
  showToAuthorContentOnly: Scalars['Boolean'];
};

export type ContextMenuPayload = {
  __typename?: 'ContextMenuPayload';
  authorId: Scalars['String'];
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  label: Scalars['String'];
  module: Context_Menu_Module_Type;
  option: ContextMenuOptionPayload;
  orderNo: Scalars['Int'];
  type: Context_Menu_Type;
  updatedAt: Scalars['Float'];
};

export type ContextMenuUpdateDataInput = {
  label?: InputMaybe<Scalars['String']>;
  module: Context_Menu_Module_Type;
  option: ContextMenuDataOptionInput;
  orderNo?: InputMaybe<Scalars['Int']>;
  type: Context_Menu_Type;
};

export type CreateCommentInput = {
  parentId?: InputMaybe<Scalars['String']>;
  repliedTo?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  threadId: Scalars['String'];
};

export type CreateCommunityInput = {
  color: CommunityColorInput;
  description: Scalars['String'];
  /** id property from the presigned url response. */
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** id property from the presigned url response. */
  thumbnail?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<Array<Scalars['String']>>;
};

export type DefaultPermissionTypesPayload = {
  __typename?: 'DefaultPermissionTypesPayload';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type DefaultPermissionValuesPayload = {
  __typename?: 'DefaultPermissionValuesPayload';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type DeleteUserImageProfileInput = {
  method: Graphql_Delete_Methods;
};

export type DummyMessagePayload = {
  __typename?: 'DummyMessagePayload';
  id: Scalars['String'];
  text: Scalars['String'];
};

export type DynamicNavigationCreateDataInput = {
  index?: InputMaybe<Scalars['Float']>;
  label: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  route: Scalars['String'];
};

export type DynamicNavigationPayload = {
  __typename?: 'DynamicNavigationPayload';
  authorId: Scalars['String'];
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  index: Scalars['Float'];
  label: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  permissions: Array<ModulePermissionPayload>;
  route: Scalars['String'];
  subNavigations: Array<DynamicNavigationPayload>;
  updatedAt: Scalars['Float'];
};


export type DynamicNavigationPayloadPermissionsArgs = {
  roleId: Scalars['String'];
  values?: InputMaybe<Array<Permission_Values>>;
};

export type DynamicNavigationUpdateDataInput = {
  index?: InputMaybe<Scalars['Float']>;
  label?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Scalars['String']>;
};

export type ExampleContentRuleInput = {
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ExampleContentRulePayload = {
  __typename?: 'ExampleContentRulePayload';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  order: Scalars['Int'];
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum Featured_Communities_Setting_Sort_Type {
  Fixed = 'FIXED',
  Random = 'RANDOM'
}

export type FeaturedCommunitiesSettingDataInput = {
  sortType: Featured_Communities_Setting_Sort_Type;
};

export type FeaturedCommunitiesSettingPayload = {
  __typename?: 'FeaturedCommunitiesSettingPayload';
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  sortType: Featured_Communities_Setting_Sort_Type;
  updatedAt: Scalars['Float'];
};

export type FeaturedCommunityDataInput = {
  communityId: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type FeaturedCommunityPayload = {
  __typename?: 'FeaturedCommunityPayload';
  authorId: Scalars['String'];
  community?: Maybe<Community>;
  communityId: Scalars['String'];
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  updatedAt: Scalars['Float'];
};

export type FeaturedCommunityUpdateDataInput = {
  order: Scalars['Int'];
};

export type FileMetadataPayload = {
  __typename?: 'FileMetadataPayload';
  filename: Scalars['String'];
  filepath: Scalars['String'];
  id: Scalars['String'];
  imageId: Scalars['String'];
  imageUrls: Array<Scalars['String']>;
  mimeType: Scalars['String'];
  referenceId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uploaded: Scalars['Boolean'];
};

export type FilesMetadataPayload = {
  __typename?: 'FilesMetadataPayload';
  filename: Scalars['String'];
  filepath: Scalars['String'];
  imageId: Scalars['String'];
  mimeType: Scalars['String'];
  referenceId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uploaded: Scalars['Boolean'];
};

export enum Gated_Content_Module_Types {
  All = 'ALL',
  Spaces = 'SPACES',
  Videos = 'VIDEOS'
}

export enum Gated_Content_Types {
  Article = 'ARTICLE',
  Space = 'SPACE',
  Video = 'VIDEO'
}

export enum Gated_Role_Types {
  NftContract = 'NFT_CONTRACT',
  Subscription = 'SUBSCRIPTION'
}

export type GqlDateObjectType = {
  __typename?: 'GQLDateObjectType';
  user: Scalars['String'];
  when: Scalars['Float'];
};

export enum Graphql_Delete_Methods {
  HardDelete = 'HARD_DELETE',
  SoftDelete = 'SOFT_DELETE'
}

export type GatedContentInput = {
  contentType: Gated_Content_Module_Types;
  roleId: Scalars['String'];
};

export type GatedContentItemInput = {
  contentId: Scalars['String'];
  contentType: Gated_Content_Types;
  roleId: Scalars['String'];
};

export type GatedContentPayload = {
  __typename?: 'GatedContentPayload';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  communityId: Scalars['String'];
  content?: Maybe<GatedContentUnionPayload>;
  contentId?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  id: Scalars['ID'];
  role: CommunityRolePayload;
  roleId: Scalars['String'];
};

export type GatedContentUnionPayload = Article;

export type GatedRoleInput = {
  referenceId: Scalars['String'];
  roleId: Scalars['String'];
  type: Gated_Role_Types;
};

export type GatedRolePayload = {
  __typename?: 'GatedRolePayload';
  authorId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['ID'];
  referenceId: Scalars['String'];
  role?: Maybe<CommunityRolePayload>;
  roleId: Scalars['String'];
  type: Gated_Role_Types;
};

export enum Launcher_Settings_Position_Types {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export enum Launcher_Settings_Themes {
  Dark = 'DARK',
  Light = 'LIGHT'
}

export enum Markup_Content_Text_Mention_Types {
  Channel = 'CHANNEL',
  Role = 'ROLE',
  User = 'USER'
}

export enum Message_Action_Types {
  Created = 'CREATED',
  Deleted = 'DELETED',
  DeleteAttachment = 'DELETE_ATTACHMENT',
  Updated = 'UPDATED'
}

export enum Message_Content_Types {
  Regular = 'REGULAR',
  Thread = 'THREAD'
}

export enum Module_Notification_Preference_Modules {
  Channel = 'CHANNEL',
  ChannelGroup = 'CHANNEL_GROUP',
  Community = 'COMMUNITY',
  User = 'USER'
}

export enum Module_Notification_Preference_Values {
  CommunityDefaults = 'COMMUNITY_DEFAULTS',
  Custom = 'CUSTOM',
  UserPreferences = 'USER_PREFERENCES'
}

export enum Module_Notification_Setting_Mention_Options {
  ExcludeEveryoneMention = 'EXCLUDE_EVERYONE_MENTION',
  ExcludeRoleMention = 'EXCLUDE_ROLE_MENTION'
}

export enum Module_Notification_Setting_Modules {
  Channel = 'CHANNEL',
  ChannelGroup = 'CHANNEL_GROUP',
  Community = 'COMMUNITY',
  User = 'USER'
}

export enum Module_Notification_Setting_Options {
  Mentions = 'MENTIONS',
  MessageReply = 'MESSAGE_REPLY',
  NewComment = 'NEW_COMMENT',
  SyncToChildModules = 'SYNC_TO_CHILD_MODULES'
}

export enum Module_Notification_Setting_Values {
  AllMessages = 'ALL_MESSAGES',
  Limited = 'LIMITED',
  Nothing = 'NOTHING'
}

export enum Module_Permission_Options {
  DefaultStatus = 'DEFAULT_STATUS',
  DisallowOverride = 'DISALLOW_OVERRIDE',
  Enabled = 'ENABLED',
  VisibleOnCap = 'VISIBLE_ON_CAP'
}

export enum Module_Permission_Reference_Types {
  Role = 'ROLE',
  User = 'USER'
}

export type MarkupContentMentionsUnion = ChannelPayload | CommunityRolePayload | User;

export type MarkupContentTextLinkPreviewPayload = {
  __typename?: 'MarkupContentTextLinkPreviewPayload';
  deleted: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  mediaType: Scalars['String'];
  siteName: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type MarkupContentTextMentionPayload = {
  __typename?: 'MarkupContentTextMentionPayload';
  id: Scalars['ID'];
  node?: Maybe<MarkupContentMentionsUnion>;
  type: Markup_Content_Text_Mention_Types;
};

export type MarkupContentTextPayload = {
  __typename?: 'MarkupContentTextPayload';
  contentId: Scalars['String'];
  contentText: Scalars['String'];
  id: Scalars['ID'];
  linkPreviews: Array<MarkupContentTextLinkPreviewPayload>;
  markupText: Scalars['String'];
  mentions: Array<MarkupContentTextMentionPayload>;
};

export type MessageBasePayload = {
  __typename?: 'MessageBasePayload';
  authorId: Scalars['String'];
  contentType: Message_Content_Types;
  createdAt: Scalars['Float'];
  deleted: Scalars['Boolean'];
  deletedAt: Scalars['Float'];
  edited: Scalars['Boolean'];
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  publishedAt: Scalars['Float'];
  read: Scalars['Boolean'];
  /** @deprecated use `rawJson` instead. */
  text: Scalars['String'];
  threadId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
};

export type MessageBasePayloadEdge = {
  __typename?: 'MessageBasePayloadEdge';
  cursor: Scalars['String'];
  node: MessageBasePayload;
};

export type MessageCreateDummyOptionInput = {
  count?: InputMaybe<Scalars['Int']>;
};

export type MessageRunIntervalCreateDummyOptionInput = {
  chancePercentage?: InputMaybe<Scalars['Int']>;
};

export type ModuleNotificationPreferencesInput = {
  communityId?: InputMaybe<Scalars['String']>;
  module?: InputMaybe<Module_Notification_Preference_Modules>;
  moduleId?: InputMaybe<Scalars['String']>;
  value: Module_Notification_Preference_Values;
};

export type ModuleNotificationPreferencesPayload = {
  __typename?: 'ModuleNotificationPreferencesPayload';
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  module: Module_Notification_Preference_Modules;
  moduleId: Scalars['String'];
  ownerId: Scalars['String'];
  updatedAt: Scalars['Float'];
  value: Module_Notification_Preference_Values;
};

export type ModuleNotificationSettingsBasePayload = {
  __typename?: 'ModuleNotificationSettingsBasePayload';
  createdAt: Scalars['Float'];
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  mentionOptions?: Maybe<Array<Module_Notification_Setting_Mention_Options>>;
  module?: Maybe<Module_Notification_Setting_Modules>;
  moduleId?: Maybe<Scalars['String']>;
  options: Array<Module_Notification_Setting_Options>;
  ownerId: Scalars['String'];
  updatedAt: Scalars['Float'];
  value: Module_Notification_Setting_Values;
};

export type ModuleNotificationSettingsInput = {
  customized?: InputMaybe<Scalars['Boolean']>;
  mentionOptions?: InputMaybe<Array<Module_Notification_Setting_Mention_Options>>;
  module: Module_Notification_Setting_Modules;
  moduleId: Scalars['String'];
  options?: InputMaybe<Array<Module_Notification_Setting_Options>>;
  ownerId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Module_Notification_Setting_Values>;
};

export type ModuleNotificationSettingsPayload = {
  __typename?: 'ModuleNotificationSettingsPayload';
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  mentionOptions?: Maybe<Array<Module_Notification_Setting_Mention_Options>>;
  module?: Maybe<Module_Notification_Setting_Modules>;
  moduleId?: Maybe<Scalars['String']>;
  options: Array<Module_Notification_Setting_Options>;
  ownerId: Scalars['String'];
  updatedAt: Scalars['Float'];
  value: Module_Notification_Setting_Values;
};

export type ModuleNotificationSettingsUpdateInput = {
  mentionOptions?: InputMaybe<Array<Module_Notification_Setting_Mention_Options>>;
  options?: InputMaybe<Array<Module_Notification_Setting_Options>>;
  value: Module_Notification_Setting_Values;
};

export type ModuleOnboardingStepPayload = {
  __typename?: 'ModuleOnboardingStepPayload';
  completedAt: Scalars['Float'];
  skipped: Scalars['Boolean'];
  status: Scalars['Boolean'];
  step: Scalars['Float'];
  userId?: Maybe<Scalars['String']>;
};

export type ModulePermissionBulkInput = {
  permissionGroupModuleType: Permission_Group_Module_Types;
  permissions: Array<ModulePermissionInputBase>;
};

export type ModulePermissionInputBase = {
  status: Scalars['Boolean'];
  value: Permission_Values;
};

export type ModulePermissionPayload = {
  __typename?: 'ModulePermissionPayload';
  communityId?: Maybe<Scalars['String']>;
  created?: Maybe<GqlDateObjectType>;
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  deleted?: Maybe<GqlDateObjectType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  moduleId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  options: Array<Module_Permission_Options>;
  permissionGroupModuleType: Permission_Group_Module_Types;
  permissionGroupName?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updated?: Maybe<GqlDateObjectType>;
  /** @deprecated use `updated` field instead. */
  updatedAt?: Maybe<Scalars['Float']>;
  value: Permission_Values;
};

export type ModuleRolePermissionBulkInput = {
  data: Array<ModulePermissionBulkInput>;
  roleId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptRoleMemberInvitation: CommunityRoleMemberInvitationPayload;
  activateUserAccount: User;
  addCoHostToCommunitySpace: CommunityRolesMemberPayload;
  addCommunityAdmin: Scalars['Boolean'];
  addCommunityModerator: Scalars['Boolean'];
  /** Add individual user on the community role. */
  addCommunityRoleMember: CommunityRolesMemberPayload;
  /**
   * Add user to the community role member by role level.
   * @deprecated will caused some issue for community custom role.
   */
  addCommunityRoleMemberByRoleLevel: CommunityRolesMemberPayload;
  /** Add multiple community roles to the member. */
  addCommunityRolesToMember: CommunityRolesMemberPayload;
  /** add topic to the community. */
  addCommunityTopic: Community;
  addFeaturedCommunity: FeaturedCommunityPayload;
  addListenerToCommunitySpace: CommunityRolesMemberPayload;
  addParentTopics: Topic;
  addSpeakerToCommunitySpace: CommunityRolesMemberPayload;
  addTypeItem?: Maybe<TypePayload>;
  blockUser: BlockedUsersPayload;
  cancelRoleMemberInvitation: CommunityRoleMemberInvitationPayload;
  cancelUserChangeEmailRequest: UserChangeEmailRequestType;
  channelGroupMarkAllChannelsAsRead: Scalars['Boolean'];
  channelMessageCreateAttachmentPresignedUrl: PreSignedPost;
  channelMessageCreateBatchAttachmentPresignedUrl: Array<PreSignedPost>;
  channelMessageMarkAllAsRead: Scalars['Boolean'];
  channelMessageMarkAsUnread: Scalars['Boolean'];
  channelMessageRemoveAttachment?: Maybe<ChannelMessagePayload>;
  channelMessageRemoveAttachments: ChannelMessagePayload;
  communityKickMember: Scalars['Boolean'];
  /** it will complete the specific step. */
  completeUserOnboarding: UserOnboardingPayload;
  /** it will complete the specific step. */
  completeUserOnboardingAllSteps: UserOnboardingPayload;
  /** it will complete the specific step. */
  completeUserOnboardingStep: UserOnboardingPayload;
  createBrandAssetsType: BrandAssetsTypePayload;
  createBrandAssetsTypeItem: BrandAssetsItemTypePayload;
  createBucket: Scalars['Boolean'];
  createChannel: ChannelPayload;
  createChannelGroup: ChannelGroupPayload;
  createChannelMessage: ChannelMessagePayload;
  createChannelMessageThread: ContentThreadPayload;
  createComment: Comment;
  /** Create community */
  createCommunity: Community;
  createCommunityConnectedPrice: CommunityConnectedPricePayload;
  createCommunityConnectedProduct: Scalars['String'];
  createCommunityContent: CommunityContentPayload;
  createCommunityCustomDomain: CommunityCustomDomainPayload;
  createCommunityPaymentAccount: Scalars['String'];
  createCommunityRole: CommunityRolePayload;
  createCommunityRule: ContentRulePayload;
  createCommunitySettings: CommunitySettingsPayload;
  createCommunitySpace: SpacesPayload;
  createDummyChannelMessages: Scalars['Boolean'];
  createDummyComments: Array<Comment>;
  createDummyPhotoContent: ContentBasePayload;
  createDummyPhotoContents: Scalars['Boolean'];
  createDynamicNavigation: DynamicNavigationPayload;
  createExampleContentRule: ExampleContentRulePayload;
  createGatedContent: GatedContentPayload;
  createGatedContentItem: GatedContentPayload;
  createGatedRole: GatedRolePayload;
  createNFTGatedAccess: NftGatedAccessPayload;
  /** for development purposes only. will remove in the future. */
  createNonProUsername: AccountUsernamePayload;
  createPermission: PermissionPayload;
  createPermissionGroup: PermissionGroupPayload;
  createPermissions: Scalars['Boolean'];
  createPhotoContent: ContentBasePayload;
  createPlatformContextMenu: ContextMenuPayload;
  createPlatformRole: PlatformRolePayload;
  createPlatformRule: PlatformContentRulePayload;
  createPriceSubscription: Scalars['String'];
  createProUserSubscription: Scalars['String'];
  createProUsername: AccountUsernamePayload;
  createReportOnComment: ReportPayload;
  createReportOnMessage: ReportPayload;
  createReportOnPhotoContent: ReportPayload;
  createReportViolatedRule: ReportedContentViolatedRulesPayload;
  createReportViolatedRules: Array<ReportedContentViolatedRulesPayload>;
  createRoleMemberInvitation: Array<CommunityRoleMemberInvitationPayload>;
  createShopifyIntegration: ShopifyIntegrationPayload;
  createTestUser?: Maybe<AuthTestResponse>;
  createTopic: Topic;
  createType: TypePayload;
  createUserAPIKey: UserApiKeyCreatePayload;
  /** this is for dev purposes only. user onboarding should be created after user being created. */
  createUserOnboarding: UserOnboardingPayload;
  createUserWalletAddress: UserWalletAddressPayload;
  deactivateProUserSubscription: AccountProUsernameSubscription;
  deactivateUserAccount: User;
  deleteBrandAssetsTypeItem: BrandAssetsItemTypePayload;
  deleteChannelMessage: ChannelMessagePayload;
  deleteComment: Comment;
  /** delete community. */
  deleteCommunity: Community;
  deleteCommunityRule: ContentRulePayload;
  /** remove topic to the community. */
  deleteCommunityTopic: Community;
  deleteExampleContentRule: ExampleContentRulePayload;
  deletePhotoContent: ContentBasePayload;
  deletePlatformRule: PlatformContentRulePayload;
  /** only admin route. */
  deleteUser: User;
  deleteUserAccount: User;
  /** retool admin mutation. */
  deleteUserProfileImage?: Maybe<FileMetadataPayload>;
  generateBrandAssetPresignedUrl: BrandAssetsPresignedPayload;
  generateCommunityLogoImagePreSignedPostUrl: PreSignedPost;
  generateCommunityRoleIconPreSignedUrl: PreSignedPost;
  generateCommunityThumbnailImagePreSignedPostUrl: PreSignedPost;
  generateDefaultPlatformRoles: Scalars['Boolean'];
  generateDefaultTypes: Scalars['Boolean'];
  generatePhotoContentImagePreSignedPostUrl: PreSignedPost;
  generatePlatformRoleIconPreSignedPostUrl: PreSignedPost;
  generateTopicImagePreSignedPostUrl: PreSignedPost;
  generateUserPreSignedPostUrl: PreSignedPost;
  joinCommunity: Community;
  joinCommunitySpace: CommunityRolesMemberPayload;
  kickToCommunitySpace: CommunityRolesMemberPayload;
  leaveCommunity: Community;
  leaveCommunitySpace: Scalars['Boolean'];
  likeComment: Comment;
  likePhotoContent: ContentBasePayload;
  /** Mark all user notification new status as false. */
  markNotificationAsRead: Scalars['Boolean'];
  /** Mark all user notification new status as false. */
  markNotificationsAsRead: Scalars['Boolean'];
  /** Mark all user notification as seen. */
  markNotificationsAsSeen: Scalars['Boolean'];
  oAuthExchangeCode: OAuthPayload;
  oAuthLogout: OAuthPayload;
  otpGenerate: Scalars['String'];
  otpVerify: Scalars['Boolean'];
  platformBlockUser: BlockedUsersPayload;
  platformUnblockUser: BlockedUsersPayload;
  populateDefaultPlatformRolePermissions: Scalars['Boolean'];
  populateDefaultPlatformRolesPermissions: Scalars['Boolean'];
  preCreateChannelMessage: ChannelMessagePayload;
  preUpdateUserEmail: UserChangeEmailRequestType;
  reactivateProUserSubscription: AccountProUsernameSubscription;
  rejectRoleMemberInvitation: CommunityRoleMemberInvitationPayload;
  removeChannel: ChannelPayload;
  removeChannelGroup: ChannelGroupPayload;
  removeChannelGroupNotificationSettings?: Maybe<ModuleNotificationSettingsPayload>;
  removeCoHostToCommunitySpace: Scalars['Boolean'];
  removeCommunityAdmin: Scalars['Boolean'];
  removeCommunityConnectedPrice: CommunityConnectedPricePayload;
  removeCommunityCustomDomain: CommunityCustomDomainPayload;
  removeCommunityLogoImage?: Maybe<FileMetadataPayload>;
  removeCommunityModerator: Scalars['Boolean'];
  removeCommunityNotificationSettings?: Maybe<ModuleNotificationSettingsPayload>;
  removeCommunityPaymentMethod: UserPaymentMethodsPayload;
  removeCommunityRole: CommunityRolePayload;
  removeCommunityRoleIcon?: Maybe<FileMetadataPayload>;
  removeCommunityRoleMember: CommunityRolesMemberPayload;
  /** Remove multiple community roles to the member. */
  removeCommunityRolesToMember: Array<CommunityRolesMemberPayload>;
  removeCommunitySpace: SpacesPayload;
  removeCommunityThumbnailImage?: Maybe<FileMetadataPayload>;
  removeContextMenu: ContextMenuPayload;
  removeDynamicNavigation?: Maybe<DynamicNavigationPayload>;
  removeDynamicNavigationPermission: ModulePermissionPayload;
  removeFeaturedCommunity?: Maybe<FeaturedCommunityPayload>;
  removeGatedContent: GatedContentPayload;
  removeGatedRole: GatedRolePayload;
  removeListenerToCommunitySpace: Scalars['Boolean'];
  removeModuleNotificationSetting?: Maybe<ModuleNotificationSettingsPayload>;
  removeNFTGatedAccess: NftGatedAccessPayload;
  removeParentTopics: Topic;
  removePermission: PermissionPayload;
  removePermissionGroup: PermissionGroupPayload;
  removePhotoContentImage?: Maybe<FileMetadataPayload>;
  removePlatformContextMenu: ContextMenuPayload;
  removePlatformRole: PlatformRolePayload;
  removePlatformRoleIcon?: Maybe<FileMetadataPayload>;
  removeReportViolatedRule: ReportedContentViolatedRulesPayload;
  removeShopifyIntegration: ShopifyIntegrationPayload;
  removeShopifyIntegrationByShopDomainName: ShopifyIntegrationPayload;
  removeSpeakerToCommunitySpace: Scalars['Boolean'];
  removeTopic: Topic;
  removeTopicImage?: Maybe<FileMetadataPayload>;
  removeTypeItem?: Maybe<TypePayload>;
  removeUserAPIKey: UserApiKeyPayload;
  removeUserImageUrl?: Maybe<FileMetadataPayload>;
  removeUserNotificationSettings?: Maybe<ModuleNotificationSettingsBasePayload>;
  removeUserWalletAddress: UserWalletAddressPayload;
  reportedContentCreateViolatedRule: ReportedContentViolatedRulesPayload;
  reportedContentCreateViolatedRules: Array<ReportedContentViolatedRulesPayload>;
  reportedContentRemoveViolatedRule: ReportedContentViolatedRulesPayload;
  /**
   *
   * Message about violation was right.
   * Flagged Entities must implement reaction to resolving flag.
   * Reaction must be paired on frontend with this mutation.
   *
   */
  reportedContentUpdateStatus: ReportPayload;
  resendUserChangeEmailRequest: UserChangeEmailRequestType;
  restoreComment: Comment;
  runCreateDummyChannelMessagesIntervally: Scalars['Boolean'];
  saveAccountProUsernameSubscriptionTypes: AccountProUsernameTypePayload;
  saveBrandAssetsType: BrandAssetsItemTypePayload;
  saveChannelGroupNotificationSettings: ModuleNotificationSettingsPayload;
  saveChannelMessageReaction: Scalars['Boolean'];
  saveCommunityNotificationSettings: ModuleNotificationSettingsPayload;
  saveFeaturedCommunitiesSettings: FeaturedCommunitiesSettingPayload;
  saveModuleNotificationPreferences: ModuleNotificationPreferencesPayload;
  saveModuleNotificationSettings: ModuleNotificationSettingsPayload;
  saveProUsername: AccountUsernamePayload;
  saveUserNotificationSettings: ModuleNotificationSettingsBasePayload;
  sendDeactivateUserAccountEmail: Scalars['Boolean'];
  sendDeleteUserAccountEmail: Scalars['Boolean'];
  signInViaEmail: Scalars['String'];
  signUpViaEmail: Scalars['Boolean'];
  transferHostCommunitySpace: CommunityRolesMemberPayload;
  unblockUser: BlockedUsersPayload;
  updateBrandAssetsTypeItem: BrandAssetsItemTypePayload;
  updateChannel: ChannelPayload;
  updateChannelGroup: ChannelGroupPayload;
  updateChannelGroupMembers: Scalars['Boolean'];
  updateChannelGroupsOrder: Scalars['Boolean'];
  updateChannelMembers: Scalars['Boolean'];
  updateChannelMessage: ChannelMessagePayload;
  updateChannelPrivateStatus: ChannelPayload;
  /** update all of the existing channels data that doesn't have slug value. for development purposes only. will remove in the production stage. */
  updateChannelSlugOfExistingChannels?: Maybe<Scalars['Boolean']>;
  updateChannelsOrder: Scalars['Boolean'];
  updateCommentText: Comment;
  updateCommunityConnectedPrice: CommunityConnectedPricePayload;
  updateCommunityConnectedPriceArchiveStatus: CommunityConnectedPricePayload;
  updateCommunityContentStatus: CommunityContentPayload;
  updateCommunityCustomDomain: CommunityCustomDomainPayload;
  updateCommunityCustomDomainActivationStatus: CommunityCustomDomainPayload;
  /** Update community default channel. */
  updateCommunityDefaultChannel: Community;
  updateCommunityExplorePageAdminReviewStatus: CommunityExplorePageSettingsPayload;
  updateCommunityExplorePageSettingsStatus: CommunityExplorePageSettingsPayload;
  updateCommunityLauncherSettings: CommunityLauncherSettingsPayload;
  updateCommunityLogoImageUploadedStatus?: Maybe<FileMetadataPayload>;
  /** Update community profile details. */
  updateCommunityProfile?: Maybe<Community>;
  updateCommunityRole: CommunityRolePayload;
  updateCommunityRoleIconUploadedStatus?: Maybe<FileMetadataPayload>;
  updateCommunityRolePermissions: Array<ModulePermissionPayload>;
  updateCommunityRule: ContentRulePayload;
  updateCommunityRuleActiveStatus: ContentRulePayload;
  /** @deprecated Use `updateCommunityRuleOrder` instead. */
  updateCommunityRuleIndexNo: ContentRulePayload;
  updateCommunityRuleOrder: ContentRulePayload;
  updateCommunityRulesSort: Array<ContentRulePayload>;
  updateCommunitySpace: SpacesPayload;
  updateCommunityThumbnailImageUploadedStatus?: Maybe<FileMetadataPayload>;
  /** update the existing topics of the community. */
  updateCommunityTopics: Community;
  updateContextMenu: ContextMenuPayload;
  updateDynamicNavigation: DynamicNavigationPayload;
  updateDynamicNavigationIndexes: Array<DynamicNavigationPayload>;
  updateDynamicNavigationPermissions: Array<ModulePermissionPayload>;
  updateExampleContentRule: ExampleContentRulePayload;
  updateExampleContentRuleIndexNo: ExampleContentRulePayload;
  updateExampleContentRulesOrder: Array<ExampleContentRulePayload>;
  updateFeaturedCommunity?: Maybe<FeaturedCommunityPayload>;
  updatePermission?: Maybe<PermissionPayload>;
  updatePermissionGroup: PermissionGroupPayload;
  updatePermissionGroupOrderNo: Array<PermissionGroupPayload>;
  updatePermissions?: Maybe<PermissionPayload>;
  updatePhotoContent: ContentBasePayload;
  updatePhotoContentImageUploadedStatus?: Maybe<FileMetadataPayload>;
  updatePlatformContextMenu: ContextMenuPayload;
  updatePlatformContextMenuOrderNo: Array<ContextMenuPayload>;
  updatePlatformRole: PlatformRolePayload;
  updatePlatformRoleActiveStatus: PlatformRolePayload;
  updatePlatformRoleIconUploadedStatus?: Maybe<FileMetadataPayload>;
  updatePlatformRolePermissions: Array<ModulePermissionPayload>;
  updatePlatformRolesOrder: Array<PlatformRolePayload>;
  updatePlatformRule: PlatformContentRulePayload;
  updatePlatformRuleActiveStatus: PlatformContentRulePayload;
  updatePlatformRuleIndexNo: PlatformContentRulePayload;
  updatePlatformRuleSortOrder: PlatformContentRulePayload;
  updatePlatformRulesOrder: Scalars['Boolean'];
  updateProUsername: AccountUsernamePayload;
  /**
   *
   * Message about violation was right.
   * Flagged Entities must implement reaction to resolving flag.
   * Reaction must be paired on frontend with this mutation.
   *
   */
  updateReportedContentHideStatus: ReportPayload;
  /**
   *
   * Message about violation was right.
   * Flagged Entities must implement reaction to resolving flag.
   * Reaction must be paired on frontend with this mutation.
   *
   */
  updateReportedContentStatus: ReportPayload;
  updateShopifyIntegration: ShopifyIntegrationPayload;
  updateShopifyIntegrationByShopDomainName: ShopifyIntegrationPayload;
  updateTopic: Topic;
  updateTopicImageUploadedStatus?: Maybe<FileMetadataPayload>;
  updateType: TypePayload;
  updateTypeItem?: Maybe<TypePayload>;
  updateUser: User;
  updateUserEmail: User;
  updateUserGeneralSettings: UserGeneralSettingsResponse;
  updateUserImageAsUploaded?: Maybe<FileMetadataPayload>;
  updateUserNotificationPreferences: UserNotificationPreferencesResponse;
  updateUserProfile: User;
  updateUserStatus: User;
  updateUsername: AccountUsernamePayload;
  uploadTopicsAsCSV: Scalars['Boolean'];
  validateProUsername: Scalars['Boolean'];
  validateUserEmail: Scalars['Boolean'];
  validateUserWallet: Scalars['Boolean'];
  verifySignIn: AuthTestResponse;
  verifySignUp: AuthTestResponse;
};


export type MutationAcceptRoleMemberInvitationArgs = {
  communityId: Scalars['String'];
  invitationId: Scalars['String'];
  spaceId: Scalars['String'];
};


export type MutationAddCoHostToCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddCommunityAdminArgs = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddCommunityModeratorArgs = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddCommunityRoleMemberArgs = {
  communityId: Scalars['String'];
  roleId: Scalars['String'];
  spaceId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationAddCommunityRoleMemberByRoleLevelArgs = {
  communityId: Scalars['String'];
  level: Role_Levels;
  spaceId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationAddCommunityRolesToMemberArgs = {
  communityId: Scalars['String'];
  roleIds: Array<Scalars['String']>;
  spaceId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationAddCommunityTopicArgs = {
  communityId: Scalars['String'];
  topicId: Scalars['String'];
};


export type MutationAddFeaturedCommunityArgs = {
  data: FeaturedCommunityDataInput;
};


export type MutationAddListenerToCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddParentTopicsArgs = {
  topicId: Scalars['String'];
  topics: Array<Scalars['String']>;
};


export type MutationAddSpeakerToCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddTypeItemArgs = {
  data: TypeItemInput;
  id: Scalars['String'];
};


export type MutationBlockUserArgs = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCancelRoleMemberInvitationArgs = {
  communityId: Scalars['String'];
  invitationId: Scalars['String'];
  spaceId: Scalars['String'];
};


export type MutationCancelUserChangeEmailRequestArgs = {
  id: Scalars['String'];
};


export type MutationChannelGroupMarkAllChannelsAsReadArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationChannelMessageCreateAttachmentPresignedUrlArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationChannelMessageCreateBatchAttachmentPresignedUrlArgs = {
  communityId: Scalars['String'];
  size: Scalars['Int'];
};


export type MutationChannelMessageMarkAllAsReadArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  option?: InputMaybe<ReadContentMarkReadInput>;
};


export type MutationChannelMessageMarkAsUnreadArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  data: ReadContentMarkUnreadInput;
};


export type MutationChannelMessageRemoveAttachmentArgs = {
  attachmentId: Scalars['String'];
  communityId: Scalars['String'];
  messageId: Scalars['String'];
};


export type MutationChannelMessageRemoveAttachmentsArgs = {
  attachmentsId: Array<Scalars['String']>;
  communityId: Scalars['String'];
  messageId: Scalars['String'];
};


export type MutationCommunityKickMemberArgs = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCompleteUserOnboardingStepArgs = {
  data: UserOnboardingDataInput;
};


export type MutationCreateBrandAssetsTypeItemArgs = {
  data: BrandAssetsTypeItemInput;
};


export type MutationCreateBucketArgs = {
  name: Scalars['String'];
};


export type MutationCreateChannelArgs = {
  channelGroupId: Scalars['String'];
  communityId: Scalars['String'];
  data: ChannelCreateInput;
};


export type MutationCreateChannelGroupArgs = {
  communityId: Scalars['String'];
  data: ChannelGroupCreateInput;
};


export type MutationCreateChannelMessageArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  data: ChannelMessageCreateInput;
};


export type MutationCreateChannelMessageThreadArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  data: ChannelMessageThreadInput;
};


export type MutationCreateCommentArgs = {
  communityId: Scalars['String'];
  data: CreateCommentInput;
};


export type MutationCreateCommunityArgs = {
  data: CreateCommunityInput;
};


export type MutationCreateCommunityConnectedPriceArgs = {
  communityId: Scalars['String'];
  data: CommunityConnectedPriceInput;
};


export type MutationCreateCommunityConnectedProductArgs = {
  communityId: Scalars['String'];
  data: CommunityConnectedProductInput;
};


export type MutationCreateCommunityContentArgs = {
  communityId: Scalars['String'];
  data: CommunityContentInput;
};


export type MutationCreateCommunityCustomDomainArgs = {
  communityId: Scalars['String'];
  data: CommunityCustomDomainInput;
};


export type MutationCreateCommunityPaymentAccountArgs = {
  communityId: Scalars['String'];
};


export type MutationCreateCommunityRoleArgs = {
  communityId: Scalars['String'];
  data: CommunityRoleInput;
};


export type MutationCreateCommunityRuleArgs = {
  communityId: Scalars['String'];
  data: ContentRuleInput;
};


export type MutationCreateCommunitySettingsArgs = {
  communityId: Scalars['String'];
};


export type MutationCreateCommunitySpaceArgs = {
  communityId: Scalars['String'];
  data: SpacesInput;
};


export type MutationCreateDummyChannelMessagesArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  option?: InputMaybe<MessageCreateDummyOptionInput>;
};


export type MutationCreateDummyCommentsArgs = {
  communityId: Scalars['String'];
  data: CommentDummyCreateInput;
};


export type MutationCreateDummyPhotoContentArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  data: ContentDummyCreateInput;
};


export type MutationCreateDummyPhotoContentsArgs = {
  communityId: Scalars['String'];
  data: BulkContentCreateInput;
};


export type MutationCreateDynamicNavigationArgs = {
  data: DynamicNavigationCreateDataInput;
};


export type MutationCreateExampleContentRuleArgs = {
  data: ExampleContentRuleInput;
};


export type MutationCreateGatedContentArgs = {
  communityId: Scalars['String'];
  data: GatedContentInput;
};


export type MutationCreateGatedContentItemArgs = {
  communityId: Scalars['String'];
  data: GatedContentItemInput;
};


export type MutationCreateGatedRoleArgs = {
  communityId: Scalars['String'];
  data: GatedRoleInput;
};


export type MutationCreateNftGatedAccessArgs = {
  communityId: Scalars['String'];
  data: NftGatedAccessInput;
};


export type MutationCreateNonProUsernameArgs = {
  username: Scalars['String'];
};


export type MutationCreatePermissionArgs = {
  data: PermissionInput;
  permissionGroup: Permission_Group_Module_Types;
};


export type MutationCreatePermissionGroupArgs = {
  data: PermissionGroupInput;
};


export type MutationCreatePermissionsArgs = {
  data: Array<PermissionInput>;
  permissionGroup: Permission_Group_Module_Types;
};


export type MutationCreatePhotoContentArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  data: ContentCreateInput;
};


export type MutationCreatePlatformContextMenuArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  data: ContextMenuDataInput;
};


export type MutationCreatePlatformRoleArgs = {
  data: PlatformRoleInput;
};


export type MutationCreatePlatformRuleArgs = {
  data: PlatformContentRuleDataInput;
};


export type MutationCreateProUserSubscriptionArgs = {
  priceId: Scalars['String'];
};


export type MutationCreateProUsernameArgs = {
  username: Scalars['String'];
};


export type MutationCreateReportOnCommentArgs = {
  communityId: Scalars['String'];
  contentId: Scalars['String'];
  rulesId: Array<Scalars['String']>;
};


export type MutationCreateReportOnMessageArgs = {
  communityId: Scalars['String'];
  contentId: Scalars['String'];
  rulesId: Array<Scalars['String']>;
};


export type MutationCreateReportOnPhotoContentArgs = {
  communityId: Scalars['String'];
  contentId: Scalars['String'];
  rulesId: Array<Scalars['String']>;
};


export type MutationCreateReportViolatedRuleArgs = {
  communityId: Scalars['String'];
  reportId: Scalars['String'];
  ruleId: Scalars['String'];
};


export type MutationCreateReportViolatedRulesArgs = {
  communityId: Scalars['String'];
  reportId: Scalars['String'];
  rulesId: Array<Scalars['String']>;
};


export type MutationCreateRoleMemberInvitationArgs = {
  communityId: Scalars['String'];
  roleId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateShopifyIntegrationArgs = {
  data: ShopifyIntegrationCreateInput;
};


export type MutationCreateTestUserArgs = {
  asApp?: InputMaybe<Scalars['Boolean']>;
  data?: InputMaybe<UserTestCreateInput>;
};


export type MutationCreateTopicArgs = {
  data: TopicInput;
};


export type MutationCreateTypeArgs = {
  data: TypeInput;
};


export type MutationCreateUserApiKeyArgs = {
  data: UserApiKeyInput;
};


export type MutationCreateUserWalletAddressArgs = {
  walletAddress: Scalars['String'];
};


export type MutationDeactivateUserAccountArgs = {
  data: UserOtpInput;
};


export type MutationDeleteBrandAssetsTypeItemArgs = {
  id: Scalars['String'];
};


export type MutationDeleteChannelMessageArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteCommentArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteCommunityArgs = {
  id: Scalars['String'];
  option?: InputMaybe<CommunityDeleteOptions>;
};


export type MutationDeleteCommunityRuleArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteCommunityTopicArgs = {
  communityId: Scalars['String'];
  topicId: Scalars['String'];
};


export type MutationDeleteExampleContentRuleArgs = {
  id: Scalars['String'];
};


export type MutationDeletePhotoContentArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeletePlatformRuleArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  data: UserDeleteInput;
  id: Scalars['String'];
};


export type MutationDeleteUserAccountArgs = {
  data: UserOtpInput;
};


export type MutationDeleteUserProfileImageArgs = {
  data: DeleteUserImageProfileInput;
  userId: Scalars['String'];
};


export type MutationGenerateBrandAssetPresignedUrlArgs = {
  fileType: Scalars['String'];
};


export type MutationGenerateCommunityLogoImagePreSignedPostUrlArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationGenerateCommunityRoleIconPreSignedUrlArgs = {
  communityId: Scalars['String'];
  communityRoleId: Scalars['String'];
};


export type MutationGenerateCommunityThumbnailImagePreSignedPostUrlArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationGenerateDefaultTypesArgs = {
  slug?: InputMaybe<Type_Module_Slug>;
};


export type MutationGeneratePhotoContentImagePreSignedPostUrlArgs = {
  id: Scalars['String'];
};


export type MutationGeneratePlatformRoleIconPreSignedPostUrlArgs = {
  platformRoleId: Scalars['String'];
};


export type MutationGenerateTopicImagePreSignedPostUrlArgs = {
  id: Scalars['String'];
};


export type MutationJoinCommunityArgs = {
  communityId: Scalars['String'];
};


export type MutationJoinCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
};


export type MutationKickToCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationLeaveCommunityArgs = {
  communityId: Scalars['String'];
};


export type MutationLeaveCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
};


export type MutationLikeCommentArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationLikePhotoContentArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationMarkNotificationAsReadArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  notificationId: Scalars['String'];
};


export type MutationMarkNotificationsAsReadArgs = {
  communityId?: InputMaybe<Scalars['String']>;
};


export type MutationOAuthExchangeCodeArgs = {
  code: Scalars['String'];
};


export type MutationOtpGenerateArgs = {
  referenceId: Scalars['String'];
};


export type MutationOtpVerifyArgs = {
  referenceId: Scalars['String'];
  value: Scalars['String'];
};


export type MutationPlatformBlockUserArgs = {
  userId: Scalars['String'];
};


export type MutationPlatformUnblockUserArgs = {
  userId: Scalars['String'];
};


export type MutationPopulateDefaultPlatformRolePermissionsArgs = {
  id: Scalars['String'];
};


export type MutationPreCreateChannelMessageArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
};


export type MutationPreUpdateUserEmailArgs = {
  data: PreUpdateUserEmailInput;
};


export type MutationRejectRoleMemberInvitationArgs = {
  communityId: Scalars['String'];
  invitationId: Scalars['String'];
  spaceId: Scalars['String'];
};


export type MutationRemoveChannelArgs = {
  channelGroupId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveChannelGroupArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveChannelGroupNotificationSettingsArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveCoHostToCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveCommunityAdminArgs = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveCommunityConnectedPriceArgs = {
  communityId: Scalars['String'];
  priceId: Scalars['String'];
};


export type MutationRemoveCommunityCustomDomainArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveCommunityLogoImageArgs = {
  communityId: Scalars['String'];
};


export type MutationRemoveCommunityModeratorArgs = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveCommunityNotificationSettingsArgs = {
  id: Scalars['String'];
};


export type MutationRemoveCommunityPaymentMethodArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveCommunityRoleArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveCommunityRoleIconArgs = {
  communityId: Scalars['String'];
  communityRoleId: Scalars['String'];
};


export type MutationRemoveCommunityRoleMemberArgs = {
  communityId: Scalars['String'];
  roleId: Scalars['String'];
  spaceId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationRemoveCommunityRolesToMemberArgs = {
  communityId: Scalars['String'];
  roleIds: Array<Scalars['String']>;
  spaceId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationRemoveCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
};


export type MutationRemoveCommunityThumbnailImageArgs = {
  communityId: Scalars['String'];
};


export type MutationRemoveContextMenuArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveDynamicNavigationArgs = {
  id: Scalars['String'];
};


export type MutationRemoveDynamicNavigationPermissionArgs = {
  id: Scalars['String'];
};


export type MutationRemoveFeaturedCommunityArgs = {
  id: Scalars['String'];
};


export type MutationRemoveGatedContentArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveGatedRoleArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  type: Gated_Role_Types;
};


export type MutationRemoveListenerToCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveModuleNotificationSettingArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  module: Module_Notification_Setting_Modules;
};


export type MutationRemoveNftGatedAccessArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveParentTopicsArgs = {
  topicId: Scalars['String'];
  topics: Array<Scalars['String']>;
};


export type MutationRemovePermissionArgs = {
  id: Scalars['String'];
  permissionGroupModuleType: Permission_Group_Module_Types;
};


export type MutationRemovePermissionGroupArgs = {
  id: Scalars['String'];
};


export type MutationRemovePhotoContentImageArgs = {
  photoContentImageId: Scalars['String'];
};


export type MutationRemovePlatformContextMenuArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationRemovePlatformRoleArgs = {
  id: Scalars['String'];
};


export type MutationRemovePlatformRoleIconArgs = {
  platformRoleId: Scalars['String'];
};


export type MutationRemoveReportViolatedRuleArgs = {
  communityId: Scalars['String'];
  reportId: Scalars['String'];
  reportViolatedId: Scalars['String'];
};


export type MutationRemoveShopifyIntegrationArgs = {
  id: Scalars['String'];
};


export type MutationRemoveShopifyIntegrationByShopDomainNameArgs = {
  shopDomainName: Scalars['String'];
};


export type MutationRemoveSpeakerToCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveTopicArgs = {
  id: Scalars['String'];
};


export type MutationRemoveTopicImageArgs = {
  topicImageId: Scalars['String'];
};


export type MutationRemoveTypeItemArgs = {
  id: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationRemoveUserApiKeyArgs = {
  id: Scalars['String'];
};


export type MutationRemoveUserNotificationSettingsArgs = {
  id: Scalars['String'];
};


export type MutationRemoveUserWalletAddressArgs = {
  id: Scalars['String'];
};


export type MutationReportedContentCreateViolatedRuleArgs = {
  communityId: Scalars['String'];
  reportId: Scalars['String'];
  ruleId: Scalars['String'];
};


export type MutationReportedContentCreateViolatedRulesArgs = {
  communityId: Scalars['String'];
  reportId: Scalars['String'];
  rulesId: Array<Scalars['String']>;
};


export type MutationReportedContentRemoveViolatedRuleArgs = {
  communityId: Scalars['String'];
  reportId: Scalars['String'];
  reportViolatedId: Scalars['String'];
};


export type MutationReportedContentUpdateStatusArgs = {
  communityId: Scalars['String'];
  data: ReportedContentDataInput;
  id: Scalars['String'];
};


export type MutationResendUserChangeEmailRequestArgs = {
  id: Scalars['String'];
};


export type MutationRestoreCommentArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRunCreateDummyChannelMessagesIntervallyArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  option?: InputMaybe<MessageRunIntervalCreateDummyOptionInput>;
};


export type MutationSaveAccountProUsernameSubscriptionTypesArgs = {
  data: AccountProUsernameSubscriptionTypeInput;
};


export type MutationSaveBrandAssetsTypeArgs = {
  data: BrandAssetsTypeItemInput;
};


export type MutationSaveChannelGroupNotificationSettingsArgs = {
  communityId: Scalars['String'];
  data: ModuleNotificationSettingsUpdateInput;
  id: Scalars['String'];
};


export type MutationSaveChannelMessageReactionArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  data: ContentReactionInput;
  id: Scalars['String'];
};


export type MutationSaveCommunityNotificationSettingsArgs = {
  data: ModuleNotificationSettingsUpdateInput;
  id: Scalars['String'];
};


export type MutationSaveFeaturedCommunitiesSettingsArgs = {
  data: FeaturedCommunitiesSettingDataInput;
};


export type MutationSaveModuleNotificationPreferencesArgs = {
  data: ModuleNotificationPreferencesInput;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type MutationSaveModuleNotificationSettingsArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  data: ModuleNotificationSettingsInput;
};


export type MutationSaveProUsernameArgs = {
  username: Scalars['String'];
};


export type MutationSaveUserNotificationSettingsArgs = {
  data: ModuleNotificationSettingsUpdateInput;
};


export type MutationSendDeleteUserAccountEmailArgs = {
  data: UserDeleteAccountInput;
};


export type MutationSignInViaEmailArgs = {
  email: Scalars['String'];
};


export type MutationSignUpViaEmailArgs = {
  email: Scalars['String'];
};


export type MutationTransferHostCommunitySpaceArgs = {
  communityId: Scalars['String'];
  spaceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateBrandAssetsTypeItemArgs = {
  data: BrandAssetsTypeItemInput;
  id: Scalars['String'];
};


export type MutationUpdateChannelArgs = {
  communityId: Scalars['String'];
  data: ChannelUpdateInput;
  id: Scalars['String'];
};


export type MutationUpdateChannelGroupArgs = {
  communityId: Scalars['String'];
  data: ChannelGroupUpdateInput;
  id: Scalars['String'];
};


export type MutationUpdateChannelGroupMembersArgs = {
  communityId: Scalars['String'];
  data: Array<ChannelGroupAddMemberInput>;
  id: Scalars['String'];
};


export type MutationUpdateChannelGroupsOrderArgs = {
  communityId: Scalars['String'];
  ids: Array<Scalars['String']>;
};


export type MutationUpdateChannelMembersArgs = {
  channelGroupId: Scalars['String'];
  communityId: Scalars['String'];
  data: Array<ChannelAddMemberInput>;
  id: Scalars['String'];
};


export type MutationUpdateChannelMessageArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  data: ChannelMessageUpdateInput;
  id: Scalars['String'];
};


export type MutationUpdateChannelPrivateStatusArgs = {
  channelGroupId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationUpdateChannelSlugOfExistingChannelsArgs = {
  communityId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateChannelsOrderArgs = {
  channelGroupId: Scalars['String'];
  communityId: Scalars['String'];
  ids: Array<Scalars['String']>;
};


export type MutationUpdateCommentTextArgs = {
  communityId: Scalars['String'];
  data: UpdateCommentInput;
  id: Scalars['String'];
};


export type MutationUpdateCommunityConnectedPriceArgs = {
  communityId: Scalars['String'];
  data: CommunityConnectedPriceInput;
  priceId: Scalars['String'];
};


export type MutationUpdateCommunityConnectedPriceArchiveStatusArgs = {
  communityId: Scalars['String'];
  priceId: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationUpdateCommunityContentStatusArgs = {
  communityId: Scalars['String'];
  contentId: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationUpdateCommunityCustomDomainArgs = {
  communityId: Scalars['String'];
  data: CommunityCustomDomainInput;
  id: Scalars['String'];
};


export type MutationUpdateCommunityCustomDomainActivationStatusArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationUpdateCommunityDefaultChannelArgs = {
  communityId: Scalars['String'];
  data: UpdateCommunityDefaultChannelInput;
};


export type MutationUpdateCommunityExplorePageAdminReviewStatusArgs = {
  communityId: Scalars['String'];
  data: CommunityExplorePageSettingsAdminReviewStatusInput;
};


export type MutationUpdateCommunityExplorePageSettingsStatusArgs = {
  communityId: Scalars['String'];
  data: CommunityExplorePageSettingsStatusInput;
};


export type MutationUpdateCommunityLauncherSettingsArgs = {
  communityId: Scalars['String'];
  data: CommunityLauncherSettingsInput;
};


export type MutationUpdateCommunityLogoImageUploadedStatusArgs = {
  communityId: Scalars['String'];
};


export type MutationUpdateCommunityProfileArgs = {
  communityId: Scalars['String'];
  data: UpdateCommunityProfileDataInput;
};


export type MutationUpdateCommunityRoleArgs = {
  communityId: Scalars['String'];
  data: CommunityRoleInput;
  id: Scalars['String'];
};


export type MutationUpdateCommunityRoleIconUploadedStatusArgs = {
  communityId: Scalars['String'];
  communityRoleId: Scalars['String'];
};


export type MutationUpdateCommunityRolePermissionsArgs = {
  communityId: Scalars['String'];
  data: Array<RoleModulePermissionBulkInput>;
  roleId: Scalars['String'];
};


export type MutationUpdateCommunityRuleArgs = {
  communityId: Scalars['String'];
  data: ContentRuleInput;
  id: Scalars['String'];
};


export type MutationUpdateCommunityRuleActiveStatusArgs = {
  communityId: Scalars['String'];
  data: ContentRuleActiveStatusDataInput;
  id: Scalars['String'];
};


export type MutationUpdateCommunityRuleIndexNoArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  indexNo: Scalars['Int'];
};


export type MutationUpdateCommunityRuleOrderArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
};


export type MutationUpdateCommunityRulesSortArgs = {
  communityId: Scalars['String'];
  rulesId: Array<Scalars['String']>;
};


export type MutationUpdateCommunitySpaceArgs = {
  communityId: Scalars['String'];
  data: SpacesInput;
  spaceId: Scalars['String'];
};


export type MutationUpdateCommunityThumbnailImageUploadedStatusArgs = {
  communityId: Scalars['String'];
};


export type MutationUpdateCommunityTopicsArgs = {
  communityId: Scalars['String'];
  topicsId: Array<Scalars['String']>;
};


export type MutationUpdateContextMenuArgs = {
  communityId: Scalars['String'];
  data: ContextMenuUpdateDataInput;
};


export type MutationUpdateDynamicNavigationArgs = {
  data: DynamicNavigationUpdateDataInput;
  id: Scalars['String'];
};


export type MutationUpdateDynamicNavigationIndexesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationUpdateDynamicNavigationPermissionsArgs = {
  communityId: Scalars['String'];
  data: Array<ModuleRolePermissionBulkInput>;
  id?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateExampleContentRuleArgs = {
  data: ExampleContentRuleInput;
  id: Scalars['String'];
};


export type MutationUpdateExampleContentRuleIndexNoArgs = {
  id: Scalars['String'];
  order: Scalars['Int'];
};


export type MutationUpdateExampleContentRulesOrderArgs = {
  rulesId: Array<Scalars['String']>;
};


export type MutationUpdateFeaturedCommunityArgs = {
  data: FeaturedCommunityUpdateDataInput;
  id: Scalars['String'];
};


export type MutationUpdatePermissionArgs = {
  data: PermissionUpdateInput;
  id: Scalars['String'];
  permissionGroupModuleType: Permission_Group_Module_Types;
};


export type MutationUpdatePermissionGroupArgs = {
  data: PermissionGroupUpdateInput;
  id: Scalars['String'];
};


export type MutationUpdatePermissionGroupOrderNoArgs = {
  data: Array<PermissionGroupOrderNoInput>;
};


export type MutationUpdatePermissionsArgs = {
  data: Array<PermissionUpdateBatchInput>;
};


export type MutationUpdatePhotoContentArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  data: ContentUpdateInput;
  id: Scalars['String'];
};


export type MutationUpdatePhotoContentImageUploadedStatusArgs = {
  photoContentImageId: Scalars['String'];
};


export type MutationUpdatePlatformContextMenuArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  data: ContextMenuUpdateDataInput;
  id: Scalars['String'];
};


export type MutationUpdatePlatformContextMenuOrderNoArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['String']>;
};


export type MutationUpdatePlatformRoleArgs = {
  data: PlatformRoleInput;
  id: Scalars['String'];
};


export type MutationUpdatePlatformRoleActiveStatusArgs = {
  data: UpdatePlatformRoleActiveStatus;
  id: Scalars['String'];
};


export type MutationUpdatePlatformRoleIconUploadedStatusArgs = {
  platformRoleId: Scalars['String'];
};


export type MutationUpdatePlatformRolePermissionsArgs = {
  data: Array<PlatformRolePermissionsInput>;
  moduleId?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
};


export type MutationUpdatePlatformRolesOrderArgs = {
  data: Array<UpdatePlatformRolesOrderInput>;
};


export type MutationUpdatePlatformRuleArgs = {
  data: PlatformContentRuleUpdateDataInput;
  id: Scalars['String'];
};


export type MutationUpdatePlatformRuleActiveStatusArgs = {
  data: ContentRuleActiveStatusDataInput;
  id: Scalars['String'];
};


export type MutationUpdatePlatformRuleIndexNoArgs = {
  id: Scalars['String'];
  indexNo: Scalars['Int'];
};


export type MutationUpdatePlatformRuleSortOrderArgs = {
  id: Scalars['String'];
  indexNo: Scalars['Int'];
};


export type MutationUpdatePlatformRulesOrderArgs = {
  ids: Array<Scalars['String']>;
  parent: Scalars['String'];
};


export type MutationUpdateProUsernameArgs = {
  username: Scalars['String'];
};


export type MutationUpdateReportedContentHideStatusArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationUpdateReportedContentStatusArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  status: Reported_Content_Statuses;
};


export type MutationUpdateShopifyIntegrationArgs = {
  data: ShopifyIntegrationUpdateInput;
  id: Scalars['String'];
};


export type MutationUpdateShopifyIntegrationByShopDomainNameArgs = {
  data: ShopifyIntegrationByShopDomainUpdateInput;
  shopDomainName: Scalars['String'];
};


export type MutationUpdateTopicArgs = {
  data: TopicInput;
  id: Scalars['String'];
};


export type MutationUpdateTopicImageUploadedStatusArgs = {
  topicImageId: Scalars['String'];
};


export type MutationUpdateTypeArgs = {
  data: TypeInput;
  id: Scalars['String'];
};


export type MutationUpdateTypeItemArgs = {
  data: TypeItemInput;
  id: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserProfileInput;
  userId: Scalars['String'];
};


export type MutationUpdateUserEmailArgs = {
  data: UserOtpInput;
};


export type MutationUpdateUserGeneralSettingsArgs = {
  data: UserGeneralSettingsInput;
};


export type MutationUpdateUserImageAsUploadedArgs = {
  fileId: Scalars['String'];
};


export type MutationUpdateUserNotificationPreferencesArgs = {
  data: UserNotificationPreferencesInput;
};


export type MutationUpdateUserProfileArgs = {
  bio?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserStatusArgs = {
  data: UpdateUserStatusInput;
  id: Scalars['String'];
};


export type MutationUpdateUsernameArgs = {
  username: Scalars['String'];
};


export type MutationUploadTopicsAsCsvArgs = {
  csv: Scalars['String'];
};


export type MutationValidateProUsernameArgs = {
  username: Scalars['String'];
};


export type MutationValidateUserEmailArgs = {
  email: Scalars['String'];
};


export type MutationValidateUserWalletArgs = {
  communityId: Scalars['String'];
};


export type MutationVerifySignInArgs = {
  otp: Scalars['String'];
};


export type MutationVerifySignUpArgs = {
  otp: Scalars['String'];
};

export type NftGatedAccessInput = {
  chainId: Scalars['Int'];
  contractAddress: Scalars['String'];
  requiredQuantity?: InputMaybe<Scalars['Float']>;
  tokenId?: InputMaybe<Scalars['String']>;
  tokenType: Nft_Token_Types;
};

export type NftGatedAccessPayload = {
  __typename?: 'NFTGatedAccessPayload';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  chainId: Scalars['Float'];
  communityId: Scalars['String'];
  contractAddress: Scalars['String'];
  id: Scalars['ID'];
  requiredQuantity: Scalars['Float'];
  tokenId?: Maybe<Scalars['String']>;
  tokenType: Nft_Token_Types;
};

export enum Nft_Token_Types {
  Erc20 = 'ERC20',
  Erc721 = 'ERC721',
  Erc1155 = 'ERC1155'
}

export enum Notification_List_Types {
  New = 'NEW',
  PreviousMonth = 'PREVIOUS_MONTH',
  ThisMonth = 'THIS_MONTH',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY'
}

export type NewestCommunityConnection = {
  __typename?: 'NewestCommunityConnection';
  edges: Array<NewestCommunityPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NewestCommunityPayload = {
  __typename?: 'NewestCommunityPayload';
  _v: Scalars['Int'];
  community: Community;
  communityId: Scalars['ID'];
  id: Scalars['ID'];
};

export type NewestCommunityPayloadEdge = {
  __typename?: 'NewestCommunityPayloadEdge';
  cursor: Scalars['String'];
  node: NewestCommunityPayload;
};

export type NotificationContent = Comment;

export type NotificationPaginationPayload = {
  __typename?: 'NotificationPaginationPayload';
  edges: Array<NotificationPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NotificationPayload = {
  __typename?: 'NotificationPayload';
  author: User;
  authorId: Scalars['String'];
  community?: Maybe<Community>;
  communityId: Scalars['String'];
  content: NotificationContent;
  contentId: Scalars['String'];
  contentText: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  owner?: Maybe<User>;
  read: Scalars['Boolean'];
  seen: Scalars['Boolean'];
  type: Scalars['String'];
};

export type NotificationPayloadEdge = {
  __typename?: 'NotificationPayloadEdge';
  cursor: Scalars['String'];
  node: NotificationPayload;
};

export type NotificationSubscriptionPayload = {
  __typename?: 'NotificationSubscriptionPayload';
  communityId?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  payload?: Maybe<NotificationPayload>;
};

export type OAuthPayload = {
  __typename?: 'OAuthPayload';
  accessToken: Scalars['String'];
};

export enum Payment_Subscription_States {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Deactivated = 'DEACTIVATED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Inactive = 'INACTIVE',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Revoked = 'REVOKED',
  Suspended = 'SUSPENDED'
}

export enum Permission_Group_Module_Types {
  Channel = 'CHANNEL',
  ChannelGroup = 'CHANNEL_GROUP',
  ChannelMessage = 'CHANNEL_MESSAGE',
  ContentRule = 'CONTENT_RULE',
  DynamicNavigation = 'DYNAMIC_NAVIGATION',
  General = 'GENERAL',
  Photo = 'PHOTO',
  Role = 'ROLE',
  Space = 'SPACE'
}

export enum Permission_Values {
  ChannelCreate = 'CHANNEL_CREATE',
  ChannelDelete = 'CHANNEL_DELETE',
  ChannelEdit = 'CHANNEL_EDIT',
  ChannelGroupCreate = 'CHANNEL_GROUP_CREATE',
  ChannelGroupDelete = 'CHANNEL_GROUP_DELETE',
  ChannelGroupEdit = 'CHANNEL_GROUP_EDIT',
  ChannelGroupList = 'CHANNEL_GROUP_LIST',
  ChannelGroupView = 'CHANNEL_GROUP_VIEW',
  ChannelGroupViewPrivate = 'CHANNEL_GROUP_VIEW_PRIVATE',
  ChannelList = 'CHANNEL_LIST',
  ChannelView = 'CHANNEL_VIEW',
  ChannelViewPrivate = 'CHANNEL_VIEW_PRIVATE',
  ContentRuleCreate = 'CONTENT_RULE_CREATE',
  ContentRuleDelete = 'CONTENT_RULE_DELETE',
  ContentRuleEdit = 'CONTENT_RULE_EDIT',
  ContentRuleList = 'CONTENT_RULE_LIST',
  ContentRuleView = 'CONTENT_RULE_VIEW',
  DynamicNavigationCreate = 'DYNAMIC_NAVIGATION_CREATE',
  DynamicNavigationDelete = 'DYNAMIC_NAVIGATION_DELETE',
  DynamicNavigationEdit = 'DYNAMIC_NAVIGATION_EDIT',
  DynamicNavigationList = 'DYNAMIC_NAVIGATION_LIST',
  DynamicNavigationView = 'DYNAMIC_NAVIGATION_VIEW',
  GeneralCreate = 'GENERAL_CREATE',
  GeneralDelete = 'GENERAL_DELETE',
  GeneralEdit = 'GENERAL_EDIT',
  GeneralView = 'GENERAL_VIEW',
  MessageAttachments = 'MESSAGE_ATTACHMENTS',
  MessageCopyLink = 'MESSAGE_COPY_LINK',
  MessageCreateThread = 'MESSAGE_CREATE_THREAD',
  MessageDelete = 'MESSAGE_DELETE',
  MessageDeleteOwn = 'MESSAGE_DELETE_OWN',
  MessageEdit = 'MESSAGE_EDIT',
  MessageEditOwn = 'MESSAGE_EDIT_OWN',
  MessageList = 'MESSAGE_LIST',
  MessageMarkAsRead = 'MESSAGE_MARK_AS_READ',
  MessageMarkAsUnread = 'MESSAGE_MARK_AS_UNREAD',
  MessageReaction = 'MESSAGE_REACTION',
  MessageReply = 'MESSAGE_REPLY',
  MessageReport = 'MESSAGE_REPORT',
  MessageSend = 'MESSAGE_SEND',
  PhotoComment = 'PHOTO_COMMENT',
  PhotoCreate = 'PHOTO_CREATE',
  PhotoDelete = 'PHOTO_DELETE',
  PhotoEdit = 'PHOTO_EDIT',
  RoleCreate = 'ROLE_CREATE',
  RoleDelete = 'ROLE_DELETE',
  RoleEdit = 'ROLE_EDIT',
  RoleList = 'ROLE_LIST',
  RoleMemberOwnerAdd = 'ROLE_MEMBER_OWNER_ADD',
  RoleMemberOwnerRemove = 'ROLE_MEMBER_OWNER_REMOVE',
  RoleView = 'ROLE_VIEW',
  SpaceCreate = 'SPACE_CREATE',
  SpaceDelete = 'SPACE_DELETE',
  SpaceEdit = 'SPACE_EDIT'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  count: Scalars['Int'];
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentMethodCardPayload = {
  __typename?: 'PaymentMethodCardPayload';
  brand: Scalars['String'];
  country: Scalars['String'];
  exp_month: Scalars['String'];
  exp_year: Scalars['String'];
  last4: Scalars['String'];
};

export type PermissionGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slug: Permission_Group_Module_Types;
  value?: InputMaybe<Scalars['String']>;
};

export type PermissionGroupOrderNoInput = {
  id: Scalars['String'];
  orderNo: Scalars['Int'];
};

export type PermissionGroupPayload = {
  __typename?: 'PermissionGroupPayload';
  authorId: Scalars['String'];
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  order: Scalars['Int'];
  permissions: Array<PermissionPayload>;
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};


export type PermissionGroupPayloadPermissionsArgs = {
  values?: Array<Permission_Values>;
};

export type PermissionGroupUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Permission_Group_Module_Types>;
  value?: InputMaybe<Scalars['String']>;
};

export type PermissionInput = {
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slug: Permission_Values;
  value?: InputMaybe<Scalars['String']>;
};

export type PermissionPayload = {
  __typename?: 'PermissionPayload';
  authorId: Scalars['String'];
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  order: Scalars['Int'];
  permissionGroupModuleType: Permission_Group_Module_Types;
  permissionGroupName: Scalars['String'];
  slug: Permission_Values;
  value?: Maybe<Scalars['String']>;
};

export type PermissionUpdateBatchInput = {
  id: Scalars['String'];
  permission: PermissionUpdateInput;
  permissionGroupSlug: Scalars['String'];
};

export type PermissionUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slug: Permission_Values;
  value?: InputMaybe<Scalars['String']>;
};

export type PlatformContentRuleDataInput = {
  html?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parents?: InputMaybe<Array<ContentRuleParentInput>>;
  rawJSON?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: Content_Rule_Type;
};

export type PlatformContentRulePayload = {
  __typename?: 'PlatformContentRulePayload';
  _v?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  childrens: Array<PlatformContentRulePayload>;
  created?: Maybe<GqlDateObjectType>;
  deleted?: Maybe<GqlDateObjectType>;
  html: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  parents: Array<PlatformContentRulePayload>;
  rawJSON: Scalars['String'];
  title: Scalars['String'];
  type: Content_Rule_Type;
  updated?: Maybe<GqlDateObjectType>;
};

export type PlatformContentRuleUpdateDataInput = {
  html?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parents?: InputMaybe<Array<ContentRuleParentInput>>;
  rawJSON?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Content_Rule_Type>;
};

export type PlatformModulePermissionInput = {
  options: Array<Module_Permission_Options>;
  status: Scalars['Boolean'];
  value: Permission_Values;
};

export type PlatformRoleInput = {
  description: Scalars['String'];
  level?: InputMaybe<Role_Levels>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type PlatformRolePayload = {
  __typename?: 'PlatformRolePayload';
  active?: Maybe<Scalars['Boolean']>;
  authorId: Scalars['String'];
  communityId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  iconImageUrls?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  /** @deprecated will remove in the future */
  isDefault?: Maybe<Scalars['Boolean']>;
  level: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  permissions: Array<ModulePermissionPayload>;
};


export type PlatformRolePayloadPermissionsArgs = {
  moduleId?: InputMaybe<Scalars['String']>;
  permissionGroupModuleType?: InputMaybe<Array<Permission_Group_Module_Types>>;
  values?: InputMaybe<Array<Permission_Values>>;
};

export type PlatformRolePermissionsInput = {
  permissionGroup: Permission_Group_Module_Types;
  permissions: Array<PlatformModulePermissionInput>;
};

export type PreSignedPost = {
  __typename?: 'PreSignedPost';
  fields: Array<PreSignedPostField>;
  id: Scalars['String'];
  url: Scalars['String'];
};

export type PreSignedPostField = {
  __typename?: 'PreSignedPostField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type PreUpdateUserEmailInput = {
  email: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  CommunityRoleIcon?: Maybe<FileMetadataPayload>;
  DynamicNavigationPermissions: Array<ModulePermissionPayload>;
  NFTGatedAccess: Array<NftGatedAccessPayload>;
  accountProUsernameSubscriptionTypes: Array<AccountProUsernameTypeItemPayload>;
  brandAssetsTypes: Array<BrandAssetsItemTypePayload>;
  channel: ChannelPayload;
  channelBySlug?: Maybe<ChannelPayload>;
  channelGroup: ChannelGroupPayload;
  channelGroupNotificationSettings?: Maybe<ModuleNotificationSettingsPayload>;
  /** roles member. */
  channelGroupRoleMembers: CommunityRoleConnection;
  /** users member. */
  channelGroupUserMembers: UsersConnection;
  channelGroups: ChannelGroupPaginatedPayload;
  channelMessage?: Maybe<ChannelMessagePayload>;
  channelMessages: ChannelMessageConnectionPayload;
  /** new contents and new mentioned */
  channelNotificationCounts: ChannelNotificationCountPayload;
  /** roles member. */
  channelRoleMembers: CommunityRoleConnection;
  /** users member. */
  channelUserMembers: UsersConnection;
  channels: ChannelPaginationPayload;
  checkUsernameAvailability: Scalars['Boolean'];
  comment?: Maybe<Comment>;
  comments: CommentsConnection;
  /** list of the community. */
  communities: CommunitiesConnection;
  community?: Maybe<Community>;
  communityBlockedMembers: BlockedUsersConnectionPayload;
  /** get community data by slug. */
  communityBySlug?: Maybe<Community>;
  /**
   * get community data by slug.
   * @deprecated use communityBySlug instead.
   */
  communityBySlugName?: Maybe<Community>;
  communityConnectedPrices: Array<CommunityConnectedPricePayload>;
  communityConnectedProducts: Array<CommunityConnectedProductPayload>;
  communityContents: Array<CommunityContentPayload>;
  communityCustomDomains: Array<CommunityCustomDomainPayload>;
  communityExplorePageSettings?: Maybe<CommunityExplorePageSettingsPayload>;
  communityLauncherSettings?: Maybe<CommunityLauncherSettingsPayload>;
  communityLauncherSettingsByButtonId: CommunityLauncherSettingsPayload;
  communityLauncherSettingsByCommunityId: CommunityLauncherSettingsPayload;
  communityLogoImage?: Maybe<FileMetadataPayload>;
  /** get the total online members count. */
  communityMemberCounts: Scalars['Int'];
  communityMembers: CommunityMembersConnection;
  /** get unread and mentioned notification counts */
  communityNotificationCounts: CommunityNotificationCountPayload;
  communityNotificationSettings?: Maybe<ModuleNotificationSettingsPayload>;
  /** get the total online members count. */
  communityOnlineMemberCounts: Scalars['Int'];
  communityOnlineMembers: UsersConnection;
  communityPaymentAccount: ConnectedAccountPayload;
  communityPaymentMethod?: Maybe<UserPaymentMethodsPayload>;
  communityRole?: Maybe<CommunityRolePayload>;
  communityRoleMembers: CommunityRolesMemberConnectionPayload;
  communityRoleMembersByRoleLevel: UsersConnection;
  communityRolePermissions: Array<ModulePermissionPayload>;
  communityRoles: Array<CommunityRolePayload>;
  communityRules: Array<ContentRulePayload>;
  communitySettings?: Maybe<CommunitySettingsPayload>;
  /** get community snippet button id. */
  communitySnippetButtonId: CommunitySnippetButtonIdPayload;
  communitySpaceCoHosts: UsersConnection;
  communitySpaceHosts: UsersConnection;
  communitySpaceListeners: UsersConnection;
  communitySpaceSpeakers: UsersConnection;
  communitySpaces: SpacesConnection;
  communityThumbnailImage?: Maybe<FileMetadataPayload>;
  contentReactionUsers: UsersConnection;
  contentThreads: Array<ContentThreadPayload>;
  contextMenus: Array<ContextMenuPayload>;
  defaultPermissionGroupModuleTypes: Array<DefaultPermissionTypesPayload>;
  defaultPermissionValues: Array<DefaultPermissionValuesPayload>;
  dynamicNavigations: Array<DynamicNavigationPayload>;
  exampleContentRules: Array<ExampleContentRulePayload>;
  featuredCommunities: Array<FeaturedCommunityPayload>;
  featuredCommunitiesSettings?: Maybe<FeaturedCommunitiesSettingPayload>;
  gatedContents: Array<GatedContentPayload>;
  gatedRoles: Array<GatedRolePayload>;
  loadPhotoContent: Scalars['Boolean'];
  me: User;
  moduleNotificationPreferences?: Maybe<ModuleNotificationPreferencesPayload>;
  moduleNotificationSettingByModule?: Maybe<ModuleNotificationSettingsPayload>;
  moduleNotificationSettings: Array<ModuleNotificationSettingsPayload>;
  modulePermissions: Array<ModulePermissionPayload>;
  newestCommunities: NewestCommunityConnection;
  /** Get the notification counts */
  notificationCount: Scalars['Int'];
  notifications: NotificationPaginationPayload;
  permissionGroups: Array<PermissionGroupPayload>;
  permissions: Array<PermissionPayload>;
  photoContent: ContentBasePayload;
  photoContentImage?: Maybe<FileMetadataPayload>;
  photoContents: ContentPaginationPayload;
  platformBlockedUsers: BlockedUsersConnectionPayload;
  platformContextMenus: Array<ContextMenuPayload>;
  platformRoleByLevel: PlatformRolePayload;
  platformRoleIcon?: Maybe<FileMetadataPayload>;
  platformRolePermissions: Array<ModulePermissionPayload>;
  platformRoles: Array<PlatformRolePayload>;
  platformRules: Array<PlatformContentRulePayload>;
  reportedContentHistories: Array<ReportedContentViolatedRulesPayload>;
  reportedContentModerationHistories: Array<ReportedContentViolatedRulesPayload>;
  reportedContents: ReportConnection;
  reports: ReportConnection;
  roleMemberInvitations: Array<CommunityRoleMemberInvitationPayload>;
  shopifyIntegrationByShopDomainName: ShopifyIntegrationPayload;
  spaceChannelCoHosts: UsersConnection;
  spaceChannelListeners: UsersConnection;
  spaceChannelSpeakers: UsersConnection;
  topic: Topic;
  topicImage?: Maybe<FileMetadataPayload>;
  topics: TopicsConnection;
  type?: Maybe<TypePayload>;
  typeItems: Array<TypeItemPayload>;
  ungroupedChannels: ChannelPaginationPayload;
  user: User;
  userAPIKeys: UserApiKeyPaginatedPayload;
  userChangeEmailRequests: Array<UserChangeEmailRequestType>;
  /** list of the communities that are either owned by the current logged-in user or where they are a member. */
  userCommunities: CommunitiesConnection;
  userCommunityPermissions: Array<ModulePermissionPayload>;
  userCommunityRoles: Array<CommunityRolePayload>;
  userGeneralSettings: UserGeneralSettingsResponse;
  userNotificationPreferences?: Maybe<UserNotificationPreferencesResponse>;
  userNotificationSettings?: Maybe<ModuleNotificationSettingsBasePayload>;
  userOnboarding?: Maybe<UserOnboardingPayload>;
  userPermissions: Array<ModulePermissionPayload>;
  userProSubscription?: Maybe<AccountProUsernameSubscription>;
  userShopifyIntegrations: ShopifyIntegrationPaginationPayload;
  userWalletAddresses: Array<UserWalletAddressPayload>;
  users: UsersConnection;
  verifyUserAPIKey: UserApiKeyPayload;
};


export type QueryCommunityRoleIconArgs = {
  id: Scalars['String'];
};


export type QueryDynamicNavigationPermissionsArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
  roleId?: InputMaybe<Scalars['String']>;
};


export type QueryNftGatedAccessArgs = {
  communityId: Scalars['String'];
};


export type QueryChannelArgs = {
  channelGroupId?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryChannelBySlugArgs = {
  communityId: Scalars['String'];
  slug: Scalars['String'];
};


export type QueryChannelGroupArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryChannelGroupNotificationSettingsArgs = {
  communityId: Scalars['String'];
  customized?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type QueryChannelGroupRoleMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  channelGroupId: Scalars['String'];
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryChannelGroupUserMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  channelGroupId: Scalars['String'];
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryChannelGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryChannelMessageArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryChannelMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  jumpTo?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  threadId?: InputMaybe<Scalars['String']>;
};


export type QueryChannelNotificationCountsArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryChannelRoleMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryChannelUserMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryChannelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  channelGroupId?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCheckUsernameAvailabilityArgs = {
  username: Scalars['String'];
};


export type QueryCommentArgs = {
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  threadId: Scalars['String'];
};


export type QueryCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludeFeatured?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCommunityArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityBlockedMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCommunityBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCommunityBySlugNameArgs = {
  slugName: Scalars['String'];
};


export type QueryCommunityConnectedPricesArgs = {
  communityConnectedProductId?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
};


export type QueryCommunityConnectedProductsArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityContentsArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityCustomDomainsArgs = {
  communityId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCommunityExplorePageSettingsArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityLauncherSettingsArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityLauncherSettingsByButtonIdArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityLogoImageArgs = {
  id: Scalars['String'];
};


export type QueryCommunityMemberCountsArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCommunityNotificationCountsArgs = {
  id: Scalars['String'];
};


export type QueryCommunityNotificationSettingsArgs = {
  customized?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type QueryCommunityOnlineMemberCountsArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityOnlineMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCommunityPaymentAccountArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityPaymentMethodArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunityRoleArgs = {
  communityId: Scalars['String'];
  roleId: Scalars['String'];
};


export type QueryCommunityRoleMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  roleId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCommunityRoleMembersByRoleLevelArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  excludedRoleLevel?: InputMaybe<Array<Role_Levels>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  level: Role_Levels;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCommunityRolePermissionsArgs = {
  communityId: Scalars['String'];
  moduleId?: InputMaybe<Scalars['String']>;
  permissionGroupModuleType?: InputMaybe<Array<Permission_Group_Module_Types>>;
  roleId: Scalars['String'];
};


export type QueryCommunityRolesArgs = {
  communityId: Scalars['String'];
  option: CommunityRoleListOption;
};


export type QueryCommunityRulesArgs = {
  communityId: Scalars['String'];
  option?: InputMaybe<ContentRuleListOption>;
};


export type QueryCommunitySettingsArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunitySnippetButtonIdArgs = {
  communityId: Scalars['String'];
};


export type QueryCommunitySpaceCoHostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['String'];
};


export type QueryCommunitySpaceHostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['String'];
};


export type QueryCommunitySpaceListenersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['String'];
};


export type QueryCommunitySpaceSpeakersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['String'];
};


export type QueryCommunitySpacesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCommunityThumbnailImageArgs = {
  id: Scalars['String'];
};


export type QueryContentReactionUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contentReactionId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryContentThreadsArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
};


export type QueryContextMenusArgs = {
  communityId: Scalars['String'];
  module: Context_Menu_Module_Type;
};


export type QueryDynamicNavigationsArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
};


export type QueryFeaturedCommunitiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sortType?: InputMaybe<Featured_Communities_Setting_Sort_Type>;
};


export type QueryGatedContentsArgs = {
  communityId: Scalars['String'];
};


export type QueryGatedRolesArgs = {
  communityId: Scalars['String'];
  roleId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Gated_Role_Types>;
};


export type QueryLoadPhotoContentArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
};


export type QueryModuleNotificationPreferencesArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  module?: InputMaybe<Module_Notification_Preference_Modules>;
  moduleId?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type QueryModuleNotificationSettingByModuleArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  module: Module_Notification_Setting_Modules;
  moduleId: Scalars['String'];
  ownerId?: InputMaybe<Scalars['String']>;
};


export type QueryModuleNotificationSettingsArgs = {
  communityId?: InputMaybe<Scalars['String']>;
};


export type QueryModulePermissionsArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  moduleId?: InputMaybe<Scalars['String']>;
  permissionGroupModuleTypes?: InputMaybe<Array<Permission_Group_Module_Types>>;
  referenceId: Scalars['String'];
  referenceType: Module_Permission_Reference_Types;
};


export type QueryNewestCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryNotificationCountArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  unread?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  listType: Notification_List_Types;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryPermissionGroupsArgs = {
  moduleTypes?: InputMaybe<Array<Permission_Group_Module_Types>>;
};


export type QueryPermissionsArgs = {
  permissionGroupModuleType?: InputMaybe<Array<Permission_Group_Module_Types>>;
};


export type QueryPhotoContentArgs = {
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryPhotoContentImageArgs = {
  id: Scalars['String'];
};


export type QueryPhotoContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryPlatformBlockedUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryPlatformContextMenusArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  module: Context_Menu_Module_Type;
};


export type QueryPlatformRoleByLevelArgs = {
  level: Role_Levels;
};


export type QueryPlatformRoleIconArgs = {
  id: Scalars['String'];
};


export type QueryPlatformRolePermissionsArgs = {
  moduleId?: InputMaybe<Scalars['String']>;
  permissionGroupModuleType?: InputMaybe<Array<Permission_Group_Module_Types>>;
  platformId: Scalars['String'];
};


export type QueryPlatformRulesArgs = {
  parentId?: InputMaybe<Scalars['String']>;
  parents?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryReportedContentHistoriesArgs = {
  communityId: Scalars['String'];
  reportId: Scalars['String'];
};


export type QueryReportedContentModerationHistoriesArgs = {
  communityId: Scalars['String'];
  reportId: Scalars['String'];
};


export type QueryReportedContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<Array<Reported_Content_Types>>;
  first?: InputMaybe<Scalars['Int']>;
  history?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  rulesId?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryRoleMemberInvitationsArgs = {
  communityId: Scalars['String'];
  roleId?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['String'];
  state?: InputMaybe<Community_Role_Member_Invitation_States>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryShopifyIntegrationByShopDomainNameArgs = {
  shopDomainName: Scalars['String'];
};


export type QuerySpaceChannelCoHostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['String'];
};


export type QuerySpaceChannelListenersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['String'];
};


export type QuerySpaceChannelSpeakersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  spaceId: Scalars['String'];
};


export type QueryTopicArgs = {
  id: Scalars['String'];
};


export type QueryTopicImageArgs = {
  id: Scalars['String'];
};


export type QueryTopicsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryTypeArgs = {
  id: Scalars['String'];
};


export type QueryTypeItemsArgs = {
  id: Scalars['String'];
};


export type QueryUngroupedChannelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserApiKeysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryUserCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludeFeatured?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryUserCommunityRolesArgs = {
  communityId: Scalars['String'];
};


export type QueryUserNotificationSettingsArgs = {
  customized?: InputMaybe<Scalars['Boolean']>;
};


export type QueryUserShopifyIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryUserWalletAddressesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  showPlatformBlockedUsers?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryVerifyUserApiKeyArgs = {
  scopes: Array<User_Api_Key_Scopes>;
  token: Scalars['String'];
};

export enum Reported_Content_Statuses {
  HardDelete = 'HARD_DELETE',
  Keep = 'KEEP',
  Pending = 'PENDING',
  Restore = 'RESTORE',
  SoftDelete = 'SOFT_DELETE'
}

export enum Reported_Content_Types {
  Article = 'ARTICLE',
  Comment = 'COMMENT',
  Message = 'MESSAGE',
  PhotoContent = 'PHOTO_CONTENT',
  UserBio = 'USER_BIO',
  UserDisplayName = 'USER_DISPLAY_NAME',
  UserProfilePhoto = 'USER_PROFILE_PHOTO',
  UserUsername = 'USER_USERNAME',
  UserWebsite = 'USER_WEBSITE'
}

export enum Role_Levels {
  Admin = 'ADMIN',
  CoHost = 'CO_HOST',
  Creator = 'CREATOR',
  Custom = 'CUSTOM',
  Host = 'HOST',
  Listener = 'LISTENER',
  Member = 'MEMBER',
  Moderator = 'MODERATOR',
  NonMember = 'NON_MEMBER',
  Owner = 'OWNER',
  Speaker = 'SPEAKER',
  Visitor = 'VISITOR'
}

export enum Role_List_Types {
  All = 'ALL',
  Community = 'COMMUNITY',
  Spaces = 'SPACES'
}

export type ReadContentMarkReadInput = {
  timestamp?: InputMaybe<Scalars['Float']>;
};

export type ReadContentMarkUnreadInput = {
  timestamp: Scalars['Float'];
};

export type ReportConnection = {
  __typename?: 'ReportConnection';
  edges: Array<ReportPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ReportModeratorActionsPayload = {
  __typename?: 'ReportModeratorActionsPayload';
  actionType: Scalars['String'];
  author?: Maybe<User>;
  authorId: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ReportPayload = {
  __typename?: 'ReportPayload';
  communityId?: Maybe<Scalars['String']>;
  content?: Maybe<ReportedContentUnion>;
  contentId: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  hide: Scalars['Boolean'];
  id: Scalars['String'];
  moderationHistories: Array<ReportedContentViolatedRulesHistoryPayload>;
  moderatorActions: Array<ReportModeratorActionsPayload>;
  reportHistories: Array<ReportedContentViolatedRulesHistoryPayload>;
  status: Reported_Content_Statuses;
  violatedRules: Array<ViolatedRulesPayload>;
};

export type ReportPayloadEdge = {
  __typename?: 'ReportPayloadEdge';
  cursor: Scalars['String'];
  node: ReportPayload;
};

export type ReportedContentDataInput = {
  status: Reported_Content_Statuses;
};

export type ReportedContentUnion = Comment | ContentBasePayload;

export type ReportedContentViolatedRulesHistoryPayload = {
  __typename?: 'ReportedContentViolatedRulesHistoryPayload';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  data?: Maybe<Array<ReportedContentViolatedRulesPayload>>;
};

export type ReportedContentViolatedRulesPayload = {
  __typename?: 'ReportedContentViolatedRulesPayload';
  author?: Maybe<User>;
  authorId: Scalars['String'];
  communityId?: Maybe<Scalars['String']>;
  contentRule?: Maybe<ContentRulePayload>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reportId: Scalars['String'];
  ruleId: Scalars['String'];
  status: Reported_Content_Statuses;
};

export type RoleModulePermissionBulkInput = {
  data: Array<ModulePermissionBulkInput>;
  moduleId?: InputMaybe<Scalars['String']>;
};

export type ShopifyIntegrationByShopDomainUpdateInput = {
  communityId?: InputMaybe<Scalars['String']>;
  scriptTag?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type ShopifyIntegrationCreateInput = {
  shopDomainName: Scalars['String'];
  token: Scalars['String'];
};

export type ShopifyIntegrationPaginationPayload = {
  __typename?: 'ShopifyIntegrationPaginationPayload';
  edges: Array<ShopifyIntegrationPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ShopifyIntegrationPayload = {
  __typename?: 'ShopifyIntegrationPayload';
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  scriptTag?: Maybe<Scalars['String']>;
  shopDomainName: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['Float'];
  userId: Scalars['String'];
};

export type ShopifyIntegrationPayloadEdge = {
  __typename?: 'ShopifyIntegrationPayloadEdge';
  cursor: Scalars['String'];
  node: ShopifyIntegrationPayload;
};

export type ShopifyIntegrationUpdateInput = {
  communityId?: InputMaybe<Scalars['String']>;
  scriptTag?: InputMaybe<Scalars['String']>;
  shopDomainName?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type SpacesConnection = {
  __typename?: 'SpacesConnection';
  edges: Array<SpacesPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SpacesInput = {
  name: Scalars['String'];
};

export type SpacesPayload = {
  __typename?: 'SpacesPayload';
  cohosts: UsersConnection;
  commentThreadId: Scalars['String'];
  communityId: Scalars['String'];
  hosts: UsersConnection;
  id: Scalars['ID'];
  listeners: UsersConnection;
  name: Scalars['String'];
  owner: User;
  speakers: UsersConnection;
  state: Scalars['String'];
  userId: Scalars['String'];
};


export type SpacesPayloadCohostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type SpacesPayloadHostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type SpacesPayloadListenersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type SpacesPayloadSpeakersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<CommunityMembersListModuleOption>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type SpacesPayloadEdge = {
  __typename?: 'SpacesPayloadEdge';
  cursor: Scalars['String'];
  node: SpacesPayload;
};

export type StatsPoint = {
  __typename?: 'StatsPoint';
  date: Scalars['DateTime'];
  guests?: Maybe<Scalars['Int']>;
  /** @deprecated use `date` instead. */
  time: Scalars['DateTime'];
  users?: Maybe<Scalars['Int']>;
};

export type StripePricePayload = {
  __typename?: 'StripePricePayload';
  amount: Scalars['Float'];
  communityConnectedProductId: Scalars['String'];
  currency: Community_Connected_Price_Currency;
  label?: Maybe<Scalars['String']>;
  recurring?: Maybe<CommunityConnectedPriceRecurringPayload>;
  type: Community_Connected_Price_Types;
};

export type StripeProductPayload = {
  __typename?: 'StripeProductPayload';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  channelContents?: Maybe<ChannelContentSubscriptionPayload>;
  channelMessages: ChannelMessageSubscriptionPayload;
  communityOnlineMember: CommunityOnlineMemberObject;
  notifications: NotificationSubscriptionPayload;
};


export type SubscriptionChannelContentsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
};


export type SubscriptionChannelMessagesArgs = {
  actionType?: InputMaybe<Message_Action_Types>;
  channelId?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
};


export type SubscriptionCommunityOnlineMemberArgs = {
  communityId: Scalars['String'];
};

export enum Type_Module_Slug {
  ContextMenuModule = 'CONTEXT_MENU_MODULE',
  ContextMenuType = 'CONTEXT_MENU_TYPE',
  ExampleContentRule = 'EXAMPLE_CONTENT_RULE',
  PermissionGroup = 'PERMISSION_GROUP',
  RoleLevel = 'ROLE_LEVEL'
}

export type TextField = {
  __typename?: 'TextField';
  label: Scalars['String'];
  maxLength: Scalars['Float'];
  minLength: Scalars['Float'];
  name: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  rows: Scalars['Float'];
};

export type Topic = {
  __typename?: 'Topic';
  aliases: Array<Scalars['String']>;
  allAncestors: Array<Topic>;
  author: User;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  parents: Array<Topic>;
  scoreBasedFeed: Scalars['String'];
  timeBasedFeed: Scalars['String'];
};

export type TopicEdge = {
  __typename?: 'TopicEdge';
  cursor: Scalars['String'];
  node: Topic;
};

export type TopicInput = {
  aliases?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type TopicsConnection = {
  __typename?: 'TopicsConnection';
  edges: Array<TopicEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TypeInput = {
  description: Scalars['String'];
  items: Array<TypeItemInput>;
  label: Scalars['String'];
  parent?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type TypeItemInput = {
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type TypeItemPayload = {
  __typename?: 'TypeItemPayload';
  authorId: Scalars['String'];
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  order: Scalars['Int'];
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type TypePayload = {
  __typename?: 'TypePayload';
  authorId: Scalars['String'];
  created?: Maybe<GqlDateObjectType>;
  /** @deprecated use `created` field instead. */
  createdAt?: Maybe<Scalars['Float']>;
  deleted?: Maybe<GqlDateObjectType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  items: Array<TypeItemPayload>;
  label: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  updated?: Maybe<GqlDateObjectType>;
  /** @deprecated use `updated` field instead. */
  updatedAt?: Maybe<Scalars['Float']>;
};

export enum User_Api_Key_Scopes {
  Article = 'ARTICLE',
  ArticleCreate = 'ARTICLE_CREATE',
  ArticleDelete = 'ARTICLE_DELETE',
  ArticleList = 'ARTICLE_LIST',
  ArticleUpdate = 'ARTICLE_UPDATE',
  Community = 'COMMUNITY',
  CommunityCreate = 'COMMUNITY_CREATE',
  CommunityDelete = 'COMMUNITY_DELETE',
  CommunitySettingsLauncher = 'COMMUNITY_SETTINGS_LAUNCHER',
  CommunitySettingsLauncherUpdate = 'COMMUNITY_SETTINGS_LAUNCHER_UPDATE',
  CommunitySettingsLauncherView = 'COMMUNITY_SETTINGS_LAUNCHER_VIEW',
  CommunityUpdate = 'COMMUNITY_UPDATE',
  CommunityView = 'COMMUNITY_VIEW'
}

export enum User_Change_Email_Request_Status {
  Cancel = 'CANCEL',
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export enum User_Crypto_Wallet_Statuses {
  Active = 'ACTIVE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export enum User_General_Settings_Display_Preferences {
  Auto = 'auto',
  Dark = 'dark',
  Light = 'light'
}

export enum User_Status_Input {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED'
}

export type UpdateCommentInput = {
  repliedTo?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type UpdateCommunityDefaultChannelInput = {
  channelId: Scalars['String'];
};

export type UpdateCommunityProfileDataInput = {
  color?: InputMaybe<CommunityColorInput>;
  description?: InputMaybe<Scalars['String']>;
  /** id property from the presigned url response. */
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** id property from the presigned url response. */
  thumbnail?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdatePlatformRoleActiveStatus = {
  status: Scalars['Boolean'];
};

export type UpdatePlatformRolesOrderInput = {
  id: Scalars['String'];
  order: Scalars['Int'];
};

export type UpdateUserProfileInput = {
  bio?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  numericId?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateUserStatusInput = {
  reason?: InputMaybe<Scalars['String']>;
  status: User_Status_Input;
};

export type User = {
  __typename?: 'User';
  bio?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  deactivatedAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  notificationSettings?: Maybe<ModuleNotificationSettingsBasePayload>;
  /** @deprecated no longer needed. will remove in the future */
  numericId?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
  permissions: Array<ModulePermissionPayload>;
  pro?: Maybe<AccountUsernamePayload>;
  proUsername?: Maybe<Scalars['String']>;
  scoreBasedFeed: Scalars['String'];
  status: Scalars['String'];
  timeBasedFeed: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type UserNotificationSettingsArgs = {
  customized?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type UserPermissionsArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  moduleId?: InputMaybe<Scalars['String']>;
  permissionGroupModuleType?: InputMaybe<Array<Permission_Group_Module_Types>>;
  values?: InputMaybe<Array<Permission_Values>>;
};

export type UserApiKeyCreatePayload = {
  __typename?: 'UserAPIKeyCreatePayload';
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  lastUsedAt: Scalars['Float'];
  name: Scalars['String'];
  scopes: Array<User_Api_Key_Scopes>;
  secretKey: Scalars['String'];
  updatedAt: Scalars['Float'];
  userId: Scalars['String'];
};

export type UserApiKeyInput = {
  communityId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  scopes: Array<User_Api_Key_Scopes>;
};

export type UserApiKeyPaginatedPayload = {
  __typename?: 'UserAPIKeyPaginatedPayload';
  edges: Array<UserApiKeyPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserApiKeyPayload = {
  __typename?: 'UserAPIKeyPayload';
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  lastUsedAt: Scalars['Float'];
  name: Scalars['String'];
  scopes: Array<User_Api_Key_Scopes>;
  updatedAt: Scalars['Float'];
  userId: Scalars['String'];
};

export type UserApiKeyPayloadEdge = {
  __typename?: 'UserAPIKeyPayloadEdge';
  cursor: Scalars['String'];
  node: UserApiKeyPayload;
};

export type UserChangeEmailRequestType = {
  __typename?: 'UserChangeEmailRequestType';
  _v?: Maybe<Scalars['Int']>;
  created?: Maybe<GqlDateObjectType>;
  deleted?: Maybe<GqlDateObjectType>;
  email: Scalars['String'];
  id: Scalars['ID'];
  status: User_Change_Email_Request_Status;
  updated?: Maybe<GqlDateObjectType>;
};

export type UserCryptoWalletPayload = {
  __typename?: 'UserCryptoWalletPayload';
  contractAddress: Scalars['String'];
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  nonce: Scalars['Float'];
  status: User_Crypto_Wallet_Statuses;
  updatedAt: Scalars['Float'];
  userId: Scalars['String'];
};

export type UserDeleteAccountInput = {
  reason?: InputMaybe<Scalars['String']>;
};

export type UserDeleteInput = {
  method: Graphql_Delete_Methods;
  reason?: InputMaybe<Scalars['String']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserGeneralSettingsInput = {
  displayPreferences: User_General_Settings_Display_Preferences;
  ownerId?: InputMaybe<Scalars['String']>;
};

export type UserGeneralSettingsResponse = {
  __typename?: 'UserGeneralSettingsResponse';
  displayPreferences: Scalars['String'];
  id: Scalars['String'];
  ownerId: Scalars['String'];
};

export type UserNotificationPreferencesInput = {
  email?: InputMaybe<UserNotificationPreferencesOptionsInput>;
  push?: InputMaybe<UserNotificationPreferencesOptionsInput>;
};

export type UserNotificationPreferencesOptionsInput = {
  mentions?: InputMaybe<Scalars['Boolean']>;
  newComment?: InputMaybe<Scalars['Boolean']>;
};

export type UserNotificationPreferencesOptionsOutput = {
  __typename?: 'UserNotificationPreferencesOptionsOutput';
  mentions: Scalars['Boolean'];
  newComment: Scalars['Boolean'];
};

export type UserNotificationPreferencesResponse = {
  __typename?: 'UserNotificationPreferencesResponse';
  email: UserNotificationPreferencesOptionsOutput;
  id: Scalars['String'];
  ownerId: Scalars['String'];
  push: UserNotificationPreferencesOptionsOutput;
};

export type UserOtpInput = {
  otpValue: Scalars['String'];
};

export type UserOnboardingDataInput = {
  skipped?: InputMaybe<Scalars['Boolean']>;
  step: Scalars['Float'];
};

export type UserOnboardingPayload = {
  __typename?: 'UserOnboardingPayload';
  id: Scalars['ID'];
  status: Scalars['String'];
  steps: Array<ModuleOnboardingStepPayload>;
};

export type UserPaymentMethodsPayload = {
  __typename?: 'UserPaymentMethodsPayload';
  card?: Maybe<PaymentMethodCardPayload>;
  communityId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ownerId: Scalars['String'];
  paymentMethodId: Scalars['String'];
  state: Scalars['String'];
};

export type UserTestCreateInput = {
  bio?: InputMaybe<Scalars['String']>;
  /** user will join to the provided communities */
  communityIds?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type UserWalletAddressPayload = {
  __typename?: 'UserWalletAddressPayload';
  id: Scalars['ID'];
  ownerId: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ViolatedRulesPayload = {
  __typename?: 'ViolatedRulesPayload';
  _v?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  author?: Maybe<User>;
  communityId?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  created?: Maybe<GqlDateObjectType>;
  deleted?: Maybe<GqlDateObjectType>;
  html: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  rawJSON: Scalars['String'];
  title: Scalars['String'];
  type: Content_Rule_Type;
  updated?: Maybe<GqlDateObjectType>;
};
