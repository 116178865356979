import { UserOnboardingPayload } from '@10x/foundation/types';
import type {
  IOnboardingRepositoryInterface,
  IUserRepository,
} from '@mainApp/src/repositories';
import type { IUserStore } from '@mainApp/src/stores';
import { UserModel } from '@mainApp/src/stores/User.model';

import { SubscriptionTypeEnum } from '@mainApp/src/stores/User.store.types';

export enum StepNamesEnum {
  USERNAME = 'username',
  DISPLAY_NAME = 'displayName',
  AVATAR = 'avatar',
  SUBSCRIPTION = 'subscription',
}

export type BaseData<T> = {
  data: T;
  loading: boolean;
  error: null | string;
  successMessage: string;
};

export type Step<T> = BaseData<T> & {
  // some steps are expected by the complete
  backendStepNumber: number | null;
  getSuccessMessage?: () => string;
  // returns true if there is some error happens
  next: () => Promise<boolean>;
  getPrevStepName: () => null | StepNamesEnum;
  getNextStepName: () => StepNamesEnum | null;
  getIsvalid: () => boolean;
};

export type NameData = {
  value: string;
};

export type UsernameData = NameData & {
  lastStandardValue: string;
};

export type AvatarData = {
  showCropper: boolean;
  preview: null | string;
  file: null | File;
  croppedPreview: null | string;
};

export type SubscriptionData = {
  activeSubscriptionType: SubscriptionTypeEnum;
};

export type Steps = {
  [StepNamesEnum.USERNAME]: Step<UsernameData>;
  [StepNamesEnum.DISPLAY_NAME]: Step<NameData>;
  [StepNamesEnum.AVATAR]: Step<AvatarData>;
  [StepNamesEnum.SUBSCRIPTION]: Step<SubscriptionData>;
};

export type SetStepDataPyaload =
  | NameData
  | AvatarData
  | UsernameData
  | SubscriptionData;

export interface IPostAuthOnboardingStore {
  steps: Steps;
  userRepository: IUserRepository;
  // subscriptionPrices: AccountProUsernameTypeItemPayload[] | null;
  onboardingRepository: IOnboardingRepositoryInterface;
  userStore: IUserStore;
  userPostOnboardingData: UserOnboardingPayload | null | void;
  proMode: boolean;
  completed: boolean;
  activeStepName: StepNamesEnum;
  activeStep: Step<any>;
  show: boolean;

  setStepData: (
    stepName: StepNamesEnum,
    data: SetStepDataPyaload,
    error?: string | null
  ) => void;
  setProMode: (proMode: boolean) => void;
  setShow: (val: boolean) => void;
  setCompleted: (val: boolean) => void;
  switchToStandardUsername: () => void;
  back: () => void;
  next: (skip: boolean) => void;
  updateUsername: () => Promise<boolean>;
  updateDisplayName: () => Promise<boolean>;
  getUserOnboardingData: () => Promise<void>;
  tryToRestore: (data: string | null) => void;
  setupData: (meData: UserModel | null) => void;
  completeUserOnboardingStep: (
    stepNumber: number,
    skipped: boolean
  ) => Promise<boolean>;
  completeUserOnboardingAllSteps: () => Promise<string | null>;
  checkUsernameAvailability: () => Promise<void>;
  validateProUsername: () => void;
  createProUsername: (username: string) => void;
  updateProUsername: (username: string) => void;
  completeSubscription: (failed?: boolean) => Promise<void>;
}
