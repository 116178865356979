'use client';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangesAlert, HashTagLockSVG } from '@foundationPathAlias/components';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { TextInputCommonValidators } from '@10x/foundation/src/components/inputs';
import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { SmallLoader } from '@foundationPathAlias/components/loaders';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  MembersRoleList,
  MembersScrollableListWrapper,
  MembersUserList,
} from '@mainApp/src/modules/channel/common/members-list';
import { SearchEnum } from '@mainApp/src/stores/permissions';

import { NoResult } from '@mainApp/src/modules/channel/common/permissions-components/NoResult';

import { FooterModal } from './FooterModal';
import { HeaderModal } from './HeaderModal';

const _AddMembers = () => {
  const { t } = useTranslation(['common', 'channel']);

  const {
    dimensionsStore: { isMobile },
    createChannelOrGroupStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.createChannelOrGroupStore,
  ]);

  const {
    channelName,
    isGroupMode,
    isSubmitting,
    mode,
    channelGroupName,
    onViewRoleMembers,
    submitCreateChannel,
    submitCreateChannelGroup,
    moveBack,
  } = createChannelOrGroupStore;

  const {
    memberItems,
    roleItems,
    areMembersAndRolesEmpty,
    isSearchActive,
    hasAddedAnyMember,
    addRole,
    addUser,
    removeRole,
    removeUser,
    setupData,
    fetchMoreCommunityMembers,
    setSearchCommunityMembers,
    [SearchEnum.COMMUNITY_MEMBERS]: communityMembersSearch,
  } = createChannelOrGroupStore.permissionsServiceStore;

  let submitAction = submitCreateChannel;
  let successText = t<string>('channel:modal.createChannel');

  if (isGroupMode) {
    submitAction = submitCreateChannelGroup;
    successText = t<string>('channel:modal.createChannelGroup');
  }

  const handleSubmit = async () => {
    await submitAction();
  };

  useEffect(() => {
    setupData();
  }, []);

  const isEmptySearchResult = isSearchActive && memberItems.data?.length === 0;

  return (
    <div className="themed-layout relative flex flex-1 flex-col overflow-hidden rounded-[5px] md:block md:flex-none">
      <HeaderModal
        onBack={moveBack}
        title={t('channel:modal.addMembers')}
        onClose={createChannelOrGroupStore.requestCloseModal}
        showBackButton={true}
      />
      <div className="relative flex flex-1 flex-col">
        <div className="flex flex-1 flex-col px-[16px] pb-[88px] pt-[24px] text-start md:pb-[24px]">
          <div className="flex items-baseline">
            <p className="mb-0 text-body16R text-text-secondary dark:text-text-secondary-dark">
              {t('channel:modal.addPeopleTo')}
            </p>

            <HashTagLockSVG
              className="ml-2 mr-1 text-text-secondary dark:text-text-secondary-dark"
              height={14}
              width={14}
            />

            <b className="text-body16SB text-text-secondary dark:text-text-secondary-dark">
              {mode === 'channel' ? channelName.value : channelGroupName.value}
            </b>
          </div>

          <div className="w-full">
            <TextInputCommonValidators
              inputName="search"
              rootCn="mt-[12px] my-[24px]"
              onChange={setSearchCommunityMembers}
              allowEmptyValue={true}
              value={communityMembersSearch.value}
              rightSideContent={
                communityMembersSearch.loading ? <SmallLoader /> : null
              }
              placeholder={t<string>('channel:modal.findMembersToAdd')}
              leftSideContent={<MagnifyingGlassIcon width={20} height={20} />}
            />
          </div>

          <MembersScrollableListWrapper onScrollEnd={fetchMoreCommunityMembers}>
            {areMembersAndRolesEmpty && (
              <NoResult>{t('channel:modal.noRolesOrMembersFound')}</NoResult>
            )}

            {!isSearchActive && (
              <MembersRoleList
                data={roleItems?.data}
                title={t<string>('channel:modal.roles')}
                onAdd={(roleModel) => {
                  addRole(roleModel);
                }}
                onRemove={(roleModel) => {
                  removeRole(roleModel);
                }}
                onClick={(roleItem) => {
                  onViewRoleMembers(roleItem);
                }}
              />
            )}

            <MembersUserList
              data={memberItems?.data}
              title={t<string>('channel:modal.members')}
              onAdd={(userItem) => {
                addUser(userItem);
              }}
              onRemove={(userItem) => {
                removeUser(userItem);
              }}
              loading={memberItems?.loading}
            />

            {isEmptySearchResult && (
              <NoResult cn="text-body16R">
                {t('channel:modal.noMembersFound')}
              </NoResult>
            )}
          </MembersScrollableListWrapper>
        </div>
      </div>
      <FooterModal
        onCancel={moveBack}
        cancelText={t<string>('common:back')}
        onSuccess={handleSubmit}
        disabledOkButton={!hasAddedAnyMember}
        loading={isSubmitting}
        successText={successText}
        hideCancelButton={isMobile}
        className="absolute bottom-0 mt-auto w-full md:relative md:mt-0"
      />
      <ChangesAlert
        contentWrapperCn="border-[1px]"
        title={t('channel:modal.discardChangesTitle')}
        description={t('channel:modal.discardChangesDescription')}
        descriptionCn="text-left"
        show={createChannelOrGroupStore.discardAlertIsActive}
        onFirstBtnClick={() => {
          createChannelOrGroupStore.setDiscardAlertIsActive(false);
        }}
        onSecondBtnClick={createChannelOrGroupStore.hide}
        firstBtnText={t<string>('channel:modal.keepEditing')}
        secondBtnText={t<string>('channel:modal.closeAndDiscard')}
      />
    </div>
  );
};

export const AddMembers = withAnimatedStack(observer(_AddMembers));
