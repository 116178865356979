import { classNames, useDetectScrollEnd } from '@foundationPathAlias/utilities';
import { useEffect, useRef } from 'react';

type MembersScrollableListWrapperPropsType = {
  onScrollEnd: () => void;
  children: React.ReactNode;
  rootCn?: string;
  /** by default this component triggers onScrollEnd when
   * there is not enough height for the content to scroll.
   * Without it there is no change to trigger onScrollEnd so
   * automatically it will do it when there is no scroll.
   * You can disable this behavior by setting this prop to true
   */
  disableScrollEndTriggerWhenNoScroll?: boolean;
  contenttWrapperCn?: string;
};

export function MembersScrollableListWrapper(
  props: MembersScrollableListWrapperPropsType
) {
  const { onScrollEnd, children, rootCn, contenttWrapperCn } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollEnd } = useDetectScrollEnd(0.1, scrollRef);

  useEffect(() => {
    const scrollContainer = scrollRef.current;

    const checkIfFetchNeeded = () => {
      if (
        scrollContainer &&
        scrollContainer.scrollHeight <= scrollContainer.clientHeight
      ) {
        onScrollEnd();
      }
    };

    checkIfFetchNeeded();
  }, [children, onScrollEnd]);

  useEffect(() => {
    if (scrollEnd) {
      onScrollEnd();
    }
  }, [scrollEnd, onScrollEnd]);

  return (
    <div
      ref={scrollRef}
      className={classNames(
        'relative flex flex-1 flex-col overflow-y-auto scrollbar-thin md:max-h-[270px]',
        rootCn
      )}
    >
      <div
        className={classNames('absolute w-full md:static', contenttWrapperCn)}
      >
        {children}
      </div>
    </div>
  );
}
