import { useTranslation } from 'react-i18next';

import { ItemHeader } from '@mainApp/src/modules/channel/channel-settings/components';
import { AddRolesMembersActionBtn } from './AddRolesMembersActionBtn';

type Props = {
  title: string;
  onAddMembers: () => void;
};

export function NoAddedMembers(props: Props) {
  const { onAddMembers, title } = props;

  const { t } = useTranslation(['common', 'channel']);

  return (
    <div className="pt-[12px]">
      <ItemHeader cn="mb-[12px]">
        <span className="flex w-full justify-between">{title}</span>
      </ItemHeader>

      <div className="flex flex-col items-center">
        <ItemHeader cn="mt-[64px] mb-[16px]">
          {t('channel:modal.noRolesOrMembersAdded')}
        </ItemHeader>
        <AddRolesMembersActionBtn
          onClick={onAddMembers}
          text={t('channel:modal.addMembersAndRoles')}
        />
      </div>
    </div>
  );
}
