import { ContentReactionPayload } from '@10x/foundation/types';
import {
  TooltipHoverContent,
  TooltipHoverContentStyles,
} from '@foundationPathAlias/components';
import { Emoji } from '@foundationPathAlias/forked-thirdparty/emoji-picker-react';
import {
  emojiByUnified,
  emojiName,
} from '@foundationPathAlias/forked-thirdparty/emoji-picker-react/dataUtils/emojiSelectors';
import { classNames, useLongPress } from '@foundationPathAlias/utilities';
import Popup from '@mainApp/src/components/forked-thirdparty/reactjs-popup/src';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { formatReactedNames, mergeStyles } from '@mainApp/src/utils';
import { colorSchema } from '@rootConfig/color-schema';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactionAndCount } from './ReactionAndCount';

type UserReactionPopupProps = {
  index: number;
  reaction: ContentReactionPayload;
  handleEmojiSelection?: (reaction: ContentReactionPayload) => void;
  activePopupIndex: number | null;
  setActivePopupIndex: (index: number | null) => void;
  handleLongPress?: () => void;
  handleUserReaction?: () => void;
  disabled?: boolean;
};

export const UserReactionPopup: React.FC<UserReactionPopupProps> = ({
  index,
  reaction,
  handleEmojiSelection,
  activePopupIndex,
  setActivePopupIndex,
  handleLongPress,
  handleUserReaction,
  disabled,
}) => {
  const { t } = useTranslation(['common']);
  const {
    systemStore,
    userStore: { me },
    dimensionsStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.userStore,
    IOC_TOKENS.dimensionsStore,
  ]);
  const longPressHandlers = useLongPress(() => {
    handleLongPress?.();
  });
  const { text, others } = formatReactedNames(
    reaction,
    me,
    t('and'),
    t('others')
  );

  const reactionName = emojiName(emojiByUnified(reaction.unified));
  const popupContent = useMemo(() => {
    if (text === null) {
      return <TooltipHoverContent label="React" />;
    }

    return (
      <div className="flex h-[90px] w-[353px] flex-row items-center rounded-[5px] bg-background-primary px-[16px] py-[12px] shadow-chip-shadow dark:bg-background-secondary-dark">
        <Emoji unified={reaction.unified} size={30} />
        {/* <div className="mr-4 h-6 w-6 border-2 bg-[yellow]" /> */}
        <div className="ml-[20px] flex flex-col">
          <div className="text-sm14SB text-text-primary dark:text-text-primary-dark">
            :{reactionName}
          </div>
          <div className="text-sm14R text-text-secondary dark:text-text-secondary-dark">
            <span>{text}</span>
            {others && (
              <span
                onClick={handleUserReaction}
                className="cursor-pointer text-sm14SB text-color-12 dark:text-color-12-dark"
              >
                {' '}
                {others}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }, [text, others, handleUserReaction, reaction.unified]);

  const popupProps = useMemo(() => {
    const themeColor = systemStore.isDarkTheme
      ? colorSchema['background-primary']
      : colorSchema['background-primary-dark'];

    return text
      ? {
          offsetY: -15,
          offsetX: 40,
          arrow: false,
        }
      : {
          offsetY: 8,
          offsetX: 0,
          arrow: !dimensionsStore.isMobile && true,
          contentStyle: mergeStyles(TooltipHoverContentStyles.tooltip, {
            backgroundColor: themeColor,
          }),
          arrowStyle: { color: themeColor },
        };
  }, [text, systemStore.isDarkTheme]);

  const triggerClassNames = useMemo(
    () =>
      classNames(
        'mr-2 flex h-[28px] w-[44px] shrink flex-row items-center justify-center rounded-[15px] px-3',
        reaction.reacted
          ? 'border-[1px] border-primary-100 bg-primary-10 dark:border-primary-100-dark dark:bg-primary-10-dark'
          : 'bg-background-secondary dark:bg-background-quaternary-dark  ',
        !disabled &&
          'hover:border-element-subtle hover:bg-hovered-selected active:bg-pressed dark:active:bg-pressed-dark dark:hover:border-element-subtle-dark hover:border-[1px] dark:hover:bg-hovered-selected-dark cursor-pointer'
      ),
    [reaction.reacted]
  );

  return (
    <Popup
      key={index}
      position={['top center', 'bottom center']}
      on={['hover']}
      onOpen={() => setActivePopupIndex(index)}
      onClose={() => setActivePopupIndex(null)}
      open={activePopupIndex === index && !dimensionsStore.isMobile}
      {...popupProps}
      trigger={
        <div
          className={triggerClassNames}
          onClick={() => handleEmojiSelection?.(reaction)}
          onTouchStart={longPressHandlers.onTouchStart as any}
          onTouchEnd={longPressHandlers.onTouchEnd as any}
        >
          <ReactionAndCount
            emojiUnified={reaction.unified}
            count={reaction.count}
          />
        </div>
      }
    >
      {!dimensionsStore.isMobile && popupContent}
    </Popup>
  );
};
