import { gql } from '@urql/core';

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
    count
  }
`;

export const GQLDateObjectTypeFragment = gql`
  fragment GQLDateObjectTypeFragment on GQLDateObjectType {
    user
    when
  }
`;
