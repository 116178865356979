import {
  TextInput,
  TextInputPropsType,
  TextInputRefType,
  validators,
} from '@10x/foundation/src/components';
import { classNames } from '@foundationPathAlias/utilities';
import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

type FormFieldInputProps = {
  textAreaMode?: boolean;
  onChange: (val: string) => void;
  onError?: (error: null | string) => void;
  value: string;
  maxCharsLimit?: number;
  inputName: string;
  label?: string | null;
  cnWrapper?: string;
} & Partial<TextInputPropsType>;

export function FormFieldInput(props: FormFieldInputProps) {
  const {
    textAreaMode,
    onChange,
    onError,
    value,
    maxCharsLimit,
    label,
    cnWrapper,
    ...otherProps
  } = props;

  const inputRef = useRef<TextInputRefType>(null);
  const { t } = useTranslation(['common']);

  // should fill the input only 1 time when return to the filled screen
  useEffect(() => {
    inputRef.current?.setValue(value);
  }, [value]);

  const errorUI = (errorText: React.ReactNode) => (
    <div className="flex text-left text-sm14R">
      <div
        className={classNames(
          'flex-1 text-text-primary dark:text-text-primary-dark ',
          props.errorTextCn
        )}
      >
        {errorText}
      </div>
      {Boolean(maxCharsLimit) && (
        <span className="justify-end text-text-placeholder dark:text-text-placeholder-dark">
          {value?.length}/{maxCharsLimit}
        </span>
      )}
    </div>
  );

  const maxCharValidatorErr = t('errors.maxCharLength', {
    maxCharCount: maxCharsLimit,
  });

  return (
    <div
      className={classNames(
        'mt-[24px] flex flex-col items-start gap-3',
        cnWrapper
      )}
    >
      {label && (
        <label className="text-body16SB text-text-primary dark:text-text-primary-dark">
          {label}
        </label>
      )}

      <TextInput
        textAreaMode={textAreaMode}
        // @ts-ignore
        ref={inputRef}
        maxLength={maxCharsLimit}
        validators={
          maxCharsLimit
            ? [
                validators.getMaxLengthValidator(
                  maxCharsLimit,
                  maxCharValidatorErr
                ),
              ]
            : []
        }
        onChange={(val = '') => {
          onChange(val);
        }}
        onError={onError}
        getCustomErrorUI={errorUI}
        {...otherProps}
      />
    </div>
  );
}
