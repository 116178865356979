'use client';
import { Avatar, TextButtonPrimary } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { CommunityRoleModel } from '@mainApp/src/stores/CommunityRole.model';
import { useTranslation } from 'react-i18next';

export type RoleItemPropsType = {
  model: CommunityRoleModel;
  onAction?: () => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  disableOnClick?: boolean;
  actionLabel: string;
  rootCn?: string;
};

export const RoleItem = (props: RoleItemPropsType) => {
  const { onAction, model, actionLabel, onClick, disableOnClick, rootCn } =
    props;
  const { name, description } = model.serverData;

  const { t } = useTranslation(['common']);

  return (
    <div
      className={classNames(
        'flex items-center',
        onClick &&
          !disableOnClick &&
          'cursor-pointer transition-colors hover:bg-background-hover hover:dark:bg-background-hover-dark',
        rootCn
      )}
    >
      <div
        onClick={disableOnClick ? undefined : onClick}
        className="flex flex-1 items-center gap-[12px] py-[8px]"
      >
        <Avatar
          width={36}
          height={36}
          src={undefined}
          alt={t<string>('common:avatarImage', { name })}
        />

        <div className="flex flex-[1] flex-col items-start">
          <b className="text-sm14T text-text-primary dark:text-text-primary-dark">
            {name}
          </b>

          <small className="text-text-secondary dark:text-text-secondary">
            {description}
          </small>
        </div>
      </div>
      {onAction && (
        <TextButtonPrimary cn="w-auto" onClick={onAction}>
          {actionLabel}
        </TextButtonPrimary>
      )}
    </div>
  );
};
