import {
  MembersList,
  MembersListPropsType,
} from '@mainApp/src/modules/channel/common/members-list/MembersList';
import { useTranslation } from 'react-i18next';

import { RoleItem } from './RoleItem';

import { RoleItem as RoleItemType } from '@mainApp/src/stores/permissions/Persmissions.service.store.types';

type MembersRoleListProps = {
  data: RoleItemType[] | null | void;
  onAdd?: (item: RoleItemType) => void;
  onRemove?: (item: RoleItemType) => void;
  onClick?: (item: RoleItemType) => void;
  disableOnClick?: boolean;
  noTitle?: boolean;
} & Omit<MembersListPropsType, 'children'>;

export function MembersRoleList(props: MembersRoleListProps) {
  const {
    data,
    onAdd,
    onRemove,
    onClick,
    disableOnClick,
    noTitle,
    ...otherProps
  } = props;

  const { t } = useTranslation(['channel', 'common']);
  const title = noTitle ? undefined : t<string>('channel:modal.roles');

  const content = data
    ? data.map((item) => {
        const { model, isAdded } = item;
        let action = onAdd;
        let actionLabel = t('common:add');

        if (isAdded) {
          action = onRemove;
          actionLabel = t('common:remove');
        }
        return (
          <RoleItem
            key={model.serverData.id}
            disableOnClick={disableOnClick}
            model={model}
            onAction={() => {
              action?.(item);
            }}
            onClick={() => {
              onClick?.(item);
            }}
            actionLabel={actionLabel}
          />
        );
      })
    : null;

  return (
    <div>
      <MembersList title={title} {...otherProps}>
        {content}
      </MembersList>
    </div>
  );
}
