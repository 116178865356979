import { DeleteModalContent } from '@mainApp/src/components/common/interactive-modal-content';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { useDeleteChannelGroup } from '@mainApp/src/hooks';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';

type Props = {
  onClose?: () => void;
  onCancel?: () => void;
  onProceed?: (isError: boolean) => void;
  channelGroupModel: ChannelGroupModel;
};

export function _DeleteModaChannelGroupContent(props: Props) {
  const {
    onClose,
    onCancel,
    onProceed: onProceedOuter,
    channelGroupModel,
  } = props;
  const interactiveStore = useInjection(IOC_TOKENS.interactiveStore);

  const { isLoading, deleteChannelGroup } = useDeleteChannelGroup(
    channelGroupModel.communityId,
    channelGroupModel.id
  );

  const { t } = useTranslation(['common', 'channel']);
  const title = t('channel:channelGroupSettings.deleteChannelGroup');

  const onDeleteCancel: () => void = () => {
    interactiveStore.setInteractiveElementOpen(false);
    onCancel?.();
  };

  const onProceed = async () => {
    const isError = await deleteChannelGroup();
    if (!isError) {
      interactiveStore.setInteractiveElementOpen(false);
    }
    onProceedOuter?.(isError);
  };

  return (
    <DeleteModalContent
      title={title}
      onClose={() => {
        interactiveStore.setInteractiveElementOpen(false);
        onClose?.();
      }}
      actionsPanelData={{
        cancelAction: onDeleteCancel,
        proceedAction: onProceed,
        getCancelActionText: () => t('common:cancel'),
        getProceedActionText: () => t('common:delete'),
      }}
      ActionsPanelProps={{
        isLoading: isLoading,
      }}
    >
      <p className="themed-text-secondary text-left text-body16M">
        {t('channel:channelGroupSettings.deleteChannelGroupWarning')}
      </p>
    </DeleteModalContent>
  );
}

export const DeleteModaChannelGroupContent = observer(
  _DeleteModaChannelGroupContent
);
