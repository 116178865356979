import React, { CSSProperties, ReactNode, useMemo } from 'react';

export type MasonryHeightGridPropsType = {
  children: ReactNode;
  columnsCount?: number;
  /** gutter between the items */
  gutter?: number;
  /** HTML tag tag will be used to wrap every item. Default - div */
  itemTag?: keyof JSX.IntrinsicElements;
  /** inline style that will be applied to an item tag */
  itemStyle?: CSSProperties;
  className?: string;
  /** inline style that will be applied to a container tag */
  style?: CSSProperties;
  /** HTML tag tag will be used to wrap every container (column). Default - div */
  containerTag?: keyof JSX.IntrinsicElements;
};

export function MasonryHeightGrid(props: MasonryHeightGridPropsType) {
  const {
    children,
    columnsCount = 4,
    gutter = 10,
    itemTag = 'div',
    itemStyle = {},
    className,
    style = {},
    containerTag = 'div',
  } = props;

  const columns: React.ReactElement[][] = Array.from(
    { length: columnsCount },
    () => []
  );

  const memoizedColumns = useMemo(() => {
    let validIndex = 0;
    React.Children.forEach(children, (child) => {
      if (child && React.isValidElement(child)) {
        columns[validIndex % columnsCount].push(child);
        validIndex++;
      }
    });
    return columns;
  }, [children, columnsCount]);

  const mappedColumnsWithChildren = useMemo(() => {
    return memoizedColumns.map((column, i) => {
      return React.createElement(
        itemTag,
        {
          key: i,
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignContent: 'stretch',
            flex: 1,
            width: 0,
            gap: gutter,
            ...itemStyle,
          },
        },
        column.map((item) => item)
      );
    });
  }, [memoizedColumns, itemTag, gutter, itemStyle]);

  return React.createElement(
    containerTag,
    {
      style: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'stretch',
        boxSizing: 'border-box',
        width: '100%',
        gap: gutter,
        ...style,
      },
      className,
    },
    mappedColumnsWithChildren
  );
}
