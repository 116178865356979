import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import _throttle from 'lodash/throttle';

import { CommunityCard } from '@foundationPathAlias/components/community-card';
import {
  MasonryHeightGrid,
  MasonryHeightGridResponsiveWrapper,
} from '@foundationPathAlias/components/grids/masonry-height-grid';
import { SmallLoader } from '@foundationPathAlias/components/loaders';

import { CLOUDFLARE_VARIANTS_ENUM } from '@foundationPathAlias/utilities/getCloudflareSizeRecognition';

import { paths, systemConfig } from '@mainApp/src/config';
import { GlobalEvents } from '@mainApp/src/events';

import { detectScrollEnd } from '@foundationPathAlias/utilities';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { CommunityCardLoader } from '@mainApp/src/components/loaders';
import { SectionTitle } from './SectionTitle';

type Props = {
  isWebsiteMode?: boolean;
};

export function _AllCommunitiesSection(props: Props) {
  const { isWebsiteMode } = props;
  const {
    communityStore,
    systemStore: { isDarkTheme },
    eventBus,
  } = useMultipleInjection([
    IOC_TOKENS.communityStore,
    IOC_TOKENS.systemStore,
    IOC_TOKENS.eventBus,
  ]);

  const rootElRef = useRef<HTMLElement | null>(null);

  const element =
    globalThis.document.scrollingElement || globalThis.document.documentElement;

  const handleScroll = useCallback(
    _throttle(() => {
      const rootEl = rootElRef.current;
      if (!(rootEl instanceof HTMLElement)) {
        return;
      }

      const screenBottomOfTheRootEl =
        element.scrollHeight - (rootEl.offsetTop + rootEl.offsetHeight);

      const threshold =
        screenBottomOfTheRootEl + systemConfig.scrollToBottomThreshold;

      if (detectScrollEnd(element, threshold)) {
        handleSidebarScrollEnd();
      }
    }, systemConfig.throttleDelay),
    []
  );

  useEffect(() => {
    communityStore.getNewestCommunities();

    if (isWebsiteMode) {
      globalThis.document.addEventListener('scroll', handleScroll);

      return () => {
        globalThis.document.removeEventListener('scroll', handleScroll);
      };
    }

    // should happen only on the explore page (product mode with sidebar layout)
    eventBus.on(
      GlobalEvents.PRIMARY_SIDEBAR_CONTENT_SCROLL_REACHED_BOTTOM,
      handleSidebarScrollEnd
    );

    return () => {
      eventBus.removeListener(
        GlobalEvents.PRIMARY_SIDEBAR_CONTENT_SCROLL_REACHED_BOTTOM,
        handleSidebarScrollEnd
      );
    };
  }, [isWebsiteMode]);

  function handleSidebarScrollEnd() {
    communityStore.fetchMoreNewestCommunities();
  }

  const { t } = useTranslation(['common', 'create-community']);

  const newestCommunities = communityStore.newestCommunities;
  const newestCommunitiesData = newestCommunities.data.valuesArray;

  const renderCommunityCards = () => {
    // show skeletons only on the initial loading. Pagination shouldn't use it
    if (newestCommunities.loading && !newestCommunitiesData.length) {
      return [...Array(8)].map((_community, index) => {
        return (
          <CommunityCardLoader key={`newest-communities-loader--${index}`} />
        );
      });
    }

    if (!newestCommunitiesData || newestCommunitiesData.length === 0) {
      return null;
    }

    return newestCommunitiesData.map((newestCommunity) => {
      const communityModel = newestCommunity.community;
      const { slug, members, name, description, color, topics } =
        communityModel.serverData;
      const newPath = paths.getCommunityPath(slug);
      const imageSrc = communityModel.getThumbnailUrl(
        CLOUDFLARE_VARIANTS_ENUM.MEDIUM
      );
      const logoSrc = communityModel.getLogoUrl(
        CLOUDFLARE_VARIANTS_ENUM.MEDIUM
      );
      const membersCount = members.totalCount;
      const membersStr =
        membersCount > 0
          ? t('members', { count: membersCount })
          : t('noMembers');
      return (
        <a href={newPath} target="__blank" key={`a_${name}`}>
          <CommunityCard
            key={name}
            imageSrc={imageSrc}
            logoSrc={logoSrc}
            description={description}
            name={name}
            membersCount={membersCount}
            membersCountStr={membersStr}
            communityColor={isDarkTheme ? color.dark : color.light}
            topics={topics}
            cn="w-full h-[380px]"
          />
        </a>
      );
    });
  };

  return (
    <section
      ref={rootElRef}
      className="relative min-h-[110px] flex-1 px-[12px] md:px-[48px]"
    >
      <SectionTitle title={t('common:allCommunities')} />
      <MasonryHeightGridResponsiveWrapper>
        <MasonryHeightGrid gutter={24}>
          {renderCommunityCards()}
        </MasonryHeightGrid>
      </MasonryHeightGridResponsiveWrapper>

      {communityStore.newestCommunities.loading &&
        Boolean(newestCommunitiesData?.length) && (
          <div className=" absolute bottom-[-48px] left-[50%] flex translate-x-[-50%] items-center justify-center">
            <SmallLoader width={42} height={42} />
          </div>
        )}
    </section>
  );
}

export const AllCommunitiesSection = observer(_AllCommunitiesSection);
