import { classNames } from '@foundationPathAlias/utilities';
import { ButtonBase, ButtonBasePropsType } from './ButtonBase';
export type TextButtonPrimaryPropsType = {
  // use default community colors theme for the button text
  dynamicColorsMode?: boolean;
} & ButtonBasePropsType;

export function TextButtonPrimary(props: TextButtonPrimaryPropsType) {
  const { cn, dynamicColorsMode, ...otherProps } = props;
  return (
    <ButtonBase
      {...otherProps}
      cn={classNames(
        'w-auto bg-transparent active:opacity-20  px-[8px]',
        dynamicColorsMode
          ? 'themed-btn-text'
          : 'text-primary-100 hover:text-primary-hover active:!bg-transparent ',
        cn
      )}
    />
  );
}
