import { useTranslation } from 'react-i18next';

export enum ALACARTE_PLAN_TYPE {
  CONTENT_TYPES = 'contentTypes',
  WEBSITE_EMBEDS = 'websiteEmbeds',
  ADD_ONS = 'addOns',
  ACTIVE_COMMUNITY = 'activeCommunity',
}

export const usePlanDetails = () => {
  const { t } = useTranslation(['pricing']);

  return {
    free: {
      id: 'free',
      title: t('pricing:free.title'),
      description: t('pricing:free.description'),
      priceText: t('pricing:free.priceText'),
      price: 0,
      offer: '',
      features: [
        t('pricing:free.features.messageHistory'),
        t('pricing:free.features.messages'),
        t('pricing:free.features.images'),
        t('pricing:free.features.roles'),
        t('pricing:free.features.members'),
      ],
      styles: {
        outerCard:
          'relative flex-grow w-[310px] md:w-[427px] flex-col bg-background-primary dark:bg-background-website-dark flex justify-center rounded-[22px] h-[619px]',
        innerCard:
          'mx-[2px] mb-[2px] mt-[50px] bg-background-secondary dark:bg-pro-dark rounded-[20px] p-[24px] flex flex-col h-full',
        button:
          'h-[40px] rounded-[20px] border-[.5px] w-full border-element-subtle text-sm14SB themed-text',
        buttonRules:
          'h-[40px] rounded-full bg-brand dark:bg-color-16 px-3 py-1 text-sm14SB text-common-color-3 dark:text-primary-100-dark text-center',
        outerCardRules:
          'relative flex-grow w-[310px] md:w-[465px] flex-col bg-color-14 md:dark:bg-color-15 md:bg-color-14 dark:bg-background-secondary-dark  flex justify-center rounded-[22px] h-[555px]',
        innerCardRules:
          'mx-[2px] mb-[2px] bg-background-secondary-1/0 md:dark:bg-color-15/0 md:bg-color-14/0 dark:bg-background-secondary-dark/0 rounded-[20px] p-[24px] flex flex-col h-[555px]',
      },
    },
    highlighted: {
      id: 'paid',
      title: t('pricing:highlighted.title'),
      description: t('pricing:highlighted.description'),
      priceText: t('pricing:highlighted.priceText'),
      price: 0.5,
      offer: t('pricing:highlighted.offer'),
      features: [
        t('pricing:highlighted.features.messageHistory'),
        t('pricing:highlighted.features.messages'),
        t('pricing:highlighted.features.images'),
        t('pricing:highlighted.features.roles'),
        t('pricing:highlighted.features.members'),
        t('pricing:highlighted.features.channels'),
      ],
      styles: {
        outerCard:
          'relative flex-grow w-[310px] md:w-[427px] flex-col bg-gradient-to-r from-tab-customize to-tab-create flex justify-center rounded-[22px] h-[619px]',
        innerCard:
          'mx-[2px] mb-[2px] mt-[50px] bg-background-primary dark:bg-background-website-dark rounded-[20px] p-[24px] flex flex-col h-full',
        button:
          'h-[40px] rounded-[20px] w-full bg-gradient-to-r from-tab-customize to-tab-create text-sm14SB text-background-primary',
        buttonRules:
          'h-[40px] rounded-[20px] w-full bg-gradient-to-r from-tab-customize to-tab-create text-sm14SB text-background-primary text-center',
        outerCardRules:
          'relative flex-grow w-[310px] md:w-[465px] flex-col bg-gradient-to-r from-tab-customize to-tab-create flex justify-center rounded-[22px] h-[555px]',
        innerCardRules:
          'mx-[2px] mb-[2px] mt-[10px] bg-background-primary dark:bg-background-tetriary-dark rounded-[20px] p-[24px] flex flex-col h-[545px]',
      },
    },
  };
};

export const usePricingDataRules = () => {
  const { t } = useTranslation();

  return [
    {
      type: ALACARTE_PLAN_TYPE.ACTIVE_COMMUNITY,
      sectionTitle: t('pricing:sections.activeCommunity'),
      items: [
        {
          title: t('pricing:items.posts.title'),
          price: 25,
          features: [
            t('pricing:items.posts.features.included'),
            t('pricing:items.posts.features.trial'),
          ],
        },
        {
          title: t('pricing:items.websiteBlocks.title'),
          price: 25,
          features: [
            t('pricing:items.websiteBlocks.features.views'),
            t('pricing:items.websiteBlocks.features.ai'),
            t('pricing:items.websiteBlocks.features.trial'),
          ],
        },
      ],
    },
    {
      type: ALACARTE_PLAN_TYPE.CONTENT_TYPES,
      sectionTitle: t('pricing:sections.contentTypes'),
      items: [
        {
          title: t('pricing:items.messages.title'),
          price: 25,
          features: [
            t('pricing:items.messages.features.included'),
            t('pricing:items.messages.features.history'),
            t('pricing:items.messages.features.attachments'),
            t('pricing:items.messages.features.trial'),
          ],
        },
        {
          title: t('pricing:items.courses.title'),
          price: 25,
          features: [
            t('pricing:items.courses.features.channel'),
            t('pricing:items.courses.features.included'),
            t('pricing:items.courses.features.trial'),
          ],
        },
      ],
    },
    {
      type: ALACARTE_PLAN_TYPE.WEBSITE_EMBEDS,
      sectionTitle: t('pricing:sections.websiteEmbeds'),
      items: [
        {
          title: t('pricing:items.customerSupport.title'),
          price: 25,
          features: [
            t('pricing:items.customerSupport.features.included'),
            t('pricing:items.customerSupport.features.trial'),
          ],
        },
      ],
    },
    {
      type: ALACARTE_PLAN_TYPE.ADD_ONS,
      sectionTitle: t('pricing:sections.addOns'),
      items: [
        {
          title: t('pricing:items.admin.title'),
          price: 10,
          features: [t('pricing:items.admin.features.included')],
          allowQuantity: true,
        },
        {
          title: t('pricing:items.customRoles.title'),
          price: 10,
          features: [t('pricing:items.customRoles.features.included')],
          allowQuantity: true,
        },
        {
          title: t('pricing:items.moderators.title'),
          price: 10,
          features: [t('pricing:items.moderators.features.included')],
          allowQuantity: true,
        },
        {
          title: t('pricing:items.channels.title'),
          price: 10,
          features: [t('pricing:items.channels.features.included')],
          allowQuantity: true,
        },
        {
          title: t('pricing:items.privateCommunity.title'),
          price: 10,
          features: [t('pricing:items.privateCommunity.features.included')],
        },
        {
          title: t('pricing:items.aiContent.title'),
          price: 10,
          features: [t('pricing:items.aiContent.features.included')],
        },
      ],
    },
  ];
};

export const usePricingData = () => {
  const { t } = useTranslation();

  return [
    {
      type: ALACARTE_PLAN_TYPE.CONTENT_TYPES,
      sectionTitle: t('pricing:sections.contentTypes'),
      items: [
        {
          title: t('pricing:items.messages.title'),
          price: 25,
          features: [
            t('pricing:items.messages.features.included'),
            t('pricing:items.messages.features.history'),
            t('pricing:items.messages.features.attachments'),
            t('pricing:items.messages.features.trial'),
          ],
        },
        {
          title: t('pricing:items.posts.title'),
          price: 25,
          features: [
            t('pricing:items.posts.features.included'),
            t('pricing:items.posts.features.pricing'),
            t('pricing:items.posts.features.trial'),
          ],
        },
        {
          title: t('pricing:items.courses.title'),
          price: 50,
          features: [
            t('pricing:items.courses.features.channel'),
            t('pricing:items.courses.features.included'),
            t('pricing:items.courses.features.additional'),
            t('pricing:items.courses.features.trial'),
          ],
        },
      ],
    },
    {
      type: ALACARTE_PLAN_TYPE.WEBSITE_EMBEDS,
      sectionTitle: t('pricing:sections.websiteEmbeds'),
      items: [
        {
          title: t('pricing:items.websiteBlocks.title'),
          price: 25,
          features: [
            t('pricing:items.websiteBlocks.features.views'),
            t('pricing:items.websiteBlocks.features.ai'),
            t('pricing:items.websiteBlocks.features.trial'),
          ],
        },
        {
          title: t('pricing:items.customerSupport.title'),
          price: 25,
          features: [
            t('pricing:items.customerSupport.features.included'),
            t('pricing:items.customerSupport.features.trial'),
          ],
        },
      ],
    },
    {
      type: ALACARTE_PLAN_TYPE.ADD_ONS,
      sectionTitle: t('pricing:sections.addOns'),
      items: [
        {
          title: t('pricing:items.admin.title'),
          price: 20,
          features: [t('pricing:items.admin.features.included')],
          allowQuantity: true,
        },
        {
          title: t('pricing:items.customRoles.title'),
          price: 10,
          features: [t('pricing:items.customRoles.features.included')],
          allowQuantity: true,
        },
        {
          title: t('pricing:items.moderators.title'),
          price: 10,
          features: [t('pricing:items.moderators.features.included')],
          allowQuantity: true,
        },
        {
          title: t('pricing:items.channels.title'),
          price: 10,
          features: [t('pricing:items.channels.features.included')],
          allowQuantity: true,
        },
        {
          title: t('pricing:items.privateCommunity.title'),
          price: 10,
          features: [t('pricing:items.privateCommunity.features.included')],
        },
        {
          title: t('pricing:items.aiContent.title'),
          price: 10,
          features: [t('pricing:items.aiContent.features.included')],
        },
      ],
    },
  ];
};

export const useGetFreeAddOns = () => {
  const { t } = useTranslation(['pricing']);
  return [
    t('pricing:freeAddOns.admins'),
    t('pricing:freeAddOns.customRoles'),
    t('pricing:freeAddOns.moderators'),
    t('pricing:freeAddOns.channels'),
  ];
};

export const PRICING_THRESHOLD_FOR_FREE_ADD_ONS = {
  min: 20,
  max: 50,
};

export const usePricingFreeAddOns = () => {
  const { t } = useTranslation(['pricing']);

  return [
    {
      title: t('pricing:pricingFreeAddOns.admin.title'),
      features: [t('pricing:pricingFreeAddOns.admin.features.included')],
      price: 20,
    },
    {
      title: t('pricing:pricingFreeAddOns.customRoles.title'),
      features: [t('pricing:pricingFreeAddOns.customRoles.features.included')],
      price: 10,
    },
    {
      title: t('pricing:pricingFreeAddOns.moderators.title'),
      features: [t('pricing:pricingFreeAddOns.moderators.features.included')],
      price: 10,
    },
    {
      title: t('pricing:pricingFreeAddOns.channels.title'),
      features: [t('pricing:pricingFreeAddOns.channels.features.included')],
      price: 10,
    },
  ];
};
