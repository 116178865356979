import _debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
import { useCallback, useRef } from 'react';

import { LoadingStatusEnum } from '@mainApp/src/stores/types';
import { FixedCropperRef } from 'react-advanced-cropper';
import { useTranslation } from 'react-i18next';
import { DropZone } from './DropZone';

import { LoadedImagePreview } from './LoadedImagePreview';

import {
  CropperPropsType,
  CropperSnapshot,
} from '@10x/foundation/src/components/cropper';
import { systemConfig } from '@mainApp/src/config';

const CropperFND = dynamic(
  () => {
    return import('@10x/foundation/src/components/cropper').then((res) => {
      return res.Cropper;
    });
  },
  { ssr: false }
);

type ImageContainerProps = {
  label: string;
  fileSrc?: string | null;
  onFileCatch: (file: FileList, originalFileSrc: string) => void;
  onCancel: () => void;
  onFileCrop: (file: File) => void;
  onSave: () => void;
  uploadingStatus: LoadingStatusEnum;
  uploadingPercentage: number;
  cropperSnapshot: CropperSnapshot | null;
  setCropperSnapshot: (snapshot: CropperSnapshot) => void;
  error: string | void;
  fileName: string | null;
  fileSize: number | null;
  cropperProps?: Partial<CropperPropsType>;
};

export const UploadImageContainer = (props: ImageContainerProps) => {
  const {
    fileSrc,
    label,
    onFileCatch,
    onSave,
    onFileCrop,
    onCancel,
    setCropperSnapshot,
    cropperSnapshot,
    uploadingStatus,
    uploadingPercentage,
    error,
    fileName,
    fileSize,
    cropperProps = {},
  } = props;
  const src = fileSrc as string;

  const cropperRef = useRef<FixedCropperRef | null>(null);
  const { t } = useTranslation(['common']);

  const srcRef = useRef<any | null>(src);

  const onCropDeb = useCallback(
    _debounce((cropper) => {
      cropper.getCanvas().toBlob((blob: any) => {
        onFileCrop(blob);
      });
    }, systemConfig.debounceDelay),
    []
  );

  const reset = () => {
    cropperRef.current?.reset?.();
    onCancel?.();
    srcRef.current = null;
  };

  const nonDropZoneContent =
    uploadingStatus === LoadingStatusEnum.initial ? (
      <div>
        <CropperFND
          src={src}
          onUpdate={(cropper, snapshot) => {
            setCropperSnapshot(snapshot);
            onCropDeb(cropper);
          }}
          ref={cropperRef}
          snapshot={cropperSnapshot}
          onZoomChange={(snapshot) => {
            setCropperSnapshot(snapshot);
          }}
          renderRangeSlider={(rangeSlider) => {
            return (
              <div className="mb-[24px]">
                <p className="mb-[16px] mt-[24px] text-center text-body16SB">
                  {label}
                </p>
                {rangeSlider}
              </div>
            );
          }}
          {...cropperProps}
        />

        <div className="flex space-x-[16px]">
          <button
            className="hover-el themed-text radius-[2px] flex-1 border border-[element-normal] px-[24px] py-[9px] text-body16R"
            onClick={reset}
          >
            {t('cancel')}
          </button>
          <button
            className="hover-el ml-[16px] flex-1 bg-primary-100 px-[24px] py-[9px] text-body16R text-text-primary-dark"
            onClick={onSave}
          >
            {t('save')}
          </button>
        </div>
      </div>
    ) : uploadingStatus === LoadingStatusEnum.completed ? (
      <div className="flex items-center gap-3 rounded-md border border-element-subtle bg-background-primary p-2 pr-4 dark:border-element-normal-dark dark:bg-background-tetriary-dark">
        <img
          className="h-10 w-10 rounded object-cover"
          width={40}
          height={40}
          src={src}
          alt={'logo image preview'}
        />

        <span className="text-body16R text-text-primary dark:text-text-primary-dark">
          {label}
        </span>

        <button
          onClick={reset}
          className="ml-auto text-sm14T text-primary-100-dark"
          type="button"
        >
          {t('change')}
        </button>
      </div>
    ) : (
      <LoadedImagePreview
        error={error}
        fileName={fileName}
        fileSize={fileSize}
        onCloseClick={reset}
        uploading={uploadingStatus === LoadingStatusEnum.uploading}
        uploadingPercentage={uploadingPercentage}
      />
    );

  return (
    <div className="mt-[24px] w-full md:max-w-[338px]">
      {src ? (
        nonDropZoneContent
      ) : (
        <DropZone
          onFileCatch={(files: FileList) => {
            const file = files[0];
            const fileUrl = URL.createObjectURL(file);
            if (file) {
              srcRef.current = fileUrl;
            }

            onFileCatch(files, fileUrl);
          }}
        />
      )}
    </div>
  );
};
