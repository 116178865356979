// TODO: this service store should contain all logic about the permissions for create channel | group and channel | group settings. and this service store isn't a singletone but it's just a class that should just be used inside the other stores
import { inject, injectable } from 'inversify';

import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import _debounce from 'lodash/debounce';

import {
  Channel_Group_Member_Action,
  Channel_Group_Member_Type,
  Channel_Member_Action,
  Channel_Member_Type,
  ChannelAddMemberInput,
  ChannelGroupAddMemberInput,
  Community_Member_Module_Types,
  Role_Levels,
  Role_List_Types,
} from '@10x/foundation/types';

import { invariant } from '@foundationPathAlias/utilities';
import { IOC_TOKENS } from '@mainApp/src/ioc';
import {
  ArrayOrientedOrderedRegistryService,
  CommonApiDataShapeType,
  CommunityRoleModel,
  GetChannelGroupMembersVariables,
  type IChannelStore,
  type ISystemStore,
  type IToastStore,
} from '@mainApp/src/stores';

import { ApiBase, IterableDataShape } from '@mainApp/src/stores/ApiBase';

import type {
  GetChannelMembersVariables,
  ICommunityRepository,
} from '@mainApp/src/repositories';

import { UserModel } from '@mainApp/src/stores/User.model';

import { type ICommunityStore } from '@mainApp/src/stores/Community.store.types';

import type { IReactionsRegistryService } from '@mainApp/src/stores/data-objects/ReactionsRegistry.service';

import {
  IPermissionsServiceStore,
  LoadSavedUsersOptions,
  ReactionsEnum,
  RoleItem,
  UserItem,
} from './Persmissions.service.store.types';

import {
  ChannlOrGroupFetchMembersPayload,
  SearchEnum,
} from './Persmissions.service.store.types';

const initialSearchItem = {
  value: '',
  error: null,
  loading: false,
  /** if it's a first initial search the appropriate fields might keep
   * the data from the normal data fetch without search. Before the search need to clear the data because the search data will become important. After search it must be set to false
   */
  hasResetDataBeforeSearch: false,
};

const DEBOUNCE_TIMEOUT = 500;

@injectable()
export class PermissionsServiceStore
  extends ApiBase
  implements IPermissionsServiceStore
{
  communityStore: ICommunityStore;
  communityRepository: ICommunityRepository;
  channelStore: IChannelStore;
  systemStore: ISystemStore;

  get isDirty() {
    const usersDelta =
      this.addedMembersReg.itemsLn !== this.savedUserMembers.data?.length;
    const rolesDelta =
      this.addedRolesReg.itemsLn !== this.savedRoleMembers.data?.length;

    return usersDelta || rolesDelta;
  }
  addedMembersSnapshot = {
    data: {},
    length: 0,
  };
  addedRolesSnapshot = {
    data: {},
    length: 0,
  };

  mode: 'group' | 'channel' = 'channel';
  // escape hatch for the search. To avoid multiple param passing through
  activeChannelOrGroupId = '';
  debouncedLoadMoreSavedMembers: () => Promise<void>;
  debouncedLoadMoreMembers: () => Promise<void>;
  private savedUserMembersLimit = 20;

  private reactionsRegistryService: IReactionsRegistryService;

  [SearchEnum.COMMUNITY_MEMBERS] = { ...initialSearchItem };
  [SearchEnum.SAVED_MEMBERS] = { ...initialSearchItem };

  // selected role for members view
  selectedRole: CommunityRoleModel | null = null;

  roleItems: CommonApiDataShapeType<RoleItem[] | null> =
    ApiBase.generateCommonApiDataShape();

  memberItems: CommonApiDataShapeType<UserItem[] | null> =
    ApiBase.generateCommonApiDataShape();

  // members (users) that were added and saved on BE
  savedUserMembers: CommonApiDataShapeType<UserItem[] | null> =
    ApiBase.generateCommonApiDataShape();
  // members (roles) that were added and saved on BE
  savedRoleMembers: CommonApiDataShapeType<RoleItem[] | null> =
    ApiBase.generateCommonApiDataShape();

  addedMembersReg: ArrayOrientedOrderedRegistryService<UserItem> =
    new ArrayOrientedOrderedRegistryService();
  addedRolesReg: ArrayOrientedOrderedRegistryService<RoleItem> =
    new ArrayOrientedOrderedRegistryService();

  get isGroupMode() {
    return this.mode === 'group';
  }

  constructor(
    @inject(IOC_TOKENS.reactiosnRegistryService)
    reactionsRegistryService: IReactionsRegistryService,
    @inject(IOC_TOKENS.communityStore) communityStore: ICommunityStore,
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.communityRepository)
    communityRepository: ICommunityRepository,
    @inject(IOC_TOKENS.channelStore) channelStore: IChannelStore,
    @inject(IOC_TOKENS.systemStore) systemStore: ISystemStore
  ) {
    super(toastStore);
    this.reactionsRegistryService = reactionsRegistryService;
    this.communityStore = communityStore;
    this.communityRepository = communityRepository;
    this.channelStore = channelStore;
    this.systemStore = systemStore;

    makeObservable(this, {
      isSearchActive: computed,
      selectedRoleMembers: computed,
      hasAddedAnyMember: computed,
      areMembersAndRolesEmpty: computed,
      isSelectedRoleMembersLoading: computed,
      isDirty: computed,

      [SearchEnum.COMMUNITY_MEMBERS]: observable,
      [SearchEnum.SAVED_MEMBERS]: observable,

      addedRolesReg: observable,
      addedMembersReg: observable,

      savedRoleMembers: observable,
      savedUserMembers: observable,
      memberItems: observable,
      roleItems: observable,

      selectedRole: observable,
      addedMembersSnapshot: observable,
      addedRolesSnapshot: observable,

      loadSavedRoleMembers: action,
      loadSavedUserMembers: action,
      loadMoreSavedMembers: action,

      setCommunityMembers: action,
      setSearchCommunityMembers: action,
      setSearchSavedMembers: action,
      addRole: action,
      removeRole: action,
      addUser: action,
      removeUser: action,
      setSelectedRole: action,
      resetSearch: action,
      reset: action,
    });

    this.debouncedLoadMoreMembers = _debounce(
      this.loadMoreMembers.bind(this),
      DEBOUNCE_TIMEOUT
    ) as () => Promise<void>;

    this.debouncedLoadMoreSavedMembers = _debounce(
      this.loadMoreSavedMembers,
      DEBOUNCE_TIMEOUT
    ) as () => Promise<void>;
  }

  get areMembersAndRolesEmpty() {
    return !this.memberItems.data?.length && !this.roleItems.data?.length;
  }

  get activeCommunityId() {
    const id = this.communityStore.activeCommunity.data?.serverData.id;
    if (!id) {
      throw new Error('There is no active community id');
    }
    return id;
  }

  get isSelectedRoleMembersLoading() {
    return Boolean(
      !this.selectedRoleMembers && this.selectedRole?.roleMembers.loading
    );
  }

  get isSearchActive() {
    return Object.values(SearchEnum).some((key) => this[key].value);
  }

  get selectedRoleMembers(): UserItem[] | void {
    return this.selectedRole?.roleMembers.data?.valuesArray.map(
      (roleMember) => {
        const isAdded = this.addedRolesReg.hasKey(roleMember.id);

        return {
          isAdded: isAdded,
          model: roleMember,
        };
      }
    );
  }

  get hasAddedAnyMember() {
    return Boolean(this.addedMembersReg.itemsLn || this.addedRolesReg.itemsLn);
  }

  setupReactions = () => {
    this.reactionsRegistryService.addReaction(
      ReactionsEnum.GET_COMMUNITY_MEMBERS_REACTION,
      () => this.communityStore.members,
      this.handleCommunityMembersReaction
    );
  };

  private handleCommunityMembersReaction = (
    membersProp: CommonApiDataShapeType<IterableDataShape<UserModel> | null>
  ) => {
    const { data, loading } = membersProp;
    runInAction(() => {
      this.memberItems = {
        ...this.memberItems,
        loading: loading,
      };
    });
    if (!loading) {
      this.setCommunityMembers(data?.valuesArray);
    }
  };

  setCommunityMembers = (members: UserModel[] | undefined) => {
    if (!members) return;
    const memberItemsList = members.map((member) => {
      return {
        model: member,
        isAdded: member.isModuleMember,
      };
    });

    this.memberItems = {
      ...this.memberItems,
      data: memberItemsList,
      loading: false,
    };
  };

  setActiveChannelOrGroupId = (channelOrGroupId: string) => {
    this.activeChannelOrGroupId = channelOrGroupId;
  };

  loadSavedRoleMembers = async (channelOrGroupId: string) => {
    this.savedRoleMembers = {
      ...this.savedRoleMembers,
      loading: true,
    };

    let loadAction = this.channelStore.getChannelRoleMembers;
    let payload: ChannlOrGroupFetchMembersPayload = {
      communityId: this.activeCommunityId,
      channelId: channelOrGroupId,
      noCache: true,
    };
    if (this.isGroupMode) {
      loadAction = this.channelStore.getChannelGroupRoleMembers;
      payload = {
        communityId: this.activeCommunityId,
        channelGroupId: channelOrGroupId,
        noCache: true,
      };
    }
    const { data, pageInfo, error, originalResponse } = await loadAction(
      payload
    );

    if (!data) return;

    const mappedRoles = data.map((roleEdge) => {
      const role = roleEdge.node;

      const membersCount = role.members.edges.length;
      const description = this.systemStore.i18n?.t(
        'channel:modal.roleMembers',
        {
          roleName: membersCount,
        }
      ) as string;

      const model = new CommunityRoleModel({
        toastStore: this.toastStore,
        communityRepository: this.communityRepository,
        communityRolePayload: {
          ...role,
          description,
          communityId: this.activeCommunityId,
        },
      });

      return {
        model,
        isAdded: true,
      };
    });

    this.addedRolesReg.addOrReplaceItems(mappedRoles, (item) => {
      return item.model.serverData?.id as string;
    });

    this.savedRoleMembers = {
      ...this.savedRoleMembers,
      data: [...(this.savedRoleMembers.data || []), ...mappedRoles],
      loading: false,
      error: error,
      meta: {
        originalResponse,
        pageInfo,
      },
    };
  };

  loadSavedUserMembers = async (
    channelOrGroupId: string,
    options: LoadSavedUsersOptions = {}
  ) => {
    this.savedUserMembers = {
      ...this.savedUserMembers,
      loading: true,
    };

    const payloadParams = options;
    let loadAction = this.channelStore.getChannelUserMembers;
    let payload: GetChannelMembersVariables | GetChannelGroupMembersVariables =
      {
        communityId: this.activeCommunityId,
        channelId: channelOrGroupId,
        first: this.savedUserMembersLimit,
        noCache: true,
        ...payloadParams,
      };
    if (this.isGroupMode) {
      loadAction = this.channelStore.getChannelGroupUserMembers;
      payload = {
        communityId: this.activeCommunityId,
        channelGroupId: channelOrGroupId,
        first: this.savedUserMembersLimit,
        noCache: true,
        ...payloadParams,
      };
    }

    const { data, pageInfo, error, originalResponse } = await loadAction(
      payload
    );

    if (!data) return;

    const mappedMembers = data.map((edge) => {
      const member = new UserModel(edge.node);
      return {
        model: member,
        isAdded: true,
      };
    });

    this.addedMembersReg.addOrReplaceItems(
      mappedMembers,
      (item) => item.model.serverData.id
    );

    this.savedUserMembers = {
      ...this.savedUserMembers,
      data: [...(this.savedUserMembers.data || []), ...mappedMembers],
      error: error,
      loading: false,
      meta: {
        originalResponse,
        pageInfo,
      },
    };

    return;
  };

  loadCommunityMembers = async () => {
    return await this.communityStore.getCommunityMembers({
      communityId: this.activeCommunityId,
      module: {
        id: this.activeChannelOrGroupId,
        type: this.isGroupMode
          ? Community_Member_Module_Types.ChannelGroup
          : Community_Member_Module_Types.Channel,
      },
    });
  };

  fetchMoreCommunityMembers = async () => {
    return await this.communityStore.fetchMoreMembers();
  };

  setMode = (mode: 'group' | 'channel') => {
    this.mode = mode;
  };

  loadCommunityRoles = async (
    communityId: string,
    listType: Role_List_Types = Role_List_Types.All
  ) => {
    this.roleItems = {
      ...this.roleItems,
      loading: true,
    };

    const { data = [], error } =
      await this.communityRepository.getCommunityRoles(communityId, {
        listType,
        roleLevels: [Role_Levels.Member, Role_Levels.Custom],
      });

    if (error) {
      this.handleError('Community Roles', error.message);
      return;
    }
    runInAction(() => {
      this.roleItems = {
        ...this.roleItems,
        data: !data
          ? null
          : data.map((role) => {
              return {
                model: new CommunityRoleModel({
                  toastStore: this.toastStore,
                  communityRepository: this.communityRepository,
                  communityRolePayload: {
                    ...role,
                    communityId: this.activeCommunityId,
                  },
                }),
                isAdded: this.addedRolesReg.hasKey(role.id as string),
              };
            }),
        loading: false,
        error: error,
      };
    });
  };

  loadMoreMembers = async () => {
    const seachType = SearchEnum.COMMUNITY_MEMBERS;

    await this.communityStore.getCommunityMembers({
      communityId: this.activeCommunityId,
      search: this[seachType].value,

      module: {
        id: this.activeChannelOrGroupId,
        type: this.isGroupMode
          ? Community_Member_Module_Types.ChannelGroup
          : Community_Member_Module_Types.Channel,
      },
    });

    runInAction(() => {
      this[seachType] = {
        ...this[seachType],
        loading: false,
      };
    });
  };

  loadMoreSavedMembers = async () => {
    if (this.savedUserMembers.loading) return;
    const pageInfo = this.savedUserMembers.meta?.pageInfo;

    const hasNextPage = pageInfo?.hasNextPage;
    const payload: LoadSavedUsersOptions = {
      search: this[SearchEnum.SAVED_MEMBERS].value,
    };

    if (!hasNextPage) {
      return;
    }

    payload.before = pageInfo?.startCursor;
    payload.first = pageInfo?.count;

    return this.loadSavedUserMembers(this.activeChannelOrGroupId, payload);
  };

  setSearchCommunityMembers = async (val: string) => {
    const seachType = SearchEnum.COMMUNITY_MEMBERS;
    if (this[seachType].value !== val) {
      this.communityStore.resetMembers();
    }

    this[seachType] = {
      ...this[seachType],
      value: val,
      loading: true,
    };

    this.loadMoreMembers();
  };

  setSearchSavedMembers = async (val: string) => {
    const seachType = SearchEnum.SAVED_MEMBERS;
    if (this[seachType].value !== val) {
      this.savedUserMembers = ApiBase.generateCommonApiDataShape();
      this.addedMembersReg.reset();
      this.savedUserMembers.loading = true;

      if (!val) {
        await this.loadSavedUserMembers(this.activeChannelOrGroupId);
        this[seachType] = {
          ...initialSearchItem,
        };
        return;
      }

      this[seachType] = {
        ...this[seachType],
        value: val,
        loading: true,
      };

      const payload: LoadSavedUsersOptions = {
        search: val,
      };
      await this.loadSavedUserMembers(this.activeChannelOrGroupId, payload);
      this[seachType] = {
        ...this[seachType],
        loading: false,
      };
      return;
    }

    this.loadSavedUserMembers(this.activeChannelOrGroupId);
    this[seachType] = {
      ...this[seachType],
      loading: false,
    };
  };

  private updateItem = (item: RoleItem | UserItem, type: 'role' | 'user') => {
    const key = type === 'role' ? 'roleItems' : 'memberItems';
    const items = this[key];
    if (!items.data) {
      return;
    }

    const updatedData = items.data.map((o) => {
      if (o.model.serverData.id === item.model.serverData.id) {
        return item;
      }

      return o;
    });

    runInAction(() => {
      this[key] = {
        ...this[key],
        data: updatedData as any,
      };
    });
  };

  restoreRegsFromSnapshot = () => {
    const savedUsers = this.savedUserMembers.data || [];
    const savedRoles = this.savedRoleMembers.data || [];
    const mappedUserItems = savedUsers.map((userItem) => ({
      ...userItem,
      isAdded: true,
    }));
    const mappedRoleItems = savedRoles.map((roleItem) => ({
      ...roleItem,
      isAdded: true,
    }));

    this.addedMembersReg.reset();
    this.addedRolesReg.reset();

    this.addedMembersReg.addOrReplaceItems(mappedUserItems, (item) => {
      return item.model.serverData?.id as string;
    });

    this.addedRolesReg.addOrReplaceItems(mappedRoleItems, (item) => {
      return item.model.serverData?.id as string;
    });

    this.setCommunityMembers(this.communityStore.members.data?.valuesArray);
  };

  addRole = (roleItem: RoleItem) => {
    const id = roleItem.model.serverData.id;
    invariant(id, 'Role id is not defined');

    this.addedRolesReg.addOrReplaceItem(id, roleItem);
    roleItem.isAdded = true;
    this.updateItem(roleItem, 'role');
  };

  removeRole = (roleItem: RoleItem) => {
    const id = roleItem.model.serverData.id;
    invariant(id, 'Role id is not defined');

    this.addedRolesReg.removeItem(id);
    roleItem.isAdded = false;
    this.updateItem(roleItem, 'role');
  };

  addUser = (userItem: UserItem) => {
    const id = userItem.model.id;
    invariant(id, 'User id is not defined');

    this.addedMembersReg.addOrReplaceItem(userItem.model.id, userItem);

    userItem.isAdded = true;
    this.updateItem(userItem, 'user');
  };

  removeUser = (userItem: UserItem) => {
    const id = userItem.model.serverData.id;
    invariant(id, 'User id is not defined');

    this.addedMembersReg.removeItem(id);
    userItem.isAdded = false;
    this.updateItem(userItem, 'user');
  };

  setSelectedRole = (role: CommunityRoleModel) => {
    this.selectedRole = role;
  };

  setupData = async () => {
    return Promise.all([
      this.loadCommunityMembers(),
      this.loadCommunityRoles(
        this.activeCommunityId,
        Role_List_Types.Community
      ),
    ]);
  };

  setupSavedData = async (channelOrGroupId: string) => {
    return Promise.all([
      this.loadSavedRoleMembers(channelOrGroupId),
      this.loadSavedUserMembers(channelOrGroupId),
    ]);
  };

  getRemovedFromSavedRolesAndUsers = () => {
    const removedUsers: (ChannelAddMemberInput | ChannelGroupAddMemberInput)[] =
      this.savedUserMembers.data
        ?.filter((userItem) => {
          return !this.addedMembersReg.hasKey(userItem.model.serverData.id);
        })
        .map((userItem) => {
          if (this.isGroupMode) {
            return {
              action: Channel_Group_Member_Action.Remove,
              id: userItem.model.serverData.id,
              type: Channel_Group_Member_Type.User,
            } as ChannelGroupAddMemberInput;
          } else {
            return {
              action: Channel_Member_Action.Remove,
              id: userItem.model.serverData.id,
              type: Channel_Member_Type.User,
            } as ChannelAddMemberInput;
          }
        }) || [];

    const removedRoles: (ChannelAddMemberInput | ChannelGroupAddMemberInput)[] =
      this.savedRoleMembers.data
        ?.filter((roleItem) => {
          return !this.addedRolesReg.hasKey(
            roleItem.model.serverData.id as string
          );
        })
        .map((roleItem) => {
          if (this.isGroupMode) {
            return {
              action: Channel_Group_Member_Action.Remove,
              id: roleItem.model.serverData.id,
              type: Channel_Group_Member_Type.Role,
            } as ChannelGroupAddMemberInput;
          } else {
            return {
              action: Channel_Member_Action.Remove,
              id: roleItem.model.serverData.id,
              type: Channel_Member_Type.Role,
            } as ChannelAddMemberInput;
          }
        }) || [];

    return {
      removedUsers,
      removedRoles,
    };
  };

  getAddedRolesAndUsers = () => {
    let userAction: Channel_Member_Action | Channel_Group_Member_Action =
      Channel_Member_Action.Add;
    let roleAction: Channel_Member_Action | Channel_Group_Member_Action =
      Channel_Member_Action.Add;
    let userType: Channel_Member_Type | Channel_Group_Member_Type =
      Channel_Member_Type.User;
    let roleType: Channel_Member_Type | Channel_Group_Member_Type =
      Channel_Member_Type.Role;

    if (this.isGroupMode) {
      userAction = Channel_Group_Member_Action.Add;
      roleAction = Channel_Group_Member_Action.Add;
      userType = Channel_Group_Member_Type.User;
      roleType = Channel_Group_Member_Type.Role;
    }

    const users = this.addedMembersReg.valuesArray.map((userItem) => {
      return {
        action: userAction,
        id: userItem.model.id,
        type: userType,
      };
    });

    const roles = this.addedRolesReg.valuesArray.map((roleItem) => {
      return {
        action: roleAction,
        id: roleItem.model.serverData.id as string,
        type: roleType,
      };
    });

    return [...users, ...roles] as (
      | ChannelAddMemberInput
      | ChannelGroupAddMemberInput
    )[];
  };

  resetSearch = () => {
    this[SearchEnum.COMMUNITY_MEMBERS] = { ...initialSearchItem };
    this[SearchEnum.SAVED_MEMBERS] = { ...initialSearchItem };
  };

  reset = () => {
    this.resetSearch();

    this.selectedRole = null;
    this.addedMembersReg.reset();
    this.addedRolesReg.reset();
  };

  dispose = () => {
    this.reactionsRegistryService.disposeAll();
  };
}
