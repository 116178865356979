import _throttle from 'lodash/throttle';
import { useCallback, useEffect, useState } from 'react';
type Options = {
  onStart?: (
    e: React.SyntheticEvent<MouseEvent | TouchEvent> | TouchEvent | PointerEvent
  ) => void;
  onMove?: (e: React.SyntheticEvent<MouseEvent | TouchEvent>) => void;
  onCancel?: (
    e: React.SyntheticEvent<MouseEvent | TouchEvent> | TouchEvent | PointerEvent
  ) => void;
  moveThrottleMs?: number;
};

type State = {
  event:
    | React.SyntheticEvent<MouseEvent | TouchEvent>
    | TouchEvent
    | PointerEvent
    | null;
  startLongPress: boolean;
};

/** the callback must be memoized and shouldn't be an anonymous function which will be re-created on every re-render otherwise the timeout will be constantly re-created in the useEffect */
export function useLongPress(
  callback: (e: TouchEvent | MouseEvent) => void,
  ms = 300,
  options: Options = {}
) {
  const { onStart, onMove, onCancel, moveThrottleMs = 100 } = options;

  const [state, setState] = useState<State>({
    event: null,
    startLongPress: false,
  });

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined = undefined;

    if (state.startLongPress) {
      timerId = setTimeout(() => {
        if (state.event) {
          state.event.preventDefault();
          callback(state.event as unknown as MouseEvent | TouchEvent);
        }
      }, ms);
    } else if (timerId) {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [state, callback, ms]);

  const throttledOnPointerMove = useCallback(
    _throttle((e: React.SyntheticEvent<TouchEvent>) => {
      if (state.startLongPress) {
        setState({ event: e, startLongPress: false });
        onCancel?.(e);
      }
      onMove?.(e);
    }, 100),
    [state.startLongPress]
  );

  return {
    onMouseDown: (e: React.SyntheticEvent<MouseEvent>) => {
      onStart?.(e);
      setState({
        event: e,
        startLongPress: true,
      });
    },

    onMouseUp: (e: React.SyntheticEvent<MouseEvent>) => {
      setState({ event: e, startLongPress: false });
    },
    onTouchStart: (e: React.SyntheticEvent<TouchEvent> | TouchEvent) => {
      onStart?.(e);
      setState({ event: e, startLongPress: true });
    },

    onTouchEnd: (e: React.SyntheticEvent<TouchEvent> | TouchEvent) => {
      setState({ event: e, startLongPress: false });
      onCancel?.(e);
    },
    /* unified handlers for the message bottomsheet timing feature */
    onPointerDown: (e: React.SyntheticEvent<PointerEvent>) => {
      onStart?.(e);
      setState({
        event: e,
        startLongPress: true,
      });
    },
    onPointerhMove: throttledOnPointerMove,
    // a special fallback for the touch end event that doesn't work in lexical editor somehow
    onPointerUp: (e: React.SyntheticEvent<PointerEvent>) => {
      setState({ event: e, startLongPress: false });
      onCancel?.(e);
    },
  };
}
