import { classNames } from '@foundationPathAlias/utilities';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { useState } from 'react';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { PricingSource } from '@mainApp/src/modules/pricing';
import { PricingSection1 } from '@mainApp/src/modules/pricing/section-1';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';

const PricingSection2 = dynamic(
  () =>
    import('../../../pricing/section-2/PricingSection2').then(
      (module) => module.PricingSection2
    ),
  { ssr: false }
);

export type PricingItemProps = {
  title: string;
  price: number;
  features: string[];
  allowQuantity?: boolean;
  quantity?: number;
};

export type AddedItemsType = {
  basePlan: PricingItemProps | null;
  aLaCartePlan: PricingItemProps[] | null;
};

export function Billing() {
  const { t } = useTranslation(['cap']);
  const [isSheetExpanded, setIsSheetExpanded] = useState(false);
  const {
    capStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([IOC_TOKENS.capStore, IOC_TOKENS.dimensionsStore]);

  const [addedItems, setAddedItems] = useState<AddedItemsType>({
    basePlan: null,
    aLaCartePlan: null,
  });

  return (
    <ScreenWrapper
      title={t('cap:menu.billing')}
      sidebarModalController={capStore}
      showBackBtn={isMobile}
      rootCn="!p-0"
      ScreenHeaderProps={{
        childrenWrapperCn: classNames({
          'text-start': isMobile,
        }),
      }}
    >
      <div
        className={classNames(
          'w-full bg-background-primary dark:bg-background-primary-dark md:bg-background-primary md:dark:bg-background-secondary-dark ',
          isMobile && '[&_*]:!max-w-[100vw]',
          isMobile ? 'h-[calc(100vh-64px)]' : 'h-screen',
          'relative'
        )}
      >
        <div
          className={classNames(
            'h-full w-full',
            isSheetExpanded ? 'overflow-hidden' : 'overflow-y-auto',
            isMobile ? 'pb-[50px]' : 'pb-[220px]',
            '[-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden'
          )}
        >
          <div className="flex w-full flex-col">
            <span className="themed-text px-6 pt-6 text-left text-miniHeadM">
              {t('cap:plans')}
            </span>
            <div
              className={classNames(
                'w-full',
                isMobile
                  ? 'overflow-x-auto [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden'
                  : ''
              )}
            >
              <div
                className={classNames(
                  'w-full',
                  isMobile ? 'min-w-[100vw]' : ''
                )}
              >
                <PricingSection1
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  from={PricingSource.RULES}
                />
              </div>
            </div>
            <div
              id="pricing-section-2"
              className={classNames('mt-[30px] w-full text-left')}
            >
              <div className={classNames('w-full', isMobile && '!mr-[24px]')}>
                <PricingSection2
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  from={PricingSource.RULES}
                  onSheetExpand={setIsSheetExpanded}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScreenWrapper>
  );
}
