import {
  GET_CURRENT_USER_DATA,
  OAUTH_EXCHANGE_CODE_MUTATION,
  SIGN_IN_VIA_EMAIL,
  SIGN_UP_VIA_EMAIL,
  VERIFY_SIGN_IN,
  VERIFY_SIGN_UP,
} from '@mainApp/src/graphql/queries';
import { inject, injectable } from 'inversify';
import { BaseRepositoryResponse } from './types';

import { SYSTEM_VARIABLES } from '@mainApp/src/config';
import { IOC_TOKENS } from '@mainApp/src/ioc';
import { Client } from './types';

@injectable()
export class AuthRepository implements IAuthRepository {
  gqlClient;

  // currently it's urql
  constructor(@inject(IOC_TOKENS.graphqlClient) gqlClient: Client) {
    this.gqlClient = gqlClient;
  }

  async exchangeSocialAuthCode(code: string) {
    const response = await this.gqlClient
      .mutation(
        OAUTH_EXCHANGE_CODE_MUTATION,
        { code: code },
        { [SYSTEM_VARIABLES.WITHOUT_AUTH_TOKEN]: true }
      )
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;
    const res = {
      data: data?.oAuthExchangeCode?.accessToken,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async signInViaEmail(email: string) {
    const response = await this.gqlClient
      .mutation(SIGN_IN_VIA_EMAIL, { email })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { error } = response;

    const res = {
      data: null,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async signUpViaEmail(email: string) {
    const response = await this.gqlClient
      .mutation(SIGN_UP_VIA_EMAIL, { email })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { error } = response;

    const res = {
      data: null,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async verifySignIn(otp: string) {
    const response = await this.gqlClient
      .mutation(VERIFY_SIGN_IN, { otp })
      .toPromise();
    const { data, error } = response;

    const res = {
      data: data?.verifySignIn,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async verifySignUp(otp: string) {
    const response = await this.gqlClient
      .mutation(VERIFY_SIGN_UP, { otp })
      .toPromise();
    const { data, error } = response;

    const res = {
      data: data?.verifySignUp,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  // it works in the same way as any other query. It requres bearer token so there is not so many usecases right now but it will definitely be useful once BE will implement appropriate method
  async checkIsUserLoggedIn() {
    const response = await this.gqlClient
      .query(
        GET_CURRENT_USER_DATA,
        {},
        {
          requestPolicy: 'network-only',
          skipUnauthorizedEvent: true,
        }
      )
      .toPromise();

    const { error } = response;
    const isLogged = !error;

    return isLogged;
  }
}

export interface IAuthRepository {
  gqlClient: Client;

  exchangeSocialAuthCode(code: string): Promise<BaseRepositoryResponse<string>>;
  signInViaEmail(email: string): Promise<BaseRepositoryResponse<null>>;
  signUpViaEmail(email: string): Promise<BaseRepositoryResponse<null>>;
  verifySignUp(otp: string): Promise<
    BaseRepositoryResponse<{
      token: string;
      userId: string;
    }>
  >;
  verifySignIn(otp: string): Promise<
    BaseRepositoryResponse<{
      token: string;
      userId: string;
    }>
  >;
  checkIsUserLoggedIn: () => Promise<boolean>;
}
