'use client';
import { BackButton, CloseButton } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

type HeaderModalProps = {
  title: string;
  onClose: () => void;
  onBack?: () => void;
  showBackButton?: boolean;
};

export const HeaderModal = ({
  title,
  onClose,
  onBack,
  showBackButton,
}: HeaderModalProps) => {
  return (
    <div className="relative flex w-full items-center justify-center border-b border-b-element-subtle px-[8px] py-[14px] dark:border-b-element-subtle-dark md:justify-between md:px-[24px] md:py-[16px]">
      <div className="flex gap-3">
        {onBack && showBackButton && (
          <BackButton
            className="absolute left-4 top-[10px] self-center md:relative md:-top-[2px] md:left-0"
            onClick={onBack}
          />
        )}

        <h4 className="text-body16SB text-text-primary dark:text-text-primary-dark md:text-subheadingSB">
          {title}
        </h4>
      </div>

      <CloseButton
        className={classNames(
          'absolute left-4 self-center md:relative md:left-0',
          onBack && showBackButton && 'hidden md:flex'
        )}
        onClick={onClose}
      />
    </div>
  );
};
