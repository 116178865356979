import { useFormatCreatedAt } from '@mainApp/src/hooks';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { useTranslation } from 'react-i18next';
import { DeleteMessageModalActions } from './DeleteMessageModalActions';
import { DeleteMessageModalHeader } from './DeleteMessageModalHeader';
import { deleteMessageModalStoreLocal } from './data/deleteMessageModal.store.local';

import { MessagesEventsEnum } from '@mainApp/src/events';

export function DeleteMessageModalContent() {
  const {
    channelStore,
    eventBus,
    interactiveStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.channelStore,
    IOC_TOKENS.eventBus,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const close = () => {
    deleteMessageModalStoreLocal.hide();
    interactiveStore.setInteractiveElementOpen(false);
  };

  const { t } = useTranslation('common');

  const messageModel =
    deleteMessageModalStoreLocal.messageModel as MessageModel;
  const authorUsername = messageModel?.authorModel?.standardOrProUsername;

  const createdAt = useFormatCreatedAt(messageModel.createdAt);

  function deleteMessage() {
    const communityId =
      channelStore.activeChannel?.data?.serverData?.communityId;
    if (!communityId) {
      console.error('there is no such community id: ' + communityId);
      return;
    }

    const channelId = channelStore.activeChannel?.data?.serverData?.id;
    if (!channelId) {
      console.error('there is no such channel id: ' + channelId);
      return;
    }

    eventBus.emit(MessagesEventsEnum.DELETE_MESSAGE, {
      communityId,
      channelId,
      messageModel,
    });

    close();
  }

  return (
    <div className="themed-layout mb-[60px] flex w-full max-w-[600px] flex-col  rounded-[10px] md:mb-0">
      <DeleteMessageModalHeader onClose={close} isMobile={isMobile} />
      <div className="flex flex-col  gap-[24px] px-[24px]">
        <p className="themed-text-secondary text-body16R">
          {t('deleteMessageWarn')}
        </p>
        <div className="flex max-h-[150px] gap-[8px] overflow-auto rounded-[5px] border border-element-subtle p-[12px] dark:border-element-subtle-dark">
          <div className="flex h-[32px] w-[32px] items-center justify-center">
            <img
              className="h-full w-full rounded-full"
              src={messageModel?.getAuthorAvatar()}
              alt={t<string>('avatarImage', {
                name: authorUsername,
              })}
            />
          </div>

          <div className="flex grow basis-0 flex-col items-start  ">
            <div className="flex items-baseline">
              <p className="themed-text flex items-center justify-start text-body16R font-semibold">
                {authorUsername}
              </p>
              <div className="themed-text-secondary flex items-center">
                <i className="mx-[4px]">•</i>
                <span className="text-sm14T">{createdAt}</span>
              </div>
            </div>
            <p className="themed-text text-left text-body16R">
              {messageModel.serverData.text}
            </p>
          </div>
        </div>
      </div>

      <DeleteMessageModalActions
        onCancel={close}
        onDelete={deleteMessage}
        isMobile={isMobile}
      />
    </div>
  );
}
