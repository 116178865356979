export enum ThemeEnum {
  LM = 'light',
  DM = 'dark',
}

export const systemConfig = {
  zIndexMax: 10,
  redirectionSeconds: 5, // seconds
  debounceDelay: 300,
  throttleDelay: 300,
  scrollToBottomThreshold: 200,
};

export enum SYSTEM_VARIABLES {
  WITHOUT_AUTH_TOKEN = 'WITHOUT_AUTH_TOKEN',
}
