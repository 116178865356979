import { injectable } from 'inversify';
import cookie, { CookieAttributes } from 'js-cookie';
import { StorageDataKeysEnum } from './types';

@injectable()
export class StorageService implements IStorageService {
  // useful for testing
  static cookie = cookie;
  static localStorage = global.localStorage;

  getCookie(key: StorageDataKeysEnum) {
    return StorageService.cookie.get(key);
  }
  setCookie(
    key: StorageDataKeysEnum,
    data: string,
    options: CookieAttributes = {}
  ) {
    if (!StorageService.cookie) {
      throw new Error('Cookie service is undefined');
    }
    StorageService.cookie.set(key, data, {
      secure: true,
      sameSite: 'none',
      ...options,
    });

    return true;
  }

  removeCookie(key: StorageDataKeysEnum) {
    StorageService.cookie.remove(key);
  }
  removeStorageItem(key: StorageDataKeysEnum) {
    StorageService.localStorage.removeItem(key);
  }

  setStorageItem(key: StorageDataKeysEnum, data: string) {
    if (!StorageService.localStorage) return false;

    StorageService.localStorage.setItem(key, data);

    return true;
  }
  getStorageItem(key: StorageDataKeysEnum) {
    return StorageService.localStorage?.getItem(key);
  }
}

export interface IStorageService {
  getCookie(key: StorageDataKeysEnum): string | void;
  setCookie(
    key: StorageDataKeysEnum,
    data: string,
    options?: CookieAttributes
  ): boolean;
  removeCookie(key: StorageDataKeysEnum): void;
  removeStorageItem(key: StorageDataKeysEnum): void;
  setStorageItem(key: StorageDataKeysEnum, data: string): boolean;
  getStorageItem(key: StorageDataKeysEnum): string | null;
}
