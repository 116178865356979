import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { SmallLoader } from '@foundationPathAlias/components/loaders';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  MembersScrollableListWrapper,
  MembersUserList,
} from '@mainApp/src/modules/channel/common/members-list';
import { NoResult } from '@mainApp/src/modules/channel/common/permissions-components/NoResult';

import { FooterModal } from './FooterModal';
import { HeaderModal } from './HeaderModal';

const _MembersView = () => {
  const { t } = useTranslation(['channel', 'common']);

  const {
    dimensionsStore: { isMobile },
    createChannelOrGroupStore,
  } = useMultipleInjection([
    IOC_TOKENS.createChannelOrGroupStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { selectedRole, selectedRoleMembers, isSelectedRoleMembersLoading } =
    createChannelOrGroupStore.permissionsServiceStore;

  useEffect(() => {
    selectedRole?.getRoleMembers({});
  }, []);

  return (
    <div className="themed-layout relative flex flex-1 flex-col overflow-hidden rounded-[5px] md:block md:flex-none">
      <HeaderModal
        onBack={createChannelOrGroupStore.moveBack}
        title={t('channel:modal.roleMembers', {
          roleName: selectedRole?.name,
        })}
        onClose={createChannelOrGroupStore.requestCloseModal}
        showBackButton={true}
      />
      <div className="flex flex-[1] flex-col">
        <div className="flex flex-1 px-4 py-2 md:min-h-[430px] md:flex-none">
          <MembersScrollableListWrapper
            rootCn="md:max-h-none"
            contenttWrapperCn="md:absolute"
            onScrollEnd={() => {
              selectedRole?.loadMoreMembers();
            }}
          >
            {isSelectedRoleMembersLoading ? (
              <SmallLoader cn="absolute left-[50%] top-[200px]" />
            ) : selectedRoleMembers ? (
              <MembersUserList
                data={selectedRoleMembers}
                noAction={true}
                noTitle={true}
                loading={selectedRole?.roleMembers.loading}
              />
            ) : (
              <NoResult cn="mt-[20%] text-body16R">
                {t<string>('common:noMembers')}
              </NoResult>
            )}
          </MembersScrollableListWrapper>
        </div>

        {!isMobile && (
          <FooterModal
            onCancel={createChannelOrGroupStore.moveBack}
            cancelText={t<string>('common:back')}
            className="mt-auto md:mt-0"
          />
        )}
      </div>
    </div>
  );
};

export const MembersView = withAnimatedStack(observer(_MembersView));
