import { classNames } from '@foundationPathAlias/utilities';

type Props = {
  children: React.ReactNode;
  cn?: string;
};

export function TextInputCommonValidatorsLabel(props: Props) {
  const { cn, children } = props;
  return (
    <h6
      className={classNames(
        'themed-text mb-[8px] flex justify-start text-body16SB',
        cn
      )}
    >
      {children}
    </h6>
  );
}
