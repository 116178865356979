import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Toggle } from '@foundationPathAlias/components';
import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { Separator } from '@foundationPathAlias/widgets/sidebar-modal';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  ItemHeader,
  ScreenWrapper,
} from '@mainApp/src/modules/channel/channel-group-settings/components';

import { Visibility } from './components/Visibility';
import { PermissionsStore } from './data/Permissions.store.local';

export function _Permissions() {
  const {
    dimensionsStore: { isMobile },
    channelGroupSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.channelGroupSettingsStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);

  const activeStore =
    channelGroupSettingsStore.activeChildScreen as PermissionsStore;

  useEffect(() => {
    if (!activeStore) return;
    activeStore?.setChannelGroupModel(
      channelGroupSettingsStore.channelGroupModel
    );
  }, [channelGroupSettingsStore.channelGroupModel]);

  return (
    <ScreenWrapper
      title={t('channel:channelGroupSettings.channelGroupSettingsExplicit')}
      showBackBtn={isMobile}
      rootCn="overflow-hidden"
    >
      <div className="flex h-full w-full flex-1 flex-col">
        <ItemHeader cn="mb-[16px]">
          {t('channel:channelGroupSettings.channelGroupPermissions')}
        </ItemHeader>
        <div className="flex items-center justify-between">
          <div className="mr-[12px] md:mr-0">
            <p className="text-left text-sm14SB text-text-primary dark:text-text-primary-dark">
              {t('channel:modal.makePrivate')}
            </p>

            <span className="text-sm14R text-text-secondary dark:text-text-secondary-dark">
              {t('channel:modal.onlySelectedRolesAndMembers')}
            </span>
          </div>

          <Toggle
            checked={activeStore?.isPrivate}
            onChange={() => {
              activeStore.setIsPrivate(!activeStore.isPrivate);
            }}
          />
        </div>
        <Separator />
        {activeStore?.isPrivate && <Visibility />}
      </div>
    </ScreenWrapper>
  );
}

export const Permissions = withAnimatedStack(observer(_Permissions));
