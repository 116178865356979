import { TextButtonPrimary } from '@foundationPathAlias/components';
import { useJoinCommunityAction } from '@mainApp/src/hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
export function _MessageEditorGuestPanel() {
  const { t } = useTranslation('common');
  const joinCommunityAction = useJoinCommunityAction(false);
  return (
    <p className="flex flex-1 items-center justify-between">
      <span className="themed-text-secondary text-body16R">
        {t('joinToStartInteracting')}
      </span>
      <TextButtonPrimary onClick={joinCommunityAction} dynamicColorsMode={true}>
        {t('joinCommunity')}
      </TextButtonPrimary>
    </p>
  );
}

export const MessageEditorGuestPanel = observer(_MessageEditorGuestPanel);
