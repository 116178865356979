import { ActionsPanel } from '@foundationPathAlias/widgets/sidebar-modal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenIds as RootScreenIds } from '../constants';
import { ScreenIds as PermissionsScreenIds } from '../screens/permissions/constants';
export function _ActionsPanelWithMobx() {
  const {
    dimensionsStore: { isMobile },
    channelGroupSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.channelGroupSettingsStore,
  ]);

  const activeChildScren = channelGroupSettingsStore.activeChildScreen;
  const actionsPanelData = activeChildScren?.actionsPanelData;
  const { t } = useTranslation('common');

  const isPermissionsActive =
    channelGroupSettingsStore.activeScreenId === RootScreenIds.PERMISSIONS;
  let isActiveChildScreenInitial = false;
  if (activeChildScren) {
    isActiveChildScreenInitial =
      activeChildScren.activeScreenId === PermissionsScreenIds.INITIAL;
  }
  const isPermissionsInitialScreen =
    isPermissionsActive && isActiveChildScreenInitial;

  return (
    <ActionsPanel
      isMobile={isMobile}
      showCancelBtnOnMobile={isPermissionsInitialScreen}
      getTranslatedString={(key: string) => t(key)}
      actionsPanelData={actionsPanelData}
      isLoading={activeChildScren?.isLoading}
      showActionPanel={activeChildScren?.showActionPanel}
      isError={activeChildScren?.isError}
      isDisableProceedAction={activeChildScren?.isDisableProceedAction}
    />
  );
}

export const ActionsPanelWithMobx = observer(_ActionsPanelWithMobx);
