import {
  ChannelAddMemberInput,
  ChannelGroupAddMemberInput,
  Channel_Group_Member_Action,
  Channel_Group_Member_Type,
  Channel_Member_Action,
  Channel_Member_Type,
  Role_List_Types,
} from '@10x/foundation/types';

import type { ICommunityRepository } from '@mainApp/src/repositories';
import {
  ArrayOrientedOrderedRegistryService,
  CommonApiDataShapeType,
  CommunityRoleModel,
  UserModel,
} from '@mainApp/src/stores';
import { IReactionDisposer } from 'mobx';

import { type ICommunityStore } from '@mainApp/src/stores/Community.store.types';

export enum SearchEnum {
  COMMUNITY_MEMBERS = 'searchCommunityMembers',
  SAVED_MEMBERS = 'searchSavedMembers',
}

export type LoadSavedUsersOptions = {
  search?: string;
  before?: string;
  first?: number;
};

export type GetAddedRolesUsersOptions = {
  userAction?: Channel_Member_Action | Channel_Group_Member_Action;
  roleAction?: Channel_Member_Action | Channel_Group_Member_Action;
  userType?: Channel_Member_Type | Channel_Group_Member_Type;
  roleType?: Channel_Member_Type | Channel_Group_Member_Type;
};

export type RoleItem = {
  model: CommunityRoleModel;
  isAdded: boolean;
};
export type UserItem = {
  model: UserModel;
  isAdded: boolean;
};

export type ChannlOrGroupFetchMembersPayload = {
  communityId: string;
  channelId?: string;
  channelGroupId?: string;
  noCache?: boolean;
};
export type SearchItem = {
  value: string;
  error: string | null;
  loading: boolean;
};

export enum ReactionsEnum {
  GET_COMMUNITY_MEMBERS_REACTION = 'getCommunityMembersReaction',
}

export type ReactionsDisposerRegistry = {
  [ReactionsEnum.GET_COMMUNITY_MEMBERS_REACTION]: IReactionDisposer | null;
};

export interface IPermissionsServiceStore {
  communityStore: ICommunityStore;
  communityRepository: ICommunityRepository;
  selectedRole: CommunityRoleModel | null;
  roleItems: CommonApiDataShapeType<RoleItem[] | null>;
  memberItems: CommonApiDataShapeType<UserItem[] | null>;
  savedRoleMembers: CommonApiDataShapeType<RoleItem[] | null>;
  savedUserMembers: CommonApiDataShapeType<UserItem[] | null>;
  addedMembersReg: ArrayOrientedOrderedRegistryService<UserItem>;
  addedRolesReg: ArrayOrientedOrderedRegistryService<RoleItem>;
  // true if the user changed the addedMembers/Roles registry
  isDirty: boolean;

  [SearchEnum.COMMUNITY_MEMBERS]: SearchItem;
  [SearchEnum.SAVED_MEMBERS]: SearchItem;

  readonly activeCommunityId: string;
  readonly isSearchActive: boolean;
  readonly isSelectedRoleMembersLoading: boolean;
  readonly areMembersAndRolesEmpty: boolean;
  readonly selectedRoleMembers: UserItem[] | void;
  readonly hasAddedAnyMember: boolean;

  setCommunityMembers(members: UserModel[] | undefined): void;
  loadCommunityMembers(): Promise<void>;
  fetchMoreCommunityMembers(): Promise<void>;
  debouncedLoadMoreSavedMembers: () => void;
  loadCommunityRoles(
    communityId: string,
    listType?: Role_List_Types
  ): Promise<void>;
  loadMoreMembers(): Promise<void>;
  setSearchCommunityMembers(val: string): Promise<void>;
  setSearchSavedMembers(val: string): Promise<void>;
  addRole(roleItem: RoleItem): void;
  removeRole(roleItem: RoleItem): void;
  addUser(userItem: UserItem): void;
  removeUser(userItem: UserItem): void;
  setSelectedRole(role: CommunityRoleModel): void;
  setupData(): Promise<void[]>;
  restoreRegsFromSnapshot: () => void;
  setMode: (mode: 'group' | 'channel') => void;
  setActiveChannelOrGroupId: (id: string) => void;
  setupSavedData: (channelOrGroupId: string) => Promise<void[]>;
  getRemovedFromSavedRolesAndUsers: () => {
    removedUsers: (ChannelAddMemberInput | ChannelGroupAddMemberInput)[];
    removedRoles: (ChannelAddMemberInput | ChannelGroupAddMemberInput)[];
  };
  getAddedRolesAndUsers(): (
    | ChannelAddMemberInput
    | ChannelGroupAddMemberInput
  )[];
  resetSearch(): void;
  reset(): void;
  setupReactions(): void;
  dispose: () => void;
}
