import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Toggle } from '@foundationPathAlias/components';
import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import {
  ScreenWrapper,
  Separator,
} from '@foundationPathAlias/widgets/sidebar-modal';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { Visibility } from './components/Visibility';
import { PermissionsStore } from './data/Permissions.store.local';

export function _Permissions() {
  const {
    dimensionsStore: { isMobile },
    channelSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.channelSettingsStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);

  const activeStore =
    channelSettingsStore.activeChildScreen as PermissionsStore;

  useEffect(() => {
    if (!activeStore) return;
    activeStore.setChannelModel(channelSettingsStore.channelModel);
  }, [channelSettingsStore.channelModel]);

  return (
    <ScreenWrapper
      title={t('permissions')}
      sidebarModalController={channelSettingsStore}
      showBackBtn={isMobile}
      rootCn="overflow-hidden"
    >
      <div className="flex h-full w-full flex-1 flex-col">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-left text-sm14SB text-text-primary dark:text-text-primary-dark">
              {t('channel:modal.makePrivate')}
            </p>

            <span className="text-sm14R text-text-secondary dark:text-text-secondary-dark">
              {t('channel:modal.onlySelectedRolesAndMembers')}
            </span>
          </div>

          <Toggle
            checked={activeStore?.isPrivate}
            onChange={() => {
              activeStore.setIsPrivate(!activeStore.isPrivate);
            }}
          />
        </div>
        <Separator />
        {activeStore?.isPrivate && <Visibility />}
      </div>
    </ScreenWrapper>
  );
}

export const Permissions = withAnimatedStack(observer(_Permissions));
