import { Tooltip, type TooltipPropsType } from '@foundationPathAlias/main';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

export type UserProfileTooltipWrapperProps = Omit<TooltipPropsType, 'darkMode'>;

const _UserProfileTooltipWrapper = (props: UserProfileTooltipWrapperProps) => {
  const { children, contentStyle, ...otherProps } = props;
  const { isDarkTheme } = useInjection(IOC_TOKENS.systemStore);
  return (
    <Tooltip
      darkMode={!isDarkTheme}
      contentStyle={{
        width: 'fit-content',
        borderRadius: 10,
        padding: 0,
        overflow: 'hidden',
        borderWidth: isDarkTheme ? 0 : undefined,
        ...contentStyle,
      }}
      closeOnDocumentClick={false}
      arrow={false}
      on={['click']}
      position={['top right', 'right top']}
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
};

export const UserProfileTooltipWrapper = observer(_UserProfileTooltipWrapper);
