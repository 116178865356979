import { SIDEBAR_CONTENT_ID } from '@10x/foundation/src/components/sidebar/Sidebar';
import { classNames } from '@foundationPathAlias/utilities';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { animated, useSpring } from '@react-spring/web';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useHoverDirty, useScroll } from 'react-use';

type Props = {
  onClick?: () => void;
  /** by default the Sidebar Content Wrapper will be used */
  customScrollableEl?: HTMLElement;
  /** how much should be scrolled to show the button. 100 is default val */
  scrollYTreshold?: number;
  btnText?: string;
  /** where to mount the button via react portal. By default it's body */
  elToMount?: HTMLElement;
  /** root wrapper cn */
  className?: string;
  /** cn for the buttons that is next el inside the root wrapper */
  btnClassName?: string;
};

export function ScrollToTopButton(props: Props) {
  const {
    onClick,
    customScrollableEl,
    scrollYTreshold = 100,
    btnText,
    elToMount,
    className,
    btnClassName,
  } = props;
  const rootElRef = useRef<HTMLButtonElement>(null);

  const [contentWidth, setContentWidth] = useState<number | null>(null);
  const [renderMeasureEl, setRenderMeasureEl] = useState(true);

  const textRef = useRef(btnText);
  const scrollableSidebarContentElRef = useRef<HTMLElement | null>(null);

  const [appearSpring, appearSpringApi] = useSpring(
    () => ({
      from: { opacity: 0 },
    }),
    []
  );

  const [springStyle, api] = useSpring(
    () => ({
      from: { opacity: 0, width: 0 },
    }),
    []
  );

  const isHovering = useHoverDirty(rootElRef);

  const scrollValue = useScroll(scrollableSidebarContentElRef);

  useEffect(() => {
    const { y } = scrollValue;

    if (y > scrollYTreshold) {
      appearSpringApi.start({
        to: { opacity: 1 },
      });
    } else {
      appearSpringApi.start({
        to: { opacity: 0 },
        onResolve: () => {
          const btnWidth = springStyle.width.get();
          // means it's mobile press and there wasn't hover out effect to trigger so need to manually reset it
          if (btnWidth !== 0) {
            api.start({
              to: { width: 0 },
              immediate: true,
            });
          }
        },
      });
    }
  }, [scrollValue]);

  useLayoutEffect(() => {
    const sidebarContentEl = document.getElementById(SIDEBAR_CONTENT_ID);

    if (!sidebarContentEl) return;

    scrollableSidebarContentElRef.current = sidebarContentEl;
  }, []);

  useEffect(() => {
    if (!customScrollableEl) return;

    scrollableSidebarContentElRef.current = customScrollableEl;
  }, [customScrollableEl]);

  useEffect(() => {
    if (isHovering) {
      api.start({
        to: { opacity: 1, width: contentWidth },
      });
    } else {
      api.start({
        to: { opacity: 0, width: 0 },
      });
    }
  }, [isHovering, contentWidth]);

  useEffect(() => {
    const oldText = textRef.current;
    if (oldText === btnText) return;

    setRenderMeasureEl(true);

    textRef.current = btnText;
  }, [btnText, contentWidth]);

  const textEl = <div className="whitespace-nowrap px-[4px]">{btnText}</div>;

  if (!global.document) return null;

  const finalElToMount = elToMount || global.document.body;

  return createPortal(
    <>
      <animated.div
        style={{
          ...appearSpring,
          visibility: appearSpring.opacity.to((o) =>
            o === 0 ? 'hidden' : 'visible'
          ),
        }}
        className={className}
      >
        <button
          ref={rootElRef}
          onClick={() => {
            const scrollableEl = scrollableSidebarContentElRef.current;
            if (!scrollableEl) {
              throw new Error('There is no sidebar scrollable element');
            }
            scrollableEl.scrollTo({
              top: 0,
              behavior: 'smooth',
            });

            onClick?.();
          }}
          className={classNames(
            'themed-layout absolute bottom-[26px] right-[26px] flex h-[44px] items-center justify-center rounded-full border-[1px] border-element-subtle px-[10px] shadow-small-border  dark:border-element-subtle-dark',
            btnClassName
          )}
        >
          <ChevronUpIcon className="themed-text h-[22px] w-[22px]" />

          <animated.div
            className="themed-text overflow-hidden text-sm14SB"
            style={springStyle}
          >
            {textEl}
          </animated.div>
        </button>
        {!contentWidth &&
          renderMeasureEl &&
          createPortal(
            <span
              className="invisible absolute"
              ref={(el) => {
                if (!el) return;
                const w = el.clientWidth;
                if (w) {
                  setContentWidth(w);
                  setRenderMeasureEl(false);
                }
              }}
            >
              {textEl}
            </span>,
            finalElToMount
          )}
      </animated.div>
    </>,
    finalElToMount
  );
}
