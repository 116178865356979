import { YourCommunityCard } from '../shared/community-card/YourCommunityCard';

export function CommunityPreviewDesktop() {
  return (
    <div className="flex w-full max-w-[584px] items-center justify-center">
      <div className="flex w-full max-w-[360px] ">
        <YourCommunityCard />
      </div>
    </div>
  );
}
