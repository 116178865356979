import { classNames } from '@foundationPathAlias/utilities';
import { forwardRef, useImperativeHandle } from 'react';
import { StencilOverlay, StencilWrapper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import {
  CropperOrientationEnum,
  orientationRatioRegistry,
} from './CropperWrapper';

export type CropperStencilProps = {
  // TODO: they have problems with types so I haven't found any other way
  cropper: any;
  cropBoxOrientation?: CropperOrientationEnum;
  /** circle-stencil is default */
  className?: string;
  /** circle-stencil is default */
  classNameStencilOverlay?: string;
};

export const CropperStencil = forwardRef((props: CropperStencilProps, ref) => {
  const { cropper, cropBoxOrientation, className, classNameStencilOverlay } =
    props;
  const coordinates = cropper.getStencilCoordinates();
  const transitions = cropper.getTransitions();

  const aspectRatio = cropBoxOrientation
    ? orientationRatioRegistry[cropBoxOrientation]
    : orientationRatioRegistry[CropperOrientationEnum.WidePortraitLike];

  useImperativeHandle(ref, () => ({
    aspectRatio,
  }));

  return (
    <StencilWrapper
      className={classNames('circle-stencil', className)}
      transitions={transitions}
      {...coordinates}
    >
      <StencilOverlay
        className={classNames(
          'box-border cursor-move ',
          cropBoxOrientation === CropperOrientationEnum.Circle
            ? 'rounded-full'
            : 'rounded-[20px]',
          classNameStencilOverlay
        )}
      />
    </StencilWrapper>
  );
});
