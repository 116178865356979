import 'cropperjs/dist/cropper.css';
import { getTrackBackground, Range } from 'react-range';
import { classNames } from '../../../src/utilities';

export type RangeSliderPropsType = {
  range: {
    min: number;
    max: number;
  };
  step: number;
  values: number[];
  onChange: (values: number[]) => void;
  trackWrapperCn?: string;
  trackCn?: string;
  thumbCn?: string;
  /** a bg color of the track after the thumb. Eg. "filled" */
  trackBgColorBehindThumb?: string;
  trackBgColor?: string;
};

export function RangeSlider(props: RangeSliderPropsType) {
  const {
    range: { min, max },
    step,
    values,
    onChange,
    trackWrapperCn,
    trackCn,
    thumbCn,
    trackBgColorBehindThumb = '#5F5CFF',
    trackBgColor = '#ccc',
    ...otherProps
  } = props;
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={values}
      onChange={onChange}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          className={classNames(
            'relative flex h-[36px] w-full px-[16px]',
            trackWrapperCn
          )}
        >
          <div
            ref={props.ref}
            className={classNames(
              'h-[4px] w-full self-center rounded-[15px]',
              trackCn
            )}
            style={{
              background: getTrackBackground({
                values: values,
                colors: [trackBgColorBehindThumb, trackBgColor],
                min: min,
                max: max,
              }),
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          className={classNames(
            'flex h-[32px] w-[32px] content-center items-center justify-center rounded-full  border-[2px] border-color-4 transition-colors duration-200 ease-in-out hover:bg-background-secondary-1',
            isDragged ? 'bg-background-quaternary' : 'bg-white',
            thumbCn
          )}
        ></div>
      )}
      {...otherProps}
    />
  );
}
