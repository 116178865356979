import { useCallback, useEffect } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';

import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {
  MentionAdminNode,
  MentionChannelNode,
  MentionUserNode,
} from '@mainApp/src/components/editor';

import { SerializedEditorState, SerializedLexicalNode } from 'lexical';

import { MessageEditorNodeEventPlugin } from './MessageEditorNodeEventPlugin';
import { editorParserTheme } from './editor-parser-theme.js';

const editorConfig = {
  theme: editorParserTheme,
  editable: false,
  onError(error: Error) {
    throw error;
  },
  namespace: 'MessageEditorParser',
  nodes: [MentionUserNode, MentionChannelNode, MentionAdminNode],
};

type Props = {
  editorState: SerializedEditorState<SerializedLexicalNode>;
  onPointerDown?: (e: PointerEvent) => void;
  onPointerUp?: (e: PointerEvent) => void;
};

export default function MessageEditorParser(props: Props) {
  const { editorState } = props;
  return (
    <div className="flex">
      <LexicalComposer initialConfig={editorConfig as any}>
        <ParsedEditorContainer editorState={editorState} />
      </LexicalComposer>
    </div>
  );
}

function ParsedEditorContainer(props: Props) {
  const { editorState, onPointerDown, onPointerUp } = props;
  const [editor] = useLexicalComposerContext();

  const handlePointerDown = useCallback((e: PointerEvent) => {
    onPointerDown?.(e);
  }, []);

  const handlePointerUp = useCallback((e: PointerEvent) => {
    onPointerUp?.(e);
  }, []);

  useEffect(() => {
    if (!editor) return;

    const removeRootListener = editor.registerRootListener(
      (rootElement, prevRootElement) => {
        // add the listener to the current root element
        rootElement?.addEventListener('pointerdown', handlePointerDown);
        // touchend event doesn't work in lexical editor somehow so must use this as the fallback
        rootElement?.addEventListener('pointerup', handlePointerUp);
        // remove the listener from the old root element - make sure the ref to myListener is stable so the removal works and you avoid a memory leak.
        prevRootElement?.removeEventListener('pointerdown', handlePointerDown);
        prevRootElement?.removeEventListener('pointerup', handlePointerUp);
      }
    );

    return removeRootListener;
  }, [editor]);

  useEffect(() => {
    // TODO: check if the current state of editer is the same like prev
    const parsedEditorState = editor.parseEditorState(editorState);
    editor.setEditorState(parsedEditorState);
  }, [editorState]);
  return (
    <div>
      <div className="editor-readonly">
        <PlainTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={null}
          contentEditable={<ContentEditable />}
        />
        <MessageEditorNodeEventPlugin />
      </div>
    </div>
  );
}
