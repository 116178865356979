import { PrimaryButton } from '@foundationPathAlias/main';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export function _AuthSignUpButton() {
  const { t } = useTranslation(['common', 'home']);

  const {
    authStore,
    interactiveStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.authStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const isLogged = authStore.logged;

  if (isLogged || isMobile) {
    return null;
  }

  return (
    <PrimaryButton
      dynamicColorsMode={true}
      cn="h-[40px] px-[20px]"
      onClick={() => {
        authStore.setLoginMode(false);
        interactiveStore.setActiveContentType(
          InteractiveContentTypesEnum.AUTH,
          true
        );
      }}
    >
      {t('navbar.signUp')}
    </PrimaryButton>
  );
}

export const AuthSignUpButton = observer(_AuthSignUpButton);
