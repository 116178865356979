import { injectable } from 'inversify';
import { IReactionDisposer, reaction } from 'mobx';

@injectable()
export class ReactionsRegistryService {
  private disposers: Map<string, IReactionDisposer> = new Map();

  /**
   * Sets up a reaction if it doesn't already exist.
   * @param key Unique key for the reaction.
   * @param observedFn Function that returns the observable to track.
   * @param effectFn Callback to execute when the observable changes.
   */
  addReaction(
    key: string,
    observedFn: () => any,
    effectFn: (value: any) => void
  ) {
    if (!this.disposers.has(key)) {
      const disposer = reaction(observedFn, effectFn);
      this.disposers.set(key, disposer);
    }
  }

  /**
   * Disposes of a specific reaction.
   * @param key Unique key for the reaction to dispose.
   */
  disposeReaction(key: string) {
    const disposer = this.disposers.get(key);
    if (disposer) {
      disposer();
      this.disposers.delete(key);
    }
  }

  /**
   * Disposes of all reactions managed by this service.
   */
  disposeAll() {
    this.disposers.forEach((disposer) => disposer());
    this.disposers.clear();
  }
}

export interface IReactionsRegistryService {
  /**
   * Adds a reaction to the registry if it doesn't already exist.
   * @param key Unique key for the reaction.
   * @param observedFn Function that returns the observable to track.
   * @param effectFn Callback to execute when the observable changes.
   */
  addReaction(
    key: string,
    observedFn: () => any,
    effectFn: (value: any) => void
  ): void;

  /**
   * Disposes of a specific reaction.
   * @param key Unique key for the reaction to dispose.
   */
  disposeReaction(key: string): void;

  /**
   * Disposes of all reactions managed by the registry.
   */
  disposeAll(): void;
}
