import { SidebarMenuWithMobx } from '../components/SidebarMenuWithMobx';
import { ScreenIds } from '../constants';

export type ScreenIdsValuesType = (typeof ScreenIds)[keyof typeof ScreenIds];

export const screensConfig = {
  [ScreenIds.INITIAL]: {
    id: ScreenIds.INITIAL,
    title: 'channelGroupSettings',
    Component: SidebarMenuWithMobx,
  },
};
