import { Billing } from '../Billing';
import { ScreenIds } from '../constants';

export type ScreenIdsValuesType = (typeof ScreenIds)[keyof typeof ScreenIds];

export const screensConfig = {
  [ScreenIds.INITIAL]: {
    id: ScreenIds.INITIAL,
    title: 'Billing',
    Component: Billing,
  },
};
