export * from './Auth.store.types';
export * from './common';
export * from './Dimensions.store.types';
export * from './Interactive.store.types';
export * from './System.store.types';

export enum LoadingStatusEnum {
  initial = 'Initial',
  uploading = 'Uploading',
  completed = 'Completed',
  error = 'Error',
  success = 'Success',
}

export type TextFieldType = {
  value: string;
  error: null | string | JSX.Element;
};
