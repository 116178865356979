import { CropperSnapshot } from '@foundationPathAlias/components';
import {
  CLOUDFLARE_VARIANTS_ENUM,
  getCloudflareSizeRecognition,
} from '@foundationPathAlias/utilities/getCloudflareSizeRecognition';
import { ICommunityRepository } from '@mainApp/src/repositories';
import { action, makeObservable, observable } from 'mobx';
import {
  CommunityImageType,
  CommunityImageVariants,
} from './CommunityImages.types';
import { LoadingStatusEnum } from './types';

const initialImageData: CommunityImageType = {
  status: LoadingStatusEnum.initial,
  file: null,
  fileUrl: null,
  croppedFileUrl: null,
  uploadingPercent: 0,
  errorMessage: null,
  fileName: null,
  fileSize: null,
  /** and image id that provides BE upload. Should use it
    to get the uploaded image on restoration the saved model in localstorage
  */
  id: null,
};

export class CommunityImagesModel implements ICommunityImages {
  communityRepository: ICommunityRepository;
  thumbnail: CommunityImageType = {
    ...initialImageData,
  };
  logo: CommunityImageType = {
    ...initialImageData,
  };

  // it shouldn't be observable due to performance problems and furthermore the reason of it just to restore the cropper on mobile tabs switch
  cropperThumbnailSnapshot: CropperSnapshot | null = null;
  cropperLogoSnapshot: CropperSnapshot | null = null;

  constructor(communityRepository: ICommunityRepository) {
    this.communityRepository = communityRepository;

    makeObservable(this, {
      thumbnail: observable,
      logo: observable,
      setThumbnail: action,
      setLogo: action,
      triggerUploading: action,
      reset: action,
    });
  }

  setThumbnail = (file: File, originalFileUrl?: string) => {
    if (!file) return;

    const url = URL.createObjectURL(file);

    this.thumbnail = {
      ...this.thumbnail,
      file: file,
      fileName: file.name ? file.name : this.thumbnail.fileName,
      // fileSize: (file.size * 0.000001).toFixed(2) + ' MB',
      fileUrl: originalFileUrl ? originalFileUrl : this.thumbnail.fileUrl,
      croppedFileUrl: url,
      status: LoadingStatusEnum.initial,
    };
  };

  setLogo = (file: File, originalFileUrl?: string) => {
    if (!file) return;

    const url = URL.createObjectURL(file);

    this.setImage('logo', {
      file: file,
      fileName: file.name ? file.name : this.logo.fileName,
      fileSize: ((file.size * 0.000001).toFixed(2) + ' MB') as any,
      fileUrl: originalFileUrl ? originalFileUrl : this.logo.fileUrl,
      croppedFileUrl: url,
      status: LoadingStatusEnum.initial,
    });
  };

  setImage = (
    type: CommunityImageVariants,
    data: Partial<CommunityImageType>
  ) => {
    this[type] = {
      ...this[type],
      ...data,
    };
  };

  triggerUploading = async (type: CommunityImageVariants) => {
    this.setImage(type, {
      uploadingPercent: 0,
      status: LoadingStatusEnum.uploading,
    });

    try {
      await this.communityRepository.uploadImage({
        file: this[type].file as File,
        callback: (uploadPercent: number, uploadedImgUrlId: string) => {
          if (uploadPercent !== 100) {
            this.setImage(type, {
              uploadingPercent: uploadPercent,
            });
          } else {
            this.setImage(type, {
              id: uploadedImgUrlId,
              status: LoadingStatusEnum.success,
            });
          }
        },
        type,
      });
    } catch (e: any) {
      this.setImage(type, {
        error: e.message,
        status: LoadingStatusEnum.error,
      });
    }
  };

  setCropperSnapshot = (
    type: CommunityImageVariants,
    snapshot: CropperSnapshot | null
  ) => {
    if (type === 'thumbnail') {
      this.cropperThumbnailSnapshot = snapshot;
    } else {
      this.cropperLogoSnapshot = snapshot;
    }
  };
  // setCropperZoomVal = (type: CommunityImageVariants, cropperZoomVal: any) => {
  //   if (type === 'thumbnail') {
  //     this.cropperThumbnailZoomVal = cropperZoomVal;
  //   } else {
  //     this.cropperLogoZoomVal = cropperZoomVal;
  //   }
  // };

  proceedRestoredImage = async (
    type: CommunityImageVariants,
    data: any,
    prefferedSize?: CLOUDFLARE_VARIANTS_ENUM
  ) => {
    const id = data.id;
    if (id) {
      const imageUrls = await this.communityRepository.getCommunityImage(
        type,
        id
      );
      const imageUrl = getCloudflareSizeRecognition(imageUrls, prefferedSize);

      this[type] = {
        ...data,
        croppedFileUrl: imageUrl,
        fileUrl: imageUrl,
        status: LoadingStatusEnum.success,
      };
    }
  };

  reset = (type: CommunityImageVariants) => {
    if (type === 'thumbnail') {
      this.thumbnail = {
        ...initialImageData,
      };
      this.cropperLogoSnapshot = null;
    } else {
      this.logo = {
        ...initialImageData,
      };
      this.cropperLogoSnapshot = null;
    }
  };
}

interface ICommunityImages {
  readonly communityRepository: ICommunityRepository;

  readonly thumbnail: CommunityImageType;
  readonly logo: CommunityImageType;
  readonly cropperThumbnailSnapshot: CropperSnapshot | null;
  readonly cropperLogoSnapshot: CropperSnapshot | null;

  // set's a thumbnail based on the passed file
  setThumbnail: (file: File, originalFileUrl?: string) => void;
  setLogo: (file: File, originalFileUrl?: string) => void;
  setCropperSnapshot: (
    type: CommunityImageVariants,
    snapshot: CropperSnapshot | null
  ) => void;
  // uploads an image to the BE
  triggerUploading: (variant: CommunityImageVariants) => void;
  reset: (type: CommunityImageVariants) => void;
  proceedRestoredImage: (
    type: CommunityImageVariants,
    data: any,
    prefferedSize?: CLOUDFLARE_VARIANTS_ENUM
  ) => Promise<void>;
}
