// TODO: use symbols instead for strings to avoid global evt collisions?
export enum UrqlEventsEnum {
  URQL_GLOBAL_ERROR = 'URQL_GLOBAL_ERROR',
  URQL_AUTH_TOKEN_REFRESH = 'URQL_AUTH_TOKEN_REFRESH',
}
export enum MessagesEventsEnum {
  MESSAGE_SUBSCRIPTION_CREATED = 'MESSAGE_SUBSCRIPTION_CREATED',
  MESSAGE_SUBSCRIPTION_DELETED = 'MESSAGE_SUBSCRIPTION_DELETED',
  MESSAGE_SUBSCRIPTION_UPDATED = 'MESSAGE_SUBSCRIPTION_UPDATED',
  DELETE_MESSAGE = 'DELETE_MESSAGE',
  EDIT_MODE_OFF = 'EDIT_MODE_OFF',
  GO_TO_REPLY_PARENT = 'GO_TO_REPLY_PARENT',
  GO_TO_LATEST = 'GO_TO_LATEST',
}

export enum IocEventsEnum {
  IOC_CONTAINER_READY = 'IOC_CONTAINER_READY',
}

export enum CommonEventsEnum {
  INTERACTIVE_STORE_ELEMENT_CLOSE = 'INTERACTIVE_STORE_ELEMENT_CLOSE',
  PRIMARY_SIDEBAR_CONTENT_SCROLL = 'PRIMARY_SIDEBAR_CONTENT_SCROLL',
  PRIMARY_SIDEBAR_CONTENT_SCROLL_REACHED_BOTTOM = 'PRIMARY_SIDEBAR_CONTENT_SCROLL_REACHED_BOTTOM',
}

export const GlobalEvents = {
  ...UrqlEventsEnum,
  ...IocEventsEnum,
  ...MessagesEventsEnum,
  ...CommonEventsEnum,
};

export type GlobalEventsType =
  | UrqlEventsEnum
  | IocEventsEnum
  | MessagesEventsEnum
  | CommonEventsEnum;
