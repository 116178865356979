import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { RedirectionIdsEnum } from '@mainApp/src/services';
import { useCallback } from 'react';
import { InteractiveContentTypesEnum } from '../stores';
export function useJoinCommunityAction(isLoginMode = true) {
  const { communityStore, interactiveStore, authStore } = useMultipleInjection([
    IOC_TOKENS.communityStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.authStore,
  ]);
  return useCallback(() => {
    if (communityStore.activeCommunity.data?.isUserVisitor) {
      if (isLoginMode) {
        authStore.setLoginMode(true);
      }

      authStore.saveAuthCallerUrl(RedirectionIdsEnum.JOIN_COMMUNITY);

      interactiveStore.setActiveContentType(
        InteractiveContentTypesEnum.AUTH,
        true
      );
    } else if (communityStore.activeCommunity.data?.isUserNonMember) {
      communityStore.joinCommunity(
        communityStore.activeCommunity.data?.serverData.id,
        communityStore.activeCommunity.data?.serverData.slugName || ''
      );
    }
  }, [isLoginMode]);
}
