import { FormFieldInput } from '@mainApp/src/components/common';

import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { ItemHeader } from '@mainApp/src/modules/channel/channel-settings/components';

import { AddRolesMembersActionBtn } from './AddRolesMembersActionBtn';

type Props = {
  title: string;
  onChange: (value: string) => void;
  onAddMembers?: () => void;
  value: string;
  addMembersBtnText: string;
  searchPlaceholder: string;
  isSearching?: boolean;
  showAddMembersBtn?: boolean;
  isDisabledAddMemberBtn?: boolean;
};

export function PermissionsSearch(props: Props) {
  const {
    title,
    onChange,
    onAddMembers,
    value,
    showAddMembersBtn,
    addMembersBtnText,
    searchPlaceholder,
    isSearching,
    isDisabledAddMemberBtn,
  } = props;

  return (
    <div className="flex flex-col">
      <ItemHeader cn="mb-[12px]">
        <span className="flex w-full justify-between">
          {title}
          {showAddMembersBtn && (
            <AddRolesMembersActionBtn
              disabled={isDisabledAddMemberBtn}
              onClick={onAddMembers as () => void}
              text={addMembersBtnText}
            />
          )}
        </span>
      </ItemHeader>
      <FormFieldInput
        inputName="search"
        cnWrapper="mt-3 mb-6"
        onChange={onChange}
        value={value}
        placeholder={searchPlaceholder}
        leftSideContent={null}
        rightSideContent={isSearching ? <SmallLoader /> : null}
      />
    </div>
  );
}
