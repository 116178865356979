import { type CommunityRolePayload } from '@10x/foundation/types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { UniqueKeysRegistry } from '@mainApp/src/stores/types/common';
import {
  GetCommunityRoleMembersParamsType,
  type ICommunityRepository,
} from '../repositories';
import { ApiBase, CommonApiDataShapeType, IterableDataShape } from './ApiBase';
import { type IToastStore } from './Toast.store';
import { UserModel } from './User.model';

const MEMBERS_PAGE_LENGTH = 10;

export class CommunityRoleModel extends ApiBase implements ICommunityRoleModel {
  communityRepository: ICommunityRepository;

  roleMembers: CommonApiDataShapeType<IterableDataShape<UserModel> | null> =
    CommunityRoleModel.generateCommonApiDataShape();

  roleId = '';
  name = '';
  totalMemberCount = 0;
  communityId = '';

  serverData: Partial<CommunityRolePayload>;

  constructor({
    toastStore,
    communityRolePayload,
    communityRepository,
  }: {
    toastStore: IToastStore;
    communityRolePayload: Partial<CommunityRolePayload>;
    communityRepository: ICommunityRepository;
  }) {
    super(toastStore);
    this.communityRepository = communityRepository;

    this.serverData = communityRolePayload;

    this.roleId = communityRolePayload.id ?? '';
    this.name = communityRolePayload.name ?? '';
    this.totalMemberCount = communityRolePayload.totalMemberCount ?? 0;
    this.communityId = communityRolePayload.communityId ?? '';

    makeObservable(this, {
      communityId: observable,
      roleId: observable,
      name: observable,
      totalMemberCount: observable,
      roleMembers: observable,
      hasMoreMembersToLoad: computed,
      // parsedRoleMembers: computed,
      getRoleMembers: action,
      loadMoreMembers: action,
    });
  }

  // get parsedRoleMembers() {
  //   return (this.roleMembers.data?.valuesArray ?? []).map((roleMember) => {
  //     const avatarUrls = roleMember?.user?.imageUrls;
  //     // TODO: if there will be more complex logic for these fields here must be used the User.model
  //     return {
  //       id: roleMember.id,
  //       description: `@${roleMember.user?.username}`,
  //       name: roleMember.user?.displayName ?? '',
  //       memberType: Channel_Member_Type.User,
  //       isOnline: roleMember.user?.online,
  //       avatarSrc: avatarUrls
  //         ? getCloudflareSizeRecognition(
  //             avatarUrls,
  //             CLOUDFLARE_VARIANTS_ENUM.SQUARE
  //           )
  //         : undefined,
  //     };
  //   });
  // }

  get hasMoreMembersToLoad() {
    return Boolean(
      this.roleMembers?.meta?.pageInfo?.count <
        this.roleMembers?.meta?.totalCount
    );
  }

  getRoleMembers = async (
    params: Omit<GetCommunityRoleMembersParamsType, 'communityId' | 'roleId'>
  ) => {
    runInAction(() => {
      this.roleMembers = {
        ...this.roleMembers,
        loading: true,
      };
    });

    const response = await this.communityRepository.getCommunityRoleMembers({
      communityId: this.communityId,
      roleId: this.roleId,
      ...params,
      first: params.first || MEMBERS_PAGE_LENGTH,
    });
    const { error, data, originalResponse } = response;

    if (error) {
      this.handleError('Get Role Members', error.message);
      return;
    }

    const registry = data
      ? data.reduce((acc: UniqueKeysRegistry<UserModel>, edge) => {
          const node = edge?.node;
          if (node && !acc[node.id] && node.user) {
            acc[node.id] = new UserModel(node.user);
          }

          return acc;
        }, {})
      : {};

    runInAction(() => {
      this.roleMembers = {
        data: {
          registry: registry,
          valuesArray: Object.values(registry),
        },
        error: error,
        loading: false,
        meta: {
          variables: originalResponse.operation.variables,
          pageInfo: originalResponse?.data?.communityRoleMembers.pageInfo,
          nextPageLoading: false,
          totalCount: originalResponse?.data?.communityRoleMembers.totalCount,
        },
      };
    });
  };

  loadMoreMembers = async () => {
    if (!this.hasMoreMembersToLoad) {
      return;
    }

    const first = this.roleMembers.meta?.variables?.first;

    // TODO: it means that there will be re-fetched all the members that were fetched before and the model will be re-created. It's not optimal strategy. Need to rework it to be able to append new members to the existing list
    this.getRoleMembers({
      first: first ? first + MEMBERS_PAGE_LENGTH : MEMBERS_PAGE_LENGTH,
    });
  };
}

export interface ICommunityRoleModel {
  readonly communityId: string;
  readonly roleId: string;
  readonly name: string;
  readonly totalMemberCount: number;
  serverData: Partial<CommunityRolePayload>;
  // readonly parsedRoleMembers: MemberData[];
  readonly hasMoreMembersToLoad: boolean;

  getRoleMembers: (
    params: Omit<GetCommunityRoleMembersParamsType, 'communityId' | 'roleId'>
  ) => void;

  loadMoreMembers: () => Promise<void>;
}
