import { classNames } from '@foundationPathAlias/utilities';
import React from 'react';

type SidebarMenuItemProps<T> = {
  label: string;
  icon: React.ReactNode;
  active?: boolean;
  id: T;
  cn?: string;
  labelCn?: string;
  isMobile: boolean;
  onSelect: (id: T) => void;
};

export const SidebarMenuItem = <T,>(props: SidebarMenuItemProps<T>) => {
  const { label, icon, active, id, cn, labelCn, isMobile, onSelect } = props;

  const classNameContainer = React.useMemo(() => {
    if (isMobile) {
      return 'themed-text active:bg-pressed dark:active:pressed-dark';
    }

    return active
      ? 'font-semibold  bg-hovered-selected themed-text dark:bg-hovered-selected-dark'
      : 'themed-text-secondary hover:themed-text-primary hover:bg-hovered-selected hover:dark:bg-hovered-selected-dark';
  }, [isMobile, active]);

  return (
    <div
      className={classNames(
        'flex items-center gap-2 px-5 py-2',
        classNameContainer,
        cn
      )}
      role="button"
      onClick={() => onSelect(id)}
    >
      {icon}

      <span className={classNames(isMobile && 'text-body16R', labelCn)}>
        {label}
      </span>
    </div>
  );
};
