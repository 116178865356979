import ContentLoader from 'react-content-loader';
import { loaderColors } from './loaderColors';

type Props = {
  height?: number;
  width?: number;
  rx?: number;
  ry?: number;
  cn?: string;
};

export const CommunityCardLoader = (props: Props) => {
  const { height = 380, width = 300, rx = 20, ry = 20, cn } = props;
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={loaderColors.backgroundColor}
      foregroundColor={loaderColors.foregroundColor}
      className={cn}
      {...props}
    >
      <rect x="0" y="0" rx={rx} ry={ry} width="100%" height={height} />
    </ContentLoader>
  );
};

export default CommunityCardLoader;
