import { BaseFieldData } from '@10x/foundation/types';

import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import {
  ISidebarModalChildScreen,
  SidebarModalChildScreen,
} from '@foundationPathAlias/widgets/sidebar-modal';

import { ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import type { IChannelStore } from '@mainApp/src/stores';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';

enableStaticRendering(typeof window === 'undefined');

export type FieldData = BaseFieldData<string>;

const initialFieldData: FieldData = {
  data: '',
  loading: false,
  error: null,
  successMessage: '',
};

export class OverviewStore
  extends SidebarModalChildScreen<ScreenIdsValuesType>
  implements IOverviewStore
{
  private showActionReactionDisposer: IReactionDisposer;
  private disableProceedActionReactionDisposer: IReactionDisposer;
  private channelStore: IChannelStore = iocContainer.get(
    IOC_TOKENS.channelStore
  );

  isDisableProceedAction = false;

  channelGroupName = {
    ...initialFieldData,
  };
  channelGroupModel: ChannelGroupModel | null = null;

  get actionsPanelData() {
    return {
      cancelAction: () => {
        runInAction(() => {
          this.showActionPanel = false;
        });
      },
      getCancelActionText: () => 'cancel',
      proceedAction: async () => {
        const isError = await this.updateChannelGroup();
        if (!isError) {
          this.showActionPanel = false;
        }
      },
      getProceedActionText: () => 'saveChanges',
    };
  }

  constructor() {
    super(ScreenIds, screensConfig);

    makeObservable(this, {
      activeScreen: computed,
      isInitialScreen: computed,

      isDisableProceedAction: observable,
      activeScreenId: observable,
      // they exists in the parent class
      isDirty: observable,
      isLoading: observable,
      showActionPanel: observable,

      channelGroupName: observable,
      channelGroupModel: observable,
      setChannelGroupName: action,
      setChannelGroupModel: action,
      updateChannelGroup: action,

      resetData: action,
      setActiveScreenId: action,
      setNextScreenId: action,
      back: action,
      reset: action,
    });

    this.showActionReactionDisposer = reaction(
      () => {
        return this.isDirty;
      },
      (isDirty) => {
        runInAction(() => {
          this.showActionPanel = isDirty;
        });
      }
    );
    this.disableProceedActionReactionDisposer = reaction(
      () => {
        return [
          this.channelGroupName.loading,
          Boolean(this.channelGroupName.error),
        ];
      },
      (values) => {
        const [loading, error] = values;
        const shouldDisable = loading || Boolean(error);
        runInAction(() => {
          this.isDisableProceedAction = shouldDisable;
        });
      }
    );
  }

  setChannelGroupModel = (channelGroupModel: ChannelGroupModel | null) => {
    this.channelGroupModel = channelGroupModel;
    if (
      channelGroupModel &&
      channelGroupModel?.name !== this.channelGroupName.data
    ) {
      const newData = {
        ...this.channelGroupName,
        data: channelGroupModel.name,
      };
      this.channelGroupName = newData;
    }
  };

  onFirstSidebarModalSet = () => {
    const changesAlertConfig = {
      title: 'discardChanges',
      description: 'discardChangesDescr',
      firstBtnText: 'keepEditing',
      secondBtnText: 'closeAndDiscard',
    };

    this.setChangesAlertConfig(changesAlertConfig);
  };

  closeAndDiscard = () => {
    this.setChangesAlertConfig({
      show: false,
    });
  };

  setChannelGroupName = async (data: string, error: string | null = null) => {
    const newData = {
      ...this.channelGroupName,
      data,
      error: error,
    };
    this.channelGroupName = newData;

    this.showActionPanel = true;
    this.isDirty = true;
  };

  updateChannelGroup = async () => {
    this.isLoading = true;

    if (!this.channelGroupModel) {
      throw new Error('channelGroupModel is not set');
    }

    const { communityId, id } = this.channelGroupModel;

    const { error } = await this.channelStore.updateChannelGroup(
      communityId,
      id,
      {
        name: this.channelGroupName.data,
      }
    );

    if (error) {
      this.channelGroupName = {
        ...this.channelGroupName,
        error,
      };
    }

    this.isLoading = false;
    this.isDirty = false;

    return Boolean(error);
  };

  dispose = () => {
    this.showActionReactionDisposer();
    this.disableProceedActionReactionDisposer();
  };
}

export interface IOverviewStore
  extends ISidebarModalChildScreen<ScreenIdsValuesType> {
  channelGroupName: FieldData;
  channelGroupModel: ChannelGroupModel | null;
  setChannelGroupName: (data: string, error?: string | null) => void;
  setChannelGroupModel: (channelGroupModel: ChannelGroupModel | null) => void;
  closeAndDiscard: () => void;
}
