import { gql } from '@urql/core';

import { PermissionsFragment } from './permissionsFragment';
import { CommonUserFragment } from './userFragments';

export const RoleFragment = gql`
  fragment RoleFragment on CommunityRolePayload {
    id
    name
    description
    isDefault
    communityId
    authorId
    level
    permissions {
      ...PermissionsFragment
    }
    members {
      edges {
        node {
          ...CommonUserFragment
        }
        cursor
      }
    }

    ${PermissionsFragment}
    ${CommonUserFragment}
  }
`;
