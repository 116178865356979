// based on: https://advanced-cropper.github.io/react-advanced-cropper/
import { PlainObject } from '@10x/foundation/types';
import { classNames } from '@foundationPathAlias/utilities';
import { getAbsoluteZoom } from 'advanced-cropper/extensions/absolute-zoom';
import { ForwardedRef, forwardRef, useRef } from 'react';
import {
  CropperBackgroundWrapperProps,
  CropperImage,
  CropperPreviewRef,
  CropperRef,
  CropperState,
  CropperTransitions,
  CropperWrapperProps,
  FixedCropper,
  FixedCropperProps,
  FixedCropperRef,
  ImageRestriction,
  StencilSize,
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { CropperStencil } from './CropperStencil';
import {
  CropperOrientationEnum,
  CropperSnapshot,
  CustomWrapper,
} from './CropperWrapper';

export type PreviewState = {
  state: CropperState | null;
  image: CropperImage | null;
  transitions: CropperTransitions | null;
  loading?: boolean;
  loaded?: boolean;
};

export type CropperPropsType = {
  /** src image to crop */
  src: string;
  wrapperClassName?: string;
  /** cropper class names */
  className?: string;
  backgroundWrapperProps?: Partial<CropperBackgroundWrapperProps>;
  wrapperProps?: Partial<CropperWrapperProps>;
  /** there is no type in the library so just chec the API */
  stencilProps?: PlainObject;
  stencilSize?: Partial<StencilSize>;
  snapshot?: CropperSnapshot | null;
  /** default is a portrait rectangle with rounded corners 20px */
  cropBoxOrientation?: CropperOrientationEnum;
  onUpdate: (cropper: CropperRef, snapshot: CropperSnapshot) => void;
  onZoomChange?: (cropperSnapshot: CropperSnapshot) => void;
  renderRangeSlider: (rangeSlider: JSX.Element) => JSX.Element;
} & Partial<Omit<FixedCropperProps, 'onUpdate'>>;

export const Cropper = forwardRef(
  (props: CropperPropsType, ref: ForwardedRef<FixedCropperRef | null>) => {
    const {
      src,
      snapshot,
      wrapperClassName,
      className,
      backgroundWrapperProps = {},
      stencilSize = {},
      stencilProps = {},
      wrapperProps = {},
      cropBoxOrientation,
      renderRangeSlider,
      onZoomChange,
      onUpdate,
      ...otherProps
    } = props;

    const previewRef = useRef<CropperPreviewRef>(null);

    const handleUpdate = (cropper: CropperRef) => {
      previewRef.current?.update(cropper);
      const state = cropper.getState();

      if (!state) return;

      const currentAbsoluteZoom = getAbsoluteZoom(
        state,
        cropper.getSettings(),
        false
      );
      const snapshotPayload = {
        zoomValue: currentAbsoluteZoom,
        state: state,
      };
      onUpdate?.(cropper, snapshotPayload);
    };

    return (
      <div
        className={classNames(
          'border-1 min-h-[334px] w-full border-color-5 ',
          wrapperClassName
        )}
      >
        <FixedCropper
          ref={ref}
          onUpdate={handleUpdate}
          backgroundWrapperProps={{
            scaleImage: false,
            ...backgroundWrapperProps,
          }}
          stencilComponent={CropperStencil}
          wrapperComponent={CustomWrapper as any}
          defaultPosition={snapshot?.state?.coordinates || undefined}
          wrapperProps={{
            snapshot,
            renderRangeSlider,
            onZoomChange: onZoomChange,
            ...wrapperProps,
          }}
          stencilProps={{
            handlers: false,
            lines: false,
            movable: false,
            resizable: false,
            cropBoxOrientation,
            ...stencilProps,
          }}
          className={classNames('cropper h-[330px] w-full', className)}
          stencilSize={{
            width: 206,
            height: 290,
            ...stencilSize,
          }}
          src={src}
          imageRestriction={ImageRestriction.stencil}
          {...otherProps}
        />
      </div>
    );
  }
);
