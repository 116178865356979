import { User } from '@10x/foundation/types';
import {
  Avatar,
  CommunitySVG,
  DotsButton,
  IconButton,
  OutlineButton,
} from '@foundationPathAlias/components';
import { TooltipMenu } from '@foundationPathAlias/components/menu/tooltip-menu/TooltipMenu';
import { classNames } from '@foundationPathAlias/utilities';
import {
  ArrowUturnRightIcon,
  Cog6ToothIcon,
  FlagIcon,
  LinkIcon,
  NoSymbolIcon,
} from '@heroicons/react/20/solid';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

type UserOptionClick = 'share' | 'copy-profile-link' | 'report' | 'block';

export type UserDataProfile = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'username' | 'bio' | 'website' | 'imageUrls'
> & {
  avatarUrl?: string;
};

type UserProfileCardProps = {
  ownProfile?: boolean;
  rootCn?: string;
  onCtaClick: () => void;
  onSettings?: () => void;
  onClickOption?: (option: UserOptionClick) => void;
  user?: UserDataProfile | null;
  onClose: () => void;
  darkMode?: boolean;
};

const _UserProfileCard = ({
  ownProfile,
  rootCn,
  onCtaClick,
  user,
  onSettings,
  onClose,
  darkMode,
  onClickOption,
}: UserProfileCardProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const menuContentRef = React.useRef<HTMLUListElement>(null);

  const {
    dimensionsStore: { isMobile },
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
  ]);

  const { t } = useTranslation(['common']);

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const { firstName, lastName, username, bio, website, avatarUrl } = user || {};

  const displayName = [firstName, lastName].filter(Boolean).join(' ');

  let userProfileContent: any = null;

  const handleClickOption = (option: UserOptionClick) => () => {
    if (isMobile) {
      onClickOption?.(option);
      interactiveStore.setContent(userProfileContent);
      return;
    }

    onClickOption?.(option);
    setMenuIsOpen(false);
    onClose();
  };

  const handleClose = React.useCallback(() => {
    setMenuIsOpen(false);
    onClose();
  }, [menuIsOpen]);

  useClickAway(containerRef, !menuIsOpen ? handleClose : () => {});
  useClickAway(menuContentRef, menuIsOpen ? handleClose : () => {});

  const contentMenu = (
    <ul
      ref={menuContentRef}
      className={classNames(
        ' dark:bg-background-tetriary-dark',
        isMobile
          ? 'flex w-full flex-col gap-3 px-4 py-4'
          : 'w-48 rounded-[5px] border-0 py-2 shadow-shadow-menu-dekstop'
      )}
    >
      <TooltipMenu.Item
        label={t('userProfileCard.shareTo', {
          to: '...',
        })}
        onClick={handleClickOption('share')}
        icon={<ArrowUturnRightIcon width={20} />}
      />

      <TooltipMenu.Item
        label={t('userProfileCard.copyProfileLink')}
        onClick={handleClickOption('copy-profile-link')}
        icon={<LinkIcon width={20} />}
      />

      <hr className="border-t-element-subtle dark:border-t-element-subtle-dark" />

      <TooltipMenu.Item
        label={t('userProfileCard.report')}
        onClick={handleClickOption('report')}
        icon={<FlagIcon width={20} />}
      />

      <TooltipMenu.Item
        label={t('userProfileCard.block')}
        onClick={handleClickOption('block')}
        icon={<NoSymbolIcon width={20} />}
        className="text-element-error"
      />
    </ul>
  );

  const handleOpenMenu = () => {
    if (isMobile) {
      userProfileContent = interactiveStore.content;
      interactiveStore.setContent(contentMenu);
      interactiveStore.setInteractiveElementOpen(true);
      return;
    }
    setMenuIsOpen(true);
  };

  return (
    <div
      ref={containerRef}
      className={classNames(
        'relative flex w-full flex-col items-center bg-background-primary pb-5 dark:bg-background-tetriary-dark  md:w-[340px]',
        isMobile && ownProfile ? 'h-screen' : 'pt-6',
        rootCn
      )}
    >
      {ownProfile && isMobile ? (
        <div className="mb-6 flex w-full items-center justify-between gap-2 border-b border-b-element-subtle px-4 py-2 dark:border-element-subtle-dark">
          <CommunitySVG
            width={24}
            height={24}
            color={darkMode ? '#FFFFFF' : '#1A1A1A'}
          />

          <span className="text-body16SB">{t('userProfileCard.title')}</span>

          <Cog6ToothIcon
            className="dark:text-text-secondary-dark"
            title={t('settings') as string}
            width={24}
            onClick={() => {
              onSettings?.();
              onClose();

              if (isMobile) {
                interactiveStore.setInteractiveElementOpen(false);
              }
            }}
          />
        </div>
      ) : (
        <div className="absolute right-4 top-5">
          {ownProfile ? (
            <IconButton
              onClick={() => {
                onSettings?.();
                onClose();

                if (isMobile) {
                  interactiveStore.setInteractiveElementOpen(false);
                }
              }}
              cn="p-[7px] themed-text-secondary"
            >
              <Cog6ToothIcon
                className="themed-text-secondary"
                title={t('settings') as string}
                width={24}
              />
            </IconButton>
          ) : (
            <TooltipMenu
              open={menuIsOpen}
              onClose={handleClose}
              contentStyle={{
                width: 'auto',
                padding: '0',
                fontSize: '14px',
                borderRadius: 5,
                borderColor: darkMode ? '#484848' : '#E6E6E6',
              }}
              closeOnDocumentClick={false}
              arrow={false}
              trigger={
                <div title={t('more') as string}>
                  <DotsButton
                    className="dark:text-text-secondary-dark"
                    onClick={handleOpenMenu}
                  />
                </div>
              }
              position={['bottom left']}
            >
              {contentMenu}
            </TooltipMenu>
          )}
        </div>
      )}

      <Avatar src={avatarUrl} width={120} height={120} darkMode={darkMode} />

      <div className="mt-4 flex flex-col px-4 text-center">
        <span className="text-subheadingSB text-text-primary dark:text-text-primary-dark">
          {displayName}
        </span>

        <span className="text-body16SB text-text-secondary dark:text-text-secondary-dark">
          @{username}
        </span>
      </div>

      {bio && (
        <span className="mt-5 px-4 text-body16R text-text-secondary dark:text-text-secondary-dark">
          {bio}
        </span>
      )}

      {website && (
        <div className="mt-4 flex gap-1 self-start px-4">
          <LinkIcon className="text-primary-100" width={18} />

          <span className="text-body16SB text-primary-100 dark:text-primary-100-dark">
            {website}
          </span>
        </div>
      )}

      <div className="w-full px-4">
        <OutlineButton
          cn="mt-4"
          onClick={() => {
            onCtaClick();

            if (!isMobile) {
              onClose();
            }
          }}
        >
          {ownProfile
            ? t('userProfileCard.editMyProfile')
            : t('userProfileCard.sendMessage')}
        </OutlineButton>
      </div>
    </div>
  );
};

export const UserProfileCard = observer(_UserProfileCard);
