import {
  ArrowRightOnRectangleIcon,
  AtSymbolIcon,
  CreditCardIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MoonIcon,
} from '@heroicons/react/24/outline';

import { MenuListItem, SettingIdsEnum } from './types';

export const useSidebarMenu = () => {
  const MENU_DESCRIPTION: { [key in SettingIdsEnum]: string } = {
    [SettingIdsEnum.account]: 'account',
    [SettingIdsEnum.display]: 'display',
    [SettingIdsEnum.language]: 'language',
    [SettingIdsEnum.about]: 'about.label',
    [SettingIdsEnum.subscription]: 'subscription',
    [SettingIdsEnum.logOut]: 'access.logout',
  };

  const MENU_LIST: MenuListItem[] = [
    {
      id: SettingIdsEnum.account,
      label: MENU_DESCRIPTION.account,
      icon: <AtSymbolIcon width={20} />,
    },
    {
      id: SettingIdsEnum.display,
      label: MENU_DESCRIPTION.display,
      icon: <MoonIcon width={20} />,
    },
    {
      id: SettingIdsEnum.language,
      label: MENU_DESCRIPTION.language,
      icon: <GlobeAltIcon width={20} />,
    },
    {
      id: SettingIdsEnum.about,
      label: MENU_DESCRIPTION.about,
      icon: <InformationCircleIcon width={20} />,
    },
    {
      id: SettingIdsEnum.subscription,
      label: MENU_DESCRIPTION.subscription,
      icon: <CreditCardIcon width={20} />,
    },
    {
      id: SettingIdsEnum.logOut,
      label: MENU_DESCRIPTION.logOut,
      icon: <ArrowRightOnRectangleIcon width={20} />,
    },
  ];

  return {
    MENU_LIST,
    MENU_DESCRIPTION,
  };
};
