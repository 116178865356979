import { classNames } from '@foundationPathAlias/utilities';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export type ActionWithIconProps = {
  rootCn?: string;
  textCn?: string;
  defaultRightIconCn?: string;
  title: string;
  onClick: () => void;
  /** there is no default icon */
  leftIcon?: JSX.Element;
  /** the default icon is ChevronRightIcon */
  rightIcon?: JSX.Element;
  /** just hide the right icon at all */
  noDefaultRightIcon?: boolean;
  disabled?: boolean;
};
export function ActionWithIcon(props: ActionWithIconProps) {
  const {
    title,
    onClick,
    leftIcon,
    rightIcon,
    rootCn,
    textCn,
    defaultRightIconCn,
    noDefaultRightIcon,
    disabled,
  } = props;

  let finalRightIcon = null;

  if (!noDefaultRightIcon) {
    finalRightIcon = rightIcon ? (
      rightIcon
    ) : (
      <ChevronRightIcon
        className={classNames(
          ' themed-primary-text-color-dynamic ml-[8px] mt-[4px] h-[20px] w-[20px]',
          disabled && 'cursor-default',
          defaultRightIconCn
        )}
      />
    );
  }

  return (
    <div
      className={classNames(
        ' inline-flex cursor-pointer items-center',
        disabled ? 'cursor-default' : 'hover-el',
        rootCn
      )}
      onClick={disabled ? undefined : onClick}
    >
      {leftIcon}
      <span className={classNames('themed-primary-text-color-dynamic', textCn)}>
        {title}
      </span>
      {finalRightIcon}
    </div>
  );
}
