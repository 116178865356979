import { classNames } from '@foundationPathAlias/utilities';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SettingIdsEnum } from './types';

type MenuItemProps = {
  label: string;
  icon: React.ReactNode;
  active?: boolean;
  id: SettingIdsEnum;
  onSelect: (id: SettingIdsEnum) => void;
};

const _MenuItem = ({ icon, label, id, onSelect, active }: MenuItemProps) => {
  const { dimensionsStore, systemStore } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.systemStore,
  ]);
  const { isMobile } = dimensionsStore;
  const { t } = useTranslation('common');

  const classNameContainer = React.useMemo(() => {
    if (isMobile) {
      return 'text-text-primary dark:text-text-primary-dark active:bg-pressed dark:active:pressed-dark';
    }

    return active
      ? 'font-semibold text-text-primary bg-hovered-selected dark:text-text-primary-dark dark:bg-hovered-selected-dark'
      : 'text-text-secondary dark:text-text-secondary-dark hover:text-text-primary hover:bg-hovered-selected hover:dark:bg-hovered-selected-dark';
  }, [isMobile, active]);

  const lng = id === 'language' ? t(systemStore.localeLabel) : null;

  return (
    <div
      className={classNames(
        'flex items-center gap-2 px-5 py-2',
        classNameContainer
      )}
      role="button"
      onClick={() => onSelect(id)}
    >
      {icon}

      <span className={classNames(isMobile && 'text-body16R')}>{label}</span>

      <div className="ml-auto flex items-center gap-2">
        {isMobile && (
          <>
            {lng} <ChevronRightIcon className="ml-auto h-5 w-5" />
          </>
        )}
      </div>
    </div>
  );
};

export const MenuItem = observer(_MenuItem);
