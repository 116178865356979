import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { RedirectionIdsEnum } from '@mainApp/src/services';
import { useCallback } from 'react';
import { InteractiveContentTypesEnum } from '../stores';
export function useShowAuthModalAction(isLoginMode = true) {
  const { interactiveStore, authStore } = useMultipleInjection([
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.authStore,
  ]);
  return useCallback(() => {
    if (!authStore.logged) {
      if (isLoginMode) {
        authStore.setLoginMode(true);
      }

      authStore.saveAuthCallerUrl(RedirectionIdsEnum.AUTH_REQUIRED);

      interactiveStore.setActiveContentType(
        InteractiveContentTypesEnum.AUTH,
        true
      );
    }
  }, [isLoginMode]);
}
