export function detectScrollEnd(
  el: HTMLElement | any,
  threshold: number = 0
): boolean {
  if (!(el instanceof HTMLElement)) {
    throw new Error('Element is not HTMLElement');
  }

  let isReachedEnd = false;

  const clientH = el.clientHeight;
  const scrollH = el.scrollHeight;
  const scrollY = el.scrollTop;
  const scrollDelta = scrollH - scrollY - clientH;

  if (scrollDelta <= threshold) {
    isReachedEnd = true;
  }

  return isReachedEnd;
}
