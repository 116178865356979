export interface IScreenModel {
  id: string;
  title: string;
  description: string;

  prevScreenItemId: string;
  nextScreenItem: string;

  complete(): Promise<null>;
}

export type SettingIds =
  | 'account'
  | 'display'
  | 'language'
  | 'about'
  | 'subscription'
  | 'logOut'
  | 'none';

export enum MenuItemsEnum {
  ACCOUNT = 'account',
  DISPLAY = 'display',
  LANGUAGE = 'language',
  ABOUT = 'about',
  SUBSCRIPTION = 'subscription',
  LOGOUT = 'logOut',
  // actual only on mobile mode
  INITIAL = 'initialMenu',
}

// TODO: Maybe rename it to Account and move to the Account folder?
export enum AccountSettingsEnum {
  USERNAME = 'username',
  DISPLAY_NAME = 'displayName',
  DEACTIVATE_OR_DELETE = 'deactivateOrDelete',
  DELETE = 'delete',
  DEACTIVATE = 'deactivate',
  NAME = 'name',
  EMAIL = 'email',
  INITIAL = 'initial',
}

export enum DisplaySettingsEnum {
  INITIAL = 'initial',
}

export enum SubscriptionSettingsEnum {
  INITIAL = 'initial',
}

export enum AboutSettingsEnum {
  INITIAL = 'initial',
  POLICY = 'policy',
  TERMS = 'terms',
}
export enum LanguageSettingsEnum {
  INITIAL = 'initial',
}
