import { TextInput, TextInputPropsType } from '@foundationPathAlias/components';
import { useTranslation } from 'react-i18next';

import { validators, ValidatorType } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

export type InputPropsType = {
  addMailSignBeforeInput?: boolean;
  value: string;
  maxLength?: number;
  withoutDefaultValidators?: boolean;
  placeholder: string;
  infoWrapperCn?: string;
  onChange: (val: string) => void;
  successMsg: string;
  specificValidators?: ValidatorType<any>[];
} & Partial<TextInputPropsType>;

export function Input(props: InputPropsType) {
  const {
    addMailSignBeforeInput,
    placeholder,
    withoutDefaultValidators,
    value,
    inputName,
    infoWrapperCn,
    onChange,
    successMsg,
    specificValidators = [],
    ...otherProps
  } = props;

  const { t } = useTranslation('common');

  const finalSuccessMsg = successMsg
    ? () => {
        return (
          <span className={'themed-text-success text-sm14R'}>{successMsg}</span>
        );
      }
    : undefined;

  const leftSideContent = addMailSignBeforeInput ? <span>@</span> : undefined;
  const inputNameLc = inputName?.toLowerCase();
  const defaultValidators = withoutDefaultValidators
    ? []
    : [
        validators.getEmptyFieldValidation(
          t('errors.emptyValue', {
            fieldName: inputNameLc,
          }) as string
        ),
        validators.getMaxLengthValidator(
          15,
          t('errors.maxLength', {
            maxCharCount: 15,
            fieldName: inputName,
          }) as string
        ),
        validators.getNonPeriodOrUnderscoreStartValidator(
          t('errors.nonPeriodOrUnderscoreStart', {
            fieldName: inputNameLc,
          }) as string
        ),
        validators.getNonPeriodOrUnderscoreEndValidator(
          t('errors.nonPeriodOrUnderscoreEnd', {
            fieldName: inputNameLc,
          }) as string
        ),

        validators.getDoubleUnderscoreValidator(
          t('errors.doubleUnderscore', {
            fieldName: inputNameLc,
          }) as string
        ),
        validators.getPeriodAfterUnderscoreValidator(
          t('errors.periodAfterUnderscore') as string
        ),
        validators.getUnderscoreAfterPeriodValidator(
          t('errors.underscoreAfterPeriod') as string
        ),
        validators.get10XValidator(
          t('errors.10X', {
            fieldName: inputName,
          }) as string
        ),
        validators.getEveryoneHereValidator(
          t('errors.everyoneHere', {
            fieldName: inputName,
          }) as string
        ),
      ];

  return (
    <TextInput
      disabled={false}
      infoWrapperCn={classNames('min-h-[48px]', infoWrapperCn)}
      getCustomSuccessUI={finalSuccessMsg}
      InputProps={{
        value,
      }}
      inputName={inputName}
      validators={[...defaultValidators, ...specificValidators]}
      placeholder={placeholder}
      labelCn="px-[12px] px-[11px] space-x-[4px]"
      leftSideContent={leftSideContent}
      onChange={onChange}
      //   outerError={error}
      errorTextCn="text-left text-element-error dark:text-element-error-dark"
      {...otherProps}
    />
  );
}
