import { gql } from '@urql/core';
import { GQLDateObjectTypeFragment } from './commonFragments';

export const PermissionsFragment = gql`
  fragment PermissionsFragment on ModulePermissionPayload {
    id
    createdAt
    updatedAt
    created {
      ...GQLDateObjectTypeFragment
    }
    updated {
      ...GQLDateObjectTypeFragment
    }
    deleted {
      ...GQLDateObjectTypeFragment
    }
    name
    description
    moduleId
    communityId
    permissionGroupModuleType
    value
    status
    options
    permissionGroupName
  }
  ${GQLDateObjectTypeFragment}
`;
