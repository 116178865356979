import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import {
  MembersScrollableListWrapper,
  MembersUserList,
} from '@mainApp/src/modules/channel/common/members-list';

import { NoResult } from '@mainApp/src/modules/channel/common/permissions-components/NoResult';

import { PermissionsStore } from '../data/Permissions.store.local';

export function _MembersView() {
  const channelSettingsStore = useInjection(IOC_TOKENS.channelSettingsStore);

  const { t } = useTranslation(['common', 'channel']);

  const activeStore =
    channelSettingsStore.activeChildScreen as PermissionsStore;

  const { selectedRole, selectedRoleMembers, isSelectedRoleMembersLoading } =
    activeStore.permissionsServiceStore;

  useEffect(() => {
    selectedRole?.getRoleMembers({});
  }, []);

  if (!selectedRole) return null;

  return (
    <ScreenWrapper
      title={t('channel:modal.roleMembers', {
        roleName: selectedRole?.name,
      })}
      sidebarModalController={channelSettingsStore}
      showBackBtn={true}
    >
      <MembersScrollableListWrapper
        rootCn="md:max-h-none"
        onScrollEnd={() => {
          selectedRole?.loadMoreMembers();
        }}
      >
        {isSelectedRoleMembersLoading ? (
          <SmallLoader cn="absolute left-[50%] top-[200px]" />
        ) : selectedRoleMembers ? (
          <MembersUserList
            data={selectedRoleMembers}
            noAction={true}
            noTitle={true}
            loading={selectedRole?.roleMembers.loading}
          />
        ) : (
          <NoResult cn="mt-[20%] text-body16R">
            {t<string>('common:noMembers')}
          </NoResult>
        )}
      </MembersScrollableListWrapper>
    </ScreenWrapper>
  );
}

export const MembersView = withAnimatedStack(observer(_MembersView));
