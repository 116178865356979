import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { ScreenWrapper as OuterScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
  title: string;
  showBackBtn?: boolean;
  rootCn?: string;
};

export const _ScreenWrappper = (props: Props) => {
  const { children, title, showBackBtn, rootCn } = props;
  const {
    channelGroupSettingsStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.channelGroupSettingsStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);

  const finalShowBtn =
    typeof showBackBtn === 'undefined' ? isMobile : showBackBtn;

  return (
    <OuterScreenWrapper
      showBackBtn={finalShowBtn}
      title={t(title)}
      sidebarModalController={channelGroupSettingsStore}
      isMobile={isMobile}
      rootCn={rootCn}
    >
      {children}
    </OuterScreenWrapper>
  );
};

export const ScreenWrapper = observer(_ScreenWrappper);
