import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useCallback, useState } from 'react';

export function useDeleteChannelGroup(
  communityId: string,
  channelGroupId: string
) {
  const [isLoading, setIsLoading] = useState(false);

  const channelStore = useInjection(IOC_TOKENS.channelStore);

  if (!communityId) {
    throw new Error('communityId is not defined');
  }
  if (!channelGroupId) {
    throw new Error('channelGroupId is not defined');
  }

  const deleteChannelGroup = useCallback(async () => {
    setIsLoading(true);

    const { error } = await channelStore.removeChannelGroup(
      communityId,
      channelGroupId as string
    );
    setIsLoading(false);

    return Boolean(error);
  }, [communityId, channelGroupId]);

  return { deleteChannelGroup, isLoading };
}
