import {
  AccountProUsernameTypeItemPayload,
  UserGeneralSettingsInput,
  UserGeneralSettingsResponse,
  User_General_Settings_Display_Preferences,
} from '@10x/foundation/types';
import {
  AccountProUsernameSubscription,
  UserEdge,
} from '@10x/foundation/types/graphql-schema';
import { IOC_TOKENS } from '@mainApp/src/ioc';
import type {
  GetUsersQueyParamsType,
  IUserRepository,
} from '@mainApp/src/repositories/User.repository.types';
import { SubscriptionTypeEnum } from '@mainApp/src/stores/User.store.types';
import { inject } from 'inversify';
import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { BaseRepositoryResponse } from '../repositories';
import { ApiBase, CommonApiDataShapeType, IterableDataShape } from './ApiBase';
import type { IToastStore } from './Toast.store';
import { DataFieldNames, UserModel } from './User.model';
import { IUserStore } from './User.store.types';
import type { IAuthStore, ISystemStore } from './types';

export * from './User.store.types';

enableStaticRendering(typeof window === 'undefined');

type GeneralSettings = Omit<
  UserGeneralSettingsResponse,
  'ownerId' | 'id' | '__typename'
>;

export class UserStore extends ApiBase implements IUserStore {
  systemStore: ISystemStore;
  me: UserModel | null = null;
  isSubmitting = false;

  generalSettings: CommonApiDataShapeType<GeneralSettings> =
    UserStore.generateCommonApiDataShape(false, {
      displayPreferences: User_General_Settings_Display_Preferences.Auto,
    });

  isUploading = false;
  subscriptionPrices: AccountProUsernameTypeItemPayload[] | null = null;
  proSubscription: AccountProUsernameSubscription | null = null;
  users: CommonApiDataShapeType<IterableDataShape<UserModel>> =
    UserStore.generateCommonApiDataShape(true, []);

  private usersChunkAmount = 10;

  repository: IUserRepository;

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.userRepository) repository: IUserRepository,
    @inject(IOC_TOKENS.authStore) authStore: IAuthStore,
    @inject(IOC_TOKENS.systemStore) systemStore: ISystemStore
  ) {
    super(toastStore);

    this.repository = repository;
    this.systemStore = systemStore;

    makeObservable(this, {
      me: observable,
      users: observable,
      isUploading: observable,
      subscriptionPrices: observable,
      proSubscription: observable,
      generalSettings: observable,

      isSubmitting: observable,
      setProSubscription: action,
      getCurrentUser: action,
      getUsers: action,
      updateUsername: action,
      setGeneralSettings: action,
      setIsUploading: action,
      setIsSubmitting: action,
    });

    autorun(() => {
      if (authStore.logged) {
        this.getCurrentUser();
        this.getUserGeneralSettings();
      }
    });
  }

  setIsUploading(val: boolean) {
    this.isUploading = val;
  }

  setIsSubmitting(val: boolean) {
    this.isSubmitting = val;
  }

  getCurrentUser = async (forceLoad?: boolean) => {
    if (!this.me || forceLoad) {
      const { data } = await this.repository.getCurrentUser();

      runInAction(() => {
        this.me = new UserModel(data);
      });
    }

    // I think it always should be a User? Need to check it
    return this.me;
  };

  setProSubscription = (data: AccountProUsernameSubscription | null) => {
    this.proSubscription = data;
  };

  setDataField = (fieldName: DataFieldNames, value: string) => {
    if (!this.me) {
      return;
    }
    this.me.setTextFields(fieldName, value);
  };

  updateCurrentUser = async () => {
    if (!this.me) {
      return;
    }

    const { displayName, bio, website, lastName, firstName } = this.me;

    this.setIsSubmitting(true);

    const data: any = {};

    if (firstName) {
      data.firstName = firstName;
    }
    if (lastName) {
      data.lastName = lastName;
    }
    if (displayName) {
      data.displayName = displayName;
    }

    if (bio) {
      data.bio = bio;
    }

    if (website) {
      data.website = website;
    }

    const result = await this.repository.updateCurrentUser(data);

    if (!result.error) {
      this.me.serverData = {
        ...this.me.serverData,
        ...result.data,
      };
    }

    this.setIsSubmitting(false);
  };

  async updateAvatarUser(file: File) {
    this.setIsUploading(true);
    await this.repository.uploadAvatarImage(file);

    // TODO: Temporally code, until solve problem on  BE images cache
    if (this.me?.serverData) {
      this.me.setServerData({
        imageUrls: [URL.createObjectURL(file)],
      });
    }

    // TODO: Replace code above to update the avatar image for code below
    // await this.getCurrentUser(true);

    this.setIsUploading(false);
  }

  getUser = async (id: string) => {
    let res = null;
    if (id) {
      const { data } = await this.repository.getUser(id);
      res = new UserModel(data);
    }

    return res;
  };

  fetchMoreUsers = async (params: GetUsersQueyParamsType = {} as any) => {
    const { meta, loading } = this.users;
    if (!meta) {
      return;
    }

    if (loading) {
      return;
    }

    const { pageInfo, variables } = meta;

    if (!pageInfo || !pageInfo.hasNextPage) {
      return;
    }

    const options = {
      ...variables,
      after: pageInfo.endCursor,
      first: pageInfo.count,
      ...params,
    };

    this.getUsers(options);
  };

  getUsers = async (params: GetUsersQueyParamsType) => {
    this.users = {
      ...this.users,
      loading: true,
    };

    const response = await this.repository.getUsers({
      ...params,
      first: params.first || this.usersChunkAmount,
    });
    const { error, data = [], originalResponse } = response;
    if (error) {
      console.error(`users fetch Error ->>> ${error}`);
    }

    const registry = data
      ? data.reduce(
          (acc, edge: UserEdge) => {
            const node = edge.node;
            if (!acc[node.id]) {
              acc[node.id] = new UserModel(node);
            }

            return acc;
          },
          { ...this.users.data.registry } as any
        )
      : [];
    // const result = data
    //   ? data.map((edge: UserEdge) => new UserModel(edge.node))
    //   : [];

    this.users = {
      data: {
        registry: registry,
        valuesArray: Object.values(registry),
      },
      error: error,
      loading: false,
      meta: {
        variables: originalResponse.operation.variables,
        pageInfo: originalResponse?.data?.users.pageInfo,
        nextPageLoading: false,
      },
    };
  };

  createProUsername = async (username: string) => {
    await this.repository.createProUsername(username);
  };

  updateUsername = async (username: string) => {
    const res = await this.repository.updateUsername(username);
    const { data } = res;
    if (data?.username && this.me) {
      this.me?.setServerData({
        ...this.me.serverData,
        username: data.username,
      });
    }
    return res;
  };
  checkUsernameAvailability = async (username: string) => {
    const res = await this.repository.checkUsernameAvailability(username);

    return res;
  };

  updateProUsername = async (username: string) => {
    await this.repository.updateProUsername(username);
  };

  createProUserSubscription = async (priceId: string) => {
    return await this.repository.createProUserSubscription(priceId);
  };

  deactivateProUserSubscription = async () => {
    const res = await this.repository.deactivateProUserSubscription();
    const { data } = res;
    if (data) {
      this.setProSubscription(data);
    }
    return res;
  };
  reactivateProUserSubscription = async () => {
    const res = await this.repository.reactivateProUserSubscription();
    const { data } = res;
    if (data) {
      this.setProSubscription(data);
    }
    return res;
  };

  getUserProSubscription = async () => {
    const res = await this.repository.getUserProSubscription();
    const { data } = res;
    if (data) {
      this.setProSubscription(data);
    }
    return res;
  };

  saveProUsername = async (username: string) => {
    return await this.repository.saveProUsername(username);
  };
  sendDeactivateUserAccountEmail = async () => {
    return await this.repository.sendDeactivateUserAccountEmail();
  };
  sendDeleteUserAccountEmail = async (reason: string) => {
    return await this.repository.sendDeleteUserAccountEmail(reason);
  };
  deactivateUserAccount = async (otpCode: string) => {
    return await this.repository.deactivateUserAccount(otpCode);
  };
  deleteUserAccount = async (otpCode: string) => {
    return await this.repository.deleteUserAccount(otpCode);
  };
  validateUserEmail = async (email: string) => {
    return await this.repository.validateUserEmail(email);
  };
  resendUserChangeEmailRequest = async (changeEmailRequestId: string) => {
    const res = await await this.repository.resendUserChangeEmailRequest(
      changeEmailRequestId
    );
    const { error } = res;
    if (error) {
      this.handleError('Cannot resent your activation link', error.message);
    } else {
      this.showSuccessToast(
        'The activation link had been sent',
        'Check you email'
      );
    }
    return res;
  };
  cancelUserChangeEmailRequest = async (changeEmailRequestId: string) => {
    return await this.repository.cancelUserChangeEmailRequest(
      changeEmailRequestId
    );
  };
  preUpdateUserEmail = async (email: string) => {
    return await this.repository.preUpdateUserEmail(email);
  };
  updateUserEmail = async (optCode: string) => {
    const res = await this.repository.updateUserEmail(optCode);
    const { error } = res;
    if (error) {
      this.handleError('Cannot update your email', error.message);
    } else {
      this.showSuccessToast('Success', 'Your email had been updated');
    }
    return res;
  };
  getUserChangeEmailRequests = async () => {
    return await this.repository.getUserChangeEmailRequests();
  };

  updateUserGeneralSettings = async (data: UserGeneralSettingsInput) => {
    this.setGeneralSettings(true);
    const repositoryResponse = await this.repository.updateUserGeneralSettings(
      data
    );
    const { data: response, error } = repositoryResponse;
    this.proceedTheme(
      response?.displayPreferences as User_General_Settings_Display_Preferences
    );

    this.setGeneralSettings(false, repositoryResponse);
    // TODO: how to use translates inside the stores?
    if (error) {
      this.handleError('Error on update user general settings', error.message);
    }
    return error;
  };
  getUserGeneralSettings = async () => {
    this.setGeneralSettings(true);

    const repositoryResponse = await this.repository.getUserGeneralSettings();
    const { data, error } = repositoryResponse;

    this.proceedTheme(
      data?.displayPreferences as User_General_Settings_Display_Preferences
    );
    this.setGeneralSettings(false, repositoryResponse);

    if (error) {
      this.handleError(
        this.systemStore.i18n?.t('errors.generalSettingsError') ||
          'An error happened. Please, try again.',
        error.message
      );
    }

    return error;
  };

  private proceedTheme = (
    displayPreferences?: User_General_Settings_Display_Preferences
  ) => {
    const isAutoTheme =
      displayPreferences === User_General_Settings_Display_Preferences.Auto;
    const isDm =
      displayPreferences === User_General_Settings_Display_Preferences.Dark;

    if (isAutoTheme) {
      // just get the OS theme and subscribe to the future updates
      this.systemStore.subscribeToOSDarkMode();
    } else {
      // unsubscribe from the OS theme if were subscribed
      this.systemStore.unsubscribeFromOSDarkMode();
      // set the theme from the user settings
      this.systemStore.setDarkTheme(isDm);
    }
  };

  setGeneralSettings = (
    loading: boolean,
    response?: BaseRepositoryResponse<UserGeneralSettingsResponse>
  ) => {
    if (loading || !response) {
      this.generalSettings = {
        ...this.generalSettings,
        loading: true,
      };
    } else {
      this.generalSettings = {
        data: {
          displayPreferences: response?.data?.displayPreferences as string,
        },
        loading: false,
        error: response.error,
        meta: {},
      };
    }
  };

  getAccountProUsernameSubscriptionTypes = async () => {
    const { data, error } =
      await this.repository.getAccountProUsernameSubscriptionTypes();

    if (data) {
      this.subscriptionPrices = data;
    }

    if (error) {
      this.handleError('Error', error.message);
    }

    return data;
  };

  getSubscriptionPriceByType = (priceType: SubscriptionTypeEnum) => {
    if (!this.subscriptionPrices) return;

    return this.subscriptionPrices.find((o) => o.slug === priceType);
  };
}
