import { NewestCommunityPayload } from '@10x/foundation/types';
import { makeAutoObservable, observable } from 'mobx';
import { CommunityModel } from './Community.model';
import { ISystemStore } from './types';

export class NewestCommunityModel {
  community: CommunityModel;
  serverData: NewestCommunityPayload;

  constructor(
    newestCommunity: NewestCommunityPayload,
    systemStore: ISystemStore
  ) {
    this.serverData = newestCommunity;
    this.community = new CommunityModel(newestCommunity.community, systemStore);

    makeAutoObservable(this, {
      serverData: observable,
      community: observable,
    });
  }
}
