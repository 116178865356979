import { inject, injectable } from 'inversify';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import {
  Channel_Sort_Types,
  Channel_Types,
  ChannelAddMemberInput,
  ChannelGroupAddMemberInput,
} from '@10x/foundation/types';
import { AnimatedStack } from '@foundationPathAlias/main';

import type { IChannelRepository } from '@mainApp/src/repositories';

import { IOC_TOKENS } from '@mainApp/src/ioc';
import type { IToastStore } from '@mainApp/src/stores';
import {
  type IChannelStore,
  type ICommunityStore,
  type ISystemStore,
  type TextFieldType,
} from '@mainApp/src/stores';
import { ApiBase } from '@mainApp/src/stores/ApiBase';
import type { IPermissionsServiceStore } from '@mainApp/src/stores/permissions';
import { RoleItem } from '@mainApp/src/stores/permissions';

import { ICreateChannelOrGroupStore } from './CreateChannelOrGroup.store.types';
import { ScreenIds, screensConfig } from './screensConfig';

@injectable()
export class CreateChannelOrGroupStore
  extends ApiBase
  implements ICreateChannelOrGroupStore
{
  active = false;
  isSubmitting = false;
  currentChannelGroupId = '';

  mode: 'channel' | 'group' = 'channel';

  animatedStack: AnimatedStack | null = null;

  channelName: TextFieldType;
  channelGroupName: TextFieldType;
  isPrivate = false;

  communityStore: ICommunityStore;
  channelRepository: IChannelRepository;
  channelStore: IChannelStore;
  discardAlertIsActive = false;
  systemStore: ISystemStore;
  permissionsServiceStore: IPermissionsServiceStore;

  get isGroupMode() {
    return this.mode === 'group';
  }

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.permissionsServiceStore)
    permissionsServiceStore: IPermissionsServiceStore,
    @inject(IOC_TOKENS.communityStore) communityStore: ICommunityStore,
    @inject(IOC_TOKENS.channelRepository) channelRepository: IChannelRepository,
    @inject(IOC_TOKENS.channelStore) channelStore: IChannelStore,
    @inject(IOC_TOKENS.systemStore) systemStore: ISystemStore
  ) {
    super(toastStore);
    this.permissionsServiceStore = permissionsServiceStore;
    this.communityStore = communityStore;
    this.channelRepository = channelRepository;
    this.channelStore = channelStore;
    this.systemStore = systemStore;

    permissionsServiceStore.setMode('channel');

    this.channelName = {
      value: '',
      error: null,
    };
    this.channelGroupName = {
      value: '',
      error: null,
    };

    makeObservable(this, {
      active: observable,
      channelName: observable,
      channelGroupName: observable,
      isCreateFormScreen: computed,
      isPrivate: observable,
      isSubmitting: observable,

      mode: observable,

      currentChannelGroupId: observable,
      discardAlertIsActive: observable,

      isGroupMode: computed,
      setMode: action,
      setChannelName: action,
      setChannelGroupName: action,

      onViewRoleMembers: action,
      setIsPrivate: action,
      requestCloseModal: action,

      setDiscardAlertIsActive: action,
      isChannelNameAvailable: action,
      isChannelGroupNameAvailable: action,
      show: action,
      setCurrentChannelGroupId: action,
      hide: action,
      submitCreateChannel: action,
      submitCreateChannelGroup: action,
    });
  }

  get activeCommunityId() {
    const id = this.communityStore.activeCommunity.data?.serverData.id;
    if (!id) {
      throw this.handleError('Error', 'There is no active community id');
    }
    return id;
  }

  get isStackHistoryExisting() {
    return Boolean(this.animatedStack?.state.history.length);
  }

  setMode = (mode: 'channel' | 'group') => {
    this.mode = mode;
  };

  setDiscardAlertIsActive = (bool: boolean) => {
    this.discardAlertIsActive = bool;
  };

  isChannelNameAvailable = async () => {
    if (!this.channelName.value) {
      this.setChannelName({
        ...this.channelName,
        error: null,
      });

      return true;
    }

    const { data } = await this.channelRepository.getChannels({
      communityId: this.activeCommunityId,
      channelGroupId: this.currentChannelGroupId,
      search: this.channelName.value,
      noCache: true,
    });

    if (data?.edges && this.channelName.value) {
      const hasTheSameName = data.edges.some(
        (edge) =>
          edge.node.name.toLocaleLowerCase() ===
          this.channelName.value.toLocaleLowerCase()
      );

      return !hasTheSameName;
    }

    return true;
  };

  isChannelGroupNameAvailable = async () => {
    if (!this.channelGroupName.value) {
      this.setChannelGroupName({
        ...this.channelGroupName,
        error: null,
      });

      return true;
    }

    await this.channelStore.getChannelGroups(this.activeCommunityId, true);
    const data = this.channelStore.channelGroups.data;
    if (data && this.channelGroupName.value) {
      const hasTheSameName = data.some(
        (channelGroupModel) =>
          channelGroupModel.name.toLocaleLowerCase() ===
          this.channelGroupName.value.toLocaleLowerCase()
      );

      return !hasTheSameName;
    }

    return true;
  };

  requestCloseModal = () => {
    if (
      this.channelName.value ||
      this.channelGroupName.value ||
      this.permissionsServiceStore.hasAddedAnyMember
    ) {
      this.setDiscardAlertIsActive(true);
      return;
    }

    this.hide();
  };

  onViewRoleMembers = async (roleItem: RoleItem) => {
    const { model } = roleItem;

    this.permissionsServiceStore.setSelectedRole(model);
    this.moveToScreen(ScreenIds.MEMBERS_VIEW);
  };
  submitCreateChannel = async () => {
    this.isSubmitting = true;

    const members: ChannelAddMemberInput[] | undefined = this.isPrivate
      ? (this.permissionsServiceStore.getAddedRolesAndUsers() as ChannelAddMemberInput[])
      : undefined;

    const { error } = await this.channelStore.createChannel(
      this.activeCommunityId,
      this.currentChannelGroupId,
      {
        name: this.channelName.value,
        channelType: Channel_Types.Feeds,
        private: this.isPrivate,
        option: {
          sort: Channel_Sort_Types.New,
        },
        members: members,
      }
    );

    this.isSubmitting = false;

    if (error) {
      this.handleError('Error', error?.message);
      return;
    }

    this.hide();
  };

  submitCreateChannelGroup = async () => {
    runInAction(() => {
      this.isSubmitting = true;
    });

    const members: ChannelGroupAddMemberInput[] | undefined = this.isPrivate
      ? (this.permissionsServiceStore.getAddedRolesAndUsers() as ChannelGroupAddMemberInput[])
      : undefined;

    const { error } = await this.channelStore.createChannelGroup(
      this.activeCommunityId,
      {
        name: this.channelGroupName.value,
        private: this.isPrivate,
        members: members,
      }
    );

    runInAction(() => {
      this.isSubmitting = false;
    });
    if (error) {
      this.handleError('Error', error?.message);
      return;
    }

    this.hide();
  };

  setChannelName = (val: TextFieldType) => {
    this.channelName = val;
  };
  setChannelGroupName = (val: TextFieldType) => {
    this.channelGroupName = val;
  };

  setIsPrivate = (val: boolean) => {
    this.isPrivate = val;
  };

  show = (mode: 'channel' | 'group' = 'channel') => {
    this.mode = mode;
    this.active = true;
    this.permissionsServiceStore.setupReactions();

    // set initial stack item
    const { id, Component } = screensConfig[ScreenIds.CREATE_FORM];
    this.animatedStack?.setVisible(id, Component);
  };
  setCurrentChannelGroupId = (channelGroupId: string) => {
    this.currentChannelGroupId = channelGroupId;
  };

  hide = () => {
    this.active = false;

    this.reset();
  };

  setAnimatedStack = (animatedStack: AnimatedStack) => {
    this.animatedStack = animatedStack;
  };

  moveBack = () => {
    this.animatedStack?.back();
    if (this.permissionsServiceStore.isSearchActive) {
      this.permissionsServiceStore.resetSearch();
    }
  };
  moveToScreen = (id: (typeof ScreenIds)[keyof typeof ScreenIds]) => {
    const screen = screensConfig[id];
    this.animatedStack?.next(screen.id, screen.Component);
  };

  get isCreateFormScreen() {
    return this.animatedStack?.state.visibleItem.id === ScreenIds.CREATE_FORM;
  }

  reset = () => {
    this.channelName = { value: '', error: null };
    this.channelGroupName = { value: '', error: null };
    this.isPrivate = false;
    this.discardAlertIsActive = false;

    this.permissionsServiceStore.reset();
    this.permissionsServiceStore.dispose();
  };
}
