import {
  Modal as FNDModal,
  ModalPropsType,
} from '@10x/foundation/src/components/modal/Modal';
import { observer } from 'mobx-react-lite';

import {
  addStylesToEl,
  removeStylesFromEl,
  stylesSafariFix,
} from '@foundationPathAlias/utilities';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useEffect } from 'react';
import { CreateCommunityStepper } from './CreateCommunityStepper';

export function _CreateCommunityStepperModal(
  props: Partial<ModalPropsType> = {}
) {
  const {
    createCommunityStepperStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { isModalOpened, setModal } = createCommunityStepperStore;

  const closeModal = () => {
    setModal(false);
  };

  let rootCn = 'w-full';
  let contentWrapperCn = 'w-full md:w-auto';
  let contentCn = 'flex overflow-hidden h-full w-full md:w-auto ';

  if (isMobile) {
    rootCn = rootCn + 'p-0 min-h-full ';
    contentWrapperCn = contentWrapperCn + 'max-w-full p-0 min-h-full';
  } else {
    rootCn = rootCn + 'my-[20px] px-[10px] ';
    contentCn = contentCn + 'rounded-[10px]';
  }

  useEffect(() => {
    if (!isMobile) return;

    if (isModalOpened) {
      addStylesToEl(document.documentElement, stylesSafariFix);
    } else {
      removeStylesFromEl(document.documentElement, stylesSafariFix);
    }
  }, [isModalOpened, isMobile]);

  return (
    <FNDModal
      rootInnerWrapperCn={rootCn}
      contentWrapperCn={contentWrapperCn}
      contentCn={contentCn}
      open={isModalOpened}
      onClose={closeModal}
      {...props}
    >
      <CreateCommunityStepper />
    </FNDModal>
  );
}

export const CreateCommunityStepperModal = observer(
  _CreateCommunityStepperModal
);
