import { YourCommunityCard } from '../shared/community-card/YourCommunityCard';

export function CommunityPreviewMobile() {
  return (
    <div className="community-preview-mobile">
      <div
        className="flex flex-1 justify-center"
        onPointerMove={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex h-full w-full">
          <YourCommunityCard />
        </div>
      </div>
    </div>
  );
}
