import { useTranslation } from 'react-i18next';

import {
  OutlineButton,
  PrimaryButtonWithLoader,
} from '@foundationPathAlias/components';

import { classNames } from '@foundationPathAlias/utilities';

type FooterModalProps = {
  cancelText?: string;
  onCancel: () => void;
  onSuccess?: () => void;
  successText?: string;
  hideCancelButton?: boolean;
  className?: string;
  disabledOkButton?: boolean;
  loading?: boolean;
};

export const FooterModal = ({
  successText,
  onCancel,
  onSuccess,
  hideCancelButton,
  className,
  disabledOkButton,
  loading,
  cancelText,
}: FooterModalProps) => {
  const { t } = useTranslation(['common']);

  return (
    <div
      className={classNames(
        'flex justify-end gap-4 border-t border-t-element-subtle bg-background-primary p-6 dark:border-t-element-subtle-dark dark:bg-background-primary-dark',
        className
      )}
    >
      {!hideCancelButton && (
        <OutlineButton cn="w-auto" onClick={onCancel}>
          {cancelText || t('common:cancel')}
        </OutlineButton>
      )}

      {onSuccess && successText && (
        <PrimaryButtonWithLoader
          cn="md:w-auto"
          disabled={disabledOkButton}
          loading={Boolean(loading)}
          onClick={onSuccess}
        >
          {successText}
        </PrimaryButtonWithLoader>
      )}
    </div>
  );
};
