import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangesAlert } from '@foundationPathAlias/components';
import {
  BottomSheet,
  SidebarModal,
  SidebarModalAnimatedStack,
  SidebarModalPositioner,
  useSidebarModalContext,
} from '@foundationPathAlias/widgets/sidebar-modal';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { ActionsPanelWithMobx } from './components/ActionsPanelWithMobx';
import { SidebarMenuWithMobx } from './components/SidebarMenuWithMobx';

export const _ChannelSettings = () => {
  const {
    channelSettingsStore,
    dimensionsStore: { isMobile, isReady },
  } = useMultipleInjection([
    IOC_TOKENS.channelSettingsStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const sidebarModalCtx = useSidebarModalContext();

  useEffect(() => {
    channelSettingsStore.setSidebarModal(sidebarModalCtx);
  }, [sidebarModalCtx, channelSettingsStore]);

  useEffect(() => {
    if (isReady) {
      channelSettingsStore.setInitialScreen();
    }
  }, [isReady, isMobile]);

  const { t } = useTranslation(['common', 'channel']);

  const {
    title: genericChangesAlertTitleKey,
    description: genericChangesAlertDescr,
    firstBtnText: genericChangesAlertFirstBtnText,
    secondBtnText: genericChangesAlertSecondBtnText,
    ...otherGenericChangesAlertConfig
  } = sidebarModalCtx.state.changesAlertConfig;

  return (
    <SidebarModal
      isMobile={isMobile}
      onClose={() => {
        channelSettingsStore.setIsShow(false);
      }}
    >
      <SidebarModalPositioner>
        <SidebarModalAnimatedStack
          isMobile={isMobile}
          desktopSidebarMenu={<SidebarMenuWithMobx />}
          actionsPanel={<ActionsPanelWithMobx />}
        />
        <ChangesAlert
          contentWrapperCn="border-[1px]"
          titleCn="text-sm14SB"
          descriptionCn="text-sm14R"
          firstBtnCn="text-sm14SB"
          secondBtnCn="text-sm14SB"
          title={t(genericChangesAlertTitleKey as string)}
          description={t(genericChangesAlertDescr as string)}
          firstBtnText={t<string>(genericChangesAlertFirstBtnText as string)}
          secondBtnText={t<string>(genericChangesAlertSecondBtnText as string)}
          show={otherGenericChangesAlertConfig.show as boolean}
          {...otherGenericChangesAlertConfig}
        />
      </SidebarModalPositioner>
      <BottomSheet
        isMobile={isMobile}
        bottomSheetConfig={sidebarModalCtx.state?.bottomSheetConfig}
        onClose={() => {
          channelSettingsStore.setBottomSheetConfig({
            show: false,
          });
        }}
      />
    </SidebarModal>
  );
};

export const ChannelSettings = observer(_ChannelSettings);
