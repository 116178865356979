import {
  MembersList,
  MembersListPropsType,
} from '@mainApp/src/modules/channel/common/members-list/MembersList';
import { useTranslation } from 'react-i18next';

import { UserItem } from './UserItem';

import { UserItem as UserItemType } from '@mainApp/src/stores/permissions/Persmissions.service.store.types';

type MembersUserListProps = {
  data: UserItemType[] | null | void;
  onAdd?: (item: UserItemType) => void;
  onRemove?: (item: UserItemType) => void;
  onClick?: (item: UserItemType) => void;
  noAction?: boolean;
  noTitle?: boolean;
} & Omit<MembersListPropsType, 'children'>;

export function MembersUserList(props: MembersUserListProps) {
  const { data, onAdd, onRemove, onClick, noAction, noTitle, ...otherProps } =
    props;

  const { t } = useTranslation(['channel', 'common']);
  const title = noTitle ? undefined : t<string>('channel:modal.members');

  const content = data
    ? data.map((item) => {
        const { model, isAdded } = item;

        const action = !isAdded || noAction ? onAdd : onRemove;
        const actionLabel = t(isAdded ? 'common:remove' : 'common:add');
        const finalAction = noAction ? undefined : () => action?.(item);

        return (
          <UserItem
            key={model.serverData.id}
            model={model}
            onAction={finalAction}
            onClick={() => {
              onClick?.(item);
            }}
            actionLabel={actionLabel}
          />
        );
      })
    : null;

  return (
    <div>
      <MembersList title={title} {...otherProps}>
        {content}
      </MembersList>
    </div>
  );
}
