import { TextInput, TextInputPropsType } from '@foundationPathAlias/components';
import { useTranslation } from 'react-i18next';

import { validators, ValidatorType } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

export type TextInputCommonValidatorsPropsType = {
  addMailSignBeforeInput?: boolean;
  value: string;
  maxLength?: number;
  /** if true the default validators won't containt empty validator */
  allowEmptyValue?: boolean;
  withoutDefaultValidators?: boolean;
  placeholder: string;
  infoWrapperCn?: string;
  onChange: (val: string) => void;
  successMsg?: string;
  specificValidators?: ValidatorType<any>[];
} & Partial<TextInputPropsType>;

export function TextInputCommonValidators(
  props: TextInputCommonValidatorsPropsType
) {
  const {
    addMailSignBeforeInput,
    placeholder,
    withoutDefaultValidators,
    value,
    inputName,
    infoWrapperCn,
    onChange,
    successMsg,
    allowEmptyValue,
    specificValidators = [],
    ...otherProps
  } = props;

  const { t } = useTranslation('common');

  const leftSideContent = addMailSignBeforeInput ? <span>@</span> : undefined;
  const inputNameLc = inputName?.toLowerCase();
  let defaultValidators: ValidatorType<string>[] = [];
  if (!withoutDefaultValidators) {
    defaultValidators = [
      validators.getBeginSpaceValidator(
        t('errors.beginSpace', {
          fieldName: inputNameLc,
        }) as string
      ),
      validators.getEndSpaceValidator(
        t('errors.beginSpace', {
          fieldName: inputNameLc,
        }) as string
      ),

      validators.getDoubleConsequentSpacesValidator(
        t('errors.doubleConsequentSpaces', {
          fieldName: inputNameLc,
        }) as string
      ),

      validators.getMaxLengthValidator(
        25,
        t('errors.maxLength', {
          maxCharCount: 25,
          fieldName: inputName,
        }) as string
      ),
      validators.getSpecialSymbolsNonSpaceOrDashValidator(
        t('errors.nonSpecialCharactersExceptSpaceOrDash', {
          fieldName: inputNameLc,
        }) as string
      ),
      validators.getNonPeriodOrUnderscoreEndValidator(
        t('errors.nonPeriodOrUnderscoreEnd', {
          fieldName: inputNameLc,
        }) as string
      ),
    ];

    if (!allowEmptyValue) {
      defaultValidators.unshift(
        validators.getEmptyFieldValidation(
          t('errors.emptyValue', {
            fieldName: inputNameLc,
          }) as string
        )
      );
    }
  }

  return (
    <TextInput
      disabled={false}
      infoWrapperCn={classNames('text-left', infoWrapperCn)}
      getCustomSuccessUI={() => {
        return (
          <span className={'themed-text-success text-sm14R'}>{successMsg}</span>
        );
      }}
      InputProps={{
        value,
      }}
      inputName={inputName}
      validators={[...defaultValidators, ...specificValidators]}
      placeholder={placeholder}
      labelCn="px-[11px] space-x-[4px]"
      leftSideContent={leftSideContent}
      onChange={onChange}
      errorTextCn="text-left text-element-error dark:text-element-error-dark"
      {...otherProps}
    />
  );
}
