import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import { twMerge } from 'tailwind-merge';

export function classNames(...args: any) {
  const classes: string[] = [];

  args.forEach((arg: any) => {
    if (!arg) return;

    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (typeof arg === 'object') {
      Object.keys(arg).forEach((key) => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    }
  });

  return twMerge(classes);
}

export const isBrowser = typeof window !== 'undefined';

export const isNavigator = typeof navigator !== 'undefined';

export const isFunction = (param: any) => typeof param === 'function';
export const isNumber = (param: any) => typeof param === 'number';

export const isShallowDifferent = (a: any, b: any) => {
  if (typeof a != 'object' || typeof b != 'object') return a !== b;
  for (const x in a) if (!(x in b)) return true;
  for (const x in b) if (a[x] !== b[x]) return true;
  return false;
};

export const hasDepsChanged = <T extends { length: number }>(a: T, b: T) => {
  // @ts-ignore
  for (let i = 0, l = b.length; i < l; i++) if (a[i] !== b[i]) return true;
  return false;
};

export function omitPropsAndCloneObject(object: any, propsToOmit: string[]) {
  return Object.keys(object)
    .filter((key) => !propsToOmit.includes(key))
    .reduce((acc, key) => {
      acc[key] = object[key];
      return acc;
    }, {} as any);
}

export const executeFnOnce = (fn: Function) => {
  let isExecuted = false;

  return () => {
    if (!isExecuted) {
      fn();
      isExecuted = true;
    }
  };
};

export const executeFnWhenParamChangedOnly = (fn: Function) => {
  let prevArgs: any[] = [];

  return (...args: any[]) => {
    if (_isEqual(args, prevArgs)) {
      return;
    }
    fn(...args);
    prevArgs = args;
  };
};

export const getOSDmData = () => {
  if (!window || !window.matchMedia) return null;

  const dmOSThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');

  return {
    isDark: Boolean(dmOSThemeQuery.matches),
    query: dmOSThemeQuery,
  };
};

export const trackBrowserDarkMode = (cb: (isDark: boolean) => void) => {
  if (!window.matchMedia) {
    return;
  }

  const query = window.matchMedia('(prefers-color-scheme: dark)');

  cb(query.matches);

  query.addEventListener('change', (event) => cb(event.matches));
};

export const toFixedNumber = (num: number) => Math.round(+num.toFixed(2));

export const convertBytesToKb = (bytes: number) =>
  parseFloat((bytes / 1024).toFixed(2));

export function hashObj(obj: { [index: string]: any }) {
  const str = JSON.stringify(obj, Object.keys(obj).sort());

  const identifier = window.btoa(str);

  return identifier;
}

export const getHeightWidthFromTailwindCn = (cn?: string) => {
  const regex = /h-\[(\d+px)\]\s+w-\[(\d+px)\]/;
  const matches = cn?.match(regex);

  if (matches) {
    const height = parseInt(matches[1].replace('px', ''), 10);
    const width = parseInt(matches[2].replace('px', ''), 10);
    return { height, width };
  }
};
export type FnWithAnyArgs<T = any> = (...args: any[]) => T | Promise<T>;
export function debouncePromisify<T = void>(
  fn: FnWithAnyArgs<T>,
  timeout: number
): (...args: Parameters<typeof fn>) => Promise<T> {
  let resolver: ((value: T | Promise<T>) => void) | null = null;

  const fnDebounced = _debounce((...args: Parameters<typeof fn>) => {
    const res = fn(...args);
    resolver?.(res);
    resolver = null;
  }, timeout);

  return (...args: Parameters<typeof fn>) => {
    return new Promise((resolve) => {
      resolver = resolve;
      fnDebounced(...args);
    });
  };
}

export function invariant<T>(
  condition: T,
  message: string
): asserts condition is NonNullable<T> {
  if (!condition) {
    throw new Error(message);
  }
}

export type Styles = Partial<Record<keyof CSSStyleDeclaration, string>>;

export function addStylesToEl(element: HTMLElement, styles: Styles) {
  for (const property in styles) {
    if (
      Object.prototype.hasOwnProperty.call(styles, property) &&
      typeof element.style[property as keyof CSSStyleDeclaration] === 'string'
    ) {
      const key = property as keyof CSSStyleDeclaration;
      element.style[key as any] = styles[key]!;
    }
  }
}

export function removeStylesFromEl(element: HTMLElement, styles: Styles): void {
  for (const property in styles) {
    if (
      Object.prototype.hasOwnProperty.call(styles, property) &&
      typeof element.style[property as keyof CSSStyleDeclaration] === 'string'
    ) {
      const key = property as keyof CSSStyleDeclaration;
      element.style[key as any] = '';
    }
  }
}
