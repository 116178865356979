import { gql } from '@urql/core';

import { ChannelFragment } from './channelFragments';
import { GQLDateObjectTypeFragment } from './commonFragments';

export const CommunitySettingsFragment = gql`
  fragment CommunitySettingsFragment on CommunitySettingsPayload {
    id
    created {
      ...GQLDateObjectTypeFragment
    }
    updated {
      ...GQLDateObjectTypeFragment
    }
    deleted {
      ...GQLDateObjectTypeFragment
    }
    explorePage {
      status
      adminReviewStatus
    }
    launcher {
      # id
      visible
      positionType
      spacing {
        side
        bottom
      }
      theme
    }
  }
  ${GQLDateObjectTypeFragment}
`;

export const CommunityBlockedMembersFragment = gql`
  fragment CommunityBlockedMembersFragment on BlockedUsersPayload {
    id
    createdAt
    userId
    author {
      id
      email
      bio
      displayName
      firstName
      lastName
      website
      imageUrls
      username
      pro {
        username
      }
    }
    user {
      id
      email
      firstName
      bio
      website
      displayName
      lastName
      imageUrls
      username
      pro {
        username
      }
    }
  }
`;

export const CommunityMemberFragment = gql`
  fragment CommunityMemberFragment on CommunityMemberPayload {
    id
    email
    username
    firstName
    lastName
    imageUrls
    displayName
    numericId
    roles {
      id
      name
    }
    memberSince
    lastActive
    noOfReports
    isModuleMember
    memberSince
  }
`;

export const CommunityFragment = gql`
  fragment CommunityFragment on Community {
    id
    about
    imageUrl
    name
    slugName
    slug
    topics {
      id
      name
      __typename
    }
    members {
      totalCount
    }
    owner {
      id
      email
      firstName
      lastName
    }
    onlineMembers {
      totalCount
    }
    userAssignedRoles
    thumbnailImageUrls
    defaultChannel {
      ...ChannelFragment
    }
    description
    logoImageUrls
    settings {
      ...CommunitySettingsFragment
    }
    color {
      light {
        r
        g
        b
        a
      }
      dark {
        r
        g
        b
        a
      }
      __typename
    }
    __typename
  }
  ${ChannelFragment}
  ${CommunitySettingsFragment}
`;

export const CommunityRuleFragment = gql`
  fragment CommunityRuleFragment on ContentRulePayload {
    id
    title
    rawJSON
    communityId
    order
  }
`;
