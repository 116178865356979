import {
  MenuListItem,
  SidebarWithMenu,
} from '@foundationPathAlias/widgets/sidebar-modal';
import { observer } from 'mobx-react-lite';

import { CardSVG, RulesSVG } from '@foundationPathAlias/main';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { SwatchIcon, UsersIcon } from '@heroicons/react/24/outline';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useTranslation } from 'react-i18next';

type MenuItemsIds = 'users' | 'roles' | 'rules' | 'customize' | 'billing';

function useMenuItemsConfig(): MenuListItem<MenuItemsIds>[] {
  const { t } = useTranslation(['cap']);

  return [
    {
      label: t('cap:menu.users'),
      id: 'users',
      icon: <UsersIcon width={20} />,
    },
    {
      label: t('cap:menu.rules'),
      id: 'rules',
      icon: <RulesSVG width={20} height={20} />,
    },
    {
      label: t('cap:menu.customize'),
      id: 'customize',
      icon: <SwatchIcon width={20} />,
    },
    {
      label: t('cap:menu.billing'),
      id: 'billing',
      icon: <CardSVG width={20} />,
    },
  ];
}

export function _SidebarMenuWithMobx() {
  const {
    dimensionsStore: { isMobile },
    capStore,
  } = useMultipleInjection([IOC_TOKENS.dimensionsStore, IOC_TOKENS.capStore]);
  const menuItemsConfig = useMenuItemsConfig();

  const { t } = useTranslation('cap');

  let content = (
    <SidebarWithMenu
      isMobile={isMobile}
      menuItemsConfig={menuItemsConfig}
      activeMenuItemId={capStore.activeScreenId}
      sidebarCn="md:w-[240px] bg-color-14"
      onMenuSelect={(itemId) => {
        capStore.setActiveScreenId(itemId);
      }}
    />
  );

  if (isMobile) {
    content = (
      <div className="flex w-full flex-col">
        <ScreenWrapper
          showBackBtn={false}
          sidebarModalController={capStore}
          title={t('communitySettings')}
        >
          {content}
        </ScreenWrapper>
      </div>
    );
  }

  return content;
}

export const SidebarMenuWithMobx = observer(_SidebarMenuWithMobx);
