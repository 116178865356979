'use client';
import { Avatar, TextButtonPrimary } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { UserModel } from '@mainApp/src/stores/User.model';
import { useTranslation } from 'react-i18next';

export type UserItemPropsType = {
  model: UserModel;
  onAction?: () => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOver?: () => void;
  actionLabel: string;
  rootCn?: string;
};

export const UserItem = (props: UserItemPropsType) => {
  const { onAction, model, actionLabel, onClick, onMouseOver, rootCn } = props;
  const { standardOrProUsername, avatarUrl } = model;
  const { online, displayName } = model.serverData;

  const { t } = useTranslation(['common']);

  return (
    <div
      className={classNames(
        'flex items-center ',
        onClick &&
          'cursor-pointer transition-colors hover:bg-background-hover hover:dark:bg-background-hover-dark',
        rootCn
      )}
    >
      <div
        className="flex w-full items-center  gap-[12px] py-[8px]"
        onMouseOver={onMouseOver}
      >
        <Avatar
          width={36}
          height={36}
          src={avatarUrl}
          alt={t<string>('common:avatarImage', { displayName })}
        />

        <div onClick={onClick} className="flex flex-[1] flex-col items-start">
          <b className="text-sm14T text-text-primary dark:text-text-primary-dark">
            {displayName}

            {online && (
              <span className="ml-2 inline-block h-2 w-2 rounded-full bg-element-success" />
            )}
          </b>

          <small className="text-text-secondary dark:text-text-secondary">
            {'@' + standardOrProUsername}
          </small>
        </div>
      </div>

      {onAction && (
        <TextButtonPrimary cn="w-auto" onClick={onAction}>
          {actionLabel}
        </TextButtonPrimary>
      )}
    </div>
  );
};
