export const ExploreSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}> = ({ width = 24, height = 24, color, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9492 12C20.9492 16.9429 16.9422 20.9499 11.9992 20.9499C7.05627 20.9499 3.04922 16.9429 3.04922 12C3.04922 7.057 7.05627 3.04995 11.9992 3.04995C16.9422 3.04995 20.9492 7.057 20.9492 12ZM22.7992 12C22.7992 17.9646 17.9639 22.7999 11.9992 22.7999C6.03454 22.7999 1.19922 17.9646 1.19922 12C1.19922 6.03528 6.03454 1.19995 11.9992 1.19995C17.9639 1.19995 22.7992 6.03528 22.7992 12ZM14.0666 14.0674L6.34207 17.6571L9.93179 9.93252L17.6564 6.3428L14.0666 14.0674ZM13.1306 11.9999C13.1306 11.3725 12.6266 10.8685 11.9992 10.8685C11.3718 10.8685 10.8678 11.3725 10.8678 11.9999C10.8678 12.6274 11.3718 13.1314 11.9992 13.1314C12.6266 13.1314 13.1306 12.6274 13.1306 11.9999Z"
      fill={color}
      fillOpacity="0.9"
    />
  </svg>
);
