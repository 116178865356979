import type {
  ChangesAlertProps,
  ChangesAlertProps as ChangesAlertPropsType,
} from '@foundationPathAlias/components';
import { AnimatedStack } from '@foundationPathAlias/components';
import { IEventBus } from '@foundationPathAlias/utilities';
import React from 'react';
import { SidebarModalEventsType } from '../events/sidebarModalEventBus';
import { ACTIONS } from './actionConstants';
export interface IStackConsumer<TScreenId, TSetting> {
  animatedStack: AnimatedStack;
  activeScreenId: TScreenId;
  readonly isInitialScreen: boolean;
  // a getter based on the activeScreenId
  readonly activeSCreen: TSetting;
  setActiveScreenId: (id: TScreenId) => void;
  setNextScreenId: (id: TScreenId) => void;
  back: () => void;
  reset: () => void;
}

export enum DiscardActionsEnum {
  BACK = 'back',
  CLOSE = 'close',
  GO_TO_MENU = 'goToMenu',
}

export type DiscardActions = {
  [DiscardActionsEnum.BACK]: null | (() => void);
  [DiscardActionsEnum.CLOSE]: null | (() => void);
  [DiscardActionsEnum.GO_TO_MENU]: {
    savedChildScreenId: null | string;
  };
};

export type SetDiscardActionsPayload = {
  [K in keyof DiscardActions]: {
    [P in K]: DiscardActions[K];
  };
}[keyof DiscardActions];

export type BottomSheetConfig = {
  content: null | React.ReactNode;
  show: boolean;
  onClose: (() => void) | null;
};

export type ChangesAlertConfig = {
  onFirstButtonClick?: () => void;
  onSecondButtonClick?: () => void;
  firstBtnText?: string;
  secondBtnText?: string;
} & Partial<ChangesAlertPropsType>;

export type SidebarModalState = {
  changesAlertConfig: ChangesAlertConfig;
  discardActions: DiscardActions;
  activeDiscardAction: DiscardActionsEnum | null;
  show: boolean;
  bottomSheetConfig: BottomSheetConfig;
};

export type SetChangesAlertConfigAction = {
  type: typeof ACTIONS.SET_CHANGES_ALERT_CONFIG;
  payload: Partial<ChangesAlertPropsType>;
};
export type SetDiscardActions = {
  type: typeof ACTIONS.SET_DISCARD_ACTIONS;
  payload: SetDiscardActionsPayload;
};
export type SetActiveDiscardAction = {
  type: typeof ACTIONS.SET_ACTIVE_DISCARD_ACTION;
  payload: DiscardActionsEnum | null;
};

export type ShowChangesAlertAndSaveAction = {
  type: typeof ACTIONS.SHOW_CHANGES_ALERT_AND_SAVE_ACTION;
  payload: ShowChangesAlertAndSaveActionPayload;
};

export type ShowChangesAlertAndSaveActionPayload = {
  discardActionId: DiscardActionsEnum;
  savedChildScreenId?: string | null;
};

export type SetShowAction = {
  type: typeof ACTIONS.SET_SHOW;
  payload: boolean;
};

export type SetBottomSheetConfigAction = {
  type: typeof ACTIONS.SET_BOTTOM_SHEET_CONFIG;
  payload: Partial<BottomSheetConfig>;
};

export type SidebarModalActions =
  | SetChangesAlertConfigAction
  | SetDiscardActions
  | SetActiveDiscardAction
  | SetShowAction
  | ShowChangesAlertAndSaveAction
  | SetBottomSheetConfigAction;

export type ScreenConfigItem<T> = {
  id: T;
  title: string;
  Component: React.ComponentType<any>;
};

export type ScreensConfig<T extends string> = {
  [K in T]: {
    id: K;
    title: string;
    Component: React.ComponentType<any>;
  };
};

export type StackScreenPayload = {
  id: string;
  Component: React.ComponentType<any>;
};

type HasInitialKey = { INITIAL: string };

export type ObjectWithInitial<T> = T extends HasInitialKey ? T : never;

export type ActionsPanelData = {
  cancelAction: () => void;
  getCancelActionText: () => string;
  proceedAction: () => void;
  getProceedActionText: () => string;
};

export interface ISidebarModal {
  state: SidebarModalState;
  sidebarModalEventBus: IEventBus<SidebarModalEventsType>;
  animatedStack: AnimatedStack;
  setChangesAlertConfig: (config: Partial<ChangesAlertProps>) => void;
  setDiscardActions: (payload: SetDiscardActionsPayload) => void;
  setActiveDiscardAction: (actionId: DiscardActionsEnum | null) => void;
  showChangesAlertAndSaveAction: (
    pyaload: ShowChangesAlertAndSaveActionPayload
  ) => void;
  setShow: (show: boolean) => void;
  setBottomSheetConfig: (config: Partial<BottomSheetConfig>) => void;
}

export interface ISidebarModalChildScreen<T extends string> {
  sidebarModal: ISidebarModal | null;
  activeScreenId: T;

  // it's your responsibility to control the conditionals of the screen instance.

  // Use it to check if the screen is dirty. False by default.
  isDirty: boolean;
  // use it to manipulate the loaders like in the Actions for the Proceed btn. False by defaul
  isLoading: boolean;
  // use it if you need to show the actions panel (cancel | save) in your screen. False by default
  showActionPanel: boolean;
  // use it if you need to disable the Proceed btn. False by default
  isDisableProceedAction: boolean;

  // it's a getter which is false by default. You should implement your own logic if you want to use it. You can use the mobx getters or the standard own getter/setter js methods
  isError: boolean;

  // it's a getter which will retun a numm by deafult. You should implement your own logic to provide actions data for the Actions component if your screen requires it
  actionsPanelData: null | ActionsPanelData;

  readonly screenIds: Record<string, T>;
  readonly screensConfig: ScreensConfig<T>;

  readonly initialScreen: T;
  readonly activeScreen: ScreenConfigItem<T>;
  readonly isInitialScreen: boolean;

  // resets all the conditional values to the default (like isDirty, isLoading, showActionPanel, isDisableProceedAction)
  resetConditionals(): void;
  // executes once the sidebarModalController creates a new instance of the child screen class (if presented this method)
  init?: () => void;
  /**
   * for every reactive subscription in the child store must be disposer otherwise it's a potential memory leak.  The local store instance is created every time on the new menu item activation and without the disposing reactions you will easilly fall into the common rx.js etc. `reactive subscription memory leakage problem`. In this method all the disposers must be executed. This method will be automatically called by the parent controller when the child screen will be switched/disposed
   */
  dispose(): void;
  setSidebarModal: (sidebarModal: ISidebarModal) => void;
  setActiveScreenId: (id: T) => void;
  setNextScreenId: (id: T) => void;
  back(): Promise<void>;

  // executes for the new screen activation on desktop
  reset?: () => void;
  // the child screen (store/service) should provide this method to be able to listen when the sidebarModal will be set. WIll be triggered only once at the first set
  onFirstSidebarModalSet?: (sidebarModal: ISidebarModal) => void;
  // the child screen (store/service) should provide this method to be able to listen when the sidebarModal will be set. Attention. as the sidebar modal is a reducer so everytime when the new reducer state will be produced it will be re-set and this method will be trigger so keep your eyes on the performance and the memory clearance
  onSidebarModalSet?: (newSidebarModal: ISidebarModal) => void;
  // by using this method you can changed config from every child screen for better customization. This method executes the reducer and the event bus
  setChangesAlertConfig: (config: ChangesAlertConfig) => void;

  // optional callback that will be triggered after the click on the first button in the changes alert (procceed)
  onProceedFirstBtnInteraction?: () => void;
  // optional callback that will be triggered after the click on the first button in the changes alert (discard)
  onProceedSecondBtnInteraction?: () => void;
}

export type Constructor<T> = new (...args: any[]) => T;
export type ChildScreenClasses = Record<
  string,
  Constructor<ISidebarModalChildScreen<string>>
>;

export type ChildScreens<T extends ChildScreenClasses> = {
  [K in keyof T]: InstanceType<T[K]>;
};

export interface ISidebarModalController<
  T extends ChildScreenClasses,
  C extends string
> {
  sidebarModal: ISidebarModal | null;

  readonly screenIds: Record<string, C>;
  // the same screen config like in the child screens
  readonly screensConfig: ScreensConfig<C>;
  isFirstRenderCompleted: boolean;
  wasLayoutMobile: boolean;
  // the class that will extend it should provide the actual value
  isMobile: boolean;

  childScreens: ChildScreens<T>;

  activeScreenId: keyof T | null;

  readonly activeChildScreen: ISidebarModalChildScreen<string> | null;
  readonly isActiveChildScreenDirty: boolean;
  readonly isInitialScreen: boolean;

  renderInitialScreen: () => void;
  /**
   *
   * @param skipFirstRenderCheck - use if you want to forcibly recognize the initial screen depends on the mobile/desktop and set it as an active screen id. Used in the case of closing the modal when some child screen is active and need to reset everything to show the iniail screen on the next modal opening
   * @returns
   */
  setInitialScreen: (skipFirstRenderCheck?: boolean) => void;
  setActiveScreenId: <TScreenId>(id: TScreenId | string) => void;

  // NATIVE
  setIsShow: (isShow: boolean) => void;
  setIsMobile: (isMobile: boolean) => void;
  back: () => Promise<void>;
  setSidebarModal: (sidebarModal: ISidebarModal) => void;
  showChangesAlertAndSaveAction: (
    discardActionId: DiscardActionsEnum,
    savedChildScreenId?: string | null
  ) => void;
  // set config globally for all the child screens. This method executes the reducer and the event bus
  setChangesAlertConfig: (config: ChangesAlertConfig) => void;
  // the controller (store/service) should provide this method to be able to listen when the sidebarModal will be set. Will be triggered only once at the first set
  onFirstSidebarModalSet?: (sidebarModal: ISidebarModal) => void;
  // the controller (store/service) should provide this method to be able to listen when the sidebarModal will be set. Will be triggered everytime on the sidebarModal new state generation from the reducer. Eg. on every action so use this method carefully with performance in mind
  onSidebarModalSet?: (newSidebarModal: ISidebarModal) => void;

  // defaul handling for the first button in the changes alert
  // You can override this method in the extending class if needed
  proceedFirstBtnInteraction: () => void;
  // defaul handling for the second button in the changes alert
  // You can override this method in the extending class if needed. This method just triggers the discard action and depends on the active discard action (back or go_to_menu) it will do the appropriate action
  proceedSecondBtnInteraction: () => void;
  // optional callback that will be triggered after the click on the first button in the changes alert (procceed)
  onProceedFirstBtnInteraction?: () => void;
  // optional callback that will be triggered after the click on the first button in the changes alert (discard)
  onProceedSecondBtnInteraction?: () => void;

  // set default handlers for the changes alert buttons click/press
  setDefaultChangesAlertHanlers: () => void;

  setBottomSheetConfig: (config: Partial<BottomSheetConfig>) => void;
  resetToDefaultScreen: () => void;
}
