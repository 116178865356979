import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { BaseRepositoryResponse } from '../repositories/types';

import { AttachmentModel } from './attachments/Attachment.model';

import { AttachmentsRegistry } from './attachments';

import {
  ChannelMessageEdgePayload,
  ChannelMessagePayload,
  ChannelMessagePayloadEdge,
  ChannelMessageUpdateInput,
  ContentReactionInput,
  ContentReactionPayload,
  User,
  UsersConnection,
} from '@10x/foundation/types/graphql-schema';
import type {
  CreateMessagePayload,
  IMessageRepository,
  MessagesOptions,
} from '@mainApp/src/repositories/Message.repository';
import { ApiBase, CommonApiDataShapeType } from './ApiBase';
import { MessageModel } from './Message.model';
import { IMessageStore } from './Message.store.types';

import { inject, injectable } from 'inversify';
import { IOC_TOKENS } from '../ioc';
import type { IChannelStore } from './Channel.store.types';
import type { ICommunityStore } from './Community.store.types';
import type { IToastStore } from './Toast.store';
import type { IUserStore } from './User.store.types';

export * from './Message.store.types';

import type { IEventBus } from '@foundationPathAlias/utilities';
import { MessagesEventsEnum } from '@mainApp/src/events';
import { MessageNode } from './data-objects/MessageNode';
import { MessageRegistryService } from './data-objects/MessageRegistry.service';
import { VisitedChannelsDataRegistryService } from './data-objects/VisitedChannelsRegistry.service';

import { EditDataType, VisibleMessageData } from './Message.store.types';

const getInitialPaginationData = () => {
  return {
    pageInfo: {
      hasNextPage: null,
      hasPreviousPage: null,
    },
    headCursor: null,
    tailCursor: null,
    messageRegistryService: new MessageRegistryService(),
  };
};

const initialEditData: EditDataType = {
  active: false,
  // true if it had been optimistically updated while attachments uploading
  optimistic: false,
  // original model in the list
  originalMessageModel: null,
  // current active model that contains changes and could  replaced the original one
  editingMessageModel: null,
};

@injectable()
export class MessageStore extends ApiBase implements IMessageStore {
  // TODO: test. used to prevent message handling. Useful when jumping history back
  preventUrqlMessageProcessing = false;

  repository: IMessageRepository;
  userStore: IUserStore;
  communityStore: ICommunityStore;
  channelStore: IChannelStore;

  // contains data of the visible messag at the middle of message list for the active channel and the previous
  visitedChannelsReigstryService = new VisitedChannelsDataRegistryService();

  // TOOD: test. Save the message ID -> combined array order index for future easiest picking the order index
  messagesOrderIndexesRegistry: {
    [messageId: string]: number;
  } = {};

  // final combined messages from backward & forward
  messages: CommonApiDataShapeType<MessageModel[] | []> =
    MessageStore.generateCommonApiDataShape();

  backwardMessages = getInitialPaginationData();
  forwardMessages = getInitialPaginationData();

  messagesChunkAmount = 20;
  // messagesChunkAmount = 5;

  activeMessageModel: MessageModel | null = null;

  globalEventBus: IEventBus<MessagesEventsEnum>;

  editData = { ...initialEditData };

  replyModel: null | MessageModel = null;

  // backward - fetch history, forward - fetch new messages
  paginationDirection: 'forward' | 'backward' = 'backward';

  firstUnreadMessageModel: MessageModel | null = null;

  // if the messages store initially loaded first time or after returning back
  isInitialMessagesLoading = true;
  // previously visited message anchor data. It's used to
  visibleMessageData: VisibleMessageData = {
    visibleMessageCursor: null,
    visibleMessageId: null,
  };
  previouslyVisitedIndex: number | null = null;

  // should be true when executed running to the parent reply
  isJumpToRunning = false;
  isJumpToLatest = false;
  showJumpToLatestButton = false;

  // important to clear highlight model when click on the new scrollTo reply
  lastHiglightedParentReplyModel: MessageModel | null = null;

  // TODO: DI;
  static get AttachmentModel() {
    return AttachmentModel;
  }

  // TODO: replace all community ID/ChannelID arguments in methods to these
  get activeCommunityId() {
    const id = this.communityStore.activeCommunity.data?.serverData.id;
    if (!id) {
      throw new Error('There is no active community id');
    }
    return id;
  }
  get activeChannelId() {
    const id = this.channelStore.activeChannel.data?.serverData.id;
    if (!id) {
      throw new Error('There is no active channel id');
    }
    return id;
  }

  lastMessageId: string | null = null;

  getTopMessage() {
    return this.messages.data[0];
  }
  getLastMessage() {
    return this.messages.data[this.messages.data.length - 1];
  }

  // TODO: Memoize it !
  get combinedPaginationArray() {
    let currentOrderIndex = 0;
    const msgRegistryServicePrev = this.backwardMessages.messageRegistryService;
    const msgRegistryServiceNext = this.forwardMessages.messageRegistryService;

    let isFoundUnreadMsgModel = false;

    const result: MessageModel[] = [];
    // TODO: ann issue: check: https://www.notion.so/10xteam/Frontend-04f18a60803b4ea8a0d20800a8cc8e38?p=7c6e49d4aa7d4eca82144d19c6b4cb62&pm=s
    for (let i = 0; i < msgRegistryServicePrev.orderedNodeArray.length; i++) {
      const msgModel = msgRegistryServicePrev.orderedNodeArray[i].messageModel;
      this.messagesOrderIndexesRegistry[msgModel.id] = currentOrderIndex;

      msgModel.orderIndex = currentOrderIndex;

      // checking if the messages that were previously are unread because might be loaded a new pagination portion and in this case the previous batch messages that were unread should be marked as read
      if (
        !msgModel.serverData.read &&
        this.firstUnreadMessageModel &&
        !isFoundUnreadMsgModel
      ) {
        if (this.firstUnreadMessageModel.id === msgModel.id) {
          isFoundUnreadMsgModel = true;
        } else {
          msgModel.serverData.read = true;
        }
      }

      result.push(msgModel);
      currentOrderIndex++;
    }

    for (let i = 0; i < msgRegistryServiceNext.orderedNodeArray.length; i++) {
      const msgModel = msgRegistryServiceNext.orderedNodeArray[i].messageModel;
      this.messagesOrderIndexesRegistry[msgModel.id] = currentOrderIndex;

      msgModel.orderIndex = currentOrderIndex;

      result.push(msgModel);
      currentOrderIndex++;
    }

    if (
      this.isInitialMessagesLoading &&
      !this.messages.data?.length &&
      result.length > 1
    ) {
      // trying to handle previously visited channel visible message
      const previousVisitedData =
        this.visitedChannelsReigstryService.tryToGetChannelData(
          this.activeChannelId
        );
      if (previousVisitedData) {
        const previouslyVisitedMessageIndex =
          this.messagesOrderIndexesRegistry[
            previousVisitedData.visibleMessageId
          ];

        this.previouslyVisitedIndex = previouslyVisitedMessageIndex;

        // restoring the current visible message data because when the user returns back and the scrollbar will jumps to the prevously visited messages
        this.visibleMessageData = {
          visibleMessageCursor: previousVisitedData.visibleMessageCursor,
          visibleMessageId: previousVisitedData.visibleMessageId,
        };

        // clear the previously visited (scrolled) messages because looks like the user scrolled to the bottom and there is no need to save this scroll position
        this.visitedChannelsReigstryService.clearChannelData(
          this.activeChannelId
        );
      }

      this.isInitialMessagesLoading = false;
    }

    return result;
  }

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.userStore) userStore: IUserStore,
    @inject(IOC_TOKENS.communityStore) communityStore: ICommunityStore,
    @inject(IOC_TOKENS.channelStore) channelStore: IChannelStore,
    @inject(IOC_TOKENS.messageRepository) repository: IMessageRepository,
    @inject(IOC_TOKENS.eventBus) globalEventBus: IEventBus<MessagesEventsEnum>
  ) {
    super(toastStore);

    this.userStore = userStore;
    this.communityStore = communityStore;
    this.channelStore = channelStore;

    this.repository = repository;
    this.globalEventBus = globalEventBus;

    makeObservable(this, {
      messages: observable,
      editData: observable,
      firstUnreadMessageModel: observable,
      activeMessageModel: observable,
      showJumpToLatestButton: observable,
      replyModel: observable,
      combinedPaginationArray: computed,
      setActiveMessageModel: action,
      setReplyModel: action,
      setReaction: action,
      setActiveNewEmptyMessageModel: action,
      setShowJumpToLatestButton: action,
      setFirstUnreadMessageModel: action,
    });
  }

  onSubscriptionCreated = (message?: ChannelMessagePayload) => {
    // shouldn't do anything if there exists a next page because no reason to add this message to the end of the list if there is no end presented
    if (this.messages.meta.hasNextPage) return;

    if (!message) {
      throw new Error(
        'There is no message came from the created message subscription'
      );
    }
    const msgModel = new MessageModel(
      message,
      this.userStore,
      this.communityStore,
      this.channelStore
    );

    const node = new MessageNode(msgModel);

    // if there exists headCursor so the forward pagination was happened and need to add the msg to it as it's a tail of the messages
    if (this.forwardMessages.headCursor) {
      this.forwardMessages.messageRegistryService.addNode(node);
    } else {
      // means there is only backward pagination so need to add a node to te end of it
      this.forwardMessages.messageRegistryService.addNode(node);
    }

    runInAction(() => {
      this.messages = {
        ...this.messages,
        data: this.combinedPaginationArray,
      };
    });
  };

  onSubscriptionDeleted = (message?: ChannelMessagePayload) => {
    if (!message) {
      throw new Error(
        'There is no message came from the deleted message subscription'
      );
    }
    this.deleteMessageFromRegistries(message.id);
  };

  onSubscriptionUpdated = (message?: ChannelMessagePayload) => {
    if (!message) {
      throw new Error(
        'There is no message came from the updated message subscription'
      );
    }
    const msgModel = new MessageModel(
      message,
      this.userStore,
      this.communityStore,
      this.channelStore
    );
    this.replaceMessageModel(msgModel.id, msgModel);
  };

  setActiveMessageModel = (messageModel: MessageModel) => {
    this.activeMessageModel = messageModel;
  };
  setReplyModel = (model: MessageModel | null) => {
    this.replyModel = model;
  };

  setShowJumpToLatestButton = (show: boolean) => {
    this.showJumpToLatestButton = show;
  };
  setFirstUnreadMessageModel = (msgModel: MessageModel | null) => {
    const isVisitor =
      this.communityStore.activeCommunity.data?.isUserVisitorOrNonMember;

    // shouldn't use unread messages feature for the visitors
    if (isVisitor) return;

    this.firstUnreadMessageModel = msgModel;
  };

  // TODO: set reaction to update the active message model when new community ID changes
  setActiveNewEmptyMessageModel() {
    this.activeMessageModel = MessageModel.generateMockedMessageModel(
      this.userStore,
      this.communityStore,
      this.channelStore
    );

    this.setReplyModel(null);
  }

  createMessage = async (
    communityId: string,
    channelId: string,
    payload: CreateMessagePayload
  ) => {
    return await this.repository.createChannelMessage(
      communityId,
      channelId,
      payload
    );
  };

  optimisticCreateMessage = (optimisticPayload: ChannelMessagePayload) => {
    // updating the current ID to the real one from the precreated message
    const id = optimisticPayload.id;

    const msgAttachments: AttachmentsRegistry | void =
      this.activeMessageModel?.attachments;
    if (msgAttachments) {
      // TODO: probably it's unnecessary because the MessageModel
      // in optimistic generation generates the optimistic attachments as well
      Object.values(msgAttachments).forEach((attachmentModel) => {
        const { optimisticData: _optimisticData, ...restServerData } =
          attachmentModel.generateOptimisticResponse(true);
        attachmentModel.serverData = restServerData;
        attachmentModel.optimistic = true;
      });
    }

    if (!this.activeMessageModel) {
      throw new Error('active message model does not exists');
    }

    // create a real new messages to have attached user model/parent model etc.
    this.activeMessageModel = new MessageModel(
      optimisticPayload,
      this.userStore,
      this.communityStore,
      this.channelStore
    );
    this.activeMessageModel.id = id;
    // attach attachments from old message model to the new one
    this.activeMessageModel.attachments = msgAttachments as AttachmentsRegistry;

    this.activeMessageModel.optimistic = true;

    const optimisticMessageModel = this.activeMessageModel;

    const node = new MessageNode(optimisticMessageModel);

    // if there exists headCursor so the forward pagination was happened and need to add the msg to it as it's a tail of the messages
    if (this.forwardMessages.headCursor) {
      this.forwardMessages.messageRegistryService.addNode(node);
    } else {
      // means there is only backward pagination so need to add a node to te end of it
      this.forwardMessages.messageRegistryService.addNode(node);
    }

    runInAction(() => {
      this.messages = {
        ...this.messages,
        data: this.combinedPaginationArray,
      };
    });

    return optimisticMessageModel;
  };

  jumpTo = async (cursor: string) => {
    this.isJumpToRunning = true;
    await this.repository.jumpTo(
      this.activeCommunityId,
      this.activeChannelId,
      cursor
    );
  };

  goToLatest = async () => {
    const latestCursor = this.messages.meta.latestCursor;
    this.isJumpToLatest = true;

    const result = await this.repository.jumpTo(
      this.activeCommunityId,
      this.activeChannelId,
      latestCursor
    );
    this.reset();

    this.proceedStateMessages(result);

    this.globalEventBus.emit(
      MessagesEventsEnum.GO_TO_LATEST,
      result?.data?.length
    );
    this.setShowJumpToLatestButton(false);
  };

  goToReplyParent = (
    communityId: string,
    channelId: string,
    reply: ChannelMessageEdgePayload
  ) => {
    const node = reply.node;
    if (!node) {
      throw new Error('Passed reply does not contain a message node data');
    }

    const backwardNode =
      this.backwardMessages.messageRegistryService.tryToGetNode(node.id);

    const forwardNode =
      this.backwardMessages.messageRegistryService.tryToGetNode(node.id);

    const isMsgFetchedAlready = Boolean(backwardNode) || Boolean(forwardNode);

    // reset it for the old parent
    if (this.lastHiglightedParentReplyModel) {
      this.lastHiglightedParentReplyModel.setIsParentAndScrollTo(false);
    }

    if (isMsgFetchedAlready) {
      let replyParentIndex;
      if (backwardNode) {
        replyParentIndex = backwardNode.orderIndex;
      } else {
        // need to add the backward size as well in case of forward messages
        replyParentIndex =
          this.backwardMessages.messageRegistryService.registry.size -
          1 +
          forwardNode.orderIndex;
      }

      // check if it in the view port and highlight
      // or scroll to it

      this.lastHiglightedParentReplyModel = (
        backwardNode || forwardNode
      ).messageModel;
      this.globalEventBus.emit(MessagesEventsEnum.GO_TO_REPLY_PARENT, {
        replyParentMsgModel: this.lastHiglightedParentReplyModel,
        replyParentIndex: replyParentIndex,
        isFetched: false,
      });
    } else {
      // jump to
      this.fetchParentChunkData(communityId, channelId, reply);
    }
  };

  private fetchParentChunkData = async (
    communityId: string,
    channelId: string,
    reply: ChannelMessageEdgePayload
  ) => {
    const { cursor: parentCursor } = reply;
    this.isJumpToRunning = true;
    const res = await this.repository.jumpTo(
      communityId,
      channelId,
      parentCursor
    );
    this.reset();
    const { replyParentIndex } = this.proceedStateMessages(res, reply);

    this.globalEventBus.emit(MessagesEventsEnum.GO_TO_REPLY_PARENT, {
      replyParentMsgModel: this.lastHiglightedParentReplyModel,
      replyParentIndex: replyParentIndex,
      isFetched: true,
    });

    this.setShowJumpToLatestButton(true);
  };

  preCreateChannelMessage = async (communityId: string, channelId: string) => {
    const { data } = await this.repository.preCreateChannelMessage(
      communityId,
      channelId
    );

    if (!data) {
      throw new Error(`data is empty: ${data}`);
    }

    return data;
  };

  editMessage = (messageModel: MessageModel) => {
    messageModel.setEditing(true);
    const clonedMessageModel = messageModel.clone();

    this.setActiveMessageModel(clonedMessageModel);
    this.setEditData({
      active: true,
      optimistic: false,
      originalMessageModel: messageModel,
      editingMessageModel: clonedMessageModel,
    });
  };

  cancelEditMessage = () => {
    // should remove the edit mode highlight from the model in the list
    const originalMessageModel = this.editData.originalMessageModel;

    if (!originalMessageModel) {
      throw new Error('There is no originalMessageModel in this.editData');
    }

    originalMessageModel.setEditing(false);

    this.setEditData({ ...initialEditData });
    this.setActiveNewEmptyMessageModel();
    this.globalEventBus.emit(MessagesEventsEnum.EDIT_MODE_OFF);
  };

  applyEditChangesOptimistically = () => {
    const originalMessageModel = this.editData.originalMessageModel;

    if (!originalMessageModel) {
      throw new Error('There is no originalMessageModel in this.editData');
    }

    originalMessageModel.setEditing(false);

    this.setEditData({
      ...this.editData,
      optimistic: true,
    });

    this.setActiveNewEmptyMessageModel();

    const editingModel = this.editData.editingMessageModel;
    if (!editingModel) throw new Error('There is no editingModel');

    this.replaceMessageModel(editingModel.id, editingModel);
  };

  updateMessage = async (
    communityId: string,
    channelId: string,
    id: string,
    data: ChannelMessageUpdateInput
  ) => {
    // if it's already optimistic so the changes already in the list
    if (!this.editData.optimistic) {
      const optimisticPayload =
        this.activeMessageModel?.generateOptimisticResponse({
          text: data.text || '',
          stringifiedEditorState: data.rawJson,
          id,
        });

      const msgRegistryServicePrev =
        this.backwardMessages.messageRegistryService;
      const msgRegistryServiceNext =
        this.forwardMessages.messageRegistryService;

      let existingMessageModel;
      const possibleMsgNodeFromPrev = msgRegistryServicePrev.tryToGetNode(id);
      const possibleMsgNodeFromNext = msgRegistryServiceNext.tryToGetNode(id);

      if (possibleMsgNodeFromPrev) {
        existingMessageModel = possibleMsgNodeFromPrev.messageModel;
      } else if (possibleMsgNodeFromNext) {
        existingMessageModel = possibleMsgNodeFromNext.messageModel;
      } else {
        throw new Error(`There is no msg node with ID: ${id}`);
      }

      existingMessageModel.updateFromJSON(optimisticPayload);

      // it's a simple update without optimistic uploading. The empty model hadn't been set yet
      this.setEditData({
        ...this.editData,
        active: false,
      });
      this.setActiveNewEmptyMessageModel();
    } else {
      // if it's optimistic so the empty model had been set at
      // the moment of optimistic apply
      this.setEditData({
        ...this.editData,
        active: false,
      });
    }

    const { error } = await this.repository.updateChannelMessage(
      communityId,
      channelId,
      id,
      data
    );

    if (error) {
      this.handleError('Update message error', error.message);
      const originalMsgModel = this.editData.originalMessageModel;
      // rollback the original model
      if (!originalMsgModel) throw new Error('There is no originalMsgModel');

      this.replaceMessageModel(originalMsgModel.id, originalMsgModel);
    }

    this.setEditData({ ...initialEditData });
  };

  deleteMessage = async (
    communityId: string,
    channelId: string,
    id: string
  ) => {
    this.deleteMessageFromRegistries(id);
    await this.repository.deleteChannelMessage(communityId, channelId, id);
  };

  markMessagesAllAsRead = async (timestamp?: number) => {
    await this.repository.channelMessageMarkAllAsRead(
      this.activeCommunityId,
      this.activeChannelId,
      timestamp
    );
  };

  /**
   * Scenarios while setting the reaction for the message:
   * - reaction is already present for this message:
   *  - reaction is mine:
   *    = count of the reaction is only 1,
   *        :remove the reaction (DELETING)
   *    = count of the reaction is more then 1,
   *        :reduce the count of the reaction (UPDATING)
   *  - reaction is not mine,
   *      :increment the count (UPDATING)
   * - reaction is not present for this message,
   *    :add this reaction (INSERTING)
   */
  setReaction = (
    model: MessageModel,
    userReaction: ContentReactionInput
  ): boolean => {
    const dummyUserObj: UsersConnection = {
      totalCount: 1,
      pageInfo: {
        startCursor: '',
        endCursor: '',
        hasNextPage: false,
        hasPreviousPage: false,
        count: 1,
      },
      edges: [
        {
          cursor: '',
          node: this.userStore?.me?.serverData as User,
        },
      ],
    };
    const messageReactions = model.serverData.reactions;
    /** finding if this reaction is already present for this message */
    const alreadyPresentReactionIndex = messageReactions.findIndex(
      (reaction: ContentReactionPayload) =>
        reaction.unified === userReaction.unified
    );
    /** this reaction is already present for this message */
    if (alreadyPresentReactionIndex !== -1) {
      /** if the reaction is mine */
      if (messageReactions[alreadyPresentReactionIndex].reacted) {
        /** if the count of the reaction is only 1, remove the reaction */
        if (messageReactions[alreadyPresentReactionIndex].count === 1) {
          messageReactions.splice(alreadyPresentReactionIndex, 1);
          return false;
        } else {
          /** if the count of the reaction is more then 1, reduce the count of the reaction */
          messageReactions[alreadyPresentReactionIndex] = {
            ...messageReactions[alreadyPresentReactionIndex],
            count: messageReactions[alreadyPresentReactionIndex].count - 1,
            reacted: false,
          };
          return false;
        }
      } else {
        /** If the reaction is not mine, increment the count */
        messageReactions[alreadyPresentReactionIndex] = {
          ...messageReactions[alreadyPresentReactionIndex],
          count: messageReactions[alreadyPresentReactionIndex].count + 1,
          reacted: true,
        };
        return true;
      }
    } else {
      /** this reaction is not present for this message, add this reaction */
      messageReactions.push({
        unified: userReaction.unified,
        reacted: true,
        count: 1,
        users: messageReactions[0]?.users || dummyUserObj,
      } as ContentReactionPayload);
      return true;
    }
  };

  saveChannelMessageReaction = async (
    messageModel: MessageModel,
    emoji: ContentReactionInput
  ) => {
    const { id } = messageModel;
    const status = this.setReaction(messageModel, emoji);
    await this.repository.saveChannelMessageReaction(
      this.activeCommunityId,
      this.activeChannelId,
      id,
      {
        unified: emoji.unified,
        status,
      }
    );
  };

  markMessageAsUnread = async (messageModel: MessageModel) => {
    const timestamp = messageModel.serverData.createdAt;

    await this.repository.channelMessageMarkAllAsUnRead(
      this.activeCommunityId,
      this.activeChannelId,
      timestamp
    );

    let foundMarkingModel = false;
    this.messages.data.forEach((msgModel) => {
      const id = msgModel.id;

      if (id === messageModel.id && !foundMarkingModel) {
        foundMarkingModel = true;
        msgModel.serverData.read = true;
        this.setFirstUnreadMessageModel(msgModel);
      }

      if (foundMarkingModel) {
        msgModel.serverData.read = false;
      }
    });
  };

  fetchMoreMessages = () => {
    this.paginationDirection = 'backward';
    const meta = this.messages.meta;
    if (!meta) {
      return;
    }

    const { pageInfo, variables } = meta;

    if (!pageInfo) {
      return;
    }

    const communityId = variables.communityId;
    const channelId = variables.channelId;

    const options = {
      first: undefined,
      // if there is no backward tail cursor so should use the initial head cursor of the formard messages because before that point should be loaded older messages. In general this situation shouldn't happen as initially we load things in 'before' format
      before:
        this.backwardMessages.headCursor ||
        this.forwardMessages.tailCursor ||
        '',
      last: this.messagesChunkAmount,
    };

    this.getChannelMessages(communityId, channelId, options);
  };

  fetchMoreUnreadMessages = () => {
    this.paginationDirection = 'forward';
    // TODO: maybe combine it with the fetchMoreMessages
    const meta = this.messages.meta;
    if (!meta) {
      return;
    }

    const { variables } = meta;

    const communityId = variables.communityId;
    const channelId = variables.channelId;
    const first = this.messagesChunkAmount;

    const options = {
      first: first,
      // if there is no forward tail cursor so should use the initial head cursor of the backward messages as it's exactly the point after that should start the new unread messages
      after:
        this.forwardMessages.tailCursor ||
        // by default initial request is backward so there will be a backwardMessages and need to use it's tail for further forward messages
        this.backwardMessages.tailCursor ||
        '',
    };
    this.getChannelMessages(communityId, channelId, options);
  };

  markMessageAsRead = async (msgModel: MessageModel) => {
    const timestamp = msgModel.serverData.createdAt;
    await this.repository.channelMessageMarkAllAsRead(
      this.activeCommunityId,
      this.activeChannelId,
      timestamp
    );
  };

  // TEST: currently using it. The plain promise fetch instead of urql wonka subscriber to have the full controll over the data flow
  async getChannelMessages(
    communityId: string,
    channelId: string,
    options: MessagesOptions = {}
  ) {
    const response = await this.repository.getChannelMessages(
      communityId,
      channelId,
      options
    );

    this.proceedQueryMessageResult(response);
  }

  private proceedQueryMessageResult = (
    response: BaseRepositoryResponse<ChannelMessagePayloadEdge[]>
  ) => {
    const { error } = response;
    // theoretically shouldn't do anything when jumping to parent/latest is running
    if (this.isJumpToRunning || this.isJumpToLatest) {
      return;
    }

    if (error) {
      console.error(`channel messages Error ->>> ${error}`);
    }

    const { hasNextPage } = this.proceedStateMessages(response);

    // existed unreadMessage means it's possible to have some next page content
    // the same with previouslyVisitedIndex - means the same because the user could scroll far away from the bottom
    const possibleToShowJumpToLatest =
      Boolean(this.firstUnreadMessageModel) || this.previouslyVisitedIndex;

    if (possibleToShowJumpToLatest && hasNextPage) {
      this.setShowJumpToLatestButton(true);
    }
  };

  private proceedDataToPagination = (
    direction: 'backward' | 'forward',
    originalResponse: any,
    data: ChannelMessagePayloadEdge[]
  ) => {
    const paginatedMessages = this[`${direction}Messages`];
    const msgRegistryService = paginatedMessages.messageRegistryService;
    paginatedMessages.pageInfo =
      originalResponse?.data?.channelMessages.pageInfo;
    const latestCursor = originalResponse?.data?.channelMessages?.latestCursor;

    if (direction === 'backward') {
      // if there is no tailCursor so it's the first backward pagination and this tail must be the final because there will be only added messages to the start of the list that's why the tailCursor should be the final one
      if (!paginatedMessages.tailCursor) {
        paginatedMessages.tailCursor =
          originalResponse?.data?.channelMessages.pageInfo.endCursor;
      }
      // bakward pagination always fetches new previous messagas which come before the current headCursor so it must be updated every new fetch
      paginatedMessages.headCursor =
        originalResponse?.data?.channelMessages.pageInfo.startCursor;
    } else {
      // in fowrard direction it works in opposite way. Head should be the first one always because if need to fetch before this cursor it will be the backward pagination
      if (!paginatedMessages.headCursor) {
        paginatedMessages.headCursor =
          originalResponse?.data?.channelMessages.pageInfo.startCursor;
      }
      // in forward direction should always update tailCursor as we move forward
      paginatedMessages.tailCursor =
        originalResponse?.data?.channelMessages.pageInfo.endCursor;
    }

    // first unread model that will be used in the list
    let unreadModel: MessageModel | null = null;

    /* trying to revert []
     * [ 76 ... 80 ] to [80 - 76]
     *
     * add to start: 76 .... -> 79 ->  80
     *
     * [ 71 ... 75 ] to [75 ... 71]
     * add to start:  74 -> 75 -> 76
     *
     */
    const finalData = direction === 'forward' ? data : data.reverse();

    finalData.forEach((edge) => {
      // it's null when the optimistic message manual adding happens
      // if everything would be handled by urql and it's own pagination it wouldn't happen but the relayPagination does not work
      if (!edge.node) return;
      const msgModel = new MessageModel(
        edge.node,
        this.userStore,
        this.communityStore,
        this.channelStore
      );

      msgModel.cursor = edge.cursor;

      if (edge.cursor === latestCursor) {
        this.lastMessageId = edge.node.id;
      }

      const node = new MessageNode(msgModel);

      if (direction === 'backward') {
        if (!edge.node.read) {
          unreadModel = msgModel;
        } else if (unreadModel) {
          // in the backward list exists unread message. but
          // now the first read detected. Should set the prev message as unread

          // if there is unreadModel and on the new forward pagination
          // previous messages from the prev pagination should be marked as read under the hood without moving the initial unread model
          if (!this.firstUnreadMessageModel) {
            this.setFirstUnreadMessageModel(unreadModel);
          }

          // found the first one so just no need to caclulate it more
          unreadModel = null;
        }
        msgRegistryService.addNodeToStart(node);
      } else {
        if (!edge.node.read && !unreadModel) {
          unreadModel = msgModel;

          // if there is unreadModel and on the new forward pagination
          // previous messages from the prev pagination should be marked as read under the hood without moving the initial unread model
          if (!this.firstUnreadMessageModel) {
            this.setFirstUnreadMessageModel(unreadModel);
          }
        }
        msgRegistryService.addNode(node);
      }
    });
  };

  dispose = () => {
    // don't need to receive messages from the previous channel
    // this.repository.unsubscribeFromExistingMessagesQuery();

    this.isInitialMessagesLoading = true;
    const { visibleMessageCursor, visibleMessageId } = this.visibleMessageData;
    // saving the previous visible data of this channel before dispose
    if (visibleMessageCursor && visibleMessageId) {
      this.visitedChannelsReigstryService.setChannelData(this.activeChannelId, {
        visibleMessageCursor: visibleMessageCursor,
        visibleMessageId,
      });
      // reset visible message data for other channel because the message store is singletone;
      this.visibleMessageData = {
        visibleMessageCursor: null,
        visibleMessageId: null,
      };
    }

    this.reset();
  };

  reset = () => {
    // the default one
    this.paginationDirection = 'backward';
    this.backwardMessages.messageRegistryService.reset();
    this.forwardMessages.messageRegistryService.reset();

    this.backwardMessages = getInitialPaginationData();
    this.forwardMessages = getInitialPaginationData();

    this.setShowJumpToLatestButton(false);

    runInAction(() => {
      const emptyMessagesState = MessageStore.generateCommonApiDataShape(
        false,
        []
      );
      this.messages = emptyMessagesState;
    });
  };

  private deleteMessageFromRegistries(id: string) {
    // just not to check in what pagination registry it is, it's possible just remove this node in both. If it doesn't exists - won't happen anything
    this.backwardMessages.messageRegistryService.removeNode(id);
    this.forwardMessages.messageRegistryService.removeNode(id);

    runInAction(() => {
      this.messages = {
        ...this.messages,
        data: this.combinedPaginationArray,
      };
    });
  }

  private replaceMessageModel = (
    existingModelId: string,
    newMessageModel: MessageModel
  ) => {
    const newEditMessageNode = new MessageNode(newMessageModel);
    const msgRegistryServicePrev = this.backwardMessages.messageRegistryService;
    const msgRegistryServiceNext = this.forwardMessages.messageRegistryService;

    if (msgRegistryServicePrev.tryToGetNode(existingModelId)) {
      msgRegistryServicePrev.replaceNode(existingModelId, newEditMessageNode);
    } else if (msgRegistryServiceNext.tryToGetNode(existingModelId)) {
      msgRegistryServiceNext.replaceNode(existingModelId, newEditMessageNode);
    }

    runInAction(() => {
      this.messages = {
        ...this.messages,
        data: this.combinedPaginationArray,
      };
    });
  };

  private setEditData = (editData: EditDataType) => {
    runInAction(() => {
      this.editData = editData;
    });
  };

  private proceedStateMessages(
    urqlResponse: BaseRepositoryResponse<ChannelMessagePayloadEdge[]>,
    reply?: ChannelMessageEdgePayload
  ) {
    const { data, originalResponse, error } = urqlResponse;

    let replyParentIndex;

    let hasNextPage = null;

    runInAction(() => {
      const finalData = data || [];

      /**
       * TOOD: TEMPORARY. Remove whe BE provides a fix for it
       * workaround for the BE issue when there is no more items left but
       * the BE result pageInfo contains the wrong hasNextPage = true.
       * Which is wrong. Need to handle it because the system things that there
       * is active forward pagination but it is not
       */
      let isEmptyForwardRequestBEIssue = false;

      if (this.paginationDirection === 'backward') {
        this.proceedDataToPagination('backward', originalResponse, finalData);
      } else {
        this.proceedDataToPagination('forward', originalResponse, finalData);

        if (
          !originalResponse.data.channelMessages.edges.length &&
          originalResponse.data.channelMessages.pageInfo.endCursor === '' &&
          originalResponse.data.channelMessages.pageInfo.startCursor === ''
        ) {
          isEmptyForwardRequestBEIssue = true;
        }
      }

      let result = this.combinedPaginationArray;
      if (reply) {
        result = this.combinedPaginationArray.map((messageModel, index) => {
          if (messageModel.serverData.id === reply.node.id) {
            replyParentIndex = index;
            this.lastHiglightedParentReplyModel = messageModel;
            // test. Theoretically can use it to highlight the message after scroll
            messageModel.setIsParentAndScrollTo(true);
          }
          return messageModel;
        });
      }

      (hasNextPage =
        this.forwardMessages?.pageInfo?.hasNextPage !== null &&
        !isEmptyForwardRequestBEIssue
          ? // initial value. Should normall be false|true
            this.forwardMessages?.pageInfo?.hasNextPage
          : this.backwardMessages?.pageInfo?.hasNextPage),
        (this.messages = {
          data: result,
          error: error,
          loading: false,
          meta: {
            variables: originalResponse.operation.variables,
            pageInfo: originalResponse?.data?.channelMessages?.pageInfo,
            hasNextPage,
            hasPreviousPage:
              this.backwardMessages?.pageInfo?.hasNextPage !== null
                ? this.backwardMessages?.pageInfo?.hasPreviousPage
                : this.forwardMessages?.pageInfo?.hasPreviousPage,
            nextPageLoading: false,
            originalResponse: originalResponse?.data?.channelMessages,
            newMessagesCount:
              originalResponse?.data?.channelMessages.newMessagesCount,
            latestCursor: originalResponse?.data?.channelMessages.latestCursor,
          },
        });
    });

    return {
      replyParentIndex,
      hasNextPage,
    };
  }
}
