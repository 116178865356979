import { CommunityRolePayload, PageInfo } from './graphql-schema';

export enum RolesEnum {
  Admin = 'Admin',
  Owner = 'Owner',
  Moderator = 'Moderator',
  Member = 'Member',
  NonMember = 'Non-Member',
  Visitor = 'Visitor',
  Creator = 'Creator',
}

export type PlainObject = {
  [key: string]: any;
};

export type CommunityRolesObj = {
  [key in keyof typeof RolesEnum]: Partial<CommunityRolePayload>;
};

export type CloudFlareUrlData = {
  id: string;
  files: [];
  url: string;
};

export enum FileUploadStatusEnum {
  INITIAL = 'INITIAL',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  RETRYING = 'RETRYING',
  FAILED = 'FAILED',
}

export type PaginatedData<T> = {
  edges: T[];
  pageInfo: PageInfo;
};

export type ForwardPaginationVariables = {
  first: number;
  after: string;
};

export type BackwardPaginationVariables = {
  last: number;
  before: string;
};

export type CommonPaginationVariables = {
  sort?: string;
  search?: string;
};

export type AllPaginationVars = Partial<ForwardPaginationVariables> &
  Partial<BackwardPaginationVariables> &
  Partial<CommonPaginationVariables>;

export type KeysInObj<T extends string> = { [key in T]: string };

export type BaseFieldData<T> = {
  data: T;
  loading: boolean;
  error: null | string;
  successMessage: string;
};
