import { useState } from 'react';
import { PricingSection1 } from './section-1/PricingSection1';

import dynamic from 'next/dynamic';

const PricingSection2 = dynamic(
  () =>
    import('./section-2/PricingSection2').then(
      (module) => module.PricingSection2
    ),
  { ssr: false }
);

export enum PricingSource {
  RULES = 'rules',
  PRICING = 'pricing',
}

export type PricingItemProps = {
  title: string;
  price: number;
  features: string[];
  allowQuantity?: boolean;
  quantity?: number;
};

export type AddedItemsType = {
  basePlan: PricingItemProps | null;
  aLaCartePlan: PricingItemProps[] | null;
};

export function Pricing() {
  const [addedItems, setAddedItems] = useState<AddedItemsType>({
    basePlan: null,
    aLaCartePlan: null,
  });

  return (
    <div className="flex flex-1 flex-col gap-[72px] bg-background-primary dark:bg-background-website-dark md:gap-[130px]">
      <PricingSection1
        addedItems={addedItems}
        setAddedItems={setAddedItems}
        from={PricingSource.PRICING}
      />
      <PricingSection2
        addedItems={addedItems}
        setAddedItems={setAddedItems}
        from={PricingSource.PRICING}
      />
    </div>
  );
}
