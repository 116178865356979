import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { TextButtonPrimary } from '@foundationPathAlias/main';
import { Separator } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  InputField,
  ItemHeader,
  ScreenWrapper,
} from '@mainApp/src/modules/channel/channel-group-settings/components';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useMinLoading } from '@foundationPathAlias/components/loaders';
import { IOverviewStore } from './data/Overview.store.local';

import { DeleteModaChannelGroupContent } from '@mainApp/src/components/common';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';
import { useEffect } from 'react';

export function _Overview() {
  const { channelGroupSettingsStore, interactiveStore } = useMultipleInjection([
    IOC_TOKENS.channelGroupSettingsStore,
    IOC_TOKENS.interactiveStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);

  const activeStore =
    channelGroupSettingsStore.activeChildScreen as IOverviewStore;

  useEffect(() => {
    activeStore?.setChannelGroupModel(
      channelGroupSettingsStore.channelGroupModel
    );
  }, [channelGroupSettingsStore.channelGroupModel]);

  const channelNameProxy = activeStore?.channelGroupName;

  const availabilityCheckLoading = useMinLoading(
    Boolean(channelNameProxy?.loading),
    500
  );

  const delChannelTitle = t('channel:channelGroupSettings.deleteChannelGroup');

  return (
    <ScreenWrapper title="channel:channelGroupSettings.channelGroupSettingsExplicit">
      <div className="h-full w-full flex-1">
        <ItemHeader cn="mb-[16px]">
          {t('channel:channelGroupSettings.channelGroupName')}
        </ItemHeader>
        <InputField
          successMsg=""
          inputName={t<string>('channel:channelGroupSettings.channelGroupName')}
          placeholder={t(
            'channel:channelSettings.channelGroupSettings.enterChannelGroupName'
          )}
          value={channelNameProxy?.data || ''}
          onChange={(val) => {
            activeStore.setChannelGroupName(val);
          }}
          outerError={
            channelNameProxy?.error ? t<string>(channelNameProxy?.error) : null
          }
          showLoader={availabilityCheckLoading}
          onError={(error) => {
            const existingError = channelNameProxy?.error;
            if (existingError !== error) {
              activeStore?.setChannelGroupName(channelNameProxy.data, error);
            }
          }}
        />
        <Separator cn="mb-[24px]" />
        <div className="flex flex-col items-start">
          <ItemHeader>{delChannelTitle}</ItemHeader>
          <p className="themed-text-secondary text-left text-body16R">
            {t('channel:channelGroupSettings.deleteChannelGroupDescription')}
          </p>

          <TextButtonPrimary
            className="themed-text-error hover-el-opacity animation-classic mt-[16px] text-body16SB"
            onClick={() => {
              channelGroupSettingsStore.setIsShow(false);

              interactiveStore.setActiveContentType(
                InteractiveContentTypesEnum.OTHER,
                true
              );
              interactiveStore.setContent(
                <DeleteModaChannelGroupContent
                  channelGroupModel={
                    channelGroupSettingsStore.channelGroupModel as ChannelGroupModel
                  }
                  onCancel={() => {
                    channelGroupSettingsStore.setIsShow(true);
                  }}
                />
              );

              interactiveStore.setInteractiveElementOpen(true);
            }}
          >
            {t('channel:channelGroupSettings.deleteChannelGroup')}
          </TextButtonPrimary>
        </div>
      </div>
    </ScreenWrapper>
  );
}

export const Overview = withAnimatedStack(observer(_Overview));
