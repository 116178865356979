import { colorSchema } from '@rootConfig/color-schema';
import React from 'react';
import Popup from 'reactjs-popup';
import { PopupProps } from 'reactjs-popup/dist/types';

export type TooltipPropsType = {
  triggerEl: JSX.Element;
  darkMode: boolean;
  // if provided the trigger el will be wrapped in a div with this class
  triggerWrapperCn?: string;
  children: React.ReactNode;
  contentStyle?: React.CSSProperties;
  arrowStyle?: React.CSSProperties;
} & Partial<PopupProps>;

export function Tooltip(props: TooltipPropsType) {
  const {
    triggerEl,
    children,
    darkMode,
    triggerWrapperCn,
    contentStyle = {},
    arrowStyle = {},
    ...otherProps
  } = props;
  let bgColor = darkMode
    ? colorSchema['background-primary']
    : colorSchema['background-primary-dark'];

  let trigger = triggerWrapperCn ? (
    <div className={triggerWrapperCn}>{triggerEl}</div>
  ) : (
    triggerEl
  );

  return (
    <Popup
      contentStyle={{
        backgroundColor: bgColor,
        width: 'auto',
        padding: '4px 8px',
        borderRadius: '5px',
        color: 'white',
        fontSize: '14px',
        ...contentStyle,
      }}
      arrowStyle={{
        color: bgColor,
        ...arrowStyle,
      }}
      on="hover"
      trigger={trigger}
      position={['top center', 'bottom center', 'left center']}
      {...otherProps}
    >
      {children}
    </Popup>
  );
}
