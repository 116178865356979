import React, { useMemo } from 'react';

const DEFAULT_COLUMNS_COUNT = 1;

import { useWindowWidth } from '@foundationPathAlias/utilities';

type ColumnsCountBreakPointsType = {
  [key: number]: number;
};

export type MasonryHeightGridResponsiveWrapperPropsType = {
  columnsCountBreakPoints?: ColumnsCountBreakPointsType;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export function MasonryHeightGridResponsiveWrapper(
  props: MasonryHeightGridResponsiveWrapperPropsType
) {
  const {
    columnsCountBreakPoints = {
      375: 1,
      580: 2,
      640: 3,
      1024: 4,
    },
    children,
    className,
    style,
  } = props;

  const windowWidth = useWindowWidth();

  const columnsCount = useMemo(() => {
    const breakPoints = Object.keys(columnsCountBreakPoints)
      .map((key) => parseInt(key))
      .sort((a, b) => a - b);
    let count =
      breakPoints.length > 0
        ? columnsCountBreakPoints[breakPoints[0]]
        : DEFAULT_COLUMNS_COUNT;

    breakPoints.forEach((breakPoint) => {
      if (breakPoint < windowWidth) {
        count = columnsCountBreakPoints[breakPoint];
      }
    });

    return count;
  }, [windowWidth, columnsCountBreakPoints]);

  return (
    <div className={className} style={style}>
      {React.Children.map(children, (child, index) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              key: index,
              ...child.props,
              columnsCount: columnsCount,
            })
          : child
      )}
    </div>
  );
}
