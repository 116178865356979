import {
  Channel_Sort_Types,
  Channel_Types,
  ChannelAddMemberInput,
} from '@10x/foundation/types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

import type {
  IPermissionsServiceStore,
  RoleItem,
  UserItem,
} from '@mainApp/src/stores/permissions';

import {
  ISidebarModalChildScreen,
  SidebarModalChildScreen,
} from '@foundationPathAlias/widgets/sidebar-modal';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';

import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import type {
  IChannelStore,
  ICommunityStore,
  ISystemStore,
} from '@mainApp/src/stores';

import { ICommunityRepository } from '@mainApp/src/repositories';
import { ApiBase } from '@mainApp/src/stores/ApiBase';

enableStaticRendering(typeof window === 'undefined');

export class PermissionsStore
  extends SidebarModalChildScreen<ScreenIdsValuesType>
  implements ISidebarModalChildScreen<ScreenIdsValuesType>
{
  systemStore: ISystemStore = iocContainer.get(IOC_TOKENS.systemStore);
  communityStore: ICommunityStore = iocContainer.get(IOC_TOKENS.communityStore);
  communityRepository: ICommunityRepository = iocContainer.get(
    IOC_TOKENS.communityRepository
  );

  permissionsServiceStore: IPermissionsServiceStore = iocContainer.get(
    IOC_TOKENS.permissionsServiceStore
  );

  channelModel: ChannelModel | null = null;
  private channelStore: IChannelStore = iocContainer.get(
    IOC_TOKENS.channelStore
  );
  private apiBaseInstance: ApiBase = new ApiBase(
    iocContainer.get(IOC_TOKENS.toastStore)
  );

  isPrivate = false;
  isInitializing = true;

  get actionsPanelData() {
    return {
      cancelAction: () => {
        runInAction(() => {
          this.showActionPanel = false;
          this.isDirty = false;
          this.permissionsServiceStore.restoreRegsFromSnapshot();
        });
      },
      getCancelActionText: () => 'cancel',
      proceedAction: async () => {
        const isError = await this.updateChannel();
        if (!isError) {
          this.showActionPanel = false;
          this.isDirty = false;
        }
      },
      getProceedActionText: () => 'saveChanges',
    };
  }

  get activeCommunityId() {
    const id = this.communityStore.activeCommunity.data?.serverData.id;
    if (!id) {
      throw this.apiBaseInstance.handleError(
        'Error',
        'There is no active community id'
      );
    }
    return id;
  }
  get activeChannelId() {
    const id = this.channelModel?.serverData.id;
    if (!id) {
      throw this.apiBaseInstance.handleError(
        'Error',
        'There is no active channel id'
      );
    }
    return id;
  }

  constructor() {
    super(ScreenIds, screensConfig);

    this.permissionsServiceStore.setMode('channel');

    makeObservable(this, {
      activeScreen: computed,
      isInitialScreen: computed,

      addUser: action,
      removeUser: action,
      addRole: action,
      removeRole: action,

      isDirty: observable,
      isLoading: observable,
      isInitializing: observable,
      showActionPanel: observable,
      channelModel: observable,
      isPrivate: observable,
      activeScreenId: observable,

      showActionPanelIfDirty: action,

      setIsPrivate: action,
      resetData: action,
      setChannelModel: action,
      setActiveScreenId: action,
      setNextScreenId: action,
      back: action,
      reset: action,
    });

    when(
      () => Boolean(this.channelModel),
      () => {
        this.setupData();
      }
    );
  }

  showActionPanelIfDirty = () => {
    const isDirty = this.permissionsServiceStore.isDirty;
    if (isDirty && !this.showActionPanel) {
      this.showActionPanel = true;
    } else if (!isDirty && this.showActionPanel) {
      this.showActionPanel = false;
    }

    this.isDirty = isDirty;
  };

  onProceedSecondBtnInteraction = () => {
    // when the user clicks on the discard btn we need to just rollback our changes before we'll move to the next screen
    this.permissionsServiceStore.restoreRegsFromSnapshot();
  };

  init() {
    this.permissionsServiceStore.setupReactions();
  }

  private setupData = () => {
    this.permissionsServiceStore.setActiveChannelOrGroupId(
      this.activeChannelId
    );
    this.permissionsServiceStore
      .setupSavedData(this.activeChannelId)
      .finally(() => {
        runInAction(() => {
          this.setIsPrivate(this.channelModel?.serverData.private ?? false);
          this.isInitializing = false;
        });
      });
  };

  addUser = (userItem: UserItem) => {
    this.permissionsServiceStore.addUser(userItem);
    this.showActionPanelIfDirty();
  };
  removeUser = (userItem: UserItem) => {
    this.permissionsServiceStore.removeUser(userItem);
    this.showActionPanelIfDirty();
  };
  addRole = (roleItem: RoleItem) => {
    this.permissionsServiceStore.addRole(roleItem);
    this.showActionPanelIfDirty();
  };
  removeRole = (roleItem: RoleItem) => {
    this.permissionsServiceStore.removeRole(roleItem);
    this.showActionPanelIfDirty();
  };

  setChannelModel = (channelModel: ChannelModel | null) => {
    this.channelModel = channelModel;
  };

  setIsPrivate = (isPrivate: boolean) => {
    this.isPrivate = isPrivate;
    if (isPrivate !== this.channelModel?.serverData.private) {
      this.showActionPanel = true;
      this.isDirty = true;
    }
  };

  onViewRoleMembers = async (roleItem: RoleItem) => {
    const { model } = roleItem;

    this.permissionsServiceStore.setSelectedRole(model);

    this.setNextScreenId(ScreenIds.MEMBERS_VIEW);
  };

  setActiveScreenId = (id: string) => {
    if (this.activeScreenId === id) return;
    this.activeScreenId = id;

    if (this.permissionsServiceStore.isSearchActive) {
      this.permissionsServiceStore.resetSearch();
    }
  };

  updateChannel = async () => {
    if (!this.channelModel) {
      throw new Error('channelModel is not defined');
    }

    const { communityId, id } = this.channelModel.serverData;

    this.isLoading = true;

    const { removedUsers, removedRoles } =
      this.permissionsServiceStore.getRemovedFromSavedRolesAndUsers();

    const members: ChannelAddMemberInput[] | undefined =
      this.permissionsServiceStore.getAddedRolesAndUsers() as ChannelAddMemberInput[];

    const finalMembers = members
      ? ([
          ...members,
          ...removedUsers,
          ...removedRoles,
        ] as ChannelAddMemberInput[])
      : undefined;

    const payload = {
      members: finalMembers,
      private: this.isPrivate,
      option: {
        sort: Channel_Sort_Types.New,
      },
      channelType: Channel_Types.Messages,
    };

    const { error } = await this.channelStore.updateChannel(
      communityId,
      id,
      payload,
      true
    );
    this.isLoading = false;

    return Boolean(error);
  };

  dispose = () => {
    this.permissionsServiceStore.dispose();
  };
}
