// TODO: test. Want to check if it would be normal to put all
// common api stuff here
import type { IToastStore } from '@mainApp/src/stores';
import { ToastTypesEnum } from '@mainApp/src/stores';
import { UniqueKeysRegistry } from '@mainApp/src/stores/types/common';
import { injectable } from 'inversify';
export type CommonApiDataShapeType<T> = {
  data: T;
  loading: boolean;
  error: string | any;
  meta: {
    [index: string]: any;
  };
};

/* the main purpose of this data structure
 * is to dedupe data items as in the case of
 * paginated items we could have duplicates in BE response
 * so I decided to use unique key based registry and just
 * generate the values array based on it
 */
export type IterableDataShape<T> = {
  registry: UniqueKeysRegistry<T>;
  valuesArray: T[];
};

export const generateCommonApiDataShape = <T>(
  loading = true,
  initialData: T | null = null
) => ({
  data: initialData,
  loading: loading,
  error: null,
  meta: {},
});

@injectable()
export class ApiBase {
  toastStore: IToastStore;

  static generateCommonApiDataShape(loading = true, initialData: any = null) {
    return generateCommonApiDataShape(loading, initialData);
  }

  constructor(toastStore: IToastStore) {
    this.toastStore = toastStore;
  }

  handleError(title: string, content: string | JSX.Element) {
    // TODO: temporary till some Sentry will be available.
    // this error happens when auth and redirection to the caller page because the urql mutatio is interrapting due to new page opening
    if (
      content == '[Network] NetworkError when attempting to fetch resource.'
    ) {
      console.error('An error happened!');
      console.error(title, content);
      return;
    }

    this.toastStore.show({
      type: ToastTypesEnum.ERROR,
      title: title,
      content: content,
    });
  }

  showSuccessToast(title: string, content: string | JSX.Element) {
    this.toastStore.show({
      type: ToastTypesEnum.SUCCESS,
      title: title,
      content: content,
    });
  }
}
