import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export function _AuthLoginButton() {
  const { t } = useTranslation(['common', 'home']);

  const {
    authStore,
    interactiveStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.authStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const isLogged = authStore.logged;

  if (isLogged) {
    return null;
  }

  const text = isMobile ? t('navbar.signUp') : t('navbar.logIn');

  return (
    <button
      className="themed-primary-text-color-dynamic whitespace-nowrap text-sm14SB"
      onClick={() => {
        if (isMobile) {
          authStore.setLoginMode(false);
          interactiveStore.setActiveContentType(
            InteractiveContentTypesEnum.AUTH,
            true
          );
        } else {
          authStore.setLoginMode(true);
          interactiveStore.setActiveContentType(
            InteractiveContentTypesEnum.AUTH,
            true
          );
        }
      }}
    >
      {text}
    </button>
  );
}

export const AuthLoginButton = observer(_AuthLoginButton);
