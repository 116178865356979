type Props = {
  title: string;
};
export function SectionTitle(props: Props) {
  const { title } = props;
  return (
    <h1 className="mb-[24px] mt-[24px] text-miniHead font-bold  md:mt-[34px] md:text-subHeadM">
      {title}
    </h1>
  );
}
