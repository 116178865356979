import { ActionWithIcon } from '@foundationPathAlias/main';
import { PlusIcon } from '@heroicons/react/24/outline';

type Props = {
  onClick: () => void;
  text: string;
  disabled?: boolean;
};

export function AddRolesMembersActionBtn(props: Props) {
  const { onClick, text } = props;
  return (
    <ActionWithIcon
      onClick={onClick}
      leftIcon={
        <PlusIcon className="themed-primary-text-color-dynamic mr-[4px] h-[20px] w-[20px]" />
      }
      title={text}
      noDefaultRightIcon={true}
      disabled={props.disabled}
    />
  );
}
