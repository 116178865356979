import { AddMembers } from '../AddMembers';
import { CreateChannelOrGroupForm } from '../CreateChannelOrGroupForm';
import { MembersView } from '../MembersView';

export const ScreenIds = {
  CREATE_FORM: 'createForm',
  ADD_MEMBERS: 'addMembers',
  MEMBERS_VIEW: 'membersView',
};

export const screensConfig = {
  [ScreenIds.CREATE_FORM]: {
    id: ScreenIds.CREATE_FORM,
    Component: CreateChannelOrGroupForm,
  },
  [ScreenIds.ADD_MEMBERS]: {
    id: ScreenIds.ADD_MEMBERS,
    Component: AddMembers,
  },
  [ScreenIds.MEMBERS_VIEW]: {
    id: ScreenIds.MEMBERS_VIEW,
    Component: MembersView,
  },
};
