import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { classNames } from '@foundationPathAlias/utilities';

export type MembersListPropsType = {
  title?: string;
  titleCn?: string;
  rootCn?: string;
  contentWrapperCn?: string;
  loaderWrapperCn?: string;
  loading?: boolean;
  children: React.ReactNode;
  customLoader?: React.ReactNode;
};

export const MembersList = (props: MembersListPropsType) => {
  const {
    title,
    titleCn,
    loading,
    customLoader,
    rootCn,
    contentWrapperCn,
    loaderWrapperCn,
    children,
  } = props;

  const content = (
    <div className={classNames('mt-[8px] flex flex-col', contentWrapperCn)}>
      {children}
    </div>
  );

  const loader = customLoader || <SmallLoader />;

  return (
    <div className={classNames('relative min-h-[64px] pb-[16px]', rootCn)}>
      {Boolean(title) && (
        <b className={classNames('themed-text text-body16SB', titleCn)}>
          {title}
        </b>
      )}

      {content}

      {loading && (
        <div
          className={classNames(
            'absolute bottom-[6px] left-[50%] flex translate-x-[-50%] transform items-center justify-center',
            loaderWrapperCn
          )}
        >
          {loader}
        </div>
      )}
    </div>
  );
};
