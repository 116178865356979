import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import { ChannelGroupAddMemberInput } from '@10x/foundation/types';
import {
  ISidebarModalChildScreen,
  SidebarModalChildScreen,
} from '@foundationPathAlias/widgets/sidebar-modal';

import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import type { IChannelStore, ICommunityStore } from '@mainApp/src/stores';
import type {
  IPermissionsServiceStore,
  RoleItem,
  UserItem,
} from '@mainApp/src/stores/permissions';

import { ApiBase } from '@mainApp/src/stores/ApiBase';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';

import { ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

enableStaticRendering(typeof window === 'undefined');

export class PermissionsStore
  extends SidebarModalChildScreen<ScreenIdsValuesType>
  implements ISidebarModalChildScreen<ScreenIdsValuesType>
{
  communityStore: ICommunityStore = iocContainer.get(IOC_TOKENS.communityStore);

  permissionsServiceStore: IPermissionsServiceStore = iocContainer.get(
    IOC_TOKENS.permissionsServiceStore
  );

  channelGroupModel: ChannelGroupModel | null = null;
  private channelStore: IChannelStore = iocContainer.get(
    IOC_TOKENS.channelStore
  );
  private apiBaseInstance: ApiBase = new ApiBase(
    iocContainer.get(IOC_TOKENS.toastStore)
  );

  isPrivate = false;
  isInitializing = true;

  get actionsPanelData() {
    return {
      cancelAction: () => {
        runInAction(() => {
          this.showActionPanel = false;
          this.isDirty = false;
          this.permissionsServiceStore.restoreRegsFromSnapshot();
        });
      },
      getCancelActionText: () => 'cancel',
      proceedAction: async () => {
        const isError = await this.updateChannelGroup();
        if (!isError) {
          this.showActionPanel = false;
          this.isDirty = false;
        }
      },
      getProceedActionText: () => 'saveChanges',
    };
  }

  get activeCommunityId() {
    const id = this.communityStore.activeCommunity.data?.serverData.id;
    if (!id) {
      throw this.apiBaseInstance.handleError(
        'Error',
        'There is no active community id'
      );
    }
    return id;
  }

  get activeChannelGroupId() {
    const id = this.channelGroupModel?.id;
    if (!id) {
      throw this.apiBaseInstance.handleError(
        'Error',
        'There is no active channel group id'
      );
    }
    return id;
  }

  constructor() {
    super(ScreenIds, screensConfig);

    this.permissionsServiceStore.setMode('group');

    makeObservable(this, {
      activeScreen: computed,
      isInitialScreen: computed,

      addUser: action,
      removeUser: action,
      addRole: action,
      removeRole: action,

      isDirty: observable,
      isLoading: observable,
      isInitializing: observable,

      showActionPanel: observable,

      channelGroupModel: observable,
      isPrivate: observable,

      activeScreenId: observable,

      showActionPanelIfDirty: action,

      setIsPrivate: action,
      resetData: action,
      setChannelGroupModel: action,
      setActiveScreenId: action,
      setNextScreenId: action,
      back: action,
      reset: action,
    });

    when(
      () => Boolean(this.channelGroupModel),
      () => {
        this.setupData();
      }
    );
  }

  showActionPanelIfDirty = () => {
    const isDirty = this.permissionsServiceStore.isDirty;
    if (isDirty && !this.showActionPanel) {
      this.showActionPanel = true;
    } else if (!isDirty && this.showActionPanel) {
      this.showActionPanel = false;
    }

    this.isDirty = isDirty;
  };

  onProceedSecondBtnInteraction = () => {
    // when the user clicks on the discard btn we need to just rollback our changes before we'll move to the next screen
    this.permissionsServiceStore.restoreRegsFromSnapshot();
  };

  init() {
    this.permissionsServiceStore.setupReactions();
  }

  private setupData = () => {
    this.permissionsServiceStore.setActiveChannelOrGroupId(
      this.activeChannelGroupId
    );
    this.permissionsServiceStore
      .setupSavedData(this.activeChannelGroupId)
      .finally(() => {
        runInAction(() => {
          this.isInitializing = false;
        });
      });
  };

  addUser = (userItem: UserItem) => {
    this.permissionsServiceStore.addUser(userItem);
    this.showActionPanelIfDirty();
  };
  removeUser = (userItem: UserItem) => {
    this.permissionsServiceStore.removeUser(userItem);
    this.showActionPanelIfDirty();
  };
  addRole = (roleItem: RoleItem) => {
    this.permissionsServiceStore.addRole(roleItem);
    this.showActionPanelIfDirty();
  };
  removeRole = (roleItem: RoleItem) => {
    this.permissionsServiceStore.removeRole(roleItem);
    this.showActionPanelIfDirty();
  };

  setChannelGroupModel = (channelGroupModel: ChannelGroupModel | null) => {
    this.channelGroupModel = channelGroupModel;
    this.setIsPrivate(channelGroupModel?.private ?? false);
  };

  setIsPrivate = (isPrivate: boolean) => {
    this.isPrivate = isPrivate;
    if (isPrivate !== this.channelGroupModel?.private) {
      this.showActionPanel = true;
      this.isDirty = true;
    }
  };

  onViewRoleMembers = async (roleItem: RoleItem) => {
    const { model } = roleItem;
    this.permissionsServiceStore.setSelectedRole(model);

    this.setNextScreenId(ScreenIds.MEMBERS_VIEW);
  };

  setActiveScreenId = (id: string) => {
    if (this.activeScreenId === id) return;
    this.activeScreenId = id;

    if (this.permissionsServiceStore.isSearchActive) {
      this.permissionsServiceStore.resetSearch();
    }
  };

  updateChannelGroup = async () => {
    if (!this.channelGroupModel) {
      throw new Error('channelGroupModel is not defined');
    }
    const { communityId, id } = this.channelGroupModel;

    this.isLoading = true;

    const { removedUsers, removedRoles } =
      this.permissionsServiceStore.getRemovedFromSavedRolesAndUsers();

    const members: ChannelGroupAddMemberInput[] | undefined =
      this.permissionsServiceStore.getAddedRolesAndUsers() as ChannelGroupAddMemberInput[];

    const finalMembers = members
      ? ([
          ...members,
          ...removedUsers,
          ...removedRoles,
        ] as ChannelGroupAddMemberInput[])
      : undefined;

    const payload = {
      members: finalMembers,
      private: this.isPrivate,
    };

    const { error } = await this.channelStore.updateChannelGroup(
      communityId,
      id,
      payload
    );

    await this.permissionsServiceStore.setupSavedData(
      this.activeChannelGroupId
    );

    this.isLoading = false;
    this.isDirty = false;

    return Boolean(error);
  };

  dispose = () => {
    this.permissionsServiceStore.dispose();
  };
}
