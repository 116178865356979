'use client';

import { CommunityCard } from '@foundationPathAlias/components/community-card';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { ICustomizeStoreLocal } from '../data/Customize.store.local';

const _CommunitySettingsPreview = () => {
  const {
    dimensionsStore: { isMobile },
    capStore,
  } = useMultipleInjection([IOC_TOKENS.dimensionsStore, IOC_TOKENS.capStore]);

  const customizeForm =
    capStore.activeChildScreen as unknown as ICustomizeStoreLocal;

  const { communityColors, communityImages, topicsList } = customizeForm || {};
  const { thumbnail, logo } = communityImages || {};

  return (
    <div
      className={classNames('flex w-full flex-col', {
        'bg-background-primary dark:bg-background-tetriary-dark': !isMobile,
      })}
    >
      <div className="m-auto w-full max-w-[360px]">
        <CommunityCard
          name={customizeForm?.name.value}
          description={customizeForm?.description.value}
          imageSrc={thumbnail?.croppedFileUrl || ''}
          logoSrc={logo?.croppedFileUrl || ''}
          cn="h-[380px] w-full"
          membersCount={1}
          communityColor={communityColors?.primaryColor.pureRgba}
          topics={topicsList || []}
        />
      </div>
    </div>
  );
};

export const CommunitySettingsPreview = observer(_CommunitySettingsPreview);
