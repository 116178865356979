import {
  ActionsPanelData,
  ChangesAlertConfig,
  ISidebarModal,
  ISidebarModalChildScreen,
  ScreensConfig,
  StackScreenPayload,
} from '../data/types';

export class SidebarModalChildScreen<T extends string>
  implements ISidebarModalChildScreen<T>
{
  private isSidebarModalWasSetFirstTime = false;

  isDirty = false;
  isLoading = false;
  showActionPanel = false;
  isDisableProceedAction = false;
  _isError = false;

  get isError() {
    return this._isError;
  }

  set isError(value: boolean) {
    this._isError = value;
  }

  get actionsPanelData(): null | ActionsPanelData {
    return null;
  }

  sidebarModal: ISidebarModal | null = null;
  activeScreenId: T;
  readonly screensConfig: ScreensConfig<T>;

  onSidebarModalSet?: (newSidebarModal: ISidebarModal) => void;
  onFirstSidebarModalSet?: (sidebarModal: ISidebarModal) => void;

  readonly screenIds: Record<string, T>;

  constructor(screenIds: Record<string, T>, screensConfig: ScreensConfig<T>) {
    this.screenIds = screenIds;
    this.activeScreenId = screenIds.INITIAL;
    this.screensConfig = screensConfig;
  }
  resetData(): void {
    throw new Error('Method not implemented.');
  }

  get initialScreen() {
    return this.screenIds.INITIAL;
  }

  get activeScreen() {
    return this.screensConfig[this.activeScreenId];
  }

  get isInitialScreen() {
    return this.activeScreenId === this.screenIds.INITIAL;
  }

  resetConditionals = () => {
    this.isDirty = false;
    this.isLoading = false;
    this.showActionPanel = false;
    this.isDisableProceedAction = false;
    this.isError = false;
  };

  dispose = () => {
    console.info(
      'Must override this method in the child class if you want to to use it'
    );
  };

  setSidebarModal = (sidebarModal: ISidebarModal) => {
    this.sidebarModal = sidebarModal;
    this.onSidebarModalSet?.(sidebarModal);

    if (!this.isSidebarModalWasSetFirstTime) {
      this.isSidebarModalWasSetFirstTime = true;
      this.onFirstSidebarModalSet?.(sidebarModal);
    }
  };

  setActiveScreenId = (id: T) => {
    if (this.activeScreenId === id) return;
    this.activeScreenId = id;
  };

  setNextScreenId(id: T) {
    if (this.sidebarModal?.animatedStack.isAnimationRunning) {
      return;
    }
    this.setActiveScreenId(id);
    const nextScreen = this.screensConfig[id];
    const { Component: ScreenComponent } = nextScreen;
    this.sidebarModal?.animatedStack.next(id, ScreenComponent);
  }

  back = async () => {
    const animatedStack = this.sidebarModal?.animatedStack;
    if (animatedStack?.isAnimationRunning || !animatedStack?.canMoveBack) {
      return;
    }
    const prevScreenId = (await this.sidebarModal?.animatedStack.back()) as T;
    this.resetConditionals();
    if (!prevScreenId) return;
    this.setActiveScreenId(prevScreenId);
  };

  reset = () => {
    this.setActiveScreenId(this.initialScreen);
    const animatedStack = this.sidebarModal?.animatedStack;
    animatedStack?.reset();

    const { id: screenId, Component: ScreenComponent } = this
      .activeScreen as StackScreenPayload;

    animatedStack?.setVisible(screenId, ScreenComponent);
  };

  setChangesAlertConfig = (config: ChangesAlertConfig) => {
    this.sidebarModal?.setChangesAlertConfig(config);
  };
}
