/**
 * on safari exists a common bug with the fixed position and the bottomsheet
 * https://stackoverflow.com/questions/37287148/ios-safari-chrome-unwanted-scrolling-when-focusing-an-input-inside-the-modal
 * so these styles can help to fix it
 */
export const stylesSafariFix = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};
