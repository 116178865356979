import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { Modal as FNDModal } from '@10x/foundation/src/components/modal/Modal';
import { classNames } from '@foundationPathAlias/utilities';

import { useAnimatedStack } from '@foundationPathAlias/components/animated-stack';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

const MAX_HEIGHT = 620;

export const _CreateChannelOrGroupModal = () => {
  const animatedStack = useAnimatedStack();

  const {
    createChannelOrGroupStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.createChannelOrGroupStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const [hidePrevStackItem, setHidePrevStackItem] = useState(false);

  const stackState = animatedStack.state;

  const stackVisibleItem = stackState.visibleItem;
  const stackPrevItem = stackState.prevItem;
  const stackNextItem = stackState.nextItem;

  useEffect(() => {
    createChannelOrGroupStore.setAnimatedStack(animatedStack);
  }, [animatedStack]);

  const rootCn = 'w-full xs:justify-normal md:justify-center';
  const contentWrapperCn =
    'xs:p-0 sx:p-0 xs:justify-normal xs:w-full md:w-screen z-8';
  const contentCn = `flex overflow-hidden h-full xs:w-full md:max-w-[620px] md:max-h-[${MAX_HEIGHT}px] md:w-full md:mx-auto`;

  return (
    <FNDModal
      rootInnerWrapperCn={rootCn}
      contentWrapperCn={contentWrapperCn}
      contentCn={contentCn}
      open={createChannelOrGroupStore.active}
      onClose={createChannelOrGroupStore.requestCloseModal}
    >
      <div
        className={classNames(
          'relative flex h-full w-full flex-col',
          isMobile ? 'h-full' : 'overflow-hidden md:rounded-lg md:pt-[40px]'
        )}
      >
        <div className="flex flex-1">
          <div className="relative flex w-[374px] flex-1 overflow-hidden">
            {stackPrevItem.Component && (
              <stackPrevItem.Component
                stackWrapperCn={classNames(
                  '!bg-transparent',
                  hidePrevStackItem && 'hidden'
                )}
                key={stackPrevItem.id}
              />
            )}
            {stackVisibleItem.Component && (
              <stackVisibleItem.Component
                stackWrapperCn="!bg-transparent"
                key={stackVisibleItem.id}
                startLeaving={stackState.startLeaving}
                onLeaveStart={() => {
                  setHidePrevStackItem(false);
                }}
                onLeaveEnd={() => {
                  if (createChannelOrGroupStore.isStackHistoryExisting) {
                    setHidePrevStackItem(true);
                  }
                  animatedStack.onLeavingAnimationEnd();
                }}
              />
            )}

            {stackNextItem.Component && (
              <stackNextItem.Component
                stackWrapperCn="!bg-transparent"
                isNext={true}
                startEntering={stackState.startEntering}
                key={stackNextItem.id}
                onEnterEnd={() => {
                  setHidePrevStackItem(true);
                  animatedStack.onEnterAnimationEnd();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </FNDModal>
  );
};

export const CreateChannelOrGroupModal = observer(_CreateChannelOrGroupModal);
