import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { AuthLoginButton } from './AuthLoginButton';
import { AuthSignUpButton } from './AuthSignUpButton';

export function _AuthPanel() {
  const authStore = useInjection(IOC_TOKENS.authStore);

  if (authStore.logged) {
    return null;
  }
  return (
    <div className="flex space-x-[24px]">
      <AuthLoginButton />
      <AuthSignUpButton />
    </div>
  );
}

export const AuthPanel = observer(_AuthPanel);
