import { BillingStore } from './screens/billing/data/Billing.store.local';
import { CustomizeStore } from './screens/customize/data/Customize.store.local';
import { RolesStore } from './screens/roles/data/Roles.store.local';
import { RulesStore } from './screens/rules/data/Rules.store.local';
import { UsersStore } from './screens/users/data/Users.store.local';

export const ScreenIds = {
  INITIAL: 'initial_menu',
  USERS: 'users',
  ROLES: 'roles',
  RULES: 'rules',
  CUSTOMIZE: 'customize',
  BILLING: 'billing',
};

export const childScreensRegistry = {
  [ScreenIds.USERS]: UsersStore,
  [ScreenIds.ROLES]: RolesStore,
  [ScreenIds.RULES]: RulesStore,
  [ScreenIds.CUSTOMIZE]: CustomizeStore,
  [ScreenIds.BILLING]: BillingStore,
};
