import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { animated, useSpring } from '@react-spring/web';
import { SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames, useLongPress } from '@10x/foundation/src/utilities';
import { CLOUDFLARE_VARIANTS_ENUM } from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';
import { ChannelMessageEdgePayload } from '@10x/foundation/types/graphql-schema';
import { Avatar } from '@foundationPathAlias/components';

import { useFormatCreatedAt } from '@mainApp/src/hooks';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { colorSchema } from '@rootConfig/color-schema';

import MessageEditorParser from './message-editor-parser/MessageEditorParser';
import { MessageAttachmentsView } from './MessageAttachmentsView';
import { MessageMenu } from './MessageMenu';
import { MessageReactionBar } from './MessageReactionBar';
import { MessageUnreadSeparator } from './MessageUnreadSeparator';
import { MessageUserPanel } from './MessageUserPanel';

export type MessagePropsType = {
  messageModel: MessageModel;
  isFirstUnread: boolean;
  onGoToReplyParent: (
    communityId: string,
    channelId: string,
    reply: ChannelMessageEdgePayload
  ) => void;
};

export function _Message(props: MessagePropsType) {
  const { messageModel, isFirstUnread, onGoToReplyParent } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [isMessageHighlighted, setIsMessageHighlighted] = useState(false);
  const rootElRef = useRef<any>();
  const {
    attachmentsMediator,
    dimensionsStore: { isMobile },
    authStore: { logged },
    interactiveStore,
    systemStore: { areReactionsOpen, isDarkTheme },
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.attachmentsMediator,
    IOC_TOKENS.authStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.systemStore,
  ]);

  const highlightColor = isDarkTheme
    ? 'hovered-selected-dark'
    : 'hovered-selected';

  const [springStyles, api] = useSpring(
    () => ({
      from: {
        background: 'transparent',
      },
      config: {
        duration: 1000,
      },
    }),
    []
  );

  const [highlightSpringStyle, highlightSpringStyleApi] = useSpring(
    () => ({
      from: {
        background: 'transparent',
      },
      immediate: true,
    }),
    []
  );

  const handleLongPress = useCallback(() => {
    if (!logged) return;
    interactiveStore.setContent(
      <MessageMenu
        messageModel={messageModel}
        onClose={() => interactiveStore.setBottomSheet(false)}
      />
    );
    interactiveStore.setBottomSheetCn('min-h-[360px] bottom-[0px] pb-[0px]');
    interactiveStore.setBottomSheet(true);

    setIsMessageHighlighted(false);
  }, [logged]);

  const longPressHandlers = useLongPress(handleLongPress as any, 450, {
    onStart: () => {
      if (!logged) return;
      setIsMessageHighlighted(true);
    },
    onMove: () => {
      // it won't be true if the user is a guest
      if (isMessageHighlighted) {
        setIsMessageHighlighted(false);
      }
    },
    onCancel: () => {
      // it won't be true if the user is a guest
      if (isMessageHighlighted) {
        setIsMessageHighlighted(false);
      }
    },
  });

  const closeMenu = () => {
    setShowMenu(false);
  };

  const { t } = useTranslation('common');

  const createdAt = useFormatCreatedAt(messageModel.createdAt);

  useEffect(() => {
    if (messageModel.isParentAndScrollTo) {
      api.start({
        from: { background: 'transparent' },
        to: async (next) => {
          await next({ background: colorSchema[highlightColor] });
          await next({
            background: 'transparent',
          });
        },
        onResolve: () => {
          setTimeout(() => {
            // just off it when the user saw animation and there was an auto scroll
            messageModel.setIsParentAndScrollTo(false);
          }, 1000);
        },
      });
    }
  }, [messageModel.isParentAndScrollTo]);

  useEffect(() => {
    if (isMessageHighlighted) {
      highlightSpringStyleApi.start({
        from: { background: 'transparent' },

        to: { background: colorSchema[highlightColor] },
      });
    } else {
      highlightSpringStyleApi.start({
        to: { background: 'transparent' },
      });
    }
  }, [isMessageHighlighted]);

  if (!messageModel?.serverData) {
    console.error(`No server data in MesasgeModel: ${messageModel}`);
    return null;
  }
  const {
    rawJson = '',
    text = '',
    author,
    parent: reply,
  } = messageModel.serverData;

  const avatarUrl = messageModel.getAuthorAvatar(
    CLOUDFLARE_VARIANTS_ENUM.PUBLIC
  );

  // some of events have a fallback nature so they might be triggered on desktop as well but it shouldn't happen
  const bottomSheetEvents = isMobile
    ? {
        onTouchStart: longPressHandlers.onTouchStart,
        onTouchEnd: longPressHandlers.onTouchEnd,
        onTouchMove: longPressHandlers.onPointerhMove,
        onPointerUp: longPressHandlers.onPointerUp,
        onPointerMove: longPressHandlers.onPointerhMove,
        onClick: longPressHandlers.onPointerUp,
        onPointerOut: longPressHandlers.onPointerUp,
      }
    : {};

  const username = messageModel.authorModel?.standardOrProUsername;
  const replyMessageAuthorModel = messageModel.parentMessageModel?.authorModel;

  const editing = messageModel.editing;

  // TODO: somehow the BE started to return instead of an empty string the "{}"
  const messageState: SerializedEditorState<SerializedLexicalNode> | null =
    Boolean(rawJson) && rawJson !== '{}' ? JSON.parse(rawJson as string) : null;

  return (
    <animated.div
      ref={rootElRef}
      className="relative left-0 w-full"
      style={{ ...springStyles, ...highlightSpringStyle }}
    >
      <MessageUnreadSeparator isFirstUnread={isFirstUnread} />
      <div
        className={classNames(
          'animation-classic relative  px-[20px] py-[8px] ',
          !isMobile && (showMenu || editing)
            ? 'bg-hovered-selected dark:bg-hovered-selected-dark'
            : ''
        )}
        onPointerEnter={(_e) => {
          if (logged && !areReactionsOpen) {
            setShowMenu(!isMobile);
          }
        }}
        onMouseLeave={(_e) => {
          if (showMenu && !areReactionsOpen) {
            setShowMenu(false);
          }
        }}
      >
        {reply?.node && (
          <button
            className="hover-el overflow-hidden; relative mb-[8px] flex max-w-[100%]
            cursor-pointer pl-[35px]"
            onClick={() => {
              onGoToReplyParent(
                messageModel.serverData?.communityId,
                messageModel.serverData?.channelId,
                reply
              );
            }}
          >
            <i className="absolute left-[13px] top-[10px] h-[15px] w-[19px] rounded-tl-[5px] border-[2px] border-b-0 border-r-0 border-element-normal dark:bg-transparent" />

            <div className="themed-text max-w-[100%] overflow-hidden text-ellipsis whitespace-nowrap pl-[24px] text-sm14R leading-[20px]">
              <Avatar
                cn="flex-0 min-w-[24px] left-[36px] absolute"
                width={24}
                height={24}
                src={
                  reply?.node?.author?.imageUrls
                    ? messageModel.getCloudflareUrl(
                        reply?.node?.author?.imageUrls
                      )
                    : undefined
                }
              />
              <span className="ml-[6px] mr-[8px] inline-block text-sm14T leading-[20px] text-text-primary dark:text-text-primary-dark">
                {replyMessageAuthorModel?.standardOrProUsername}
              </span>

              {reply?.node?.text}
            </div>
          </button>
        )}

        <div className="mb-[16px] flex flex-col">
          <div className="flex" {...(bottomSheetEvents as any)}>
            <div className="pr-[8px]">
              <Avatar width={32} height={32} src={avatarUrl} />
            </div>
            <div className="flex-1">
              <MessageUserPanel
                author={author}
                username={username || ''}
                avatarUrl={avatarUrl}
                date={createdAt}
              />
              {messageState ? (
                <MessageEditorParser editorState={messageState} />
              ) : (
                <div className="themed-text text-sm14R leading-[20px]">
                  {text}
                </div>
              )}
              {
                // TODO: refactor. I think it should be memoized
                Boolean(Object.values(messageModel.attachments).length) && (
                  <MessageAttachmentsView
                    optimistic={messageModel.optimistic}
                    data={Object.values(messageModel.attachments)}
                  />
                )
              }
            </div>
          </div>
          <div className="ml-[36px] flex flex-row">
            <MessageReactionBar messageModel={messageModel} />
          </div>
          {messageModel.failed && (
            <p className="mt-[12px] flex items-center text-red-500">
              <ExclamationCircleIcon className="mr-[6px] h-[20px] w-[20px]" />
              {t('couldNotSend')}
              <button
                className="ml-[4px] rounded-[5px] underline"
                onClick={() => {
                  attachmentsMediator.reUploadMessage(messageModel);
                }}
              >
                {t('pressRetry')}
              </button>
            </p>
          )}
        </div>

        {showMenu && (
          <MessageMenu messageModel={messageModel} onClose={closeMenu} />
        )}
      </div>
    </animated.div>
  );
}

export const Message = observer(_Message);
