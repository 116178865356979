import { IAnimatedStackStore } from '@mainApp/src/components/common';

export type SettingIds =
  | 'account'
  | 'display'
  | 'language'
  | 'about'
  | 'subscription'
  | 'logOut';

export enum SettingIdsEnum {
  account = 'account',
  display = 'display',
  language = 'language',
  about = 'about',
  subscription = 'subscription',
  logOut = 'logOut',
}

export interface ISettingStoreBase<T, I> {
  stackStore: IAnimatedStackStore;
  isLoading: boolean;
  error: string | null;
  activeSettingId: T;

  readonly initialSetting: T;
  readonly activeSetting: I;

  setIsLoading: (isLoading: boolean) => void;
  setError: (error: string | null) => void;
  setActiveSetting: (settingId: T) => void;
  setNextSetting: (settingId: T) => void;
  back: () => void;
  renderInitialScreen: () => void;
  reset: () => void;
}
